import restClient from '../../views/lib/restClient'
import auth from '../../views/lib/auth'
//import latticeUtils from '../storeUtils/latticeUtils'

//? See https://stackoverflow.com/a/62537645
function decode_base64(base64_str) {
    return decodeURIComponent(escape(atob(base64_str)))
}


export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {

        async validate(_, configuration) {
            let user = await auth.getUser()
            try {
                let result = await restClient.post(`/v1/users/${user.id}/lattice/structures/consistency`,
                    configuration)
                if (result.data.result) {
                    return result.data.normalized
                } else {
                    throw result.data
                }
            } catch (e) {
                console.log(e)
                throw e
            }
        },

        async visualize(_, configuration) {
            let user = await auth.getUser()
            try {
                let res = await restClient.post(`/v1/users/${user.id}/lattice/structures/visualization-spins`, configuration)
                return decode_base64(res.data.svg)
            } catch (e) {
                console.log('Visualize Lattice Plot:', e.message)
                throw e
            }
        },

    }
}