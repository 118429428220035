export default {
    topics: [
        'quantum-computing',
        'ab-initio-chemistry',
        'periodic-systems-devices'
    ],

    products: [
        'spectrum',
        'active-space-finder',
        'molecule',
        'qolossal',
        'periodic',
        'device-builder',
        'system-bath',
        'open-system-science',
    ],

    toolsAndMethods: [
        'pyscf',
        'gpaw',
        'xtb',
        'dmrg',
        'dft',
        'fci',
        'chebyshev-expansion',
    ],

    systemTypes: [
        'spins',
        'fermions',
        'bosons',
        'open',
        'spinless',
        'spinful',
    ],

    calculationTypes: [
        'single-point',
        'optimization',
        'interacting',
        'non-interacting',
    ],

    quantities: [
        'energy',
        'excited states',
        'homo-lumo',
        'gradient',
        'electron-affinity',
        'charges',
        'lattice-parameters',
        'band-structure',
        'vibrations',
        'mechanical',
        'thermodynamics',
        'electric',
        'magnetic',
        'optical',
        'susceptibility',
        'dos',
        'conductivity',
        'mobility',
    ]
}