import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import restClient from '../../views/lib/restClientNewAPI'
import utils from '../../appUtils/utils.js'
import tokenUtils from '../storeUtils/tokenUtils.js'

const baseURL = 'https://token.' + process.env.VUE_APP_SUBDOMAIN + 'cloud.quantumsimulations.de'

export default {

    namespaced: true,

    state: {
        name: '',
        userGroups: [],
        userPermissions: [],
        excludedPermissions: [
            '_read-transaction',
            '_read-stripe_information',
            '_write-stripe_information',
            '_read-credit',
            '_read-token',
            '_write-token',
            '_delete-token',
            '_role_maintainer',
            '_role_user'
        ],
        permissionsObjects: [],
        userInputPermissions: [],
        token: {},
        tokens: [],
        owner: '',
        isAuthError: false,
    },

    getters: {
        getField,

        getTable: (state) => {
            let table = []
            for (let token of state.tokens) {
                let filteredPermissions = utils.removeSubsetFromArray(token.permissions, state.excludedPermissions)
                let reducedPermissions = tokenUtils.createTablePermissions(filteredPermissions)
                let start = token.token_id.indexOf('.')
                let name = token.token_id.slice(start + 1, token.token_id.length)
                let item = {
                    name: name,
                    createdDate: token.createDate,
                    expireDate: token.expireDate,
                    permissions: reducedPermissions
                }
                table.push(item)
            }
            return table
        },
    },

    mutations: {
        updateField,

        set(state, [type, payload]) {
            Vue.set(state, type, payload)
        },

        setOwner(state, tokens) {
            Vue.set(state, 'owner', tokens[0].owner)
        },

        setPermission(state, [type, permission, value]) {
            let combinedPermission = '_' + permission + '-' + type
            if (value && !state.userInputPermissions.includes(combinedPermission)) {
                state.userInputPermissions.push(combinedPermission)
            } else if (!value) {
                let index = state.userInputPermissions.indexOf(combinedPermission)
                if (index > -1) {
                    state.userInputPermissions.splice(index, 1)
                }
            }
        },

        resetUserInputPermissions(state) {
            Vue.set(state, 'userInputPermissions', [])
        },

        handlePermissions(state, permissions) {
            let filteredPermissions = utils.removeSubsetFromArray(permissions, state.excludedPermissions)
            let permissionsObjects = tokenUtils.createPermissionsAsObjects(filteredPermissions)
            Vue.set(state, 'permissionsObjects', permissionsObjects)
        },
    },

    actions: {
        async load({ commit }) {
            try {
                let result = await restClient.get(
                    `${baseURL}/token`)
                if ('token' in result.data){
                    commit('set', ['tokens', result.data.token])
                    commit('setOwner', result.data.token)
                }else if (result.data.length === 0){
                    commit('set', ['tokens', result.data])
                }
            } catch (error) {
                if (error.response.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                throw (error)
            }
        },

        async loadByID({ commit }, tokenID) {
            commit
            let result = {}
            try {
                result = await restClient.get(
                    `${baseURL}/token/${tokenID}`)
                if (result.data.token.length > 0) {
                    commit('set', ['token', result.data.token])
                }
            } catch (error) {
                if (error.response.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                throw (error)
            }
        },

        async loadPermissionsAndGroups({ commit }) {
            let result = {}
            try {
                result = await restClient.get(
                    `${baseURL}/groups_permissions`)
                if (result.data.groups.length > 0) {
                    commit('set', ['userPermissions', result.data.permissions])
                    commit('set', ['userGroups', result.data.groups])
                    commit('handlePermissions', result.data.permissions)
                }

                return result
            } catch (error) {
                if (error.response.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                throw (error)
            }
        },

        async create({ commit }, [tokenID, config]) {
            let result = {}
            try {
                result = await restClient.post(`${baseURL}/token/${tokenID}`, config)
                return result.data.token
            } catch (error) {
                if (error.response.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                throw (error)
            }
        },

        async delete({ commit }, tokenID) {
            try {
                await restClient.delete(`${baseURL}/token/${tokenID}`)
            } catch (error) {
                if (error.response.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                throw (error)
            }
        },
    }
}
