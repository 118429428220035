<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="4">
          <v-select
            :items="items"
            v-model="type"
            label="Plot Style"
            outlined
          ></v-select>
          <v-sheet>
            {{ JSON.stringify(plotData, null, 4) }}
          </v-sheet>
        </v-col>
        <v-col cols="8">
          <Plotly
            :data="plotData"
            :layout="plotLayout"
            :display-mode-bar="true"
          ></Plotly>
          <!-- <iframe src="http://localhost:8050" width=700 height=600></iframe> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly'

import dataset from './dataset.js'

export default {
  components: {
    Plotly,
  },
  data() {
    return {
      data: [
        {
          x: [1, 2, 3, 4],
          y: [10, 15, 13, 17],
          type: 'scatter',
        },
      ],
      layout: {
        title: 'My graph',
      },
      items: [
        'Scatter',
        '2D Histogram',
        'Pie Graph',
        'Contour graph',
        'Histogram',
        'Ribbon Plot',
        'Mesh Plot',
      ],
      type: 'scatter',
    }
  },

  methods: {
    normal() {
      let x = 0,
        y = 0,
        rds,
        c
      do {
        x = Math.random() * 2 - 1
        y = Math.random() * 2 - 1
        rds = x * x + y * y
      } while (rds == 0 || rds > 1)
      c = Math.sqrt((-2 * Math.log(rds)) / rds) // Box-Muller transform
      return x * c // throw away extra sample y * c
    },

    generateData(N) {
      var a = -1,
        b = 1.2

      var step = (b - a) / (N - 1)
      var t = new Array(N),
        x = new Array(N),
        y = new Array(N)

      for (var i = 0; i < N; i++) {
        t[i] = a + step * i
        x[i] = Math.pow(t[i], 3) + 0.3 * this.normal()
        y[i] = Math.pow(t[i], 6) + 0.3 * this.normal()
      }
      return [x, y]
    },

    generateRandom(n) {
      let x = []
      for (let i = 0; i < n; i++) {
        let a = Math.random()
        x.push(a)
      }
      return x
    },
  },

  computed: {
    plotData() {
      let type = ''
      let data = []
      if (this.type !== '2D Histogram') {
        type = this.type.toLowerCase().split(' ')[0]
      } else {
        type = 'histogram2d'
      }
      if (type === 'scatter') {
        data = [
          {
            x: [1, 2, 3, 4],
            y: [10, 15, 13, 17],
            type: type,
          },
          {
            x: [1, 2, 3, 4],
            y: [16, 5, 11, 9],
            type: type,
          },
        ]
      } else if (type === 'contour') {
        data = [
          {
            x: [-9, -6, -5, -3, -1],
            z: [
              [10, 10.625, 12.5, 15.625, 20],
              [5.625, 6.25, 8.125, 11.25, 15.625],
              [2.5, 3.125, 5, 8.125, 12.5],
              [0.625, 1.25, 3.125, 6.25, 10.625],
              [0, 0.625, 2.5, 5.625, 10],
            ],
            y: [0, 1, 4, 5, 7],
            type: type,
            contours: {
              start: 2,
              end: 18,
              size: 2,
            },
            autocontour: false,
          },
        ]
      } else if (type === 'histogram') {
        let x = dataset[0].x
        data = [
          {
            x: x,
            type: type,
          },
        ]
      } else if (type === 'pie') {
        data = [
          {
            values: [40, 10, 30],
            rotation: 0,
            textinfo: 'label',
            marker: {
              colors: ['orange', 'yellow', 'blue'],
            },
            labels: ['remaining', 'doing', 'done'],
            hoverinfo: 'label' + 'value' + 'percent',
            hole: 0.5,
            type: type,
            showlegend: true,
          },
        ]
      } else if (type === 'histogram2d') {
        let x = this.generateData(100)[0]
        let y = this.generateData(100)[1]
        data = [
          {
            x: x,
            y: y,
            mode: 'markers',
            name: 'points',
            marker: {
              color: 'rgb(102,0,0)',
              size: 2,
              opacity: 0.4,
            },
            type: 'scatter',
          },
          {
            x: x,
            y: y,
            name: 'density',
            ncontours: 20,
            colorscale: 'Hot',
            reversescale: true,
            showscale: false,
            type: 'histogram2dcontour',
          },
          {
            x: x,
            name: 'x density',
            marker: {
              color: 'rgb(102,0,0)',
            },
            yaxix: 'y2',
            type: 'histogram',
          },
          {
            y: y,
            name: 'y density',
            marker: {
              color: 'rgb(102,0,0)',
            },
            xaxix: 'x2',
            type: 'histogram',
          },
        ]
      } else if (type === 'ribbon') {
        data = [
          {
            x: dataset.dataset[0].x,
            y: dataset.dataset[0].y,
            z: dataset.dataset[0].z,
            name: '',
            colorscale: 'oranges',
            type: 'surface',
            showscale: false,
          },
          {
            x: dataset.dataset[1].x,
            y: dataset.dataset[1].y,
            z: dataset.dataset[1].z,
            name: '',
            colorscale: 'oranges',
            type: 'surface',
            showscale: false,
          },
          {
            x: dataset.dataset[2].x,
            y: dataset.dataset[2].y,
            z: dataset.dataset[2].z,
            name: '',
            colorscale: 'oranges',
            type: 'surface',
            showscale: false,
          },
          {
            x: dataset.dataset[3].x,
            y: dataset.dataset[3].y,
            z: dataset.dataset[3].z,
            name: '',
            colorscale: 'oranges',
            type: 'surface',
            showscale: false,
          },
          {
            x: dataset.dataset[4].x,
            y: dataset.dataset[4].y,
            z: dataset.dataset[4].z,
            name: '',
            colorscale: 'oranges',
            type: 'surface',
            showscale: false,
          },
        ]
      } else if (type === 'mesh') {
        data = [
          {
            x: this.generateRandom(50),
            y: this.generateRandom(50),
            z: this.generateRandom(50),
            type: 'mesh3d',
            opacity: 0.8,
            color: 'rgb(300,100,200)',
          },
        ]
      }
      return data
    },

    plotLayout() {
      let layout = {}
      let type = ''
      if (this.type !== '2D Histogram') {
        type = this.type.toLowerCase().split(' ')[0]
      } else {
        type = 'histogram2d'
      }
      if (type === 'scatter') {
        layout = {
          plot_bgcolor: '#d3d3d3',
          paper_bgcolor: '#d3d3d3',
          title: 'Scatter Graph Example',
        }
      } else if (type === 'contour') {
        layout = {
          title: 'Contour Graph Example',
        }
      } else if (type === 'histogram') {
        layout = {
          title: 'Histogram Example',
        }
      } else if (type === 'pie') {
        layout = {
          font: { color: '#d3d3d3' },
          title: 'Pie Graph Example',
          plot_bgcolor: 'Wheat',
          paper_bgcolor: 'LightSteelBlue',
        }
      } else if (type === 'histogram2d') {
        layout = {
          showlegend: false,
          margin: { t: 50 },
          hovermode: 'closest',
          bargap: 0,
          xaxis: {
            domain: [0, 0.85],
            showgrid: false,
            zeroline: false,
          },
          yaxis: {
            domain: [0, 0.85],
            showgrid: false,
            zeroline: false,
          },
          xaxis2: {
            domain: [0.85, 1],
            showgrid: false,
            zeroline: false,
          },
          yaxis2: {
            domain: [0.85, 1],
            showgrid: false,
            zeroline: false,
          },
        }
      } else if (type === 'ribbon') {
        layout = {
          title: 'Ribbon Plot Example',
          showlegend: false,
          autosize: true,
          width: 600,
          height: 600,
          scene: {
            xaxis: { title: 'Sample #' },
            yaxis: { title: 'Wavelength' },
            zaxis: { title: 'OD' },
          },
        }
      }

      return layout
    },
  },
}
</script>
