import dynamicFormsUtils from '../storeUtils/dynamicFormsUtils.js'
import utils from '../../appUtils/utils.js'

import Vue from 'vue'
import lodash from 'lodash'
import deepdash from 'deepdash-es'
const _ = deepdash(lodash)

export default {

    namespaced: true,

    state: {
        structureCards: [],
        currentStructureSchemaList: ['molecule', 'crystal', 'hamiltonian', 'device', 'lattice_configuration'],
        currentStructureCard: {},
        currentTopic: {},
        originalSchema: {},
        normalizedSchema: {},
        dataNode: {},
        userInput: {},
        dynamicHeader: [],
        showChild: false,
        editPath: [],
        hasOpenWritingCycle: false,
        edit: false,
    },

    getters: {

        getAllStructureCardLabels: state => {
            return state.structureCards.map(e => e = e.label)
        },

        getValue: state => path => {
            return _.cloneDeep(state.userInput)[path[0]]
        },

        getMultipleValue: state => path => {
            let last = path.pop()
            path = path.filter(element => typeof element === 'string')
            let target = dynamicFormsUtils.iterateUserInput(path, _.cloneDeep(state.userInput))
            return 'temp' in target ? target.temp[last] : -1
        },

        getNestedValue: state => path => {
            let target = _.cloneDeep(state.userInput)
            path.forEach(e => { target = target[e] })
            return target
        },

        getListValueFromMultiple: state => path => {
            let pathForListIteration = dynamicFormsUtils.getPathForListIteration(_.cloneDeep(path))
            let pathForUserInputObjectIteration = dynamicFormsUtils.getPathForUserInputIteration(_.cloneDeep(path))
            let target = dynamicFormsUtils.iterateUserInput(
                pathForUserInputObjectIteration, _.cloneDeep(state.userInput)
            )
            pathForListIteration.forEach(e => { target = target[e] })
            return target
        },

        getTable: state => (path) => {
            let table = []
            let target = dynamicFormsUtils.findMultipleInUserInput(path, _.cloneDeep(state.userInput), state.editPath)
            for (let [index, tableRow] of Object.entries(target.values)) {
                tableRow = dynamicFormsUtils.formatTableRow(tableRow)
                tableRow.id = index
                table.push(tableRow)
            }
            return table
        },

        getDataNode: (state) => {
            let schema = _.cloneDeep(state.originalSchema)
            let data = dynamicFormsUtils.createResultNodes(
                schema.definition,
                _.cloneDeep(state.userInput),
                state.normalizedSchema
            )
            data.hqschema = { label: schema.label, version: schema.version }
            Vue.set(state, 'dataNode', data)
            return data
        },

    },

    mutations: {

        set(state, [type, payload]) {
            state[type] = payload
        },

        setStructureCard(state, name) {
            let currentCard = state.structureCards.find(card => card.name === name)
            Vue.set(state, 'currentStructureCard', currentCard)
        },

        createUserInputFromSchema(state) {
            state.editPath = []
            state.hasOpenWritingCycle = false
            let userInputObject = {}
            dynamicFormsUtils.createUserInputFromNodes(userInputObject, state.normalizedSchema.nodes)
            Vue.set(state, 'userInput', userInputObject)
        },

        createStructureCards(state, structureSchemas) {
            let cards = []
            let schemas = structureSchemas.filter(e => state.currentStructureSchemaList.includes(e.label))
            for (let schema of schemas) {
                let card = {
                    label: schema.label,
                    name: utils.convertToPathNotation(schema.label),
                    title: utils.formatLabel(schema.label),
                    description: schema.doc,
                    schema: schema.label,
                    schemaVersion: schema.version,
                }
                cards.push(card)
            }
            Vue.set(state, 'structureCards', cards)
        },

        createUserInputFromDataNode(state, data) {
            let dataNode = _.cloneDeep(data)
            for (let key of Object.keys(dataNode)) {
                if (key === 'hqschema' || key.charAt(0) === '_') {
                    delete dataNode[key]
                }
                // look for multiples and handle them
                if (Array.isArray(dataNode[key]) && dataNode[key]
                    .every(e => typeof e === 'object' && !(Array.isArray(e)))) {
                    dataNode[key] = dynamicFormsUtils.createMultiplefromDataNode(dataNode[key])
                }
            }
            Vue.set(state, 'userInput', dataNode)
        },

        populateTempWithValueToEdit(state, path) {
            let clippedPath = dynamicFormsUtils.extractMultiplePath(_.cloneDeep(path))
            if (state.editPath.length === 0 && clippedPath.length > 1 && !state.hasOpenWritingCycle) {
                path.pop()
                while (path.length > 0) {
                    let currentPath = _.cloneDeep(path)
                    let index = currentPath.pop()
                    let valuesTarget = dynamicFormsUtils.iterateUserInput(currentPath, state.userInput)
                    if (valuesTarget.values.length > 0) {
                        valuesTarget.temp = _.cloneDeep(valuesTarget.values[index])
                    }
                    path.length = path.length - 2
                }
                state.hasOpenWritingCycle = true
            }
        },

        /* 
            Prepares userInput for adding a multiple item by populating 
            its temp object with the nessesary key/ valuey pairs of the node
        */
        prepareAddingMultipleItem(state, [path, nodes]) {
            state.editPath.push('add')
            let target = state.userInput
            if (path.length > 1) {
                path = path.filter(element => typeof element === 'string')
            }
            target = dynamicFormsUtils.iterateUserInput(path, target)
            target = target.temp
            dynamicFormsUtils.createUserInputFromNodes(target, nodes)
        },

        /*
            Prepares userInput for editing a multiple itemby placing the 
            item to edit from values array into the temp object of the node 
        */
        prepareEditingMultipleItem(state, [index, path]) {
            state.editPath.push(index)
            let target = state.userInput
            if (path.length > 1) {
                path = path.filter(item => typeof item === 'string')
            }
            target = dynamicFormsUtils.iterateUserInput(path, target)
            target.temp = _.cloneDeep(target.values[index])
        },

        setValue(state, [path, value]) {
            value = dynamicFormsUtils.replaceNaN(value)
            state.userInput[path[0]] = value
        },

        setMultipleValue(state, [path, value]) {
            value = dynamicFormsUtils.replaceNaN(value)
            let last = path.pop()
            path = path.filter(element => typeof element === 'string')
            let target = dynamicFormsUtils.iterateUserInput(path, state.userInput)
            target = target.temp
            target[last] = value
        },

        setDictValue(state, [path, value]) {
            value = dynamicFormsUtils.replaceNaN(value)
            let target = state.userInput
            let last = path.pop()
            for (let name of path) {
                target = target[name]
            }
            target[last] = value
        },

        setListValue(state, [path, value, index]) {
            let target = state.userInput
            let first = path.shift()
            target = target[first]
            dynamicFormsUtils.setListValue(target, path, value, index)
        },

        setListValueInMultiple(state, [path, value, index]) {
            let idx = path.reverse().findIndex(element => typeof element === 'string')
            let userInputIterationPath = path.slice(idx).reverse()
            let listIterationPath = path.slice(0, idx).reverse()
            let target = dynamicFormsUtils.iterateUserInput(userInputIterationPath, state.userInput)
            dynamicFormsUtils.setListValue(target, listIterationPath, value, index)
        },

        applyMultipleItem(state, path) {
            let currentUserInput = _.cloneDeep(state.userInput)
            if (state.editPath.length > 1 || state.hasOpenWritingCycle) {
                path = path.filter(element => typeof element === 'string')
            }
            let target = dynamicFormsUtils.iterateUserInput(path, currentUserInput)
            if (state.editPath.at(-1) === 'add') {
                target.values.push(_.cloneDeep(target.temp))
            } else if (typeof state.editPath.at(-1) === 'number') {
                target.values[state.editPath.at(-1)] = _.cloneDeep(target.temp)
            }
            target.temp = {}
            if (state.editPath.length > 0) {
                state.editPath.pop()
            }
            if (state.editPath.length === 0) {
                state.hasOpenWritingCycle = false
            }
            Vue.set(state, 'userInput', _.cloneDeep(currentUserInput))

        },

        moveTempIntoValues(state, path) {
            if (state.editPath.length === 0 && path.length > 1) {
                path.pop()
                while (path.length > 0) {
                    let currentPath = _.cloneDeep(path)
                    let index = currentPath.pop()
                    currentPath = _.cloneDeep(currentPath.filter(element => typeof element === 'string'))
                    let valuesTarget = dynamicFormsUtils.iterateUserInput(currentPath, state.userInput)
                    valuesTarget.values[index] = _.cloneDeep(valuesTarget.temp)
                    valuesTarget.temp = {}
                    path.length = path.length - 2
                }
                state.hasOpenWritingCycle = false
            }
        },

        removeItem(state, [path, index]) {
            let target = state.userInput
            if (state.editPath.length > 0) {
                path = path.filter(element => typeof element === 'string')
            }
            target = dynamicFormsUtils.iterateUserInput(path, target)
            target.values.splice(index, 1)
            state.showChild = false
        },

        reset(state) {
            state.dynamicHeader = []
            state.hasOpenWritingCycle = false
            state.editPath = []
            state.showChild = false
        },

        wipeForm(state) {
            Vue.set(state, 'normalizedSchema', {})
            Vue.set(state, 'userInput', {})
            Vue.set(state, 'originalSchema', {})
            Vue.set(state, 'dataNode', {})
            state.dynamicHeader = []
            state.hasOpenWritingCycle = false
            state.editPath = []
            state.showChild = false
        },

        wipeTemporaryNestedFormMultiple(state, path) {
            let target = state.userInput
            target = dynamicFormsUtils.iterateUserInput(path, target)
            target.temp = {}
            if (path.length === 1) {
                state.editPath = []
            } else {
                state.editPath.pop()
            }
        },

        normalizeSchema(state, [originalSchema, isJSON]) {
            if (isJSON) {
                originalSchema = JSON.parse(originalSchema)
            }
            let schema = {}
            schema['label'] = utils.formatLabel(originalSchema.label)
            schema['nodes'] = dynamicFormsUtils.createNormalizedNodes(originalSchema.definition)
            Vue.set(state, 'normalizedSchema', _.cloneDeep(schema))
            Vue.set(state, 'originalSchema', _.cloneDeep(originalSchema))
        },

    },
}
