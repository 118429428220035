<template>
  <div id="changelog" class="page-margin-left page-margin-top text">
    <a class="anchor" href="#changelog"></a>
    <v-container>
      <v-row>
        <v-col>
          <h1>Recent Changes</h1>

          <h2>Tuesday, November 22 2022</h2>
          Workflow based relaunch:
          <ul>
            <li>
              New applications:
              <ul>
                <li>Chemistry</li>
                <li>Quantum Computing</li>
              </ul>
            </li>
            <li>Temporary removal of Lattice application.</li>
            <li>New result visualization and download.</li>
          </ul>


          <h2>Friday, August 19 2022</h2>
          New in Lattice:
          <ul>
            <li>
              Introduction of new parameter for spins and spinful fermions: Jd.
            </li>
            <li>Merge of intra and inter-cell bonds in UI.</li>
            <li>Anormalous BCS Greensfunctions was added to the backend.</li>
            <li>New Schrieffer-Wolff enabled Fermions class.</li>
          </ul>
          Bugfix in Lattice: 
          <ul>
            <li>
              Bond and site tables do now change accordingly after conversion
              between spinful and spinless.
            </li>
          </ul>

          <h2>Friday, June 3 2022</h2>
          <ul>
            <li>Visualization of fermions parameters in lattice plot.</li>
            <li>Download of bandstructure plot as png.</li>
            <li>
              Changes in data protection declaration
              <router-link :to="{ name: 'privacy' }"> (privacy) </router-link>.
            </li>
          </ul>

          <h2>Wednesday, February 9 2022</h2>
          <ul>
            <li>New frontend including band structure previews.</li>
            <li>Gallery extended with large scale plots.</li>
            <li>Wave packets are enabled in the backend.</li>
            <li>
              Missing off-diagonal elements of correlators of larger systems are
              now evaluated.
            </li>
          </ul>

          <h2>Wednesday, December 22 2021</h2>
          <ul>
            <li>
              Lattice builder update including the band structure functionality.
            </li>
            <li>Gallery with band structure plots added.</li>
            <li>Better and stricter input validation.</li>
            <li>
              Single particle wave packets added to the initial state
              preparation in the backend.
            </li>
            <li>Lindblad solver extended.</li>
          </ul>

          <h2>Tuesday, December 21 2021</h2>
          <ul>
            <li>
              New Layout with differentiation between applications and
              structures
            </li>
            <li>
              Integration of unit-cell and system into one structure type.
            </li>
            <li>Dedicated dashboard for lattice application.</li>
            <li>
              Dedicated dashboards for spins structures and fermion structures.
            </li>
            <li>Restructuring of UI for spinful and spinless fermions.</li>
            <li>Integration of bandstructure plot for fermions.</li>
            <li>Dashboard for all jobs.</li>
            <li>Visualization of job states via charts.</li>
          </ul>

          <h2>Friday, September 17 2021</h2>
          <ul>
            <li>Correlated hopping RDMs added.</li>
            <li>Lindblad time evolution enabled for spin systems.</li>
            <li>Improved lattice builder.</li>
            <li>
              Release of new Feature Package for Spin Systems.
              <ul>
                <li>Visualization of bond interaction in lattice plot.</li>
                <li>Interactive legend for plot informations and settings.</li>
              </ul>
            </li>
          </ul>

          <h2>Thursday, Mai 20 2021</h2>
          <ul>
            <li>
              Thermofield approach for spin systems in the canonical ensemble
              enabled in the backend.
            </li>
            <li>New lattice builder is enabled.</li>
            <li>
              Imaginary part of the local BCS pairing phase gets evaluated.
            </li>
            <li>Spinless/spinful DMRG solver interfaces are unified.</li>
            <li>DMRG rev. 1817.</li>
          </ul>

          <h2>Thursday, April 8 2021</h2>
          <ul>
            <li>Improving the usability in the Spin Systems UI.</li>
            <li>
              Enable the thermo field approach for spinless fermions and spin
              systems in the backend.
            </li>
            <li>DMRG rev. 1806.</li>
          </ul>

          <h2>Thursday, April 1 2021</h2>
          <ul>
            <li>Fix of the input of the Bz magnetic field</li>
          </ul>

          <h2>Thursday, March 25 2021</h2>
          <ul>
            <li>Enabling of the new Spin UI</li>
          </ul>

          <h2>Thursday, February 25 2021</h2>
          <ul>
            <li>
              Cluster Greens functions enabled for BCS type spinful fermion
              systems.
            </li>
            <li>Real and imaginary time evolution added to the backend.</li>
          </ul>

          <h2>Tuesday, December 22 2020</h2>
          <ul>
            <li>
              Chebyshev based Cluster Greensfunctions enabled within the HQS
              Cloud API.
            </li>
          </ul>

          <h2>Thursday, December 3 2020</h2>
          <ul>
            <li><code>'spinful'</code> token removed from the input.</li>
            <li>
              Chebyshev based Cluster Greensfunctions enabled within the
              backend.
            </li>
          </ul>

          <h2>Thursday, November 19 2020</h2>
          <ul>
            <li>
              Index reordering of the 1RDMs and correlators: such that
              <i>E</i> = Tr( <i>h &rho;</i> )
            </li>
            <li>Combined inverse BCS-HF for spinless fermions.</li>
          </ul>

          <h2>Thursday, November 5 2020</h2>
          <ul>
            <li>Arbitrary anormalous BCS pairing terms enabled.</li>
          </ul>

          <h2>Thursday, October 22 2020</h2>
          <ul>
            <li>Fix of non-integer lattice vectors.</li>
            <li>On-site singlet BCS pairing enabled.</li>
          </ul>

          <h2>Tuesday, October 6 2020</h2>
          <ul>
            <li>Fix the imaginary part of the spin-orbit coupling.</li>
            <li>Spinless BCS pairing enabled.</li>
          </ul>

          <h2>Thursday, August 27 2020</h2>
          <ul>
            <li>Correct the imaginary part of the up-down 1RDM.</li>
            <li>Correct the consistency checker.</li>
          </ul>

          <h2>Wednesday, July 30 2020</h2>
          <ul>
            <li>Spin systems enabled.</li>
            <li>tJ model enabled.</li>
            <li>Spin-orbit coupling enabled.</li>
            <li>Complex hopping enabled.</li>
            <li>Magnetic fields in x, y, and z direction enabled.</li>
            <li>Frozen magnon example.</li>
          </ul>

          <h2>Wednesday, May 20 2020</h2>
          <ul>
            <li>
              DMRG_quality and DMRG_quality_sum as a DMRG accuracy measure
              added.
            </li>
            <li>Potential adaption improved.</li>
            <li>Spinful non-local <i>U</i> fixed.</li>
          </ul>

          <h2>Thursday, May 7 2020</h2>
          <ul>
            <li>
              S<sup>+</sup> S<sup>-</sup> + S<sup>-</sup> S<sup>+</sup>
              correlation function added.
            </li>
          </ul>

          <h2>Tuesday, April 28 2020</h2>
          <ul>
            <li>
              Effective cluster-Zeeman field for cluster S<sup>z</sup> adaption
              added.
            </li>
            <li>
              Infinite lattice S<sup>z</sup> adapation during the DMRG infinite
              lattice warm-up sweep enhanced.
            </li>
            <li>Boltzmann weights Z<sub>n</sub> added to output.</li>
          </ul>

          <h2>Thursday, April 16 2020</h2>
          <ul>
            <li>Fix an issue with the embedding for degenerate systems.</li>
            <li>Allow particle numbers above half filling.</li>
          </ul>

          <h2>Thursday, April 09 2020</h2>
          <ul>
            <li>Inverse density functional theory (iDFT) enabled.</li>
            <li>Inverse Fock density functional theory (iFDFT) enabled.</li>
            <li>Several correlation functions corrected.</li>
          </ul>

          <h2>Tuesday, March 10 2020</h2>
          <ul>
            <li>
              <strong>SCCE:</strong> Self-consistent embedding with system size
              &gt; [1, 1, 1] available.
            </li>
            <li>
              <strong>iHF:</strong> Use the inverse Hartree-Fock method to run
              the self-consistent embedding scheme.
            </li>
          </ul>

          <h2>Friday, March 06 2020</h2>
          <ul>
            <li>
              <strong
                >Control the following parameters in your simulations:</strong
              >
              <ul>
                <li>Particle number</li>
                <li>Spinful and spinless</li>
                <li>Total spin Sz</li>
                <li>Number of states to solve for</li>
                <li>
                  Boundary conditions (periodic, hard-wall, anti-periodic)
                </li>
              </ul>
            </li>
            <li>
              <strong>DMRG solver:</strong> Run quantum mechanical simulations
              of your models using a DMRG code on AWS cloud resources.
            </li>
            <li>
              <strong>Boundary conditions:</strong> Choose between periodic,
              anti-periodic, and hard-wall boundary conditions.
            </li>
            <li>
              <strong>Hubbard-like models:</strong> Build 1- and 2-dimensional
              Hubbard-like systems with arbitrary hoppings and density-density
              interactions on a lattice in 1- and 2-dimensions.
            </li>
            <li>
              <strong>2D-Lattice:</strong> Build lattice models in 2-dimensions.
            </li>
            <li>
              <strong>1D-Lattice:</strong> Build lattice models in 1-dimension.
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
h1 {
  margin-bottom: 30pt;
}
h2 {
  margin-top: 18pt;
  margin-bottom: 6pt;
}
code {
  font-weight: inherit;
  color: inherit;
  box-shadow: none;
  background: none;
}
</style>

<script>
export default {}
</script>
