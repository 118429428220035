import utils from '../../appUtils/utils.js'
import lodash from 'lodash'
import deepdash from 'deepdash-es'
const _ = deepdash(lodash)

export default {

    iterateUserInput(path, target) {
        for (let [i, name] of path.entries()) {
            if (name === path.at(-1)) {
                target = target[name]
            } else if (typeof name === 'string' && typeof path[i + 1] === 'number') {
                if (target[name].values.length === 0 || path[i + 1] === -1) {
                    target = target[name].temp
                } else {
                    target = target[name].values[path[i + 1]]
                }
            } else if (typeof name === 'string' && typeof path[i + 1] !== 'number') {
                target = 'temp' in target[name] ? target[name].temp : target[name]
            }
        }
        return target
    },

    /*
    pathes for dicts do not contain numbers, unless a dict contains a list or a multiple
     */
    extractMultiplePath(path) {
        const index = _.findIndex(path, function (e) {
            return typeof e == 'number'
        })
        return index === -1 ? [path.at(-1)] : path.slice(index - 1, path.length)
    },

    createListValue(nodes) {
        let list = []
        for (let node of nodes) {
            if ('default' in node) {
                list.push(node.default)
            } else if (node.type === 'list') {
                let nestedList = this.createListValue(node.nodes)
                list.push(nestedList)
            } else {
                list.push('')
            }
        }
        return list
    },

    setListValue(target, path, value, index) {
        for (let name of path) {
            if (Array.isArray(target[name])) {
                path.push(index)
                target = target[name]
            } else {
                target[name] = value
            }
        }
    },

    getPathForListIteration(path) {
        let indexOfLastString = path.reverse().findIndex(element => typeof element === 'string')
        return path.slice(0, indexOfLastString).reverse()
    },

    getPathForUserInputIteration(path) {
        let indexOfLastString = path.reverse().findIndex(element => typeof element === 'string')
        return path.slice(indexOfLastString).reverse()

    },

    findMultipleInUserInput(path, userInput, editPath) {
        if (editPath.length > 0) {
            path = path.filter(item => typeof item === 'string')
        }
        return this.iterateUserInput(path, _.cloneDeep(userInput))
    },

    formatTableRow(tableRow) {
        let formattedRow = {}
        for (let [key, value] of Object.entries(tableRow)) {
            if (Array.isArray(value)) {
                formattedRow[key] = JSON.stringify(value).replace(/"/g, '').replace(/,/g, ', ')
            } else if (!Array.isArray(value) && typeof value === 'object') {
                formattedRow[key] = {}
            } else {
                formattedRow[key] = value
            }
        }
        return formattedRow
    },

    getChildNodes(path, nodes) {
        path = path.filter(item => typeof item === 'string')
        let first = path.shift()
        if (nodes.length === undefined) {
            return []
        } else {
            for (let node of nodes) {
                if (first === node.name) {
                    nodes = node.nodes
                    if (path.length > 0) {
                        return this.getChildNodes(_.cloneDeep(path), _.cloneDeep(nodes))
                    } else if (path.length === 0) {
                        return nodes
                    }
                }
            }
        }
    },

    getNestedHeaders(path, nodes) {
        let nestedHeaders = []
        path = path.filter(item => typeof item === 'string')
        let childNodes = this.getChildNodes(path, nodes)
        childNodes.forEach(e => {
            if (e.type === 'multiple') {
                nestedHeaders.push(e.name)
            }
        })
        return nestedHeaders
    },

    createUserInputFromNodes(target, nodes) {
        for (let node of nodes) {
            if (node.type !== 'multiple' && node.type !== 'dict') {
                let value = this.createSimpleValue(node)
                target[node.name] = value
            } else if (node.type === 'dict') {
                target[node.name] = {}
                if ('nodes' in node) {
                    this.createUserInputFromNodes(target[node.name], node.nodes)
                }
            } else if (node.type === 'multiple') {
                target[node.name] = { temp: {}, values: [] }
            }
        }
    },

    createSimpleValue(node) {
        if ('default' in node) {
            return node.default
        } else {
            if (node.type === 'text' || node.type === 'select') { return '' }
            else if (node.type === 'number') { return '' }
            else if (node.type === 'complex') { return ['', ''] }
            else if (node.type === 'vector') { return ['', '', ''] }
            else if (node.type === 'checkbox') { return false }
            else if (node.type === 'list') { return this.createListValue(node.nodes) }
        }

    },

    /*
        Takes listed nested dict part of data and
        creates a the corresponding part of a userInput object
        for the store from it.
    */
    createMultiplefromDataNode(data) {
        let result = {
            temp: {},
            values: []
        }
        for (let item of data) {
            for (let [key, value] of Object.entries(item)) {
                if (Array.isArray(item[key]) && item[key].every(e => typeof e === 'object')) {
                    let nestedValues = _.cloneDeep(value)
                    item[key] = {
                        temp: {},
                        values: nestedValues
                    }
                    this.createMultiplefromDataNode(nestedValues)
                }
            }
        }
        result.values = data
        return result
    },

    prepareNode(defNode, node) {
        if (defNode.type !== 'boolean') {
            if ('required' in defNode) {
                node['required'] = defNode.required
            } else {
                node['required'] = true
                defNode['required'] = true
            }
        }
        if (defNode.type === 'dict' || defNode.type === 'list') {
            if (!('empty' in defNode)) {
                node['empty'] = true
                defNode['empty'] = true
            } else {
                node['empty'] = defNode.empty
            }
        }
        if ('default' in defNode) { node['default'] = defNode.default }
    },

    setNodeType(defNode, node) {
        if ('allowed' in defNode) {
            node.type = 'select'
        }
        else if (defNode.type === 'list' && 'schema' in defNode) {
            node.type = 'multiple'
        }
        else if (defNode.type === 'list' && defNode.items.length === 3
            && defNode.items.every(item => item.type === 'float' || item.type === 'integer')) {
            node.type = 'vector'
        }
        else if (defNode.type === 'boolean') {
            node.type = 'checkbox'
        }
        else if (defNode.type === 'integer' || defNode.type === 'float') {
            node.type = 'number'
        }
        else if (defNode.type === 'string' && !('allowed' in defNode)) {
            node.type = 'text'
        } else {
            node.type = defNode.type
        }
    },

    addUnit(defNode, node) {
        if (node.type === 'number'
            || node.type === 'vector'
            || node.type === 'complex'
            || node.type === 'list'
        ) {
            node.unit = defNode.meta.unit ? defNode.meta.unit : ''
        }
    },

    createNormalizedNodes(definition) {
        let nodes = []
        for (let key of Object.keys(definition)) {
            if (key.charAt(0) !== '_' && key !== 'hqschema') {
                let defNode = definition[key]
                let node = {
                    name: key,
                    label: utils.formatLabel(key),
                    type: '',
                    doc: defNode.meta.doc,
                    hint: defNode.meta.hint ? defNode.meta.hint : ''
                }
                this.prepareNode(defNode, node)
                this.setNodeType(defNode, node)
                this.addUnit(defNode, node)
                // create select node
                if ('allowed' in defNode) {
                    node['items'] = defNode.allowed
                }
                if (node.type === 'multiple') {
                    node['minlength'] = definition[key]['minlength'] ?? null
                    node['maxlength'] = definition[key]['maxlength'] ?? null
                }
                if (node.type === 'dict' || node.type === 'multiple') {
                    let childNodes = node.type === 'multiple'
                        ? this.createNormalizedNodes(defNode.schema.schema)
                        : this.createNormalizedNodes(defNode.schema)
                    node['nodes'] = childNodes
                }
                else if (node.type === 'list') {
                    node['nodes'] = this.createNormalizedListNodes(
                        defNode.items, node.required, node.empty
                    )
                } else if (node.type === 'vector' || node.type === 'complex') {
                    let hints = []
                    defNode.items.forEach(e => hints.push(e.meta.hint ? e.meta.hint : ''))
                    node.hints = hints
                }
                nodes.push(node)
            }
        }
        return nodes
    },

    createNormalizedListNodes(items, required, empty) {
        let nodes = []
        for (let [i, item] of items.entries()) {
            let node = {}
            node['required'] = required
            node['empty'] = empty
            if ('default' in item) {
                node['default'] = item.default
            }
            if (item.type !== 'list') {
                this.setNodeType(item, node)
                node['id'] = i
                nodes.push(node)
            } else {
                let newItems = item.items
                let newNodes = this.createNormalizedListNodes(newItems, required, empty)
                node['type'] = 'list'
                node['nodes'] = newNodes
                nodes.push(node)
            }
        }
        return nodes
    },

    checkForEmptyStrings(nodes, value) {
        for (let node of nodes) {
            if (node.type === 'list' || node.type === 'vector' || node.type === 'complex') {
                let [hasEmpty, isEmpty] = this.checkArrayForEmptyStrings(value[node.name])
                return [hasEmpty, isEmpty, node.name]
            } else {
                return [false, false, node.name]
            }
        }
    },

    // checks possibly nested Arrays for empty string values
    checkArrayForEmptyStrings(array, hasEmpty = false, isEmpty = true) {
        for (let item of array) {
            if (Array.isArray(item)) {
                [hasEmpty, isEmpty] = this.checkArrayForEmptyStrings(item, hasEmpty, isEmpty)
            } else if (item === '') {
                hasEmpty = true
            } else {
                isEmpty = false
            }
        }
        return [hasEmpty, isEmpty]
    },

    checkDictForEmpty(dict, isEmpty = true) {
        for (let key of Object.keys(dict)) {
            if (typeof dict[key] === 'object' && !Array.isArray(dict[key]) && !_.isEmpty(dict[key])) {
                isEmpty = this.checkDictForEmpty(dict[key], isEmpty)
            }
            else if (typeof dict[key] !== 'object' || Array.isArray(dict[key])) {
                if (Array.isArray(dict[key])) {
                    let [isEmptyArray, hasEmptyArray] = this.checkArrayForEmptyStrings(dict[key])
                    if (!isEmptyArray || !hasEmptyArray) { isEmpty = false }
                } else if (dict[key] !== '') {
                    isEmpty = false
                }
            }
        }
        return isEmpty
    },

    // checks dicts, multiples, vectors and complex value nodes for empty string values
    checkForEmpty(node, value) {
        let hasEmpty
        let isEmpty
        if (node.type === 'complex'
            || (node.type === 'list' && !('schema' in node))) {
            [hasEmpty, isEmpty] = this.checkArrayForEmptyStrings(value)
        } else if (node.type === 'dict') {
            isEmpty = this.checkDictForEmpty(value)
            hasEmpty = isEmpty
        } else if (node.type === 'list' && 'schema' in node) {
            isEmpty = false
            hasEmpty = false
            if (value.values.length === 0) {
                isEmpty = true
                hasEmpty = true
            }
        }
        return [hasEmpty, isEmpty]
    },

    createSimpleResult(def, key, value) {
        if (!Array.isArray(def[key]) && value[key] === '' && !def[key].required) {
            delete def[key]
        }
        else if (def[key].type === 'string' || def[key].type === 'boolean') { def[key] = value[key] }
        else if (def[key].type === 'float') { def[key] = parseFloat(value[key]) }
        else if (def[key].type === 'integer') { def[key] = this.parseToInt(value[key]) }
    },

    createResultNodes(def, value) {
        for (let key of Object.keys(def)) {
            // programmatic nodes will be deleted
            if (key.charAt(0) === '_' || key === 'hqschema') {
                delete def[key]
            }
            else {
                let [isEmpty, hasEmpty] = this.checkForEmpty(def[key], value[key])

                if (['string', 'boolean', 'float', 'integer'].includes(def[key].type)) {
                    this.createSimpleResult(def, key, value)
                }
                else {
                    if (def[key].type === 'dict') {
                        def[key].schema = this.createResultNodes(def[key].schema, value[key])
                    }
                    else if (def[key]['type'] === 'list' && 'schema' in def[key]) {
                        def[key].schema = this.createMultipleResult(
                            _.cloneDeep(value[key]), _.cloneDeep(def[key].schema.schema))
                    }
                    else if (def[key].type === 'list' || def[key].type === 'complex') {
                        if (!(isEmpty || hasEmpty)) {
                            let typesArray = this.createParsingArray(def[key].items)
                            def[key] = this.parseArrayValues(typesArray, value[key])
                        }
                    }
                    this.applyRequiredAndEmptyRules(def, key, isEmpty, hasEmpty)
                }
            }
        }
        return def
    },

    applyRequiredAndEmptyRules(schemaItem, key, isEmpty, hasEmpty) {
        if (schemaItem[key].required && schemaItem[key].empty && (isEmpty || hasEmpty)) {
            // result must include item but item can be empty
            if (schemaItem[key].type === 'dict') { schemaItem[key] = {} }
            else { schemaItem[key] = [] }
        } else if (!schemaItem[key].required && (isEmpty || hasEmpty)) {
            // result may not include item
            delete schemaItem[key]
        } else if ('schema' in schemaItem[key]) {
            schemaItem[key] = schemaItem[key].schema
        }
    },

    createMultipleResult(value, schema) {
        let values = _.cloneDeep(value.values)
        for (let item of values) {
            for (let key of Object.keys(item)) {
                if (typeof item[key] === 'object' && !Array.isArray(item[key])) {
                    item[key] = this.createMultipleResult(
                        _.cloneDeep(item[key]),
                        _.cloneDeep(schema[key].schema.schema))
                } else if (typeof item[key] === 'number') {
                    let keys = Object.keys(schema)
                    for (let k in keys) {
                        if (k === key && schema[k].type === 'float') {
                            parseFloat(item[key])
                        } else if (k === key && schema[k].type === 'integer') {
                            parseInt(item[key])
                        }
                    }
                } else if (!item.required && item[key] === ''
                    || (Array.isArray(item[key]) && item[key].every(e => e === ''))) {
                    delete item[key]
                } else if (Array.isArray(item[key])) {
                    let parsingArray = this.createParsingArray(schema[key].items)
                    item[key] = this.parseArrayValues(parsingArray, item[key])
                }
            }
        }
        return values
    },

    createParsingArray(items) {
        let itemsArray = []
        for (let key of Object.keys(items)) {
            if ('items' in items[key]) {
                let newItems = items[key].items
                let newArray = this.createParsingArray(newItems)
                itemsArray.push(newArray)
            } else {
                itemsArray.push(items[key].type)
            }
        }
        return itemsArray
    },

    parseArrayValues(typesArray, array) {
        for (let [index, item] of typesArray.entries()) {
            let num = Number(array[index])
            if (item === 'float') {
                parseFloat(num)
                array[index] = num

            } else if (item === 'integer') {
                array[index] = this.parseToInt(array[index])
            } else if (Array.isArray(item)) {
                let newTypesArray = item
                let newArray = array[index]
                array[index] = this.parseArrayValues(newTypesArray, newArray)
            }
        }
        return array
    },

    parseToInt(value) {
        if (value < 0 && value > -1) {
            value = 0
        } else if (value < 0) {
            value = Math.ceil(value)
        } else {
            value = Math.floor(value)
        }
        return value
    },

    replaceNaN(value) {
        if (Array.isArray(value)) {
            value.forEach(function (item, i) {
                if (isNaN(item) && !(typeof item === 'string')) { value[i] = '' }
            })
        } else if (isNaN(value) && !(typeof value === 'string')) {
            value = ''
        }
        return value
    },

}