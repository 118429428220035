<template>
  <div class="page-margin-left page-margin-top-ui page-margin-right">
    <v-card>
      <v-row class="d-flex">
        <v-col cols="2" class="d-flex"> </v-col>
      </v-row>

      <v-row>
        <v-col cols="7">
          <LatticeConfig @dirty="dirty" v-bind:isDirty="isDirty" />
        </v-col>
        <v-col cols="5">
          <v-container class="plot-container">
            <LatticePlot v-if="showGraph" />
          </v-container>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
const LatticeConfig = () =>
  import(
    /* webpackChunkName: "latticeConfig" */ '../../components/latticeApp/LatticeConfig.vue'
  )
const LatticePlot = () =>
  import(
    /* webpackChunkName: "latticePlot" */ '../../components/latticeApp/LatticePlot.vue'
  )
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    LatticeConfig,
    LatticePlot,
  },

  data() {
    return {
      showGraph: true,
      showLegend: true,
      isDirty: false,
    }
  },

  methods: {
    ...mapMutations('lattice', ['resetLattice']),

    dirty(value) {
      this.isDirty = value
    },
  },

  computed: {
    ...mapState('lattice', {
      sites: (state) => state.sites,
      bonds: (state) => state.bonds,
    }),

  },

  beforeRouteLeave(to, from, next) {
    if (this.isDirty || this.sites.length > 0 || this.bonds.length > 0) {
      let leave = confirm(
        'Do you want to leave this page? All unsaved changes will be lost.'
      )
      if (leave) {
        this.resetLattice()
        next()
      } else {
        next(false)
      }
    } else {
      this.resetLattice()
      next()
    }
  },
}
</script>

<style lang="css" scoped>
.plot-container {
  position: sticky;
  /* magic numbers suck, but afaik there's no reliable way of getting the height from Vuetify app bar dynamically. sagde */
  /* plus, we're adding the secondary app bar on top of that, both of them being 64 tall */
  top: 128px;
}
</style>
