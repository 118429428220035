<template>
  <v-app id="app">
    <!-- main app bar -->
    <v-app-bar
      color="hqsOrange lighten-1"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
    >
      <div class="app-icon">
        <router-link :to="{ name: 'home' }">
          <v-img :src="require('./assets/hqs_cloud.png')" />
        </router-link>
      </div>

      <v-spacer />

      <v-menu bottom v-if="isLoggedIn">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" class="text" data-cy="user_name">
            <v-icon color="darkGrey">mdi-account</v-icon>
            {{ user.name }}
          </v-btn>
        </template>
        <v-list class="text">
          <v-list-item data-cy="account_nav" :to="{ name: 'account' }">
            <v-list-item-title>
              <v-icon left>mdi-account</v-icon>My Account
            </v-list-item-title>
          </v-list-item>
          <v-list-item data-cy="credits_nav" :to="{ name: 'credits' }">
            <v-list-item-title>
              <v-icon left>mdi-cash-multiple</v-icon>My Credits
            </v-list-item-title>
          </v-list-item>
          <!-- temporarily removed for release april 23 -->
          <!-- <v-list-item data-cy="orders_nav" :to="{ name: 'orders' }">
            <v-list-item-title>
              <v-icon left>mdi-clipboard-list-outline</v-icon>My Orders
            </v-list-item-title>
          </v-list-item> -->
          <v-list-item
            data-cy="logout_nav"
            @click="logout()"
            :to="{ name: 'home' }"
          >
            <v-list-item-title>
              <v-icon left>mdi-logout</v-icon>Log Out
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="credits text" v-if="isLoggedIn">
        <v-icon color="darkGrey">mdi-cash-multiple</v-icon>&nbsp;
        <template v-if="loading">Loading...</template>
        <template v-if="!loading">
          <strong class="text">{{ credits.toFixed(5).slice(0, -3) }}&nbsp;</strong>
          <span class="text">HQ$</span>
          <v-tooltip v-if="!userAbleToStartJobs" bottom color="darkGrey" transition="fade-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn data-cy="insufficient_creds_tooltip_icon" v-on="on" v-bind="attrs" fab rounded x-small elevation="0" class="ml-2 cursor-help" color="darkGrey">
                <v-icon color="white">mdi-alert-circle</v-icon>
              </v-btn>
            </template>
            <div v-html="terms.INSUFFICIENT_CREDS_BODY" />
          </v-tooltip>
        </template>
      </span>

      <v-tabs right v-if="!isLoggedIn">
        <v-tabs-slider color="transparent" />
        <v-tab
          data-cy="login"
          class="text-decoration-underline"
          :to="{ name: 'login' }"
        >
          login
        </v-tab>
        <v-tab class="text-decoration-underline" :to="{ name: 'register' }">
          register
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-main>
      <!-- menu button for navigation drawer -->
      <v-btn
        data-cy="nav_button"
        v-if="!drawer && isLoggedIn"
        @click.stop="drawer = !drawer"
        color="hqsOrange lighten-1"
        elevation="6"
        small
        dark
        top
        left
        v-bind:class="{
          position_above: pageType === 'general',
          position_below: pageType === '',
        }"
      >
        <v-icon> mdi-menu </v-icon>
      </v-btn>

      <!-- app side navigation drawer -->
      <!-- home -->
      <v-navigation-drawer app color="blueGreyLight" v-model="drawer" temporary>
        <v-list nav dense class="nav-items-list text">
          <v-list-item-group>
            <v-list-item data-cy="nav_home" exact :to="{ name: 'home' }">
              <v-list-item-icon>
                <v-icon>mdi-home-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
            <!-- applications -->
            <v-list-item
              data-cy="nav_applications"
              :to="{ name: 'all-applications' }"
            >
              <v-list-item-icon>
                <v-icon>mdi-rocket</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Applications</v-list-item-title>
            </v-list-item>
            <!-- structures -->
            <v-list-item
              data-cy="nav_structures"
              :to="{ name: 'all-structures' }"
            >
              <v-list-item-icon>
                <v-icon>mdi-rotate-orbit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Structures</v-list-item-title>
            </v-list-item>
            <!-- jobs -->
            <v-list-item data-cy="nav_jobs" :to="{ name: 'jobs' }">
              <v-list-item-icon>
                <v-icon>mdi-chart-donut</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Jobs Dashboard</v-list-item-title>
            </v-list-item>
            <!-- docu -->
            <v-list-item
              data-cy="nav_docu"
              :href="`http://docs.${subdomain}cloud.quantumsimulations.de`"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon>mdi-lighthouse</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Documentation</v-list-item-title>
            </v-list-item>

            <!-- pricing -->
            <v-list-item data-cy="nav_pricing" :to="{ name: 'pricing' }">
              <v-list-item-icon>
                <v-icon>mdi-list-box-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Pricing</v-list-item-title>
            </v-list-item>
            <!-- web shop -->
            <!-- temporarily removed for release april 23 -->
            <!-- <v-list-item data-cy="nav_shop" :to="{ name: 'web-shop' }">
              <v-list-item-icon>
                <v-icon>mdi-cart-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Web Shop</v-list-item-title>
            </v-list-item> -->
            <!-- tokens -->
            <v-list-item data-cy="tokens" :to="{ name: 'tokens' }">
              <v-list-item-icon>
                <v-icon>mdi-ticket</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Tokens</v-list-item-title>
            </v-list-item>
            <!-- contact -->
            <v-list-item data-cy="nav_contact" :to="{ name: 'contact' }">
              <v-list-item-icon>
                <v-icon>mdi-email-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
            <!-- changelog -->
            <v-list-item data-cy="nav_changelog" :to="{ name: 'changelog' }">
              <v-list-item-icon>
                <v-icon>mdi-lightbulb-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Changelog</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <template v-if="$route.matched.length">
        <router-view :key="$route.fullPath"></router-view>
      </template>
    </v-main>
    <v-footer color="gray justify-center" app>
      <span class="footer__small">
        &copy; 2023 HQS -
        <router-link :to="{ name: 'imprint' }" data-cy="imprint"
          >Imprint</router-link
        >
        -
        <a href="termsOfUse.pdf" data-cy="terms">Terms of Use</a>
        <!-- <router-link to="/terms" data-cy="terms">Terms of Use</router-link> -->
        -
        <router-link :to="{ name: 'privacy' }" data-cy="privacy"
          >Privacy</router-link
        >
        -
        <router-link :to="{ name: 'pricing' }" data-cy="pricing"
          >Pricing</router-link
        >
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import auth from './views/lib/auth'
import { mapActions, mapState, mapMutations } from 'vuex'

import {
  TERMS,
  MIN_CREDITS_FOR_JOB_START,
  ALLOW_CREDITS_OVERUSE,
} from './data/constants'

export default {
  data() {
    return {
      pageType: '',
      subdomain: process.env.VUE_APP_SUBDOMAIN,
      user: {},
      isLoggedIn: false,
      drawer: false,
      generalRouteNames: [
        'home',
        'dashboard',
        'changelog',
        'terms',
        'contact',
        'imprint',
        'privacy',
        'account',
        'pricing',
        'web-shop',
        'failure',
        'success',
        'shopping-basket',
        'tokens',
        '404',
        'orders',
        'credits',
      ],
      testUserJobs: '3cae6a66-5b57-4884-b6e1-01fc9445d5ec',
      testUserStructures: 'c59e3740-4f39-4920-89f4-b3c0062acf6d',
    }
  },
  methods: {
    ...mapActions('credits', ['loadCredits']),

    ...mapActions('backend', ['load']),

    ...mapMutations('backend', [
      'set',
      'resetBackendStore',
      'createAppStructure',
    ]),

    ...mapActions('tokens', ['loadPermissionsAndGroups']),

    refresh() {
      auth.getUser().then((user) => {
        this.user = user
      })
      auth
        .isLoggedIn()
        .then(() => {
          this.isLoggedIn = true
          this.loadCredits()
        })
        .catch(() => {
          this.showAccount = false
        })
    },

    logout() {
      auth.signOut().then(() => {
        this.isLoggedIn = false
        this.showAccount = false
        localStorage.clear()
        sessionStorage.clear()
        window.location.reload()
        this.resetBackendStore()
        this.$router.push({ path: '/' })
      })
    },

    async setAlertTimeout() {
      await auth.getUser().then((user) => {
        if (
          user.id === this.testUserJobs ||
          user.id === this.testUserStructures
        ) {
          this.set(['alertTimeout', process.env.VUE_APP_ALERT_TIMEOUT_E2E_TEST])
        }
      })
    },

    async loadScopeData() {
      if (this.workflows.length === 0) {
        await this.load(['workflow'])
      }
      if (this.schemas.length === 0) {
        await this.load(['schema'])
      }
      await this.loadPermissionsAndGroups()
    },

    setPageTypeForMenuButton() {
      if (this.generalRouteNames.some((e) => e.includes(this.$route.name))) {
        this.pageType = 'general'
      }
    },
  },

  computed: {
    ...mapState('credits', ['credits', 'loading']),

    ...mapState('backend', {
      schemas: (state) => state.schemas,
      workflows: (state) => state.workflows,
      alertTimeout: (state) => state.alertTimeout,
      topicsHierarchy: (state) => state.topicsHierarchy,
    }),

    ...mapState('tokens', ['userPermissions', 'userGroups']),

    terms() {
      return TERMS
    },

    minCreditsForJobStart() {
      return MIN_CREDITS_FOR_JOB_START
    },

    userAbleToStartJobs() {
      const isUserAllowedToOveruseCredits = this.userPermissions.includes(
        ALLOW_CREDITS_OVERUSE
      )

      if (!isUserAllowedToOveruseCredits) {
        return this.credits > this.minCreditsForJobStart
      }

      return true
    },
  },

  watch: {
    $route() {
      this.pageType = ''
      if (this.generalRouteNames.some((e) => e.includes(this.$route.name))) {
        this.pageType = 'general'
      }
    },
  },

  async created() {
    this.pageType = ''
    document.title = document.title.replace(
      '${var.titlePrefix}',
      process.env.VUE_APP_TITLE_PREFIX
    )
    await this.$bus.$on('user:update', this.refresh)
    await this.refresh()
    await this.loadScopeData()
    this.createAppStructure()
    this.setPageTypeForMenuButton()
    this.setAlertTimeout()
  },
}
</script>

<style scoped>
.nav-items-list {
  position: fixed;
}

.credits {
  white-space: nowrap;
}

.footer__small {
  font-size: 0.8em;
}

.position_above {
  z-index: 9;
  top: 90px !important;
  left: 20px !important;

  position: fixed !important;
  border-radius: 5px;
  height: 45px !important;
}

.position_below {
  z-index: 9;
  top: 150px !important;
  left: 20px !important;
  position: fixed !important;
  border-radius: 5px;
  height: 45px !important;
}

.app-icon {
  left: 10px;
  float: left !important;
  height: 50px !important;
  width: 80px;
}
</style>
