export default {

    schemas: {
        basic_inputs: {
            label: "basic_inputs",
            name: "basic_inputs_v_1.0",
            base_schema_version: "1.0",
            version: "1.0",
            groups: ['hqs-internal'],
            definition: {
                text_field: {
                    type: "string",
                    meta: {
                        doc: "text field example",
                        hint: 'this field expects a string'
                    }
                },
                number_field_float: {
                    type: "float",
                    meta: {
                        doc: "number field example for float value",
                        unit: 'Angstrom',
                        hint: 'this field expects a number'
                    }
                },
                number_field_int: {
                    type: "integer",
                    meta: {
                        doc: "number field example for integer value",
                        unit: 'm/s',
                        hint: 'this field expects a number'
                    }
                },
                select_field: {
                    type: "string",
                    allowed: ['first', 'second', 'third'],
                    meta: {
                        doc: "select field example with default value",
                        hint: 'this field expects a string'
                    }
                },
                vector_field_int: {
                    type: "list",
                    items: [
                        {
                            type: "integer",
                            meta: {
                                doc: "x coordinate",
                                hint: 'x coordinate'
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: "y coordinate",
                                hint: 'y coordinate'
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: "z coordinate",
                                hint: 'z coordinate'
                            }
                        }
                    ],
                    meta: {
                        doc: "vector field example",
                        unit: 'Bohr'
                    }
                },
                vector_field_float: {
                    type: "list",
                    items: [
                        {
                            type: "float",
                            meta: {
                                doc: "x coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "y coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "z coordinate"
                            }
                        }
                    ],
                    meta: {
                        doc: "vector field example",
                        unit: 'unit',
                        hint: 'hint'
                    }
                },
                complex_field: {
                    type: "complex",
                    items: [
                        {
                            type: "float",
                            meta: {
                                doc: "Real part of complex number",
                                hint: 'real part'
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "Imaginary part of complex number",
                                hint: 'imaginary part'
                            }
                        }
                    ],
                    meta: {
                        doc: "Complex field example",
                        unit: 'unit',
                        hint: 'a complex matter...'
                    }
                },
                checkbox_field: {
                    type: "boolean",
                    meta: {
                        doc: "checkbox field example",
                        hint: 'true or not true...'
                    }
                },
            },
            doc: "Node for basic input fields",
            parent: {
                label: "data",
                version: "1.0"
            },
        },
        basic_inputs_with_default: {
            label: "basic_inputs_with_default",
            name: "basic_inputs_with_default_v_1.0",
            base_schema_version: "1.0",
            definition: {
                text_field_default: {
                    type: "string",
                    default: "test",
                    meta: {
                        doc: "text field example with default value"
                    }
                },
                number_field_float_default: {
                    type: "float",
                    default: 2.3,
                    meta: {
                        doc: "number field example for float value with default value"
                    }
                },
                number_field_int_default: {
                    type: "integer",
                    default: 5,
                    meta: {
                        doc: "number field example for integer value with default value"
                    }
                },
                select_field_default: {
                    type: "string",
                    default: 'first',
                    allowed: ['first', 'second', 'third'],
                    meta: {
                        doc: "select field example"
                    }
                },
                vector_field_int_default: {
                    type: "list",
                    default: [0, 1, 12],
                    items: [
                        {
                            type: "integer",
                            meta: {
                                doc: "x coordinate"
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: "y coordinate"
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: "z coordinate"
                            }
                        }
                    ],
                    meta: {
                        doc: "vector field example"
                    }
                },
                vector_field_float_default: {
                    type: "list",
                    default: [2e-7, 2e-6, 0.00000000000001],
                    items: [
                        {
                            type: "float",
                            meta: {
                                doc: "x coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "y coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "z coordinate"
                            }
                        }
                    ],
                    meta: {
                        doc: "vector field example"
                    }
                },
                complex_field_default: {
                    type: "complex",
                    default: [3567e+20, 3.567],
                    items: [
                        {
                            type: "float",
                            meta: {
                                doc: "Real part of complex number"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "Imaginary part of complex number"
                            }
                        }
                    ],
                    meta: {
                        doc: "complex field example"
                    }
                },
                checkbox_field_default: {
                    type: "boolean",
                    default: true,
                    meta: {
                        doc: "checkbox field example"
                    }
                },
            },
            doc: "Node for basic input fields with default values",
            parent: {
                label: "data",
                version: "1.0"
            },
            version: "1.0",
            groups: ['hqs-internal']
        },
        basic_inputs_required_fields: {
            label: "basic_inputs_required_fields",
            name: "basic_inputs_required_fields_v_1.0",
            base_schema_version: "1.0",
            definition: {
                text_field_required: {
                    type: "string",
                    required: true,
                    meta: {
                        doc: "text field example"
                    }
                },
                number_field_float_required: {
                    type: "float",
                    required: true,
                    meta: {
                        doc: "number field example for float value"
                    }
                },
                number_field_int_required: {
                    type: "integer",
                    required: true,
                    meta: {
                        doc: "number field example for integer value"
                    }
                },
                select_field_required: {
                    type: "string",
                    required: true,
                    allowed: ['first', 'second', 'third'],
                    meta: {
                        doc: "select field example with default value"
                    }
                },
                vector_field_int_required: {
                    type: "list",
                    required: true,
                    items: [
                        {
                            type: "integer",
                            meta: {
                                doc: "x coordinate"
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: "y coordinate"
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: "z coordinate"
                            }
                        }
                    ],
                    meta: {
                        doc: "vector field example"
                    }
                },
                vector_field_float_required: {
                    type: "list",
                    required: true,
                    items: [
                        {
                            type: "float",
                            meta: {
                                doc: "x coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "y coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "z coordinate"
                            }
                        }
                    ],
                    meta: {
                        doc: "vector field example"
                    }
                },
                complex_field_required: {
                    type: "complex",
                    required: true,
                    items: [{
                        type: "float",
                        meta: {
                            doc: "Real part of complex number"
                        }
                    },
                    {
                        type: "float",
                        meta: {
                            doc: "Imaginary part of complex number"
                        }
                    }],
                    meta: {
                        doc: "Complex field example"
                    }
                },
            },
            doc: "Node for required basic input fields",
            parent: {
                label: "data",
                version: "1.0"
            },
            version: "1.0",
            groups: ['hqs-internal']
        },
        basic_inputs_non_required_fields: {
            label: "basic_inputs_non_required_fields",
            name: "basic_inputs_non_required_fields_v_1.0",
            base_schema_version: "1.0",
            definition: {
                text_field_non_required: {
                    type: "string",
                    required: false,
                    meta: {
                        doc: "text field example"
                    }
                },
                number_field_float_non_required: {
                    type: "float",
                    required: false,
                    meta: {
                        doc: "number field example for float value"
                    }
                },
                number_field_int_non_required: {
                    type: "integer",
                    required: false,
                    meta: {
                        doc: "number field example for integer value"
                    }
                },
                select_field_non_required: {
                    type: "string",
                    required: false,
                    allowed: ['first', 'second', 'third'],
                    meta: {
                        doc: "select field example with default value"
                    }
                },
                vector_field_int_non_required: {
                    type: "list",
                    required: false,
                    items: [
                        {
                            type: "integer", meta: { doc: "x coordinate" }
                        },
                        {
                            type: "integer", meta: { doc: "y coordinate" }
                        },
                        {
                            type: "integer", meta: { doc: "z coordinate" }
                        }
                    ], meta: { doc: "vector field example" }
                },
                vector_field_float_non_required: {
                    type: "list",
                    required: false,
                    items: [
                        {
                            type: "float",
                            meta: {
                                doc: "x coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "y coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "z coordinate"
                            }
                        }
                    ],
                    meta: {
                        doc: "vector field example"
                    }
                },
                complex_field_non_required: {
                    type: "complex",
                    required: false,
                    items: [{
                        type: "float",
                        meta: {
                            doc: "Real part of complex number"
                        }
                    },
                    {
                        type: "float",
                        meta: {
                            doc: "Imaginary part of complex number"
                        }
                    }],
                    meta: {
                        doc: "Complex field example"
                    }
                },
                checkbox_field: {
                    type: "checkbox",
                    meta: {
                        doc: "checkbox"
                    }
                }
            },
            doc: "Node for required basic input fields",
            parent: {
                label: "data",
                version: "1.0"
            },
            version: "1.0",
            groups: ['hqs-internal']
        },
        basic_inputs_required_fields_and_default_values: {
            label: "basic_inputs_required_fields_and_default_values",
            name: "basic_inputs_required_fields_and_default_values_v_1.0",
            base_schema_version: "1.0",
            definition: {
                text_field_default_required: {
                    type: "string",
                    required: true,
                    default: 'test',
                    meta: {
                        doc: "text field example with default value"
                    }
                },
                number_field_float_default_required: {
                    type: "float",
                    required: true,
                    default: 1.3,
                    meta: {
                        doc: "number field example for float value"
                    }
                },
                number_field_int_default_required: {
                    type: "integer",
                    required: true,
                    default: 7,
                    meta: {
                        doc: "number field example for integer value"
                    }
                },
                select_field_default_required: {
                    type: "string",
                    required: true,
                    default: 'second',
                    allowed: ['first', 'second', 'third'],
                    meta: {
                        doc: "select field example with default value"
                    }
                },
                vector_field_int_default_required: {
                    type: "list",
                    required: true,
                    default: [1, 2, 3],
                    items: [
                        {
                            type: "integer",
                            meta: {
                                doc: "x coordinate"
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: "y coordinate"
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: "z coordinate"
                            }
                        }
                    ],
                    meta: {
                        doc: "vector field example"
                    }
                },
                vector_field_float_default_required: {
                    type: "list",
                    required: true,
                    default: [0.1, 0.3, 2],
                    items: [
                        {
                            type: "float",
                            meta: {
                                doc: "x coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "y coordinate"
                            }
                        },
                        {
                            type: "float",
                            meta: {
                                doc: "z coordinate"
                            }
                        }
                    ],
                    meta: {
                        doc: "vector field example"
                    }
                },
                complex_field_default_required: {
                    type: "complex",
                    required: true,
                    default: [1, 0.3],
                    items: [{
                        type: "float",
                        meta: {
                            doc: "Real part of complex number"
                        }
                    },
                    {
                        type: "float",
                        meta: {
                            doc: "Imaginary part of complex number"
                        }
                    }],
                    meta: {
                        doc: "Complex field example"
                    }
                },
            },
            doc: "Node for required basic input fields",
            parent: {
                label: "data",
                version: "1.0"
            },
            version: "1.0",
            groups: ['hqs-internal']
        },
        dict_basic_inputs: {
            base_schema_version: "1.0",
            label: "dict_basic_inputs",
            name: "dict_basic_inputs_v_1.0",
            definition: {
                dict_field: {
                    type: "dict",
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            type: "string",
                            meta: {
                                doc: "text field example"
                            }
                        },
                        text_field_default: {
                            type: "string",
                            default: "test",
                            meta: {
                                doc: "text field example with default value"
                            }
                        },
                        number_field_float: {
                            type: "float",
                            meta: {
                                doc: "number field example for float value"
                            }
                        },
                        number_field_int_default: {
                            type: "integer",
                            default: 5,
                            meta: {
                                doc: "number field example for integer value with default value"
                            }
                        },
                        select_field: {
                            type: "string",
                            allowed: ['first', 'second', 'third'],
                            meta: {
                                doc: "select field example with default value"
                            }
                        },
                        select_field_default_required: {
                            type: "string",
                            required: true,
                            default: 'first',
                            allowed: ['first', 'second', 'third'],
                            meta: {
                                doc: "select field example"
                            }
                        },
                        checkbox_field: {
                            type: "boolean",
                            meta: {
                                doc: "checkbox field example"
                            }
                        },
                        checkbox_field_default: {
                            type: "boolean",
                            default: true,
                            meta: {
                                doc: "checkbox field example"
                            }
                        },
                        vector_field_int: {
                            type: "list",
                            items: [
                                {
                                    type: "integer",
                                    meta: {
                                        doc: "x coordinate"
                                    }
                                },
                                {
                                    type: "integer",
                                    meta: {
                                        doc: "y coordinate"
                                    }
                                },
                                {
                                    type: "integer",
                                    meta: {
                                        doc: "z coordinate"
                                    }
                                }
                            ],
                            meta: {
                                doc: "vector field example"
                            }
                        },
                        vector_field_float_default_required: {
                            type: "list",
                            required: true,
                            default: [2e-7, 2e-6, 0.00000000000001],
                            items: [
                                {
                                    type: "float",
                                    meta: {
                                        doc: "x coordinate"
                                    }
                                },
                                {
                                    type: "float",
                                    meta: {
                                        doc: "y coordinate"
                                    }
                                },
                                {
                                    type: "float",
                                    meta: {
                                        doc: "z coordinate"
                                    }
                                }
                            ],
                            meta: {
                                doc: "vector field example"
                            }
                        },
                        complex_field_required: {
                            required: true,
                            type: "complex",
                            items: [{
                                type: "float",
                                meta: {
                                    doc: "Real part of complex number"
                                }
                            },
                            {
                                type: "float",
                                meta: {
                                    doc: "Imaginary part of complex number"
                                }
                            }],
                            meta: {
                                doc: "Complex field example"
                            }
                        },
                        complex_field_default: {
                            type: "complex",
                            default: [3567e+20, 3.567],
                            items: [{
                                type: "float",
                                meta: {
                                    doc: "Real part of complex number"
                                }
                            },
                            {
                                type: "float",
                                meta: {
                                    doc: "Imaginary part of complex number"
                                }
                            }],
                            meta: {
                                doc: "Complex field example"
                            }
                        },
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        dict_containing_multiple: {
            base_schema_version: "1.0",
            label: "dict_containing_multiple",
            name: "dict_containing_multiple_v_1.0",
            definition: {
                dict_field: {
                    type: "dict",
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        multiple_field: {
                            type: "list",
                            schema: {
                                type: 'dict',
                                schema: {
                                    text_field: {
                                        type: "string",
                                        meta: {
                                            doc: "text field example"
                                        }
                                    },
                                    number_field_float: {
                                        type: "float",
                                        meta: {
                                            doc: "number field example for float value"
                                        }
                                    },
                                },
                                meta: {
                                    doc: 'multiple field example'
                                }
                            },
                            meta: {
                                doc: 'multiple field example'
                            }
                        }



                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        dict_basic_inputs_required_false: {
            base_schema_version: "1.0",
            label: "dict_basic_inputs_required_false",
            name: "dict_basic_inputs_required_false_v_1.0",
            definition: {
                dict_field: {
                    type: "dict",
                    required: false,
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            type: "string",
                            required: false,
                            meta: {
                                doc: "text field example"
                            }
                        },
                        number_field_float: {
                            type: "float",
                            required: false,
                            meta: {
                                doc: "number field example for float value"
                            }
                        },
                        select_field: {
                            type: "string",
                            required: false,
                            allowed: ['first', 'second', 'third'],
                            meta: {
                                doc: "select field example with default value"
                            }
                        },
                        vector_field_int: {
                            type: "list",
                            required: false,
                            items: [
                                {
                                    type: "integer",
                                    meta: {
                                        doc: "x coordinate"
                                    }
                                },
                                {
                                    type: "integer",
                                    meta: {
                                        doc: "y coordinate"
                                    }
                                },
                                {
                                    type: "integer",
                                    meta: {
                                        doc: "z coordinate"
                                    }
                                }
                            ],
                            meta: {
                                doc: "vector field example"
                            }
                        },
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        dict_basic_inputs_required_true: {
            base_schema_version: "1.0",
            label: "dict_basic_inputs_required_true",
            name: "dict_basic_inputs_required_true_v_1.0",
            definition: {
                dict_field: {
                    type: "dict",
                    required: true,
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            type: "string",
                            required: false,
                            meta: {
                                doc: "text field example"
                            }
                        },
                        number_field_float: {
                            type: "float",
                            required: false,
                            meta: {
                                doc: "number field example for float value"
                            }
                        },
                        select_field: {
                            type: "string",
                            required: false,
                            allowed: ['first', 'second', 'third'],
                            meta: {
                                doc: "select field example with default value"
                            }
                        },
                        vector_field_int: {
                            type: "list",
                            required: false,
                            items: [
                                {
                                    type: "integer",
                                    meta: {
                                        doc: "x coordinate"
                                    }
                                },
                                {
                                    type: "integer",
                                    meta: {
                                        doc: "y coordinate"
                                    }
                                },
                                {
                                    type: "integer",
                                    meta: {
                                        doc: "z coordinate"
                                    }
                                }
                            ],
                            meta: {
                                doc: "vector field example"
                            }
                        },
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        two_dicts: {
            base_schema_version: "1.0",
            label: "two_dicts",
            name: "two_dicts_v_1.0",
            definition: {
                dict_field_1: {
                    type: "dict",
                    required: true,
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            type: "string",
                            required: false,
                            meta: {
                                doc: "text field example"
                            }
                        },
                    }
                },
                dict_field_2: {
                    type: "dict",
                    required: true,
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            type: "string",
                            required: false,
                            meta: {
                                doc: "text field example"
                            }
                        },
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_dict: {
            base_schema_version: "1.0",
            label: "nested_dict",
            name: "nested_dict_v_1.0",
            definition: {
                dict_text_fields: {
                    type: "dict",
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            type: "string",
                            meta: {
                                doc: "text field example"
                            }
                        },
                        text_field_default: {
                            type: "string",
                            default: "test",
                            meta: {
                                doc: "text field example with default value"
                            }
                        },
                        dict_number_fields: {
                            type: "dict",
                            meta: {
                                doc: "dict field example"
                            },
                            schema: {
                                number_field_float: {
                                    type: "float",
                                    meta: {
                                        doc: "number field example for float value"
                                    }
                                },
                                number_field_int_default: {
                                    type: "integer",
                                    default: 5,
                                    meta: {
                                        doc: "number field example for integer value with default value"
                                    }
                                },
                                dict_select_fields: {
                                    type: 'dict',
                                    meta: {
                                        doc: "dict field example"
                                    },
                                    schema: {
                                        select_field: {
                                            type: "string",
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example"
                                            }
                                        },
                                        select_field_default_required: {
                                            type: "string",
                                            required: true,
                                            default: 'first',
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example"
                                            }
                                        },
                                        dict_vector_fields: {
                                            type: 'dict',
                                            meta: {
                                                doc: "dict field example"
                                            },
                                            schema: {
                                                vector_field_int: {
                                                    type: "list",
                                                    items: [
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "x coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "y coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "z coordinate"
                                                            }
                                                        }
                                                    ],
                                                    meta: {
                                                        doc: "vector field example"
                                                    }
                                                },
                                                vector_field_float_default_required: {
                                                    type: "list",
                                                    required: true,
                                                    default: [2e-7, 2e-6, 0.00000000000001],
                                                    items: [
                                                        {
                                                            type: "float",
                                                            meta: {
                                                                doc: "x coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "float",
                                                            meta: {
                                                                doc: "y coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "float",
                                                            meta: {
                                                                doc: "z coordinate"
                                                            }
                                                        }
                                                    ],
                                                    meta: {
                                                        doc: "vector field example"
                                                    }
                                                },
                                                dict_complex_fields: {
                                                    type: 'dict',
                                                    meta: {
                                                        doc: "dict field example"
                                                    },
                                                    schema: {
                                                        complex_field_required: {
                                                            required: true,
                                                            type: "complex",
                                                            items: [{
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Real part of complex number"
                                                                }
                                                            },
                                                            {
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Imaginary part of complex number"
                                                                }
                                                            }],
                                                            meta: {
                                                                doc: "Complex field example"
                                                            }
                                                        },
                                                        complex_field_default: {
                                                            type: "complex",
                                                            default: [3567e+20, 3.567],
                                                            items: [{
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Real part of complex number"
                                                                }
                                                            },
                                                            {
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Imaginary part of complex number"
                                                                }
                                                            }],
                                                            meta: {
                                                                doc: "Complex field example"
                                                            }
                                                        },
                                                        dict_checkbox_fields: {
                                                            type: 'dict',
                                                            meta: {
                                                                doc: "dict field example"
                                                            },
                                                            schema: {
                                                                checkbox_field: {
                                                                    type: "boolean",
                                                                    meta: {
                                                                        doc: "checkbox field example"
                                                                    }
                                                                },
                                                                checkbox_field_default: {
                                                                    type: "boolean",
                                                                    default: true,
                                                                    meta: {
                                                                        doc: "checkbox field example"
                                                                    }
                                                                },
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_dict_required_true_empty_true: {
            base_schema_version: "1.0",
            label: "nested_dict_required_true_empty_true",
            name: "nested_dict_required_true_empty_true_v_1.0",
            definition: {
                dict_text_fields: {
                    type: "dict",
                    required: true, empty: true,
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            required: false,
                            type: "string",
                            meta: {
                                doc: "text field example"
                            }
                        },
                        dict_number_fields: {
                            required: true, empty: true,
                            type: "dict",
                            meta: {
                                doc: "dict field example"
                            },
                            schema: {
                                number_field_float: {
                                    required: false,
                                    type: "float",
                                    meta: {
                                        doc: "number field example for float value"
                                    }
                                },
                                dict_select_fields: {
                                    required: true, empty: true,
                                    type: 'dict',
                                    meta: {
                                        doc: "dict field example"
                                    },
                                    schema: {
                                        select_field: {
                                            required: false,
                                            type: "string",
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        dict_vector_fields: {
                                            required: true, empty: true,
                                            type: 'dict',
                                            meta: {
                                                doc: "dict field example"
                                            },
                                            schema: {
                                                vector_field_int: {
                                                    type: "list",
                                                    required: false,
                                                    items: [
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "x coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "y coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "z coordinate"
                                                            }
                                                        }
                                                    ],
                                                    meta: {
                                                        doc: "vector field example"
                                                    }
                                                },
                                                dict_complex_fields: {
                                                    required: true, empty: true,
                                                    type: 'dict',
                                                    meta: {
                                                        doc: "dict field example"
                                                    },
                                                    schema: {
                                                        complex_field: {
                                                            required: false,
                                                            type: "complex",
                                                            items: [{
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Real part of complex number"
                                                                }
                                                            },
                                                            {
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Imaginary part of complex number"
                                                                }
                                                            }],
                                                            meta: {
                                                                doc: "Complex field example"
                                                            }
                                                        },
                                                        dict_checkbox_fields: {
                                                            required: true, empty: true,
                                                            type: 'dict',
                                                            meta: {
                                                                doc: "dict field example"
                                                            },
                                                            schema: {
                                                                checkbox_field: {
                                                                    required: false,
                                                                    type: "boolean",
                                                                    meta: {
                                                                        doc: "checkbox field example"
                                                                    }
                                                                },
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_dict_required_false_empty_true: {
            base_schema_version: "1.0",
            label: "nested_dict_required_false_empty_true",
            name: "nested_dict_required_false_empty_true_v_1.0",
            definition: {
                dict_text_fields: {
                    type: "dict",
                    required: false, empty: true,
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            required: false,
                            type: "string",
                            meta: {
                                doc: "text field example"
                            }
                        },
                        dict_number_fields: {
                            required: false, empty: true,
                            type: "dict",
                            meta: {
                                doc: "dict field example"
                            },
                            schema: {
                                number_field_float: {
                                    required: false,
                                    type: "float",
                                    meta: {
                                        doc: "number field example for float value"
                                    }
                                },
                                dict_select_fields: {
                                    required: false, empty: true,
                                    type: 'dict',
                                    meta: {
                                        doc: "dict field example"
                                    },
                                    schema: {
                                        select_field: {
                                            required: false,
                                            type: "string",
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        dict_vector_fields: {
                                            required: false, empty: true,
                                            type: 'dict',
                                            meta: {
                                                doc: "dict field example"
                                            },
                                            schema: {
                                                vector_field_int: {
                                                    type: "list",
                                                    required: false,
                                                    items: [
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "x coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "y coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "z coordinate"
                                                            }
                                                        }
                                                    ],
                                                    meta: {
                                                        doc: "vector field example"
                                                    }
                                                },
                                                dict_complex_fields: {
                                                    required: false, empty: true,
                                                    type: 'dict',
                                                    meta: {
                                                        doc: "dict field example"
                                                    },
                                                    schema: {
                                                        complex_field: {
                                                            required: false,
                                                            type: "complex",
                                                            items: [{
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Real part of complex number"
                                                                }
                                                            },
                                                            {
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Imaginary part of complex number"
                                                                }
                                                            }],
                                                            meta: {
                                                                doc: "Complex field example"
                                                            }
                                                        },
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_dict_required_false_empty_false: {
            base_schema_version: "1.0",
            label: "nested_dict_required_false_empty_false",
            name: "nested_dict_required_false_empty_false_v_1.0",
            definition: {
                dict_text_fields: {
                    type: "dict",
                    required: false, empty: false,
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            required: false,
                            type: "string",
                            meta: {
                                doc: "text field example"
                            }
                        },
                        dict_number_fields: {
                            required: false, empty: false,
                            type: "dict",
                            meta: {
                                doc: "dict field example"
                            },
                            schema: {
                                number_field_float: {
                                    required: false,
                                    type: "float",
                                    meta: {
                                        doc: "number field example for float value"
                                    }
                                },
                                dict_select_fields: {
                                    required: false, empty: false,
                                    type: 'dict',
                                    meta: {
                                        doc: "dict field example"
                                    },
                                    schema: {
                                        select_field: {
                                            required: false,
                                            type: "string",
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        dict_vector_fields: {
                                            required: false, empty: false,
                                            type: 'dict',
                                            meta: {
                                                doc: "dict field example"
                                            },
                                            schema: {
                                                vector_field_int: {
                                                    type: "list",
                                                    required: false,
                                                    items: [
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "x coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "y coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "z coordinate"
                                                            }
                                                        }
                                                    ],
                                                    meta: {
                                                        doc: "vector field example"
                                                    }
                                                },
                                                dict_complex_fields: {
                                                    required: false, empty: false,
                                                    type: 'dict',
                                                    meta: {
                                                        doc: "dict field example"
                                                    },
                                                    schema: {
                                                        complex_field: {
                                                            required: false,
                                                            type: "complex",
                                                            items: [{
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Real part of complex number"
                                                                }
                                                            },
                                                            {
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Imaginary part of complex number"
                                                                }
                                                            }],
                                                            meta: {
                                                                doc: "Complex field example"
                                                            }
                                                        },
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_dict_required_true_empty_false: {
            base_schema_version: "1.0",
            label: "nested_dict_required_false_empty_true",
            name: "nested_dict_required_false_empty_true_v_1.0",
            definition: {
                dict_text_fields: {
                    type: "dict",
                    required: true, empty: false,
                    meta: {
                        doc: "dict field example"
                    },
                    schema: {
                        text_field: {
                            required: false,
                            type: "string",
                            meta: {
                                doc: "text field example"
                            }
                        },
                        dict_number_fields: {
                            required: true, empty: false,
                            type: "dict",
                            meta: {
                                doc: "dict field example"
                            },
                            schema: {
                                number_field_float: {
                                    required: false,
                                    type: "float",
                                    meta: {
                                        doc: "number field example for float value"
                                    }
                                },
                                dict_select_fields: {
                                    required: true, empty: false,
                                    type: 'dict',
                                    meta: {
                                        doc: "dict field example"
                                    },
                                    schema: {
                                        select_field: {
                                            required: false,
                                            type: "string",
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        dict_vector_fields: {
                                            required: true, empty: false,
                                            type: 'dict',
                                            meta: {
                                                doc: "dict field example"
                                            },
                                            schema: {
                                                vector_field_int: {
                                                    type: "list",
                                                    required: false,
                                                    items: [
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "x coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "y coordinate"
                                                            }
                                                        },
                                                        {
                                                            type: "integer",
                                                            meta: {
                                                                doc: "z coordinate"
                                                            }
                                                        }
                                                    ],
                                                    meta: {
                                                        doc: "vector field example"
                                                    }
                                                },
                                                dict_complex_fields: {
                                                    required: true, empty: false,
                                                    type: 'dict',
                                                    meta: {
                                                        doc: "dict field example"
                                                    },
                                                    schema: {
                                                        complex_field: {
                                                            required: false,
                                                            type: "complex",
                                                            items: [{
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Real part of complex number"
                                                                }
                                                            },
                                                            {
                                                                type: "float",
                                                                meta: {
                                                                    doc: "Imaginary part of complex number"
                                                                }
                                                            }],
                                                            meta: {
                                                                doc: "Complex field example"
                                                            }
                                                        },
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for dict with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        multiple_basic_inputs: {
            base_schema_version: "1.0",
            label: "multiple_basic_inputs",
            name: "multiple_basic_inputs_v_1.0",
            definition: {
                multiple_field: {
                    type: "list",
                    schema: {
                        type: 'dict',
                        schema: {
                            text_field: {
                                type: "string",
                                meta: {
                                    doc: "text field example"
                                }
                            },
                            number_field_float: {
                                type: "float",
                                meta: {
                                    doc: "number field example for float value"
                                }
                            },
                            number_field_int: {
                                type: "integer",
                                meta: {
                                    doc: "number field example for integer value"
                                }
                            },
                            select_field: {
                                type: "string",
                                allowed: ['first', 'second', 'third'],
                                meta: {
                                    doc: "select field example with default value"
                                }
                            },
                            vector_field_int: {
                                type: "list",
                                items: [
                                    {
                                        type: "integer",
                                        meta: {
                                            doc: "x coordinate"
                                        }
                                    },
                                    {
                                        type: "integer",
                                        meta: {
                                            doc: "y coordinate"
                                        }
                                    },
                                    {
                                        type: "integer",
                                        meta: {
                                            doc: "z coordinate"
                                        }
                                    }
                                ],
                                meta: {
                                    doc: "vector field example"
                                }
                            },
                            vector_field_float: {
                                type: "list",
                                items: [
                                    {
                                        type: "float",
                                        meta: {
                                            doc: "x coordinate"
                                        }
                                    },
                                    {
                                        type: "float",
                                        meta: {
                                            doc: "y coordinate"
                                        }
                                    },
                                    {
                                        type: "float",
                                        meta: {
                                            doc: "z coordinate"
                                        }
                                    }
                                ],
                                meta: {
                                    doc: "vector field example"
                                }
                            },
                            complex_field: {
                                type: "complex",
                                items: [{
                                    type: "float",
                                    meta: {
                                        doc: "docstring"
                                    }
                                },
                                {
                                    type: "float",
                                    meta: {
                                        doc: "docstring"
                                    }
                                }],
                                meta: {
                                    doc: "Complex field example"
                                }
                            },
                            checkbox_field: {
                                type: "boolean",
                                meta: {
                                    doc: "checkbox field example"
                                }
                            },
                        },
                        meta: {
                            doc: 'multiple field example'
                        }
                    },
                    meta: {
                        doc: 'multiple field example'
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "multiple with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        multiple_required_true_empty_true: {
            base_schema_version: "1.0",
            label: "multiple_required_true_empty_true",
            name: "multiple_required_true_empty_true_v_1.0",
            definition: {
                multiple_field: {
                    type: "list",
                    required: true,
                    empty: true,
                    schema: {
                        type: 'dict',
                        schema: {
                            text_field: {
                                required: false,
                                type: "string",
                                meta: {
                                    doc: "text field example"
                                }
                            },
                            number_field_float: {
                                required: false,
                                type: "float",
                                meta: {
                                    doc: "number field example for float value"
                                }
                            },
                            select_field: {
                                required: false,
                                type: "string",
                                allowed: ['first', 'second', 'third'],
                                meta: {
                                    doc: "select field example with default value"
                                }
                            },
                            vector_field_int: {
                                required: false,
                                type: "list",
                                items: [
                                    {
                                        type: "integer",
                                        meta: {
                                            doc: "x coordinate"
                                        }
                                    },
                                    {
                                        type: "integer",
                                        meta: {
                                            doc: "y coordinate"
                                        }
                                    },
                                    {
                                        type: "integer",
                                        meta: {
                                            doc: "z coordinate"
                                        }
                                    }
                                ],
                                meta: {
                                    doc: "vector field example"
                                }
                            },
                            complex_field: {
                                required: false,
                                type: "complex",
                                items: [{
                                    type: "float",
                                },
                                {
                                    type: "float",
                                }],
                                meta: {
                                    doc: "Complex field example"
                                }
                            },
                        },
                        meta: {
                            doc: 'multiple field example'
                        }
                    },
                    meta: {
                        doc: 'multiple field example'
                    }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "multiple with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_multiple: {
            base_schema_version: "1.0",
            label: "nested_multiple",
            name: "nested_multiple_v_1.0",
            definition: {
                multiple_text_numbers: {
                    type: "list",
                    meta: { doc: 'multiple with numbers' },
                    schema: {
                        type: 'dict',
                        meta: { doc: 'multiple with numbers' },
                        schema: {
                            text_field_default_required: {
                                type: "string", required: true, default: 'test',
                                meta: {
                                    doc: "text field example with default value"
                                },
                            },
                            number_field_float_default_required: {
                                type: "float", required: true, default: 1.3,
                                meta: {
                                    doc: "number field example for float value"
                                }
                            },
                            number_field_int_default_required: {
                                type: "integer", required: true, default: 7,
                                meta: {
                                    doc: "number field example for integer value"
                                }
                            },
                            multiple_select: {
                                type: 'list', meta: { doc: 'multiple select' },
                                schema:
                                {
                                    type: 'dict',
                                    meta: {
                                        doc: 'multiple with select'
                                    },
                                    schema: {
                                        select_field_default_required: {
                                            type: "string",
                                            required: true,
                                            default: 'second',
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        multiple_vectors: {
                                            type: 'list',
                                            meta: {
                                                doc: 'multiple with vectors'
                                            },
                                            schema:
                                            {
                                                type: 'dict',
                                                meta: {
                                                    doc: "dict inside multiple"
                                                },
                                                schema: {
                                                    vector_field_int_default_required: {
                                                        type: "list",
                                                        meta: {
                                                            doc: "vector field example"
                                                        },
                                                        required: true, default: [1, 2, 3],
                                                        items: [
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "x coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "y coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "z coordinate"
                                                                }
                                                            }
                                                        ],

                                                    },
                                                    vector_field_float_default_required: {
                                                        type: "list",
                                                        meta: {
                                                            doc: "vector field example"
                                                        },
                                                        required: true,
                                                        default: [0.1, 0.3, 2],
                                                        items: [
                                                            {
                                                                type: "float",
                                                                meta: {
                                                                    doc: "x coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "float",
                                                                meta: {
                                                                    doc: "y coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "float",
                                                                meta: {
                                                                    doc: "z coordinate"
                                                                }
                                                            }
                                                        ],

                                                    },
                                                    multiple_complex: {
                                                        type: 'list',
                                                        meta: {
                                                            doc: 'multiple with complex'
                                                        },
                                                        schema:
                                                        {
                                                            type: 'dict',
                                                            meta: {
                                                                doc: "dict of multiple"
                                                            },
                                                            schema: {
                                                                complex_field_default_required: {
                                                                    type: "complex",
                                                                    meta: {
                                                                        doc: "Complex field example"
                                                                    },
                                                                    required: true,
                                                                    default: [1, 0.3],
                                                                    items: [{
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Real part of complex number"
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Imaginary part of complex number"
                                                                        }
                                                                    }],

                                                                },
                                                            }
                                                        }
                                                    },
                                                }
                                            }
                                        },
                                    },

                                }
                            },
                        },
                    },
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "multiple with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_multiple_containing_list: {
            base_schema_version: "1.0",
            label: "nested_multiple_containing_list",
            name: "nested_multiple_containing_list_v_1.0",
            definition: {
                multiple_text: {
                    type: "list",
                    meta: { doc: 'multiple with numbers' },
                    schema: {
                        type: 'dict',
                        meta: { doc: 'multiple with numbers' },
                        schema: {
                            text_field: {
                                type: "string", required: true, default: 'test',
                                meta: {
                                    doc: "text field example with default value"
                                },
                            },
                            multiple_select: {
                                type: 'list', meta: { doc: 'multiple select' },
                                schema:
                                {
                                    type: 'dict',
                                    meta: {
                                        doc: 'multiple with select'
                                    },
                                    schema: {
                                        select_field: {
                                            type: "string",
                                            required: true,
                                            default: 'second',
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        multiple_list: {
                                            type: 'list',
                                            meta: {
                                                doc: 'multiple wth list'
                                            },
                                            schema:
                                            {
                                                type: 'dict',
                                                meta: {
                                                    doc: "dict inside multiple"
                                                },
                                                schema: {
                                                    list_field: {
                                                        type: "list",
                                                        meta: {
                                                            doc: "list"
                                                        },
                                                        required: true,
                                                        items: [
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "int"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "int"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "int"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "int"
                                                                }
                                                            }
                                                        ],

                                                    },
                                                }
                                            }
                                        },
                                    },

                                }
                            },
                        },
                    },
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "multiple with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_multiple_required_true_empty_true: {
            base_schema_version: "1.0",
            label: "nested_multiple_required_true_empty_true",
            name: "nested_multiple_required_true_empty_true_v_1.0",
            definition: {
                multiple_text_numbers: {
                    type: "list",
                    required: true,
                    empty: true,
                    meta: { doc: 'multiple with numbers' },
                    schema: {
                        type: 'dict',
                        meta: { doc: 'multiple with numbers' },
                        schema: {
                            text_field: {
                                type: "string", required: false,
                                meta: {
                                    doc: "text field example with default value"
                                },
                            },
                            number_field_float: {
                                type: "float", required: false,
                                meta: {
                                    doc: "number field example for float value"
                                }
                            },
                            multiple_select: {
                                type: 'list', required: true, empty: true,
                                meta: { doc: 'multiple select' },
                                schema:
                                {
                                    type: 'dict',
                                    meta: {
                                        doc: 'multiple with select'
                                    },
                                    schema: {
                                        select_field: {
                                            type: "string",
                                            required: false,
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        multiple_vectors: {
                                            type: 'list',
                                            required: true, empty: true,
                                            meta: {
                                                doc: 'multiple with vectors'
                                            },
                                            schema:
                                            {
                                                type: 'dict',
                                                meta: {
                                                    doc: "dict inside multiple"
                                                },
                                                schema: {
                                                    vector_field_int: {
                                                        type: "list",
                                                        required: false,
                                                        meta: {
                                                            doc: "vector field example"
                                                        },
                                                        items: [
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "x coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "y coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "z coordinate"
                                                                }
                                                            }
                                                        ],

                                                    },
                                                    multiple_complex: {
                                                        type: 'list',
                                                        required: true, empty: true,
                                                        meta: {
                                                            doc: 'multiple with complex'
                                                        },
                                                        schema:
                                                        {
                                                            type: 'dict',
                                                            meta: {
                                                                doc: "dict of multiple"
                                                            },
                                                            schema: {
                                                                complex_field: {
                                                                    type: "complex",
                                                                    meta: {
                                                                        doc: "Complex field example"
                                                                    },
                                                                    required: false,
                                                                    items: [{
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Real part of complex number"
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Imaginary part of complex number"
                                                                        }
                                                                    }],

                                                                },
                                                            }
                                                        }
                                                    },
                                                }
                                            }
                                        },
                                    },

                                }
                            },
                        },
                    },
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "multiple with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_multiple_required_true_empty_false: {
            base_schema_version: "1.0",
            label: "nested_multiple_required_true_empty_false",
            name: "nested_multiple_required_true_empty_false_v_1.0",
            definition: {
                multiple_text_numbers: {
                    type: "list",
                    required: true,
                    empty: false,
                    meta: { doc: 'multiple with numbers' },
                    schema: {
                        type: 'dict',
                        meta: { doc: 'multiple with numbers' },
                        schema: {
                            text_field: {
                                type: "string", required: false,
                                meta: {
                                    doc: "text field example with default value"
                                },
                            },
                            number_field_float: {
                                type: "float", required: false,
                                meta: {
                                    doc: "number field example for float value"
                                }
                            },
                            multiple_select: {
                                type: 'list', required: true, empty: false,
                                meta: { doc: 'multiple select' },
                                schema:
                                {
                                    type: 'dict',
                                    meta: {
                                        doc: 'multiple with select'
                                    },
                                    schema: {
                                        select_field: {
                                            type: "string",
                                            required: false,
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        multiple_vectors: {
                                            type: 'list',
                                            required: true, empty: false,
                                            meta: {
                                                doc: 'multiple with vectors'
                                            },
                                            schema:
                                            {
                                                type: 'dict',
                                                meta: {
                                                    doc: "dict inside multiple"
                                                },
                                                schema: {
                                                    vector_field_int: {
                                                        type: "list",
                                                        required: false,
                                                        meta: {
                                                            doc: "vector field example"
                                                        },
                                                        items: [
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "x coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "y coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "z coordinate"
                                                                }
                                                            }
                                                        ],

                                                    },
                                                    multiple_complex: {
                                                        type: 'list',
                                                        required: false, empty: false,
                                                        meta: {
                                                            doc: 'multiple with complex'
                                                        },
                                                        schema:
                                                        {
                                                            type: 'dict',
                                                            meta: {
                                                                doc: "dict of multiple"
                                                            },
                                                            schema: {
                                                                complex_field: {
                                                                    type: "complex",
                                                                    meta: {
                                                                        doc: "Complex field example"
                                                                    },
                                                                    required: false,
                                                                    items: [{
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Real part of complex number"
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Imaginary part of complex number"
                                                                        }
                                                                    }],

                                                                },
                                                            }
                                                        }
                                                    },
                                                }
                                            }
                                        },
                                    },

                                }
                            },
                        },
                    },
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "multiple with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_multiple_required_false_empty_true: {
            base_schema_version: "1.0",
            label: "nested_multiple_required_false_empty_true",
            name: "nested_multiple_required_false_empty_true_v_1.0",
            definition: {
                multiple_text_numbers: {
                    type: "list",
                    required: false,
                    empty: true,
                    meta: { doc: 'multiple with numbers' },
                    schema: {
                        type: 'dict',
                        meta: { doc: 'multiple with numbers' },
                        schema: {
                            text_field: {
                                type: "string", required: false,
                                meta: {
                                    doc: "text field example with default value"
                                },
                            },
                            number_field_float: {
                                type: "float", required: false,
                                meta: {
                                    doc: "number field example for float value"
                                }
                            },
                            multiple_select: {
                                type: 'list', required: false, empty: true,
                                meta: { doc: 'multiple select' },
                                schema:
                                {
                                    type: 'dict',
                                    meta: {
                                        doc: 'multiple with select'
                                    },
                                    schema: {
                                        select_field: {
                                            type: "string",
                                            required: false,
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        multiple_vectors: {
                                            type: 'list',
                                            required: false, empty: true,
                                            meta: {
                                                doc: 'multiple with vectors'
                                            },
                                            schema:
                                            {
                                                type: 'dict',
                                                meta: {
                                                    doc: "dict inside multiple"
                                                },
                                                schema: {
                                                    vector_field_int: {
                                                        type: "list",
                                                        required: false,
                                                        meta: {
                                                            doc: "vector field example"
                                                        },
                                                        items: [
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "x coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "y coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "z coordinate"
                                                                }
                                                            }
                                                        ],

                                                    },
                                                    multiple_complex: {
                                                        type: 'list',
                                                        required: false, empty: true,
                                                        meta: {
                                                            doc: 'multiple with complex'
                                                        },
                                                        schema:
                                                        {
                                                            type: 'dict',
                                                            meta: {
                                                                doc: "dict of multiple"
                                                            },
                                                            schema: {
                                                                complex_field: {
                                                                    type: "complex",
                                                                    meta: {
                                                                        doc: "Complex field example"
                                                                    },
                                                                    required: false,
                                                                    items: [{
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Real part of complex number"
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Imaginary part of complex number"
                                                                        }
                                                                    }],

                                                                },
                                                            }
                                                        }
                                                    },
                                                }
                                            }
                                        },
                                    },

                                }
                            },
                        },
                    },
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "multiple with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        nested_multiple_required_false_empty_false: {
            base_schema_version: "1.0",
            label: "nested_multiple_required_false_empty_false",
            name: "nested_multiple_required_false_empty_false_v_1.0",
            definition: {
                multiple_text_numbers: {
                    type: "list",
                    required: false,
                    empty: false,
                    meta: { doc: 'multiple with numbers' },
                    schema: {
                        type: 'dict',
                        meta: { doc: 'multiple with numbers' },
                        schema: {
                            text_field: {
                                type: "string", required: false,
                                meta: {
                                    doc: "text field example with default value"
                                },
                            },
                            number_field_float: {
                                type: "float", required: false,
                                meta: {
                                    doc: "number field example for float value"
                                }
                            },
                            multiple_select: {
                                type: 'list', required: false, empty: false,
                                meta: { doc: 'multiple select' },
                                schema:
                                {
                                    type: 'dict',
                                    meta: {
                                        doc: 'multiple with select'
                                    },
                                    schema: {
                                        select_field: {
                                            type: "string",
                                            required: false,
                                            allowed: ['first', 'second', 'third'],
                                            meta: {
                                                doc: "select field example with default value"
                                            }
                                        },
                                        multiple_vectors: {
                                            type: 'list',
                                            required: false, empty: false,
                                            meta: {
                                                doc: 'multiple with vectors'
                                            },
                                            schema:
                                            {
                                                type: 'dict',
                                                meta: {
                                                    doc: "dict inside multiple"
                                                },
                                                schema: {
                                                    vector_field_int: {
                                                        type: "list",
                                                        required: false,
                                                        meta: {
                                                            doc: "vector field example"
                                                        },
                                                        items: [
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "x coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "y coordinate"
                                                                }
                                                            },
                                                            {
                                                                type: "integer",
                                                                meta: {
                                                                    doc: "z coordinate"
                                                                }
                                                            }
                                                        ],

                                                    },
                                                    multiple_complex: {
                                                        type: 'list',
                                                        required: false, empty: false,
                                                        meta: {
                                                            doc: 'multiple with complex'
                                                        },
                                                        schema:
                                                        {
                                                            type: 'dict',
                                                            meta: {
                                                                doc: "dict of multiple"
                                                            },
                                                            schema: {
                                                                complex_field: {
                                                                    type: "complex",
                                                                    meta: {
                                                                        doc: "Complex field example"
                                                                    },
                                                                    required: false,
                                                                    items: [{
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Real part of complex number"
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "float",
                                                                        meta: {
                                                                            doc: "Imaginary part of complex number"
                                                                        }
                                                                    }],

                                                                },
                                                            }
                                                        }
                                                    },
                                                }
                                            }
                                        },
                                    },

                                }
                            },
                        },
                    },
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "multiple with basic inputs",
            version: "1.0",
            groups: ['hqs-internal']
        },
        multiple_containing_dict: {
            base_schema_version: "1.0",
            label: "multiple_containing_dict",
            name: "multiple_containing_dict_v_1.0",
            definition: {
                multiple: {
                    type: "list",
                    schema: {
                        type: 'dict',
                        schema: {
                            dict: {
                                type: "dict",
                                meta: {
                                    doc: "dict field example"
                                },
                                schema: {
                                    text_field: {
                                        type: "string",
                                        meta: {
                                            doc: "text field example"
                                        }
                                    },
                                }
                            },
                            meta: {
                                doc: 'multiple example'
                            }
                        },
                        meta: {
                            doc: 'multiple example'
                        }
                    },
                },
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "multiple containing dict",
            version: "1.0",
            groups: ['hqs-internal']
        },
        foldable_list: {
            base_schema_version: "1.0",
            label: "foldable_list",
            name: "foldable_list_v_1.0",
            definition: {
                array: {
                    type: "list",
                    required: true,
                    items: [
                        {
                            type: "list",
                            items: [
                                {
                                    type: "integer",
                                    default: 0,
                                },
                                {
                                    type: "float",
                                },
                                {
                                    type: "list",
                                    items: [
                                        {
                                            type: "integer",
                                            default: 0,
                                        },
                                        {
                                            type: "float",
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            type: "integer",
                            default: 0,
                        },
                        {
                            type: "float",
                        },
                    ],
                    meta: { doc: 'list field example' }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for Foldable List",
            version: "1.0",
            groups: ['hqs-internal']
        },
        simple_foldable_list: {
            base_schema_version: "1.0",
            label: "simple_foldable_list",
            name: "simple_foldable_list_v_1.0",
            definition: {
                layer_1: {
                    type: "list",
                    required: true,
                    items: [
                        {
                            type: "integer",
                            meta: {
                                doc: 'list item'
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: 'list item'
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: 'list item'
                            }
                        },
                        {
                            type: "integer",
                            meta: {
                                doc: 'list item'
                            }
                        },
                    ],
                    meta: { doc: 'list field example' }
                }
            },
            parent: {
                label: "data",
                version: "1.0"
            },
            doc: "Node for Foldable List",
            version: "1.0",
            groups: ['hqs-internal']
        },
        matrix: {
            label: 'matrix',
            name: 'matrix_v_1.0',
            version: '0.1',
            base_schema_version: '1.0',
            groups: ['test'],
            doc: 'Node for a simple 3x3 matrix schema.',
            parent: {
                label: 'data',
                version: '1.0',
            },
            definition: {
                matrix_coefficients: {
                    type: 'list',
                    items: [
                        {
                            type: 'list',
                            items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.' } },
                                { type: 'float', meta: { doc: 'matrix coefficient.' } },
                                { type: 'float', meta: { doc: 'matrix coefficient.' } },
                            ],
                            meta: { doc: 'matrix columns.' },
                        },
                        {
                            type: 'list',
                            items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.' } },
                                { type: 'float', meta: { doc: 'matrix coefficient.' } },
                                { type: 'float', meta: { doc: 'matrix coefficient.' } },
                            ],
                            meta: { doc: 'matrix columns.' },
                        },
                        {
                            type: 'list',
                            items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.' } },
                                { type: 'float', meta: { doc: 'matrix coefficient.' } },
                                { type: 'float', meta: { doc: 'matrix coefficient.' } },
                            ],
                            meta: { doc: 'matrix columns.' },
                        },
                    ],
                    meta: {
                        doc: 'matrix rows.',
                        unit: 'm/s',
                        hint: 'matrix'
                    },
                },
            },
        },
        matrix_default_values: {
            label: 'matrix_default_values',
            name: 'matrix_default_values_v_1.0',
            version: '0.1',
            base_schema_version: '1.0',
            groups: ['test'],
            doc: 'Node for a simple 3x3 matrix schema.',
            parent: {
                label: 'data',
                version: '1.0',
            },
            definition: {
                matrix_coefficients: {
                    type: 'list', empty: false,
                    items: [
                        {
                            type: 'list', items: [
                                { type: 'float', default: 1, meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', default: 2, meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', default: 3, meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: {
                                doc: 'matrix columns.',
                            },
                        },
                        {
                            type: 'list', items: [
                                { type: 'float', default: 4, meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', default: 5, meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', default: 6, meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: {
                                doc: 'matrix columns.',
                            },
                        },
                        {
                            type: 'list', items: [
                                { type: 'float', default: 7, meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', default: 8, meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', default: 9, meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: { doc: 'matrix columns.', },
                        },
                    ],
                    meta: { doc: 'matrix rows.', },
                },
            },
        },
        matrix_empty_false_required_false: {
            label: 'matrix_empty=false_required=false',
            name: 'matrix_empty=false_required=false_v_1.0',
            version: '0.1',
            base_schema_version: '1.0',
            groups: ['test'],
            doc: 'Node for a simple 3x3 matrix schema.',
            parent: {
                label: 'data',
                version: '1.0',
            },
            definition: {
                matrix_coefficients: {
                    type: 'list', empty: false, required: false,
                    items: [
                        {
                            type: 'list', required: false, items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: { doc: 'matrix columns.' },
                        },
                        {
                            type: 'list', required: false, items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: { doc: 'matrix columns.' },
                        },
                        {
                            type: 'list', required: false, items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: { doc: 'matrix columns.' },
                        },
                    ],
                    meta: { doc: 'matrix rows.', },
                },
            },
        },
        matrix_empty_true_required_false: {
            label: 'matrix_empty=true_required=false',
            name: 'matrix_empty=true_required=false_v_1.0',
            version: '0.1',
            base_schema_version: '1.0',
            groups: ['test'],
            doc: 'Node for a simple 3x3 matrix schema.',
            parent: {
                label: 'data',
                version: '1.0',
            },
            definition: {
                matrix_coefficients: {
                    type: 'list', required: false,
                    items: [
                        {
                            type: 'list', items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: {
                                doc: 'matrix columns.',
                            },
                        },
                        {
                            type: 'list', items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: {
                                doc: 'matrix columns.',
                            },
                        },
                        {
                            type: 'list', items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: { doc: 'matrix columns.', },
                        },
                    ],
                    meta: { doc: 'matrix rows.', },
                },
            },
        },
        matrix_empty_false_required_true: {
            label: 'matrix_empty=false_required=true',
            name: 'matrix_empty=false_required=true_v_1.0',
            version: '0.1',
            base_schema_version: '1.0',
            groups: ['test'],
            doc: 'Node for a simple 3x3 matrix schema.',
            parent: {
                label: 'data',
                version: '1.0',
            },
            definition: {
                matrix_coefficients: {
                    type: 'list', empty: false,
                    items: [
                        {
                            type: 'list', items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: {
                                doc: 'matrix columns.',
                            },
                        },
                        {
                            type: 'list', items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: {
                                doc: 'matrix columns.',
                            },
                        },
                        {
                            type: 'list', items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: { doc: 'matrix columns.', },
                        },
                    ],
                    meta: { doc: 'matrix rows.', },
                },
            },
        },
        matrix_empty_true_required_true: {
            label: 'matrix_empty=true_required=true',
            name: 'matrix_empty=true_required=true_v_1.0',
            version: '0.1',
            base_schema_version: '1.0',
            groups: ['test'],
            doc: 'Node for a simple 3x3 matrix schema.',
            parent: {
                label: 'data',
                version: '1.0',
            },
            definition: {
                matrix_coefficients: {
                    type: 'list',
                    items: [
                        {
                            type: 'list', items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: {
                                doc: 'matrix columns.',
                            },
                        },
                        {
                            type: 'list', items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: {
                                doc: 'matrix columns.',
                            },
                        },
                        {
                            type: 'list', items: [
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                                { type: 'float', meta: { doc: 'matrix coefficient.', } },
                            ],
                            meta: { doc: 'matrix columns.', },
                        },
                    ],
                    meta: { doc: 'matrix rows.', },
                },
            },
        },
        molecule_with_min_max_length_constraints: {
            base_schema_version: '1.0',
            tags: [
                'structure', 
                'frontend'
            ],
            label: 'molecule',
            version: '0.1',
            doc: 'Node for molecule definition.',
            parent: {
                label: 'topology',
                version: '1.0'
            },
            definition: {
              atoms: {
                type: 'multiple',
                minlength: 3,
                maxlength: 5,
                empty: false,
                schema: {
                    type: 'dict',
                    schema: {
                        symbol: {
                            type: 'string',
                            allowed: [
                                'H', 
                                'O', 
                                'C', 
                                'N',
                            ],
                            meta: {
                                doc: 'Chemical symbol of the atom.'
                            },
                            required: true
                        },
                        position: {
                            type: 'list',
                            items: [
                                {
                                    type: 'float',
                                    meta: { 
                                        doc: 'x coordinate in Angstrom.' 
                                    }
                                },
                                {
                                    type: 'float',
                                    meta: { 
                                        doc: 'y coordinate in Angstrom.' 
                                    }
                                },
                                {
                                    type: 'float',
                                    meta: { 
                                        doc: 'z coordinate in Angstrom.' 
                                    }
                                }
                            ],
                            meta: { 
                                doc: 'Position of the atom.'
                            },
                            required: true,
                            empty: true
                        }
                    },
                    meta: { 
                        doc: 'Atom in a molecule.' 
                    }
                },
                meta: { 
                    doc: 'List of atoms comprising the molecule.' 
                },
                required: true
              }
            },
            groups: [
                'hqs-internal'
            ],
            // Arbitrarily called this v0.2 because additional fields have been
            // added to the schema compared to v0.1 or whatever
            name: 'molecule_v_0.2'
        }
    },
}