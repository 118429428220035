<template>
  <div id="hqs-cloud-home">
    <v-img class="header" :src="require('../assets/landingPage.png')"></v-img>
    <div class="section_header">
      <h1 class="anchor">HQS Cloud</h1>
      <h2 class="text">Quantum Assisted Design Toolbox</h2>
    </div>

    <div class="section_header">
      <h2>HQS CLOUD<sup style="opacity: 0.3">beta</sup></h2>
    </div>

    <!-- Big info box -->
    <v-container>
      <v-row>
        <v-col>
          <div class="explanation text">
            <p>Welcome to the re-launch of the Beta-Version of HQS-Cloud.</p>
            <p>
              HQS-Cloud is an integrated development environment designed to
              enable the simulation of molecules and materials on conventional
              and quantum computers.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Small info boxes -->
    <v-container>
      <v-row>
        <v-col md="4" sm="4" lg="3">
          <div class="features text">
            <img
              class="icon"
              :src="require('../assets/features-interface.svg')"
              alt="Easy to use online platform"
            />
            <ul>
              <li>Easy to use online platform</li>
            </ul>
          </div>
        </v-col>
        <v-col md="4" sm="4" lg="3">
          <div class="features text">
            <img
              class="icon"
              :src="require('../assets/features-cloud.svg')"
              alt="Cloud based: easily scalable computational power"
            />
            <ul>
              <li>Cloud based: easily scalable computational power</li>
            </ul>
          </div>
        </v-col>
        <v-col md="4" sm="4" lg="3">
          <div class="features text">
            <img
              class="icon"
              :src="require('../assets/features-package.svg')"
              alt="Software package enables the solution of fermionic and
                spinlattice models in 1D and 2D"
            />
            <ul>
              <li>
                <p>Predict molecular properties</p>
                <p>Coming soon:</p>
                <p>Applications on NMR spectra and periodic systems</p>
              </li>
            </ul>
          </div>
        </v-col>
        <v-col md="4" sm="4" lg="3">
          <div class="features text">
            <img
              class="icon"
              :src="require('../assets/atom-variant.svg')"
              alt="Spin lattice models will be one of the first use cases that will
                allow for quantum advantage"
            />
            <ul>
              <li>
                Explore open quantum systems the HQS way of using NISQ devices
              </li>
            </ul>
          </div>
        </v-col>
        <v-col md="4" sm="4" lg="3">
          <div class="features text">
            <img
              class="icon"
              :src="require('../assets/api.svg')"
              alt="New API support"
            />
            <ul>
              <li>
                <p>Coming soon:</p>
                <p>New API support</p>
              </li>
            </ul>
          </div>
        </v-col>
        <v-col md="4" sm="4" lg="3">
          <div class="features text">
            <img
              class="icon"
              :src="require('../assets/sun_icon.svg')"
              alt="Quantum effects in organic semiconductors and solar cells coming soon"
            />
            <ul>
              <li>
                <p>Coming soon:</p>
                <p>Quantum effects in organic semiconductors and solar cells</p>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <a class="anchor" href="#hqs-cloud-home">HQS Home</a>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      subdomain: process.env.VUE_APP_SUBDOMAIN,
    }
  },

  methods: {
    ...mapActions('backend', ['load']),
  },

  async created() {
    await this.load(['workflow'])
    await this.load(['schema'])
  },
}
</script>

<style scoped>
h1,
h2 {
  text-align: center;
}
h1 {
  font-size: 56pt;
}
h2 {
  font-size: 26pt;
}
.header {
  max-height: 450px;
  margin-bottom: 30px;
}

.features,
.explanation {
  margin: 1em 0;
  padding: 1.5em 1em;
  border-radius: 1em;
  color: #666;
}
.features {
  background: #e2ebfa;
  margin-top: 5em;
  height: 300px;
}

.explanation {
  border: 2px solid #ccc;
  text-align: center;
  font-size: 140%;
  padding: 1.5em 3em;
}

.features.coming-soon {
  filter: saturate(0.25);
  opacity: 0.7;
}

.features .icon {
  width: 6em;
  height: 6em;
  margin: auto;
  background: white;
  padding: 0.8em;
  display: block;
  margin-top: -4em;
  margin-bottom: 1em;
  border-radius: 1em;
  border: 2px solid #ccc;
}
.features ul {
  list-style: none;
}
.features ul > li {
  position: relative;
  padding: 0.2em;
}
.features ul > li:before {
  content: '✓';
  position: absolute;
  left: -1em;
  top: -0.1em;
  font-size: 140%;
}
.home-image .v-image {
  margin: auto;
  width: 70%;
}
caption {
  display: block;
  margin-top: 3%;
  font-size: 90%;
  font-style: italic;
  text-align: center;
}

.color {
  color: #d97815 !important;
}

.blue {
  color: #427cbe !important;
}
</style>
