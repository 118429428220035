import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
/* general */
import Terms from '../views/general/Terms.vue'
import Contact from '../views/general/Contact.vue'
import Imprint from '../views/general/Imprint.vue'
import Privacy from '../views/general/Privacy.vue'
import Changelog from '../views/general/Changelog.vue'
import NotFound from '../views/general/NotFound.vue'
/* user account and authentication */
import auth from '../views/lib/auth'
import Account from '../views/Account.vue'
import Register from '../views/auth/Register.vue'
import Login from '../views/auth/Login.vue'
import Confirm from '../views/auth/Confirm.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import Credits from '../views/Credits.vue'
/* API (unused) */
import AuthApi from '../views/auth/AuthApi.vue'
/* cloud applications */
import ProductsPage from '../views/application/ProductsPage.vue'
import ProductDashboard from '../views/application/ProductDashboard.vue'
import ApplicationCards from '../views/application/ApplicationCards.vue'
import JobsPage from '../views/JobsPage.vue'
import StructuresPage from '../views/structure/StructuresPage.vue'
import StructureCards from '../views/structure/StructureCards.vue'
import StructureOverview from '../views/structure/StructureOverview.vue'
import DynamicStructureUI from '../views/structure/DynamicStructureUI.vue'
import StructureToApplicationDashboard from '../views/application/StructureToApplicationDashboard.vue'
import Results from '../views/Results.vue'
/* lattice application */
import LatticeUI from '../views/structure/LatticeUI.vue'
/* web-shop */
import Pricing from '../views/general/Pricing.vue'
import WebShop from '../views/webShop/WebShop.vue'
import ShoppingBasket from '../views/webShop/ShoppingBasket.vue'
import Success from '../views/webShop/Success.vue'
import Failure from '../views/webShop/Failure'
/* experiments and internals */
import Molecule from '../molecule3D/Molecule'
import PlotlyExample from '../plotting/PlotlyExample'
import SchemaTest from '../views/SchemaTest.vue'
import Dashboard from '../views/Dashboard.vue'
import Orders from '../views/webShop/Orders'
import TokenUI from '../views/TokenUI.vue'

Vue.use(VueRouter)

function requireAuth(to, from, next) {
    auth
        .isLoggedIn()
        .then(next)
        .catch(() => {
            next({
                path: '/auth/login',
                query: { redirect: to.fullPath }
            })
        })
}

export default new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        { path: '/', name: 'home', component: Home },
        { path: '/dashboard', name: 'dashboard', component: Dashboard },
        {
            path: '/applications', component: ProductsPage, beforeEnter: requireAuth,
            children: [
                {
                    path: 'all-applications/',
                    name: 'all-applications',
                    component: ApplicationCards,
                    beforeEnter: requireAuth,
                    props: true,
                },
                /* product dashboards */
                {
                    path: 'quantum-computing-dashboard/',
                    name: 'quantum-computing-dashboard',
                    component: ProductDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'periodic-systems-dashboard/',
                    name: 'periodic-systems-dashboard',
                    component: ProductDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'molecule-dashboard/',
                    name: 'molecule-dashboard',
                    component: ProductDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'open-system-science-dashboard/',
                    name: 'open-system-science-dashboard',
                    component: ProductDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'qolossal-dashboard/',
                    name: 'qolossal-dashboard',
                    component: ProductDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'periodic-dashboard/',
                    name: 'periodic-dashboard',
                    component: ProductDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'device-builder-dashboard/',
                    name: 'device-builder-dashboard',
                    component: ProductDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                /* dashboards that feature a selection of products that use a specufic structure */
                {
                    path: 'device-structure-dashboard/',
                    name: 'device-structure-dashboard',
                    component: StructureToApplicationDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'hamiltonian-structure-dashboard/',
                    name: 'hamiltonian-structure-dashboard',
                    component: StructureToApplicationDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'molecule-structure-dashboard/',
                    name: 'molecule-structure-dashboard',
                    component: StructureToApplicationDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'crystal-structure-dashboard/',
                    name: 'crystal-structure-dashboard',
                    component: StructureToApplicationDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'lattice-configuration-structure-dashboard/',
                    name: 'lattice-configuration-structure-dashboard',
                    component: StructureToApplicationDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'qolossal-disorder-structure-dashboard/',
                    name: 'qolossal-disorder-structure-dashboard',
                    component: StructureToApplicationDashboard,
                    beforeEnter: requireAuth,
                    props: true,
                },
            ]
        },
        {
            path: '/structures', name: 'structures', component: StructuresPage, beforeEnter: requireAuth,
            children: [
                {
                    path: 'all-structures/',
                    name: 'all-structures',
                    component: StructureCards,
                    beforeEnter: requireAuth,
                    props: true,
                },
                // routes to structure UIs
                {
                    path: 'crystal/',
                    name: 'crystal',
                    component: DynamicStructureUI,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'molecule/',
                    name: 'molecule',
                    component: DynamicStructureUI,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'hamiltonian/',
                    name: 'hamiltonian',
                    component: DynamicStructureUI,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'device/',
                    name: 'device',
                    component: DynamicStructureUI,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'lattice-configuration/',
                    name: 'lattice-configuration',
                    component: LatticeUI,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'qolossal-disorder/',
                    name: 'qolossal-disorder',
                    component: DynamicStructureUI,
                    beforeEnter: requireAuth,
                    props: true,
                },
                // routes to structure dashboards
                {
                    path: 'molecule-structures/',
                    name: 'molecule-structures',
                    component: StructureOverview,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'hamiltonian-structures/',
                    name: 'hamiltonian-structures',
                    component: StructureOverview,
                    beforeEnter: requireAuth,
                    props: true,
                }, {
                    path: 'device-structures/',
                    name: 'device-structures',
                    component: StructureOverview,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'crystal-structures/',
                    name: 'crystal-structures',
                    component: StructureOverview,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'lattice-configuration-structures/',
                    name: 'lattice-configuration-structures',
                    component: StructureOverview,
                    beforeEnter: requireAuth,
                    props: true,
                },
                {
                    path: 'qolossal-disorder-structures/',
                    name: 'qolossal-disorder-structures',
                    component: StructureOverview,
                    beforeEnter: requireAuth,
                    props: true,
                },
            ]
        },
        { path: '/jobs', name: 'jobs', component: JobsPage, beforeEnter: requireAuth },
        { path: '/results', name: 'results', component: Results, beforeEnter: requireAuth },

        { path: '/changelog', name: 'changelog', component: Changelog },
        { path: '/terms', name: 'terms', component: Terms },
        { path: '/contact', name: 'contact', component: Contact },
        { path: '/imprint', name: 'imprint', component: Imprint },
        { path: '/privacy', name: 'privacy', component: Privacy },
        { path: '/account', name: 'account', component: Account, beforeEnter: requireAuth },
        { path: '/tokens', name: 'tokens', component: TokenUI, beforeEnter: requireAuth },

        // authentication routes
        { path: '/auth/register', name: 'register', component: Register },
        { path: '/auth/confirm', component: Confirm },
        { path: '/auth/confirm/:code', component: Confirm },
        { path: '/auth/login', name: 'login', component: Login },
        { path: '/auth/api', name: 'api', component: AuthApi },
        {
            path: '/auth/logout',
            async beforeEnter(to, from, next) {
                await auth.signOut()
                Vue.bus.$emit('user:update')
                next('/')
            }
        },
        { path: '/auth/reset-password', component: ResetPassword },

        // web-shop routes
        { path: '/pricing', name: 'pricing', component: Pricing },
        { path: '/web-shop', name: 'web-shop', component: WebShop },
        { path: '/web-shop/shopping-basket', name: 'shopping-basket', component: ShoppingBasket },
        { path: '/success', name: 'success', component: Success },
        { path: '/failure', name: 'failure', component: Failure },
        { path: '/orders', name: 'orders', component: Orders },

        // credits routes
        { path: '/credits', name: 'credits', component: Credits, beforeEnter: requireAuth },

        // fallback:
        { path: '*', name: '404', component: NotFound, beforeEnter: requireAuth },

        // internal pages
        { path: '/schema-test', component: SchemaTest, name: 'schema-test', beforeEnter: requireAuth },
        { path: '/molecule-test', component: Molecule, name: 'molecule-test', beforeEnter: requireAuth },
        { path: '/plotly-example', component: PlotlyExample, name: 'plotly-example', beforeEnter: requireAuth },
    ]
})
