<template>
  <div class="mt-10 ml-10">
    <!-- workflow select -->
    <v-card class="ml-15 mr-10">
      <v-row>
        <v-col cols="3">
          <Header headerTitle="Workflows" :hasMenu="false" />
          <!-- Applications select -->
          <v-autocomplete
            class="ml-5"
            outlined
            dense
            :items="topicsHierarchy"
            item-text="label"
            label="Applications"
            v-model="selectedApplication"
            return-object
            @input="filterWorkflows('application')"
          >
          </v-autocomplete>
          <!-- Products select -->
          <v-autocomplete
            class="ml-5"
            outlined
            dense
            :items="products"
            item-text="label"
            label="Products"
            v-model="selectedProduct"
            return-object
            @input="filterWorkflows('product')"
          >
          </v-autocomplete>
          <!-- Tools and methods select -->
          <v-autocomplete
            class="ml-5"
            outlined
            dense
            :items="toolsAndMethods"
            item-text="label"
            label="Tools and Methods"
            v-model="selectedToolMethod"
            return-object
            @input="filterWorkflows('toolMethod')"
          >
          </v-autocomplete>
        </v-col>
        <!-- Workflow list columns -->
        <v-col
          cols="3"
          v-for="(workflowGroup, index) in groupedWorkflows"
          :key="index"
        >
          <v-list-item-group>
            <v-list nav dense>
              <v-list-item
                v-for="(workflow, index) in workflowGroup"
                :key="index"
                @click="prepareCurrentWorkflow(workflow)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="workflow.label"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-col>
      </v-row>
    </v-card>
    <!-- Inputs, outputs and BPMN card -->
    <v-card class="ml-15 mr-10 mt-5">
      <v-row>
        <!-- Workflow inputs column -->
        <v-col cols="3">
          <Header headerTitle="Inputs" :hasMenu="false" />
          <v-list-item-group v-model="selectedInput">
            <v-list nav>
              <v-list-item
                v-for="(item, index) in inputs"
                :key="index"
                @click="showInfo(item)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.label + ' ' + item.version"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-col>
        <!-- BPMN column -->
        <v-col cols="6">
          <Header :headerTitle="headerTitle" :hasMenu="false" />
          <v-img :src="require('../assets/exampleBPMN.png')" />
        </v-col>
        <!-- Workflow outputs column -->
        <v-col cols="3">
          <Header headerTitle="Outputs" :hasMenu="false" />
          <v-list-item-group v-model="selectedOutput">
            <v-list nav>
              <v-list-item
                v-for="(item, index) in outputs"
                :key="index"
                @click="showInfo(item)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.label + ' ' + item.version"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-col>
      </v-row>
    </v-card>
    <!-- Jobs for selected workflow card -->
    <v-card class="ml-15 mr-10 mt-5">
      <v-row>
        <!-- structures and params column -->
        <!-- structures -->
        <v-col cols="3">
          <Header headerTitle="Structures" :hasMenu="false" />
          <v-list-item-group v-model="selected" v-if="hasProcess">
            <v-list nav>
              <v-list-item v-for="(item, index) in structures" :key="index">
                <v-list-item-content>
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
          <!-- params -->
          <Header headerTitle="Job Params" :hasMenu="false" />
          <v-list-item-group v-model="selected">
            <v-list nav>
              <v-list-item v-for="(item, index) in params" :key="index">
                <v-list-item-content>
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-col>
        <!-- jobs table for selected workflow -->
        <v-col cols="6" class="ml-n5">
          <Header headerTitle="Jobs Created With Workflow 1" :hasMenu="false" />
          <SimpleJobsTable />
        </v-col>
        <!-- results column -->
        <v-col cols="3">
          <Header headerTitle="Results" :hasMenu="false" />
          <v-list-item-group v-model="selected">
            <v-list nav>
              <v-list-item v-for="(item, index) in results" :key="index">
                <v-list-item-content>
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-col>
      </v-row>
    </v-card>
    <ConfigurationInfo
      ref="info"
      :structure="selectedData"
      :hasTabs="hasTabs"
    />
  </div>
</template>

<script>
const Header = () => import('../components/general/Header.vue')
const ConfigurationInfo = () =>
  import('../components/general/ConfigurationInfo.vue')
const SimpleJobsTable = () => import('../components/jobs/SimpleJobsTable')
import utils from '../appUtils/utils.js'
import { cloneDeep, orderBy, isEmpty } from 'lodash-es'
import { mapActions, mapState } from 'vuex'

export default {
  components: { Header, ConfigurationInfo, SimpleJobsTable },

  data() {
    return {
      /* Workflows card */
      products: [],
      toolsAndMethods: [],
      selectedApplication: [],
      selectedProduct: [],
      selectedToolMethod: [],
      selectedWorkflow: {},
      currentWorkflows: {},
      /* BPMN card */
      selectedInput: [],
      selectedOutput: [],
      inputs: [],
      outputs: [],
      /* jobs card */
      selected: [],
      currentProcesses: [],
      results: ['result 1', 'result 2'],
      structures: ['structure 1', 'structure 2'],
      params: ['param 1', 'param 2'],
      /* ConfigurationInfo */
      hasTabs: false,
      selectedData: {},
    }
  },

  methods: {
    ...mapActions('backend', ['load']),

    filterWorkflows(type) {
      let tags = []
      if (type === 'application') {
        tags = this.selectedApplication.tags
        this.products = this.selectedApplication.products
      } else if (type === 'product') {
        tags = this.selectedProduct.tags
        this.products = [this.selectedProduct]
        this.selectedToolMethod = []
      } else {
        if (isEmpty(this.selectedProduct)) {
          tags = this.selectedToolMethod.tags
        } else if (
          typeof this.selectedProduct === 'object' &&
          !Array.isArray(this.selectedProduct)
        ) {
          tags = [this.selectedToolMethod.tags, this.selectedProduct.tags]
        }
      }
      this.currentWorkflows = utils.filterForMatchingTags(
        cloneDeep(this.workflows),
        tags
      )
      this.toolsAndMethods = this.findToolsMethods()
    },

    findToolsMethods() {
      return cloneDeep(this.products)
        .filter((e) => 'tools_methods' in e)
        .map((e) => (e = e.tools_methods))
        .flat(1)
    },

    prepareCurrentWorkflow(workflow) {
      // todo: as soon as this feature is merged find the latest version here
      this.inputs = this.getWorkflowElements('inputs', workflow)
      this.outputs = this.getWorkflowElements('outputs', workflow)
      this.selectedWorkflow = workflow
      this.prepareJobs(workflow)
    },

    getWorkflowElements(type, workflow) {
      let items = []
      for (let item of Object.values(workflow[type])) {
        items.push(this.schemas.find((e) => e.label === item.hqschema.label))
        return items
      }
    },

    prepareJobs(workflow) {
      this.currentProcesses = this.processes.filter(
        (e) => e.workflow.label === workflow.label
      )
    },

    showInfo(item) {
      this.selectedData = item
      this.$refs.info.openDialog()
    },
  },

  computed: {
    ...mapState('backend', {
      workflows: (state) => state.workflows,
      processes: (state) => state.processes,
      process: (state) => state.process,
      schemas: (state) => state.schemas,
      topicsHierarchy: (state) => state.topicsHierarchy,
    }),

    groupedWorkflows() {
      let workflows = cloneDeep(this.currentWorkflows)
      workflows = orderBy(workflows, 'label', 'asc')
      const SUBDIVISION_LENGTH = 7
      return utils.subdivideArray(workflows, SUBDIVISION_LENGTH)
    },

    headerTitle() {
      return isEmpty(this.selectedWorkflow)
        ? 'BPMN For Workflow'
        : 'BPMN For ' + utils.formatLabel(this.selectedWorkflow.label)
    },

    hasProcess() {
      if (isEmpty(this.process)) {
        return false
      }
      return true
    },
  },

  created() {
    if (this.processes.length === 0) {
      this.load(['process'])
    }
    if (this.workflows.length === 0) {
      this.load(['workflow'])
    }
    if (this.schemas.length === 0) {
      this.load(['schema'])
    }
    this.products = cloneDeep(this.topicsHierarchy)
      .map((e) => (e = e.products))
      .flat(1)
    this.toolsAndMethods = this.findToolsMethods()
    this.currentWorkflows = cloneDeep(this.workflows)
  },
}
</script>

<style lang="scss" scoped></style>
