<template>
<div id="account" class="page-margin-left page-margin-top">
  <a class="anchor" href="#account">Account</a>
  <v-container>
    <v-row align="start">
      <v-col>
        <h1 class="text">My Account</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Attributes />
      </v-col>
      <v-col>
        <Password />
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import Attributes from '../components/account/Attributes'
import Password from '../components/account/Password'

export default {
  components: { Attributes, Password }
}
</script>
