<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn data-cy="copy_to_clipboard" x-small icon v-bind="attrs" v-on="on" @click="copyToClipboard()">
        <v-icon :color="color" :size="size">mdi-content-copy</v-icon>
      </v-btn>
    </template>
    <span>Copy to Clipboard</span>
  </v-tooltip>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  components: {},

  data() {
    return {}
  },

  props: {
    copyData: [Object, Array],
    color: String,
    size: String,
    isString: Boolean,
  },

  methods: {
    ...mapMutations('backend', ['activateAlert', 'resetAlert']),

    copyToClipboard() {
      try {
        if (this.isString) {
          navigator.clipboard.writeText(this.copyData.configuration)
        } else {
          navigator.clipboard.writeText(JSON.stringify(this.copyData, null, 2))
          this.activateAlert('success')
        }
      } catch (error) {
        this.activateAlert('error')
      }
      window.setTimeout(() => {
        this.resetAlert()
      }, 7000)
    },
  },
}
</script>

<style scoped></style>
