<template>
  <div class="page-margin-left page-margin-top">
    <v-card>
      <Header headerTitle="Credit Transactions" :hasMenu="hasMenu" />
      <CreditTransactionsTable />
    </v-card>
  </div>
</template>

<script>
const Header = () => import('../components/general/Header.vue')
const CreditTransactionsTable = () =>
  import('../components/CreditTransactionsTable.vue')

export default {
  components: { Header, CreditTransactionsTable },

  data() {
    return {
      /* Header */
      hasMenu: false,
    }
  },

}
</script>

<style lang="scss" scoped></style>
