<template>
  <div>
    <v-row>
      <v-col>
        <h1 class="text header">Molecules</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <div class="outer">
          <iframe
            class="molecule-frame"
            src="/output_2.html"
            width="80%"
            height="500"
            frameborder="2"
          >
          </iframe>
        </div>
      </v-col>
      <v-col cols="5">
        <div>
          <iframe
            class="molecule-frame"
            src="/output.html"
            width="100%"
            height="500"
            frameborder="2"
          >
          </iframe>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {},
}
</script>

<style scoped>
.molecule-frame {
  margin-left: 150px;
}

h1 {
  margin-left: 100px;
  margin-top: 100px;
}
</style>
