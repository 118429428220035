<template>
  <v-container class="mt-15 text">
    <v-row class="mb-5">
      <v-col v-if="toggleLanguage === 'de'">
        <h1>Allgemeine Geschäftsbedingungen</h1>
      </v-col>
      <v-col v-if="toggleLanguage === 'eng'">
        <h1>Terms of Use</h1>
      </v-col>
      <v-col
        ><v-btn-toggle class="toggle-button" mandatory dense>
          <v-btn @click="toggleLanguage = 'de'"> de </v-btn>
          <v-btn @click="toggleLanguage = 'eng'"> eng </v-btn>
        </v-btn-toggle></v-col
      >
    </v-row>
    <!-- german version-->
    <div v-if="toggleLanguage === 'de'">
      <v-row>
        <v-col>
          <h3 class="text-bold">
            Nutzungsvertrag über die Software und Plattform „HQS-Cloud“
          </h3>
          <br />
          <ol>
            <!-- 1. -->
            <li>
              <h4>Geltungsbereich</h4>
              <ol>
                <li>
                  Die nachfolgenden Bedingungen gelten für sämtliche Leistungen
                  der HQS Quantum Simulations GmbH (im Folgenden "HQS" genannt)
                  gegenüber Ihren Kunden (im Folgenden "Nutzer" genannt) im
                  Zusammenhang mit der Bereitstellung und Erbringung von
                  SaaS-Lösungen und dem damit zusammenhängenden Service.
                </li>
                <li>
                  Die Mitarbeiter der HQS sind nicht berechtigt, von diesen
                  Bedingungen abweichende Vereinbarungen, Nebenabreden,
                  individuelle Garantiezusagen oder Zusicherungen zu treffen, es
                  sei denn, sie sind hierzu ausdrücklich bevollmächtigt oder
                  kraft ihrer Organstellung, Prokura oder allgemeiner
                  Handlungsvollmacht berechtigt.
                </li>
                <li>
                  Soweit HQS dem Nutzer nach den vertraglichen Bestimmungen
                  Leistungen eines Dritten verschafft, gelten hinsichtlich
                  dieser Leistungen ergänzend die Geschäftsbedingungen des
                  Dritten, sofern sich der Nutzer von diesen vor Vertragsschluss
                  in zumutbarer Weise Kenntnis verschaffen konnte.
                </li>
                <li>
                  Einkaufs- oder Geschäftsbedingungen des Nutzers haben nur
                  Gültigkeit, sofern HQS diese gesondert schriftlich anerkannt
                  hat. Jedenfalls gilt unter den einzelnen Vereinbarungen
                  folgende Hierarchie der Festlegungen:
                  <ul>
                    <li>Änderungen entsprechend Ziffer 1.2.</li>
                    <li>diese Bedingungen</li>
                    <li>Einkaufs- oder Geschäftsbedingungen des Nutzers</li>
                  </ul>
                  Die zuerst genannten Bestimmungen haben bei Widersprüchen oder
                  Unklarheiten stets Vorrang vor den nachfolgend genannten
                  Bestimmungen.
                </li>
              </ol>
            </li>
            <!-- 2. -->
            <li>
              <h4>Leistungen</h4>
              <ol>
                <!-- 2.1 -->
                <li>
                  Leistungsgegenstand ist die Plattform „HQS-Cloud“ zur Lösung
                  von Gittermodellen („Dienst“). Der Dienst wird als ASP-Service
                  zur Nutzung über das Internet angeboten.
                </li>
                <!-- 2.2 -->
                <li>
                  Maßgebend für Umfang, Art und Qualität der Leistungen der HQS
                  sind die Leistungsbeschreibung der HQS (Anlage 1) und dieser
                  Vertrag.
                </li>
                <!-- 2.3 -->
                <li>
                  Produktbeschreibungen, Darstellungen, Dokumentationen und
                  vergleichbare Unterlagen sind Leistungsbeschreibungen, jedoch
                  keine Garantien. Eine Garantie bedarf der schriftlichen
                  Erklärung der HQS.
                </li>
                <!-- 2.4 -->
                <li>
                  Der Nutzer hat vor Vertragsabschluss überprüft, ob die
                  Spezifikationen des Dienstes seinen Wünschen und Bedürfnissen
                  entsprechen. Ihm sind die wesentlichen Funktionsmerkmale und
                  -bedingungen bekannt.
                </li>
                <!-- 2.5 -->
                <li>
                  Die Leistungen von HQS bei der Übermittlung von Daten
                  beschränken sich allein auf die Datenkommunikation zwischen
                  dem von HQS betriebenen Übergabepunkt des eigenen
                  Datenkommunikationsnetzes an das Internet und dem für den
                  Nutzer bereitgestellten Server. Eine Einflussnahme auf den
                  Datenverkehr außerhalb des eigenen Kommunikationsnetzes ist
                  HQS nicht möglich. Eine erfolgreiche Weiterleitung von
                  Informationen von oder zu einem die Inhalte abfragenden
                  Rechner ist daher nicht geschuldet. Die Internetverbindung
                  selbst ist nicht Leistungsgegenstand von HQS. Der Nutzer kann
                  den Dienst nur nutzen, wenn er über eine ausreichend
                  leistungsfähige Internetverbindung verfügt. Die erforderliche
                  Bandbreite hängt von der Intensität der Nutzung und der
                  Datenmenge des Nutzers ab.
                </li>
                <!-- 2.6 -->
                <li>
                  Der Nutzer hat die Möglichkeit, die Software selbst zu
                  konfigurieren. Er erhält selbst aber keinen unmittelbaren
                  Zugriff auf den Server.
                </li>
                <!-- 2.7 -->
                <li>
                  HQS erbringt die in den Ziffern 2.1. bis 2.6. genannten
                  Leistungen mit einer Gesamtverfügbarkeit von 75 % im
                  Jahresmittel. Die Verfügbarkeit wurde hier nur abschätzt, da
                  es sich hier um eine freie Beta Version des Angebotes handelt.
                  berechnet sich auf der Grundlage der auf ein Jahr entfallenden
                  Zeit abzüglich der nachfolgend definierten Wartungszeiten
                  (Ziffern 2.7.1.) und abzüglich der nachfolgend definierten
                  Zeiten der Störung des Geschäftsbetriebs (Ziffern 2.7.2.).
                  <ol>
                    <!-- 2.7.1 -->
                    <li>
                      HQS ist berechtigt, für 8 Stunden im Monat in gängigen
                      Büroöffnungszeiten (9.00-17.00 Uhr) (MEZ / MESZ)
                      Wartungsarbeiten durchzuführen. Während der
                      Wartungsarbeiten stehen die vorgenannten Leistungen nicht
                      zur Verfügung.
                    </li>
                    <!-- 2.7.2 -->
                    <li>
                      Als Störungen des Geschäftsbetriebs gelten die folgenden
                      Umstände:
                      <ul>
                        <li>
                          Unterbrechungen der Erreichbarkeit durch Störungen im
                          Bereich Dritter, auf die HQS keinen Einfluss hat und
                          dessen Verhalten HQS sich nicht zurechnen lassen muss
                        </li>
                        <li>Unterbrechungen durch höhere Gewalt</li>
                        <li>
                          kurzfristige Unterbrechungen des Betriebes, die
                          erforderlich sind, um konkrete Gefährdungen durch
                          einen möglichen Missbrauch durch Dritte (sog.
                          Exploits) vorzubeugen oder zu verhindern (z.B. durch
                          Updates)
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <!-- 2.8 -->
                <li>
                  Die Inhalte des für den Nutzer bestimmten Speicherplatzes
                  werden auf Amazon Standard S3 Buckets gespeichert. Amazon ist
                  für die Datensicherung zuständig.
                </li>
                <!-- 2.9 -->
                <li>
                  Als Dokumentation liefert HQS eine Online-Hilfe, die es
                  erlaubt, Erläuterungen zu den Funktionalitäten während des
                  Betriebs der Software abzurufen und auszudrucken. Eine
                  weitergehende Dokumentation schuldet HQS nicht.
                </li>
                <!-- 2.10 -->
                <li>
                  Änderungen, Ergänzungen und Einschränkungen des Bestands der
                  Software, insbesondere die Abkündigung einzelner Teile der
                  Software, sind bei neuen Programmversionen im Rahmen einer
                  allgemeinen Produktpolitik von HQS erlaubt. Ist mit den
                  Änderungen, Ergänzungen und Einschränkungen des Bestands der
                  Software auch eine Abweichung von der Leistungsbeschreibung
                  verbunden, so gilt Ziffer 10 entsprechend.
                </li>
              </ol>
            </li>
            <!-- 3. -->
            <li>
              <h4>Nutzungsrechte</h4>
              <ol>
                <!-- 3.1 -->
                <li>
                  Der Nutzer erwirbt mit Vertragsschluss an der Software ein
                  zeitlich auf die Dauer des Vertrags beschränktes
                  Nutzungsrecht, wobei sich dies Recht allein darauf beschränkt,
                  die Software als Application-Service über das Internet auf dem
                  von HQS zur Verfügung gestellten Servern zu nutzen.
                </li>
                <!-- 3.2 -->
                <li>
                  Die Anzahl der User, die gleichzeitig die Software nutzen
                  können, hängt von der Anzahl der gebuchten User oder von dem
                  Leistungspaket ab, das der Nutzer gebucht hat.
                </li>
                <!-- 3.3 -->
                <li>
                  HQS wird neue Programmversionen der Software für den Nutzer
                  auf dem Server bereitstellen und diese neuen Programmversionen
                  dann dem Nutzer zur Nutzung überlassen. Eine Installation der
                  neuen Programmversion erfolgt durch HQS.
                </li>
                <!-- 3.4 -->
                <li>
                  Ein Recht des Nutzers auf Einsichtnahme in den Quelltext der
                  Software besteht nicht. HQS kann insbesondere auch die
                  Einsichtnahme durch Dritte, z.B. Sachverständige im Rahmen
                  eines gerichtlichen Verfahrens, verwehren, wenn die Gefahr
                  besteht, dass hierdurch berechtigte Interessen von HQS
                  verletzt werden, insoweit insbesondere die Verletzung von
                  Betriebs- und Geschäftsgeheimnissen droht.
                </li>
              </ol>
            </li>
            <!-- 4. -->
            <li>
              <h4>Sach- und Rechtsmängelhaftung</h4>
              <ol>
                <!-- 4.1 -->
                <li>
                  Technische Daten, Spezifikationen und Leistungsangaben in
                  öffentlichen Äußerungen, insbesondere in Werbemitteln sind
                  keine Beschaffenheitsangaben. Die Funktionalität der Software
                  richtet sich nach der Beschreibung in der
                  Leistungsbeschreibung. Im Übrigen muss sich die Software für
                  die nach diesem Vertrag vorausgesetzte Verwendung eignen und
                  ansonsten eine Beschaffenheit aufweisen, die bei Software der
                  gleichen Art üblich ist.
                </li>
                <!-- 4.2 -->
                <li>
                  HQS wird die Software in einem zum vertragsgemäßen Gebrauch
                  geeigneten Zustand zur Verfügung stellen und sie in diesem
                  Zustand erhalten. Die Pflicht zur Erhaltung beinhaltet nicht
                  die Anpassung der Software an veränderte Einsatzbedingungen
                  und technische und funktionale Entwicklungen, wie etwa die
                  Anpassung an den Funktionsumfang konkurrierender Produkte oder
                  die Herstellung der Kompatibilität zu neuen Datenformaten.
                </li>
              </ol>
            </li>
            <!-- 5. -->
            <li>
              <h4>Support</h4>
              <ol>
                <!-- 5.1 -->
                <li>
                  Supportleistungen vor Ort, d.h. am Sitz des Nutzers, sind
                  nicht Bestandteil dieses Vertrages.
                </li>
                <!-- 5.2 -->
                <li>
                  Der Support von HQS erfolgt montags bis freitags von 9:00 Uhr
                  bis 16.00 Uhr, ausgenommen an Feiertagen in Baden-Württemberg
                  (im Folgenden "Dienstzeit" genannt). Eine Zusatzvereinbarung
                  über erweiterte Supportzeiten kann zwischen den
                  Vertragsparteien zu besonderen Konditionen vereinbart werden.
                  Ein Anspruch auf Abschluss einer solchen Vereinbarung besteht
                  nicht.
                </li>
                <!-- 5.3 -->
                <li>
                  Nicht erfasst vom Support von HQS werden dabei
                  Softwareprobleme, die durch eine der folgenden Handlungen des
                  Nutzers oder eines von ihm eingeschalteten Dritten verursacht
                  werden:
                  <ul>
                    <li>Fehlerhafte Konfiguration der Software,</li>
                    <li>Veränderung oder Beschädigung der Software,</li>
                    <li>
                      Veränderung der Software durch sich selbst
                      vervielfältigende Programme (Viren),
                    </li>
                    <li>
                      Gebrauch der Software zu anderen Zwecken als den in der
                      Softwarebeschreibung vorgesehenen,
                    </li>
                    <li>
                      Nichtbeachtung der in der Programmdokumentation
                      vorgegebenen Anweisungen zur Bedienung der Software
                    </li>
                  </ul>
                </li>
                <!-- 5.4 -->
                <li>
                  Sofern HQS Support leistet und sich im Nachhinein
                  herausstellt, dass die Softwareprobleme durch die unter Ziffer
                  5.3. dieses Vertrages aufgeführten Handlungen des Nutzers oder
                  eines Dritten, dessen Verhalten sich der Nutzer zurechnen
                  lassen muss, verursacht wurden, ist HQS berechtigt, diese
                  Leistungen nach den jeweils gültigen Stundensätzen gemäß
                  Preisliste (Anlage 2) abzurechnen.
                </li>
              </ol>
            </li>
            <!-- 6. -->
            <li>
              <h4>Fehlerbeseitigung/ Fehlerklassen</h4>
              <ol>
                <!-- 6.1 -->
                <li>
                  HQS wird mit der Behebung von Fehlern der Software binnen der
                  Reaktionszeit (Zeitspanne während der Dienstzeit ab der
                  Mängelrüge, bis zu deren Ablauf HQS dem Nutzer den Beginn der
                  Beseitigungstätigkeiten berichtet haben muss) beginnen und die
                  Fehler binnen der Beseitigungszeit (Zeitspanne während der
                  Dienstzeit ab der Mängelrüge, bis zu deren Ablauf HQS den
                  Mangel behoben haben muss) beheben. Die Reaktions- und
                  Beseitigungszeiten bemessen sich für jeden Fehler getrennt.
                </li>
                <!-- 6.2 -->
                <li>
                  Bei betriebsverhindernden Fehlern (Fehlerklasse 1: Der Fehler
                  verhindert die Nutzung des Vertragsgegenstands oder
                  wesentlicher Teile des Vertragsgegenstands) wird HQS binnen 4
                  Stunden mit der Beseitigung beginnen und den Fehler in
                  angemessener Zeit beheben.
                </li>
                <!-- 6.3 -->
                <li>
                  Bei betriebsbehindernden Fehlern (Fehlerklasse 2: Der Fehler
                  behindert die Nutzung des Vertragsgegenstands schwerwiegend,
                  d.h. die Nutzung des Vertragsgegenstands ist nur mit
                  erheblichem Aufwand möglich oder die Nutzung des
                  Vertragsgegenstands stellt ein nicht zumutbares Risiko für die
                  ordnungsgemäße Funktion anderer Systeme des Lizenznehmers da)
                  wird HQS binnen 8 Stunden mit der Beseitigung beginnen und den
                  Fehler in angemessener Zeit beheben.
                </li>
                <!-- 6.4 -->
                <li>
                  Bei Sonstigen Fehlern (= Fehlerklasse 3: Die Nutzung ist nicht
                  wesentlich beeinträchtigt) wird HQS binnen angemessener Frist
                  mit deren Beseitigung beginnen und diese beheben, sobald
                  interne Prozesse bei HQS (z.B. das nächste Update der
                  HQS-Software) eine effiziente Beseitigung ermöglichen.
                </li>
                <!-- 6.5 -->
                <li>
                  Ein Fehler kann nach teilweiser Nachbesserung oder nach
                  Aufzeigen einer Umgehungslösung von HQS in eine niedrigere
                  Kategorie eingeordnet werden.
                </li>
                <!-- 6.6 -->
                <li>
                  Die Mängelrüge des Nutzers kann zunächst auch (fern-)mündlich
                  erfolgen. Sie ist jedoch spätestens am nächsten Werktag in
                  Textform zu wiederholen und hat den als Fehler gerügten
                  Tatbestand so detailliert wie möglich und auch möglichst
                  reproduzierbar zu beschreiben (Fehlermeldung). HQS kann den
                  Nutzer verpflichten, für die Mängelrüge eine von HQS
                  vorgegebene Kundensupportsoftware zu nutzen.
                </li>
                <!-- 6.7 -->
                <li>
                  Der Nutzer hat HQS bei der Mängelbeseitigung zu unterstützen
                  (z.B. durch das Abschalten einzelner Maschine bzw. deren
                  Trennung von der Software) und Vorkehrungen für den Fall zu
                  treffen, dass die Mängelbeseitigung nicht bzw. nicht
                  fristgerecht durchgeführt werden kann.
                </li>
              </ol>
            </li>
            <!-- 7. -->
            <li>
              <h4>Vertragslaufzeit / Folgen der Vertragsbeendigung</h4>
              <ol>
                <!-- 7.1 -->
                <li>
                  Der Vertrag hat keine Mindestvertragslaufzeit. Der Vertrag ist
                  für beide Parteien kündbar mit einer Frist von 2 Wochen zum
                  Monatsende. Maßgeblich für die 5 | S e iteRechtzeitigkeit der
                  Kündigung ist der Zugang der Erklärung bei dem
                  Vertragspartner.
                </li>
                <!-- 7.2 -->
                <li>Die Kündigung bedarf der Textform.</li>
                <!-- 7.3 -->
                <li>
                  Bei Ablauf des Vertrags ist HQS berechtigt, die Einstellungen
                  des Nutzers und auch alle Backups unwiderruflich zu löschen.
                </li>
              </ol>
            </li>
            <!-- 8. -->
            <li>
              <h4>Gebrauchsüberlassung an Dritte</h4>
              <ol>
                <!-- 8.1 -->
                <li>
                  Der Nutzer darf nur mit voriger schriftlicher Zustimmung von
                  HQS und im Falle der Zustimmung nur zu den von HQS dabei
                  bekannt gegebenen oder von ihr mit der Zustimmung aus
                  gebilligten Bedingungen eine Untervermietung oder sonstige
                  Gebrauchsüberlassung des Dienstes vornehmen.
                </li>
                <!-- 8.2 -->
                <li>
                  Eine erteilte Zustimmung kann aus wichtigem Grund widerrufen
                  werden. Ein wichtiger Grund liegt insbesondere vor, wenn die
                  Voraussetzungen, die zur Erteilung der Zustimmung geführt
                  haben, nachträglich wegfallen. Im Falle einer berechtigten
                  Verweigerung der Zustimmung ist der Nutzer zu einer Kündigung
                  des Vertrags nicht berechtigt.
                </li>
                <!-- 8.3 -->
                <li>
                  Der Nutzer hat HQS eine Kopie des abgeschlossenen
                  Unternutzungsvertrags vorzulegen.
                </li>
                <!-- 8.4 -->
                <li>
                  Eine Untervermietung oder sonstige Gebrauchsüberlassung lässt
                  die Verpflichtung des Nutzers aus oder im Zusammenhang mit
                  diesem Vertrag unberührt.
                </li>
                <!-- 8.5 -->
                <li>
                  Zulässig ist jedoch die Überlassung an Dritte, denen kein
                  selbstständiges Gebrauchsrecht eingeräumt wird und die sich
                  hinsichtlich der Art und Weise der Benutzung dem Willen des
                  Nutzers beugen müssen. Dies ist insbesondere bei Angestellten
                  des Nutzers in der Regel der Fall.
                </li>
              </ol>
            </li>
            <!-- 9. -->
            <li>
              <h4>Entgelt</h4>
              Die Zurverfügungstellung des Dientes durch HQS erfolgt
              unentgeltlich.
            </li>
            <!-- 10. -->
            <li>
              <h4>Änderung der AGB</h4>
              <ol>
                <!-- 10.1 -->
                <li>
                  HQS ist berechtigt, die Bedingungen diese
                  Vertragsverhältnisses zu verändern, wenn
                  <ul>
                    <li>
                      die Änderungen erst zwei Monate nach Zugang einer
                      entsprechenden Erklärung beim Nutzer wirksam werden
                      sollen,
                    </li>
                    <li>
                      HQS die Änderungen dem Nutzer in Textform unter Hinweis
                      auf die Möglichkeit eines Widerspruchs sowie unter Hinweis
                      auf eine einmonatige Frist des Widerspruchs nach Zugang
                      der entsprechenden Erklärung mitteilt und
                    </li>
                    <li>
                      der Nutzer nicht binnen eines Monats nach Zugang der
                      entsprechenden Erklärung widerspricht.
                    </li>
                  </ul>
                </li>
                <!-- 10.2 -->
                <li>
                  Erfolgt ein Widerspruch nach Ziffer 10.1. wird der Vertrag
                  unverändert fortgesetzt. Das Recht der Vertragspartner zur
                  Kündigung des Vertrages bleibt hiervon unberührt.
                </li>
              </ol>
            </li>
            <!-- 11. -->
            <li>
              <h4>Haftung von HQS</h4>
              <ol>
                <!-- 11.1 -->
                <li>
                  Soweit sich die Leistung von HQS als Leihe im Sinne von 598
                  ff. BGB darstellt, haftet HQS nach § 599 BGB nur für Vorsatz
                  und grobe Fahrlässigkeit. In allen anderen Fällen richtet sich
                  die Haftung nach den Ziffer 11.2. bis 11.6.
                </li>
                <!-- 11.2 -->
                <li>
                  Die HQS haftet gegenüber dem Nutzer nur für vorsätzlich oder
                  grob fahrlässig verursachte Schäden. Dies gilt nicht, soweit
                  wesentliche Pflichten des Vertrags durch HQS verletzt werden.
                  Wesentlichen Vertragspflichten sind solche, deren Erfüllung
                  die ordnungsgemäße Durchführung des Vertrags überhaupt erst
                  ermöglichen und auf deren Einhaltung der Vertragspartner
                  regelmäßig vertraut und vertrauen darf.
                </li>
                <!-- 11.3 -->
                <li>
                  Im Falle leichter Fahrlässigkeit ist eine Haftung von HQS bei
                  Schäden, die nicht auf eine hinsichtlich Verletzung
                  wesentlicher mittelbarer Vertragspflichten Schäden,
                  zurückzuführen insbesondere sind, Mangelfolgeschäden,
                  unvorhersehbarer Schäden oder untypischer Schäden sowie
                  entgangenen Gewinns ausgeschlossen.
                </li>
                <!-- 11.4 -->
                <li>
                  Verletzt HQS fahrlässig eine wesentliche Vertragspflicht,
                  haftet HQS nur für den vertragstypisch vorhersehbaren Schaden.
                </li>
                <!-- 11.5 -->
                <li>
                  Eine gesetzlich vorgeschriebene verschuldensunabhängige
                  Haftung von HQS - insbesondere eine Haftung nach
                  Produkthaftungsgesetz sowie eine gesetzliche Garantiehaftung -
                  bleibt von den vorstehenden Haftungseinschränkungen unberührt.
                  Gleiches gilt für die Haftung von HQS bei schuldhafter
                  Verletzung von Leben, Körper oder Gesundheit.
                </li>
                <!-- 11.6 -->
                <li>
                  Die Einschränkungen der Ziffern 11.2 bis 11.5. gelten auch
                  zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen
                  der Verwenderin, wenn Ansprüche direkt gegen diese geltend
                  gemacht werden.
                </li>
                <!-- 11.7 -->
                <li>
                  Die verschuldensunabhängige Haftung der HQS für bereits bei
                  Vertragsabschluss vorhandene Fehler nach § 536 a Absatz 1 des
                  Bürgerlichen Gesetzbuchs wird ausdrücklich ausgeschlossen.
                </li>
              </ol>
            </li>
            <!-- 12. -->
            <li>
              <h4>Pflichten des Nutzers</h4>
              <ol>
                <!-- 12.1 -->
                <li>
                  Der Nutzer darf den Dienst nur im Einklang mit den
                  vertraglichen Vereinbarungen und den gesetzlichen Bestimmungen
                  nutzen.
                </li>
                <!-- 12.2 -->
                <li>
                  Die Nutzung des Dienstes durch den Nutzer, insbesondere die
                  Inhalte, die der Nutzer einstellt, verlinkt, einbettet oder
                  auf andere Weise zugänglich macht, liegt im alleinigen
                  Verantwortungsbereich des Nutzers. Der Nutzer gewährleistet,
                  dass alle von ihm eingestellten Inhalte („Inhalte des
                  Nutzers“) nicht gegen die einschlägigen insbesondere
                  inländischen des und ausländischen Verbraucherschutzrechts,
                  gesetzlichen des Regelungen, Urheberrechts, des
                  Wettbewerbsrechts und des Datenschutzrechts, die guten Sitten
                  oder Rechte Dritter verstoßen. Die Einstellung von Inhalten
                  des Nutzers, die
                  <table>
                    <tr>
                      <td class="header-column">a)</td>
                      <td>
                        diskriminierender, rassistischer, gewaltverherrlichender
                        oder menschenverachtender Art sind,
                      </td>
                    </tr>
                    <tr>
                      <td class="header-column">b)</td>
                      <td>zu Straftaten aufrufen oder diese gut heißen,</td>
                    </tr>
                    <tr>
                      <td class="header-column">c)</td>
                      <td>
                        Pornographie beinhalten oder gegen das Jugendschutzrecht
                        verstoßen oder
                      </td>
                    </tr>
                    <tr>
                      <td class="header-column">d)</td>
                      <td>
                        Persönlichkeitsrechte Dritter verletzen ist unzulässig.
                      </td>
                    </tr>
                  </table>
                </li>
                <!-- 12.3 -->
                <li>
                  Der Nutzer darf keine Software oder andere technische
                  Einrichtungen verwenden, die das Funktionieren des Dienstes
                  ändern, erweitern oder gefährden. Insbesondere darf der Nutzer
                  nicht versuchen, mit anderen als den vorgesehenen technischen
                  Mitteln auf den Dienst zuzugreifen.
                </li>
                <!-- 12.4 -->
                <li>
                  Der Nutzer behandelt Zugangsdaten zu dem Dienst streng
                  vertraulich. Er darf Zugangsdaten nur denjenigen eigenen
                  Mitarbeitern zugänglich machen, die den Dienst im Rahmen ihrer
                  Dienstaufgaben gemäß den vereinbarten Beschränkungen nutzen
                  dürfen.
                </li>
                <!-- 12.5 -->
                <li>
                  Hat der Nutzer den Verdacht, dass seine Zugangsdaten einem
                  Dritten bekannt geworden sind oder dass ein Dritter unbefugt
                  den Zugang des Nutzers zu dem Dienst nutzt, so ist der Nutzer
                  verpflichtet, HQS unverzüglich hierüber zu informieren.
                </li>
                <!-- 12.6 -->
                <li>
                  Hat HQS konkrete Anhaltspunkte dafür, dass der Nutzer den
                  Dienst entgegen den Bestimmungen dieses Vertrags oder den
                  sonstigen vertraglichen Vereinbarungen der Vertragspartner
                  nutzt oder ein unberechtigter Dritter mit den Zugangsdaten des
                  Nutzers unbefugt auf den Dienst zugreift, so ist HQS
                  berechtigt, den Zugang des Nutzers zu dem Dienst bis zur
                  Klärung der Angelegenheit zu sperren. Außer bei Gefahr im
                  Verzug wird HQS dem Nutzer vor einer solchen Maßnahme
                  Gelegenheit zur Stellungnahme geben. Der Nutzer bleibt während
                  der Zeit der Sperrung zur Zahlung einer etwaig vereinbarten
                  Vergütung verpflichtet, es sei denn, er hatte die Umstände,
                  die zu der Sperrung geführt haben, nicht zu vertreten.
                </li>
                <!-- 12.7 -->
                <li>
                  Der Nutzer stellt HQS von allen Ansprüchen Dritter frei und
                  ersetzt HQS alle Schäden, die HQS durch eine rechts- oder
                  vertragswidrige Nutzung des Dienstes entstehen, es sei denn,
                  der Nutzer weist nach, dass er die jeweilige Rechtsverletzung
                  nicht zu vertreten hat. Zu den erstattungsfähigen Schäden
                  zählen auch die angemessenen Kosten einer Rechtsverteidigung,
                  die HQS bei der Abwehr von Ansprüchen Dritter entstehen
                  sollten. HQS wird den Nutzer jedoch unverzüglich von
                  vorzunehmenden Maßnahmen der Rechtsverteidigung informieren.
                  HQS darf bei solchen Auseinandersetzungen mit Dritten
                  Vergleiche nur nach Rücksprache mit dem Nutzer schließen.
                  Andernfalls trägt HQS sämtliche Kosten der Auseinandersetzung
                  selbst.
                </li>
              </ol>
            </li>
            <!-- 13. -->
            <li>
              <h4>Geheimhaltung, Datenschutz</h4>
              <ol>
                <!-- 13.1 -->
                <li>
                  Die Vertragspartner verpflichten sich, alle ihnen bei der
                  Vertragsdurchführung von dem jeweils anderen Vertragspartner
                  bekannt werdende Betriebsgeheimnisse des anderen
                  Vertragspartners vertraulich zu behandeln. Auch vereinbaren
                  die Parteien, über den Inhalt dieses Vertrags Stillschweigen
                  zu bewahren.
                </li>
                <!-- 13.2 -->
                <li>
                  Soweit der Nutzer mit Hilfe des Dienstes von HQS Daten
                  verarbeitet, die einer bestimmten oder bestimmbaren Person
                  zuzuordnen sind (personenbezogene Daten i. S. v. § 3 Abs. 1
                  BDSG), so ist der Nutzer allein dafür verantwortlich, dass der
                  jeweils Betroffene in diese Verarbeitung seiner Daten
                  eingewilligt hat oder eine gesetzliche Erlaubnis vorliegt. Der
                  Nutzer bleibt in Bezug auf solche personenbezogenen Daten
                  stets die verantwortliche Stelle. Der Nutzer stellt HQS von
                  allen Ansprüchen des Betroffenen frei und ersetzt HQS alle
                  Schäden, die HQS durch eine datenschutzrechtswidrige Nutzung
                  von personenbezogenen Daten entstehen, es sei denn, der Nutzer
                  weist nach, dass er den Verstoß nicht zu vertreten hat.
                </li>
                <!-- 13.3 -->
                <li>
                  Auf schriftliches Verlangen des Nutzers schließen die
                  Vertragspartner eine Vereinbarung über die
                  Auftragsdatenverarbeitung nach § 11 BDSG, soweit dies nach den
                  gesetzlichen Bestimmungen erforderlich ist. HQS wird in diesen
                  Fällen einen Vorschlag für eine Vereinbarung über die
                  Auftragsdatenverarbeitung unterbreiten.
                </li>
              </ol>
            </li>
            <!-- 14. -->
            <li>
              <h4>Leistung und Fakturierung durch Dritte</h4>
              <ol>
                <!-- 14.1 -->
                <li>
                  HQS ist berechtigt, die vertraglich geschuldeten Leistungen
                  durch Dritte, insbesondere durch mit ihm verbundene
                  Unternehmen, zu erbringen.
                </li>
                <!-- 14.2 -->
                <li>
                  HQS ist berechtigt, jegliche Ansprüche aus diesem Vertrag an
                  Dritte, insbesondere an mit HQS verbundene Unternehmen,
                  abzutreten oder Dritte zur Geltendmachung im eigenen Namen zu
                  ermächtigen.
                </li>
                <!-- 14.3 -->
                <li>
                  Der Nutzer darf nur mit unbestrittenen oder rechtskräftig
                  festgestellten Forderungen aufrechnen. Der Nutzer kann ein
                  Zurückbehaltungsrecht nur in den Fällen unbestrittener oder
                  rechtskräftig festgestellter Ansprüche geltend machen. Ein
                  Zurückbehaltungsrecht oder die Einrede des nicht erfüllten
                  Vertrages stehen dem Nutzer nur innerhalb dieses
                  Vertragsverhältnisses zu.
                </li>
              </ol>
            </li>
            <!-- 15. -->
            <li>
              <h4>Allgemeines</h4>
              <ol>
                <!-- 15.1 -->
                <li>
                  Änderungen, Kündigungen und Ergänzungen dieses Vertrags
                  bedürfen der Textform (z.B. Fax, E-Mail); dies gilt auch für
                  eine Aufhebung dieses Textformerfordernisses.
                </li>
                <!-- 15.2 -->
                <li>
                  Für das Vertragsverhältnis gilt das Recht der Bundesrepublik
                  Deutschland unter Ausschluss des UN-Kaufrechts.
                </li>
                <!-- 15.3 -->
                <li>
                  Sofern der Nutzer Kaufmann, juristische Person des
                  öffentlichen Rechts oder Träger eines öffentlich-rechtlichen
                  Sondervermögens im Sinne des § 38 ZPO ist oder der Nutzer nach
                  Vertragsschluss seinen Wohnsitz, gewöhnlichen Aufenthaltsort
                  oder Firmensitz ins Ausland verlegt oder dieser nicht bekannt
                  ist, ist ausschließlicher Gerichtsstand Karlsruhe. Die
                  Gerichte in Karlsruhe sind ferner zuständig, wenn der Nutzer
                  keinen allgemeinen Gerichtsstand in Deutschland hat.
                </li>
                <!-- 15.4 -->
                <li>
                  Die Nichtigkeit oder Unwirksamkeit einzelner Bestimmungen
                  dieser Allgemeinen Geschäftsbedingungen berührt die Gültigkeit
                  der übrigen Bestimmungen nicht.
                </li>
                <!-- 15.5 -->
                <li>
                  In Konfliktfällen zwischen den Sprachversionen ist die
                  deutsche Sprachversion maßgeblich. Die englische Version ist
                  nur eine Leseversion.
                </li>
              </ol>
            </li>
          </ol>
          <br /><br />
          <h3>Anlage 1: Leistungsbeschreibung</h3>
          <br />
          <h2>Leistungsbeschreibung</h2>
          <br />
          <p>
            Dem Nutzer wird vorbehaltlich der Prüfung seiner Registrierungsdaten
            eine frei Einzelnutzerlizenz zur Verfügung gestellt. <br /><br />
            Die Software und Plattform HQS-Cloud erlaubt die näherungsweise
            Lösung von Gittermodellen in ein und zwei Dimensionen. Durch das
            'self-consistend cluster embedding' wird das Problem vereinfacht und
            es obliegt dem Nutzer die Qualität dieser Näherung zu überprüfen und
            zu bewerten. Als Lösungsmethode für das vereinfachte Problem dient
            ein DMRG Code. Die Qualität von DMRG ist hinreichen in der Literatur
            dokumentiert. <br /><br />
            Die Plattform erlaubt das Definieren eines Gitterproblems und das
            Speichern der Ergebnisse. Jedem Benutzer stehen pro Monat 100 HQ$
            zur Verfügung ohne, dass für ihn irgendwelche Kosten anfallen. Die
            HQ$ werden mit der verbrauchten Rechenleistung verrechnet. Zwei HQ$
            entsprechen einer Stunde Rechenzeit auf der HQS Standardmaschine.
            Die HQS-Cloud Plattform bietet eine Abschätzung der Menge HQ$ an,
            die voraussichtlich für eine Rechnung verbraucht werden. Eine
            Rechnung kann aber deutlich günstiger sein. Falls alle HQ$ eines
            Benutzers verbraucht sind, werden laufende Rechnungen abgebrochen
            und keine neuen Jobs gestartet. <br /><br />
            Ergebnisse der Rechnungen und Simulationen werden für mindestens
            einen Monat auf der Plattform abrufbar sein.
          </p>
          <br /><br />
          <h3>Anlage 2: Stundensatz</h3>
          <br />
          <p>Der Stundensatz beträgt 100 Euro.</p>
        </v-col>
      </v-row>
    </div>

    <!-- english version-->
    <div v-if="toggleLanguage === 'eng'">
      <v-row>
        <v-col>
          <h3 class="text-bold">
            User Agreement for the Software and Platform „HQS-Cloud“
          </h3>
          <br />
          <ol>
            <!-- 1. -->
            <li>
              <h4>Scope of Application</h4>
              <ol>
                <!-- 1.1 -->
                <li>
                  The following terms and conditions apply to all services
                  provided by HQS Quantum Simulations GmbH (hereinafter referred
                  to as "HQS") to its customers (hereinafter referred to as
                  "Users") in connection with the provision and delivery of SaaS
                  solutions and related services.
                </li>
                <!-- 1.2 -->
                <li>
                  The employees of HQS are not entitled to reach any agreements,
                  additional agreements, individual guarantees or assurances
                  deviating from these terms and conditions unless they are
                  expressly authorized to do so or are authorized by virtue of
                  their position as a governing body, proxy or general power of
                  attorney.
                </li>
                <!-- 1.3 -->
                <li>
                  As far as HQS provides the User with services of a third party
                  according to the contractual provisions, the terms and
                  conditions of the third party shall apply additionally with
                  respect to these services, provided that the User could
                  reasonably be expected to have knowledge of them before
                  conclusion of the contract.
                </li>
                <!-- 1.4 -->
                <li>
                  The User's terms and conditions of purchase and/or business
                  shall only be valid if HQS has acknowledged them separately in
                  writing. In any case, the following hierarchy of provisions
                  applies under the individual agreements:
                  <ul>
                    <li>amendments according to section 1.2.</li>
                    <li>these terms</li>
                    <li>purchase or business conditions of the User</li>
                  </ul>
                  In the event of contradictions or ambiguities, the provisions
                  mentioned first shall always take precedence over the
                  provisions mentioned subsequently.
                </li>
              </ol>
            </li>
            <!-- 2. -->
            <li>
              <h4>Services</h4>
              <ol>
                <!-- 2.1 -->
                <li>
                  The subject of the service is the platform "SCCE" for the
                  solution of grid models ("service"). The service is offered as
                  an ASP service for use via the Internet.
                </li>
                <!-- 2.2 -->
                <li>
                  Determining for scope, type and quality of the services of HQS
                  are the service description of HQS (Annex 1) and this
                  contract.
                </li>
                <!-- 2.3 -->
                <li>
                  Product descriptions, representations, documentation and
                  comparable documents are performance specifications but not
                  guarantees. A guarantee requires a written declaration by HQS.
                </li>
                <!-- 2.4 -->
                <li>
                  Before signing the contract, the User has checked whether the
                  specifications of the service meet his wishes and needs. He is
                  aware of the essential functional features and conditions.
                </li>
                <!-- 2.5 -->
                <li>
                  The services of HQS for the transmission of data are limited
                  exclusively to the data communication between the transfer
                  point of its own data communication network operated by HQS to
                  the Internet and the server provided for the User. HQS has no
                  influence on data traffic outside its own communication
                  network. A successful forwarding of information from or to a
                  computer querying the contents is therefore not owed. The
                  internet connection itself is not the subject matter of the
                  services provided by HQS. The User can only use the service if
                  he/she has a sufficiently powerful Internet connection. The
                  required bandwidth depends on the intensity of the User's
                  activity and the data volume.
                </li>
                <!-- 2.6 -->
                <li>
                  The User has the possibility to configure the software
                  himself. However, he does not get direct access to the server.
                </li>
                <!-- 2.7 -->
                <li>
                  HQS provides the services mentioned in paragraphs 2.1. to 2.6.
                  with an overall availability of 75% on an annual average. As
                  this is a free beta version of the offer, the availability was
                  only estimated here. It is calculated on the basis of the
                  period of one year less the maintenance periods defined below
                  (Clauses 2.7.1.) and less the periods of business disruption
                  defined below (Clauses 2.7.2.).
                  <ol>
                    <!-- 2.7.1 -->
                    <li>
                      HQS is entitled to carry out maintenance work for 8 hours
                      per month during normal office hours (9.00-17.00 hours)
                      (CET / CEST). During the maintenance work the above
                      mentioned services will not be available.
                    </li>
                    <!-- 2.7.2 -->
                    <li>
                      The following circumstances are considered to be
                      disruptions of business operations:
                      <ul>
                        <li>
                          interruptions in accessibility due to disruptions in
                          the area of third parties over which HQS has no
                          influence and whose behaviour HQS cannot be held
                          responsible for
                        </li>
                        <li>interruptions due to force majeure</li>
                        <li>
                          short-term interruptions of operations which are
                          necessary to anticipate or prevent concrete threats
                          from possible misuse by third parties (so-called
                          exploits) or to avoid them (e.g. through updates)
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <!-- 2.8 -->
                <li>
                  The contents of the storage space intended for the User are
                  stored on Amazon Standard S3 Buckets. Amazon is responsible
                  for data backup.
                </li>
                <!-- 2.9 -->
                <li>
                  As documentation HQS provides an online help which is designed
                  to display and print explanations of the functionalities
                  during operation of the software. HQS is not required to
                  provide any further documentation.
                </li>
                <!-- 2.10 -->
                <li>
                  Changes, additions and restrictions to the inventory of the
                  software, in particular the discontinuation of individual
                  parts of the software, are permitted for new program versions
                  within the framework of a general product policy of HQS. If
                  the changes, additions and limitations of the software
                  inventory also involve a deviation from the performance
                  specification, clause 10 applies accordingly.
                </li>
              </ol>
            </li>
            <!-- 3. -->
            <li>
              <h4>Rights of Use</h4>
              <ol>
                <!-- 3.1 -->
                <li>
                  Upon signing the contract the User will acquire the right to
                  use the software for a period limited to the duration of the
                  contract, whereby this right is limited exclusively to using
                  the software as an application service via the Internet on the
                  servers provided by HQS.
                </li>
                <!-- 3.2 -->
                <li>
                  The amount of Users who can simultaneously use the software
                  depends on the number of Users booked or on the service
                  package the User has booked.
                </li>
                <!-- 3.3 -->
                <li>
                  HQS will release new program versions of the software for the
                  User on the server and subsequently make these new program
                  versions available for use to the User. Each new program
                  version will be installed by HQS.
                </li>
                <!-- 3.4 -->
                <li>
                  The User will have no right to inspect the source code of the
                  software. In particular, HQS may also deny access to the
                  source code to third parties, e.g. experts in the course of
                  legal proceedings, if there is a risk that legitimate
                  interests of HQS may be harmed thereby, especially if there is
                  a threat of violation of trade and business secrets.
                </li>
              </ol>
            </li>
            <!-- 4. -->
            <li>
              <h4>Liability for Material Defects and Defects of Title</h4>
              <ol>
                <!-- 4.1 -->
                <li>
                  Technical data, specifications and performance data in public
                  statements, in particular in advertising media, are not
                  statements of quality. The functionality of the software is
                  based on the description stated in the performance
                  description. Moreover, the software must be suitable for the
                  use presupposed under this agreement and also exhibit a
                  quality that is customary for software of the same type.
                </li>
                <!-- 4.2 -->
                <li>
                  HQS will provide the software in a condition suitable for use
                  according to the contract and will maintain it in this
                  condition. This obligation of maintenance does not include the
                  adaptation of the software to changed conditions of use and
                  technical and functional developments, such as the adaptation
                  to the functional range of competing products or the
                  establishment of compatibility with new data formats.
                </li>
              </ol>
            </li>
            <!-- 5. -->
            <li>
              <h4>Support</h4>
              <ol>
                <!-- 5.1 -->
                <li>
                  Support services on site, i.e. at the place of business of the
                  User, are not part of this contract.
                </li>
                <!-- 5.2 -->
                <li>
                  Support by HQS is provided Monday to Friday from 9:00 am to
                  4:00 pm, except on public holidays in Baden-Württemberg
                  (hereinafter referred to as "service hours"). An additional
                  agreement on extended support hours may be agreed upon between
                  the parties to the contract at special conditions. There is no
                  entitlement to the signing of such an agreement.
                </li>
                <!-- 5.3 -->
                <li>
                  HQS support does not cover software problems caused by any of
                  the following actions of the User or a third party engaged by
                  the User:
                  <ul>
                    <li>faulty configuration of the software,</li>
                    <li>modification or damage to the software,</li>
                    <li>
                      modification of the software by self-replicating programs
                      (viruses),
                    </li>
                    <li>
                      use of the software for purposes other than those
                      specified in the software description,
                    </li>
                    <li>
                      non-observance of the operating instructions for the
                      software given in the program description.
                    </li>
                  </ul>
                </li>
                <!-- 5.4 -->
                <li>
                  If HQS provides support and in retrospect it turns out that
                  the software problems were caused by the actions of the User
                  or by a third party whose actions the User is responsible for
                  as set forth in clause 5.3. of this agreement, HQS shall be
                  entitled to invoice these services according to the hourly
                  rates applicable at the time in accordance with the price list
                  (Annex 2).
                </li>
              </ol>
            </li>
            <!-- 6. -->
            <li>
              <h4>Elimination of Errors / Classes of Errors</h4>
              <ol>
                <!-- 6.1 -->
                <li>
                  HQS will start to correct software errors within the response
                  time (time span during the service period from the notice of
                  defect until the expiration of which HQS must have reported
                  the initiation of the correction activities to the User) and
                  will correct the errors within the correction time (time span
                  during the service period from the notice of defect until the
                  expiration of which HQS must have corrected the defect). The
                  reaction and removal times are calculated separately for each
                  error.
                </li>
                <!-- 6.2 -->
                <li>
                  In case of operation-preventing errors (error class 1: the
                  error prevents the use of the subject matter of the contract
                  or essential parts of the subject matter of the contract) HQS
                  will start to eliminate the error within 4 hours and correct
                  the error within a reasonable time.
                </li>
                <!-- 6.3 -->
                <li>
                  In case of operation-impeding errors (error class 2: the error
                  severely hinders the use of the subject matter of the
                  contract, i.e. the use of the subject matter of the contract
                  is only possible with considerable effort or the use of the
                  subject matter of the contract poses an unreasonable risk to
                  the proper functioning of other systems of the licensee) HQS
                  will start the process of elimination within 8 hours and
                  correct the error within a reasonable time.
                </li>
                <!-- 6.4 -->
                <li>
                  In the case of other errors (= error class 3: use is not
                  significantly impaired) HQS will start to correct them within
                  a reasonable period of time and will eliminate them as soon as
                  internal processes at HQS (e.g. the next update of the HQS
                  software) allow efficient removal.
                </li>
                <!-- 6.5 -->
                <li>
                  After partial rectification of an error or after indicating a
                  bypass solution, HQS can reclassify the error into a lower
                  category.
                </li>
                <!-- 6.6 -->
                <li>
                  Initially, the User may notify defects verbally or by
                  telephone. However, it must be submitted again in written form
                  no later than the following work day and must describe the
                  facts regarding the error in as much detail as possible and as
                  reproducibly as possible (error message). HQS may require the
                  User to use customer support software specified by HQS for the
                  notification of defects.
                </li>
                <!-- 6.7 -->
                <li>
                  The User has to assist HQS in removing defects (e.g. by
                  switching off individual machines or disconnecting them from
                  the software) and has to take precautions in case the removal
                  of defects cannot be carried out in time.
                </li>
              </ol>
            </li>
            <!-- 7. -->
            <li>
              <h4>
                Duration of Contract / Consequences of Termination of Contract
              </h4>
              <ol>
                <!-- 7.1 -->
                <li>
                  The contract has no minimum duration. The contract is
                  terminable for both parties with a notice period of 2 weeks to
                  the end of the month. Decisive for the timeliness of the
                  termination is the receipt of the declaration by the
                  contractual partner.
                </li>
                <!-- 7.2 -->
                <li>The cancellation must be in written form.</li>
                <!-- 7.3 -->
                <li>
                  Upon expiration of the contract HQS is entitled to irrevocably
                  delete the User's settings and also all backups./li>
                </li>
              </ol>
            </li>
            <!-- 8. -->
            <li>
              <h4>Transfer of Use to Third Parties</h4>
              <ol>
                <!-- 8.1 -->
                <li>
                  The User shall not sublet or otherwise transfer the use of the
                  service without the prior written consent of HQS and, in case
                  of consent, only under the terms and conditions disclosed by
                  HQS or approved by HQS pursuant to such consent.
                </li>
                <!-- 8.2 -->
                <li>
                  Any consent granted may be revoked for good reason. An
                  important reason exists in particular if the conditions which
                  led to the granting of consent subsequently cease to apply. In
                  the event of a justified refusal of consent, the User shall
                  not be entitled to terminate the contract.
                </li>
                <!-- 8.3 -->
                <li>
                  The User has to submit to HQS a copy of the sublease agreement
                  that has been entered into.
                </li>
                <!-- 8.4 -->
                <li>
                  Any subletting or other transfer of use shall not affect the
                  User's obligations under or in connection with this agreement.
                </li>
                <!-- 8.5 -->
                <li>
                  However, it is permissible to make them available to third
                  parties to whom no independent right of use is granted and who
                  must bow to the will of the User with regard to the manner of
                  use. This is generally the case, in particular, with employees
                  of the User.
                </li>
              </ol>
            </li>
            <!-- 9. -->
            <li>
              <h4>Remuneration</h4>
              The provision of the service by HQS is free of charge.
            </li>
            <!-- 10. -->
            <li>
              <h4>Amendment of the General Terms and Conditions</h4>
              <ol>
                <!-- 10.1 -->
                <li>
                  HQS is entitled to change the conditions of this contractual
                  relationship if
                  <ul>
                    <li>
                      the changes shall only become effective two months after
                      receipt of a corresponding declaration by the User,
                    </li>
                    <li>
                      HQS notifies the User of the changes in text form with
                      reference to the possibility of an objection as well as
                      with reference to a one-month period for objection after
                      receipt of the respective declaration and
                    </li>
                    <li>
                      the User does not object within one month after receipt of
                      the corresponding declaration.
                    </li>
                  </ul>
                </li>
                <!-- 10.2 -->
                <li>
                  If an objection is made in accordance with section 10.1, the
                  contract shall continue unchanged. The right of the
                  contracting parties to terminate the contract remains
                  unaffected.
                </li>
              </ol>
            </li>
            <!-- 11. -->
            <li>
              <h4>Liability of HQS</h4>
              <ol>
                <!-- 11.1 -->
                <li>
                  As far as the services of HQS are to be understood as a loan
                  in terms of 598 ff. BGB (German Civil Code), HQS is liable
                  according to § 599 BGB only for intent and gross negligence.
                  In all other cases the liability shall be governed by clauses
                  11.2. to 11.6.
                </li>
                <!-- 11.2 -->
                <li>
                  HQS is liable to the User only for damages caused
                  intentionally or by gross negligence. This shall not apply if
                  essential obligations of the contract are violated by HQS.
                  Essential contractual obligations are those whose fulfilment
                  is fundamental to the proper performance of the contract and
                  on whose compliance the contractual partner regularly relies
                  and may rely.
                </li>
                <!-- 11.3 -->
                <li>
                  In case of slight negligence HQS shall not be liable for
                  damages which are not due to a violation of essential
                  contractual obligations with regard to indirect damages, in
                  particular consequential damages, unforeseeable damages or
                  atypical damages as well as loss of profit.
                </li>
                <!-- 11.4 -->
                <li>
                  If HQS negligently violates an essential contractual
                  obligation, HQS shall only be liable for the foreseeable
                  damage typical for the contract.
                </li>
                <!-- 11.5 -->
                <li>
                  A statutory fault-independent liability of HQS - in particular
                  a liability according to the Product Liability Act as well as
                  a statutory guarantee liability - remains unaffected by the
                  above limitations of liability. The same applies to the
                  liability of HQS in case of culpable injury to life, body or
                  health.
                </li>
                <!-- 11.56 -->
                <li>
                  The restrictions of clauses 11.2 to 11.5 also apply in favour
                  of the legal representatives and vicarious agents of the User
                  if claims are made directly against them .
                </li>
                <!-- 11.7 -->
                <li>
                  The strict liability of HQS for defects already existing at
                  the time of conclusion of the contract according to § 536 a
                  paragraph 1 of the German Civil Code is expressly excluded.
                </li>
              </ol>
            </li>
            <!-- 12. -->
            <li>
              <h4>Duties of the User</h4>
              <ol>
                <!-- 12.1 -->
                <li>
                  The User may only use the service in accordance with the
                  contractual agreements and the statutory provisions.
                </li>
                <!-- 12.2 -->
                <li>
                  The use of the service by the User, in particular the contents
                  that the User posts, links, embeds or otherwise makes
                  accessible, is the sole responsibility of the User. The User
                  guarantees that all contents posted by him ("User Contents")
                  does not violate the relevant domestic and foreign legal
                  regulations, in particular consumer protection law, copyright,
                  competition law and data protection law, the good morals
                  and/or rights of third parties. The placing of contents of the
                  User which
                  <table>
                    <tr>
                      <td class="header-column">a)</td>
                      <td>
                        are of a discriminatory, racist, violence-glorifying or
                        degrading nature
                      </td>
                    </tr>
                    <tr>
                      <td class="header-column">b)</td>
                      <td>call for or condone criminal acts,</td>
                    </tr>
                    <tr>
                      <td class="header-column">c)</td>
                      <td>
                        contain pornography or violate the Protection Law for
                        Minors or
                      </td>
                    </tr>
                    <tr>
                      <td class="header-column">d)</td>
                      <td>
                        infringe the personal rights of third parties is not
                        permissible.
                      </td>
                    </tr>
                  </table>
                </li>
                <!-- 12.3 -->
                <li>
                  The User may not use any software and/or other technical
                  equipment that changes, extends or endangers the functioning
                  of the service. In particular, the User may not attempt to
                  access the service using technical means other than those
                  provided for.
                </li>
                <!-- 12.4 -->
                <li>
                  The User shall treat access data to the service as strictly
                  confidential. He may only make access data available to those
                  of his own employees who are allowed to use the service within
                  the scope of their service tasks according to the agreed
                  restrictions.
                </li>
                <!-- 12.5 -->
                <li>
                  If the User suspects that its access data has become known to
                  a third party or that a third party is using the User's access
                  to the service without authorization, the User has to
                  immediately inform HQS.
                </li>
                <!-- 12.6 -->
                <li>
                  If HQS has concrete indications that the User is using the
                  service contrary to the provisions of this contract or other
                  contractual agreements between the parties to this contract or
                  that an unauthorized third party is accessing the service
                  without authorization using the User's access data, HQS is
                  entitled to suspend the User's access to the service until the
                  matter has been clarified. Except in case of imminent danger
                  HQS will give the User the opportunity to comment before such
                  action is taken. During the period of the suspension, the User
                  shall remain obliged to pay any agreed remuneration, unless
                  the User was not responsible for the circumstances leading to
                  the suspension.
                </li>
                <!-- 12.7 -->
                <li>
                  The User shall exempt HQS from all claims made by third
                  parties and shall compensate HQS for all damages incurred by
                  HQS as a result of any use of the service in violation of the
                  law or the contract, unless the User proves that it is not
                  responsible for the respective violation of the law.
                  Reimbursable damages shall also include the reasonable costs
                  of a legal defence that HQS would incur in defending itself
                  against third party claims. However, HQS shall inform the User
                  without undue delay of any such legal defence measures to be
                  taken. In the event of such disputes HQS may only enter into
                  settlements with third parties after consultation with the
                  User. Otherwise HQS shall bear all costs of the dispute
                  itself.
                </li>
              </ol>
            </li>
            <!-- 13. -->
            <li>
              <h4>Confidentiality, Data Protection</h4>
              <ol>
                <!-- 13.1 -->
                <li>
                  The contracting parties undertake to treat all trade secrets
                  of the other contracting party that become known to them from
                  the respective other contracting party during the execution of
                  the contract as confidential. The parties also agree to
                  maintain absolute secrecy about the contents of this contract.
                </li>
                <!-- 13.2 -->
                <li>
                  If the User applies the service of HQS to process data that
                  can be attributed to an identified or identifiable person
                  (personal data within the meaning of § 3 para. 1 BDSG (German
                  Federal Data Protection Act)), the User is solely responsible
                  for ensuring that said person has consented to the processing
                  of his/her data or that legal permission has been granted. The
                  User shall always remain the responsible party with regard to
                  such personal data. The User shall exempt HQS from all claims
                  of the party concerned and shall compensate HQS for all
                  damages incurred by HQS due to the use of personal data in
                  violation of data protection laws, unless the User proves that
                  he/she is not responsible for the violation.
                </li>
                <!-- 13.3 -->
                <li>
                  At the written request of the User, the contractual partners
                  shall conclude an agreement on commissioned data processing in
                  accordance with § 11 BDSG, insofar as this is required by the
                  statutory provisions. In such cases HQS will make a proposal
                  for an agreement on commissioned data processing.
                </li>
              </ol>
            </li>
            <!-- 14. -->
            <li>
              <h4>Performance and Invoicing by Third Parties</h4>
              <ol>
                <!-- 14.1 -->
                <li>
                  HQS is entitled to perform the contractually owed services by
                  third parties, in particular by companies affiliated with it.
                </li>
                <!-- 14.2 -->
                <li>
                  HQS is entitled to assign any claims arising from this
                  contract to third parties, especially to companies affiliated
                  with HQS, or to authorize third parties to assert claims in
                  their own name.
                </li>
                <!-- 14.3 -->
                <li>
                  The User may only offset against undisputed or legally
                  established claims. The User may only assert a right of
                  retention in cases of undisputed or legally established
                  claims. The User shall only be entitled to a right of
                  retention or the plea of non-performance of the contract
                  within this contractual relationship.
                </li>
              </ol>
            </li>
            <!-- 15. -->
            <li>
              <h4>General Information</h4>
              <ol>
                <!-- 15.1 -->
                <li>
                  Amendments, cancellations and additions to this contract must
                  be made in writing (e.g. fax, e-mail); this also applies to
                  any cancellation of this requirement for the written form.
                </li>
                <!-- 15.2 -->
                <li>
                  The contractual relationship shall be governed by the law of
                  the Federal Republic of Germany to the exclusion of the UN
                  Sales Convention.
                </li>
                <!-- 15.3 -->
                <li>
                  If the User is a businessperson, a legal entity under public
                  law or a holder of special assets under public law within the
                  meaning of § 38 ZPO (German Code of Civil Procedure) or if the
                  User relocates his domicile, usual place of residence or
                  registered office abroad after conclusion of the contract or
                  if this is unknown, the exclusive place of jurisdiction is
                  Karlsruhe. The courts in Karlsruhe shall also have
                  jurisdiction if the User has no general place of jurisdiction
                  in Germany.
                </li>
                <!-- 15.4 -->
                <li>
                  The invalidity or ineffectiveness of individual provisions of
                  these General Terms and Conditions shall not affect the
                  validity of the remaining provisions.
                </li>
                <!-- 15.5 -->
                <li>
                  In cases of conflict between the language versions, the German
                  language version shall prevail. The English version is only a
                  read-only version.
                </li>
              </ol>
            </li>
          </ol>
          <br /><br />
          <h3>Annex 1: Description of Services</h3>
          <br />
          <h2>Service Description</h2>
          <br />
          <p>
            The User is provided with a free single user license subject to
            verification of his registration data. <br /><br />
            The software and platform SCCE allows the approximate solution of
            grid models in one and two dimensions. Self-consistent cluster
            embedding simplifies the problem and it is up to the User to check
            and evaluate the quality of this approximation. A DMRG code serves
            as a solution method for the simplified problem. The quality of DMRG
            is well documented in the literature. <br /><br />
            The platform enables you to define a grid problem and save the
            results. Each User has at his/her disposal 100 HQ$ per month without
            any costs. The HQ$ are charged with the used computing power. Two
            HQ$ correspond to one hour of computing time on the HQS standard
            machine. The SCCE platform offers an estimation of the amount of HQ$
            that are likely to be used for a calculation. However, a calculation
            can be much cheaper. If all HQ$ of a User are spent, running
            calculations are aborted and no new jobs are started. <br /><br />
            Results of the calculations and simulations will be retrievable from
            the platform for at least one month.
          </p>
          <br /><br />
          <h3>Annex 2: Hourly Rate</h3>
          <br />
          <p>The hourly rate is 100 euros.</p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    toggleLanguage: 'de',
  }),
}
</script>

<style scoped type="text/css">
.toggle-button {
  float: right;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.8em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, '.') ' ';
}

ol li:before {
  font-weight: bold;
}

td {
  vertical-align: top;
  padding-bottom: 0.6em;
}

.header-column {
  width: 5%;
}
</style>
