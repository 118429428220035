import { cloneDeep } from 'lodash'

export default {
    bondKeys: {
        spinful: {
            complexDistinct: [
                't_uu_r', 't_dd_r', 't_ud_r', 't_du_r',
                'D_uu_r', 'D_dd_r', 'D_ud_r', 'D_du_r',
                't_uu_i', 't_dd_i', 't_ud_i', 't_du_i',
                'D_uu_i', 'D_dd_i', 'D_ud_i', 'D_du_i'],
            complexCombined: ['t_uu', 't_dd', 't_ud', 't_du', 'D_uu', 'D_dd', 'D_ud', 'D_du'],
            allDistinct: [
                /* currently not in use, because qolossal does not need it */
                /* 'U', */
                't_uu_r', 't_dd_r', 't_ud_r', 't_du_r',
                'D_uu_r', 'D_dd_r', 'D_ud_r', 'D_du_r',
                't_uu_i', 't_dd_i', 't_ud_i', 't_du_i',
                'D_uu_i', 'D_dd_i', 'D_ud_i', 'D_du_i'],
            allCombined: [/* 'U', */ 't_uu', 't_dd', 't_ud', 't_du', 'D_uu', 'D_dd', 'D_ud', 'D_du']
        },
        spinless: {
            complexDistinct: ['t_r', 't_i', 'D_r', 'D_i'],
            complexCombined: ['t', 'D'],
            allDistinct: [/* 'U', */ 't_r', 't_i', 'D_r', 'D_i'],
            allCombined: [/* 'U', */ 't', 'D']
        },
        jValues: ['Jz', 'Jperp', 'Jcross', 'Jd']
    },

    convertSiteToCurrentSite(site, latticeType) {
        let s = cloneDeep(site)
        let pos = cloneDeep(s.position)
        s.position = { x: pos[0], y: pos[1], z: pos[2] }
        if (latticeType.includes('spinful')) {
            s['onsite_BCS_r'] = cloneDeep(s.onsite_BCS[0]);
            s['onsite_BCS_i'] = cloneDeep(s.onsite_BCS[1]);
            delete s['onsite_BCS']
        }
        return s
    },

    convertBondToCurrentBond(bond, latticeType) {
        let b = cloneDeep(bond)
        let trans = cloneDeep(b.translation)
        b.translation = { a: trans[0], b: trans[1], c: trans[2] }
        if (latticeType.includes('spinful')) {
            b['t_uu_r'] = cloneDeep(b.t_uu[0]);
            b['t_uu_i'] = cloneDeep(b.t_uu[1]);
            b['t_dd_r'] = cloneDeep(b.t_dd[0]);
            b['t_dd_i'] = cloneDeep(b.t_dd[1]);
            b['t_ud_r'] = cloneDeep(b.t_ud[0]);
            b['t_ud_i'] = cloneDeep(b.t_ud[1]);
            b['t_du_r'] = cloneDeep(b.t_du[0]);
            b['t_du_i'] = cloneDeep(b.t_du[1]);
            b['D_uu_r'] = cloneDeep(b.D_uu[0]);
            b['D_uu_i'] = cloneDeep(b.D_uu[1]);
            b['D_dd_r'] = cloneDeep(b.D_dd[0]);
            b['D_dd_i'] = cloneDeep(b.D_dd[1]);
            b['D_ud_r'] = cloneDeep(b.D_ud[0]);
            b['D_ud_i'] = cloneDeep(b.D_ud[1]);
            b['D_du_r'] = cloneDeep(b.D_du[0]);
            b['D_du_i'] = cloneDeep(b.D_du[1]);
            this.bondKeys.spinful.complexCombined.forEach(e => delete b[e])
        }
        if (latticeType.includes('spinless')) {
            b['t_r'] = cloneDeep(b.t[0]);
            b['t_i'] = cloneDeep(b.t[1]);
            b['D_r'] = cloneDeep(b.D[0]);
            b['D_i'] = cloneDeep(b.D[1]);
            ['t', 'D'].forEach(e => delete b[e])
        }
        return b
    },

    convertCurrentSiteToSite(site, latticeType) {
        let s = cloneDeep(site)
        s.position = [s.position.x, s.position.y, s.position.z]
        if (latticeType.includes('spinful')) {
            s['onsite_BCS'] = [cloneDeep(s.onsite_BCS_r), cloneDeep(s.onsite_BCS_i)];
            ['onsite_BCS_r', 'onsite_BCS_i'].forEach(e => delete s[e])
        }
        return s
    },

    convertCurrentBondToBond(bond, latticeType) {
        let b = cloneDeep(bond)
        b.translation = [b.translation.a, b.translation.b, b.translation.c]
        if (latticeType.includes('spinful')) {
            b['t_uu'] = [cloneDeep(b.t_uu_r), cloneDeep(b.t_uu_i)];
            b['t_dd'] = [cloneDeep(b.t_dd_r), cloneDeep(b.t_dd_i)];
            b['t_ud'] = [cloneDeep(b.t_ud_r), cloneDeep(b.t_ud_i)];
            b['t_du'] = [cloneDeep(b.t_du_r), cloneDeep(b.t_du_i)];
            b['D_uu'] = [cloneDeep(b.D_uu_r), cloneDeep(b.D_uu_i)];
            b['D_dd'] = [cloneDeep(b.D_dd_r), cloneDeep(b.D_dd_i)];
            b['D_ud'] = [cloneDeep(b.D_ud_r), cloneDeep(b.D_ud_i)];
            b['D_du'] = [cloneDeep(b.D_du_r), cloneDeep(b.D_du_i)];
            this.bondKeys.spinful.complexDistinct.forEach(e => delete b[e]);
        }
        if (latticeType.includes('spinless')) {
            b['t'] = [b.t_r, b.t_i];
            b['D'] = [b.D_r, b.D_i];
            this.bondKeys.spinless.complexDistinct.forEach(e => delete b[e]);
        }
        return b
    },

    fixEmptyInputSite(site, latticeType, isCurrentSite) {
        let params = []
        let paramsComplex = []
        if (latticeType.includes('spinful')) {
            if (isCurrentSite) {
                params = ['e0', 'Bx', 'By', 'Bz', 'onsite_U', 'onsite_BCS_r', 'onsite_BCS_i']
            } else {
                params = ['e0', 'Bx', 'By', 'Bz', 'onsite_U']
                paramsComplex = ['onsite_BCS']
            }
        } else if (latticeType.includes('spinless')) {
            params = ['e0']
        } else if (latticeType.includes('spins')) {
            params = ['Bx', 'By', 'Bz']
        }
        for (let param of params) {
            if (param in site === false) { site[param] = 0 }
        }
        for (let param of paramsComplex) {
            if (param in site === false) { site[param] = [0, 0] }
        }
        return site
    },

    fixEmptyInputBond(bond, latticeType, isCurrentBond) {
        /* U is currently not in use because qolossal does not need it */
        let params = []
        let paramsComplex = []
        if (latticeType.includes('spinful')) {
            if (isCurrentBond) {
                params = [].concat(...this.bondKeys.jValues, ...this.bondKeys.spinful.allDistinct)
            } else {
                params = [...this.bondKeys.jValues, /* 'U' */]
                paramsComplex = this.bondKeys.spinful.complexCombined
            }
        } else if (latticeType.includes('spinless')) {
            if (isCurrentBond) {
                params = this.bondKeys.spinless.allDistinct
            } else {
                params = [/* 'U' */]
                paramsComplex = ['t', 'D']
            }
        } else if (latticeType.includes('spins')) {
            params = this.bondKeys.jValues
        }
        for (let param of params) {
            if (param in bond === false || bond[param] === null) { bond[param] = 0 }
        }
        for (let param of paramsComplex) {
            if (param in bond === false || bond[param] === null) {
                bond[param] = [0, 0]
            }
        }
        return bond
    },

    getCurrentSite(latticeType, id = '') {
        let base = {
            id: id,
            name: '',
            position: { x: 0, y: 0, z: 0 }
        }
        let e0 = { e0: 0 }
        let spinful = { onsite_U: 0, onsite_BCS_r: 0, onsite_BCS_i: 0 }
        let bValues = { Bx: 0, By: 0, Bz: 0 }
        if (latticeType.includes('spinful')) {
            return { ...base, ...e0, ...spinful, ...bValues }
        } else if (latticeType.includes('spinless')) {
            return { ...base, ...e0 }
        } else if (latticeType.includes('spins')) {
            return { ...base, ...bValues }
        }
    },

    setCurrentBond(latticeType, id = '') {
        let base = {
            id: id,
            id_from: '',
            id_to: '',
            translation: { a: 0, b: 0, c: 0 }
        }
        let jValues = {}
        this.bondKeys.jValues.forEach(e => jValues[e] = 0)
        let spinful = {}
        this.bondKeys.spinful.allDistinct.forEach(e => spinful[e] = 0)
        let spinless = {}
        this.bondKeys.spinless.allDistinct.forEach(e => spinless[e] = 0)
        if (latticeType.includes('spinful')) {
            return { ...base, ...jValues, ...spinful }
        } else if (latticeType.includes('spinless')) {
            return { ...base, ...spinless }
        } else if (latticeType.includes('spins')) {
            return { ...base, ...jValues }
        }
    },

    convertSites(sites, latticeType) {
        let convertedSites = []
        for (let site of sites) {
            if (latticeType.includes('spinful')) {
                site.Bx = 0
                site.By = 0
                site.Bz = 0
                site.onsite_U = 0
                site.onsite_BCS = [0, 0]
            }
            if (latticeType.includes('spinless')) {
                delete site.Bx
                delete site.By
                delete site.Bz
                delete site.onsite_U
                delete site.onsite_BCS
            }
            convertedSites.push(site)
        }
        return convertedSites
    },

    convertBonds(bonds, latticeType) {
        let convertedBonds = []
        for (let bond of bonds) {
            if (latticeType.includes('spinful')) {
                this.bondKeys.jValues.forEach(e => bond[e] = 0)
                this.bondKeys.spinful.complexCombined.forEach(e => bond[e] = [0, 0])
                delete bond.t
                delete bond.D
            }
            if (latticeType.includes('spinless')) {
                this.bondKeys.jValues.forEach(e => delete bond[e])
                this.bondKeys.spinful.complexCombined.forEach(e => delete bond[e])
                bond.t = [0, 0]
                bond.D = [0, 0]
            }
            convertedBonds.push(bond)
        }
        return convertedBonds
    },

    convertSystemType(system, latticeType) {
        if (latticeType.includes('spinless')) {
            delete system.Sz
            delete system.mod_Sz
            system.site_type = 'spinless_fermions'
        }
        if (latticeType.includes('spinful')) {
            system.Sz = 0
            system.mod_Sz = 0
            system.site_type = 'spinful_fermions'
        }
        return system
    },

}
