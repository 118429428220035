<template>
  <v-card class="elevation-12">
    <v-toolbar class="hqsOrange--text text" flat>
      <v-toolbar-title>Change Your Password</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-alert type="success" v-if="success"
        >Your password has been changed.</v-alert
      >
      <v-alert type="error" v-if="error"
        >An error has occured, please check your passwords.</v-alert
      >
      <v-form ref="form" v-model="valid">
        <v-text-field
          data-cy="old_pw"
          label="Your Current Password"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
          v-model="password"
          hint="At least 8 characters"
          :rules="rules"
        />

        <v-text-field
          data-cy="new_pw"
          label="Your New Password"
          name="newPassword"
          prepend-icon="mdi-lock"
          type="password"
          v-model="newPassword"
          hint="At least 8 characters"
          :rules="rules"
        />

        <v-text-field
          data-cy="confirm_pw"
          label="Confirm Your New Password"
          name="newPasswordConfirmation"
          prepend-icon="mdi-lock"
          type="password"
          hint="At least 8 characters"
          v-model="newPasswordConfirmation"
          :rules="rules"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        data-cy="change_pw"
        :dark="valid"
        color="hqsOrange"
        :disabled="!valid"
        @click="change()"
        >Change Password</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import auth from '../../views/lib/auth'

export default {
  data() {
    return {
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
      valid: false,
      success: false,
      error: false,
      rules: [
        (value) => !!value || 'Required.',
        (value) => (value && value.length >= 8) || 'Minimum 8 characters',
      ],
    }
  },
  methods: {
    
    async change() {
      this.success = false
      this.error = false

      if (this.newPassword !== this.newPasswordConfirmation) {
        this.error = true
        return
      }

      try {
        await auth.changePassword({
          password: this.password,
          newPassword: this.newPassword,
        })
        this.success = true
      } catch (e) {
        this.error = true
      }
      this.$refs.form.resetValidation()
      this.$refs.form.reset()
    },
  },
}
</script>
