<template>
  <v-container class="mt-15 text">
    <h1 class="header">Privacy</h1>
    <br /><br />

    <v-btn-toggle class="toggle-button" mandatory dense>
      <v-btn @click="toggleLanguage = 'de'"> de </v-btn>
      <v-btn @click="toggleLanguage = 'eng'"> eng </v-btn>
    </v-btn-toggle>

    <!-- german version-->
    <div v-if="toggleLanguage === 'de'">
      <v-row>
        <v-col>
          <h2 class="text-bold header">Datenschutzhinweise</h2>
          <h2 class="text-bold header">
            Cloud-Dienst cloud.quantumsimulations.de
          </h2>
          <br />
          <p>
            Beim Besuch und bei der Nutzung verschiedener Funktionen unseres
            Cloud-Dienstes werden personenbezogene Daten verarbeitet. Unter
            personenbezogenen Daten sind sämtliche Informationen zu verstehen,
            die sich auf eine identifizierte oder identifizierbare natürliche
            Person beziehen. Wir möchten Sie mit den nachfolgenden Angaben über
            Umfang, Art und Zwecke der Datenverarbeitung informieren und
            darüber, wie wir mit diesen Daten umgehen.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>Übersicht / Inhaltsverzeichnis</h2>
          <br />
          <p>
            In unseren Datenschutzhinweisen finden Sie folgende Informationen:
          </p>

          <!--A.-->
          <h3 class="underline">
            A. Unsere Kontaktdaten und allgemeines zur Datenverarbeitung durch
            uns
          </h3>
          <table>
            <tr>
              <td class="header-column text-bold">A.1</td>
              <td>Name und Kontaktdaten des Verantwortlichen</td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.2</td>
              <td>Kontaktdaten des Datenschutzbeauftragten</td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.3</td>
              <td>
                Allgemeines zu den Rechtsgrundlagen für die Verarbeitung
                personenbezogener Daten
              </td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.4</td>
              <td>Allgemeines zu Datenlöschung und Speicherdauer</td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.5</td>
              <td>Quellen der personenbezogenen Daten</td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.6</td>
              <td>
                Empfänger bzw. Kategorien von Empfängern der personenbezogenen
                Daten
              </td>
            </tr>
            <br />
            <tr>
              <td class="header-column text-bold">A.7</td>
              <td>Kontaktaufnahme über E-Mail, Fax und Telefonanruf</td>
            </tr>
          </table>
          <br />

          <!--B.-->
          <h3 class="underline">
            B. Umfang der Verarbeitung personenbezogener Daten über unseren
            Cloud-Dienst
          </h3>
          <table>
            <tr>
              <td class="header-column text-bold">B.1</td>
              <td>
                Hosting und Bereitstellung der Website, Erstellung von Log-Files
              </td>
            </tr>
            <tr>
              <td class="header-column text-bold">B.2</td>
              <td>Nutzung von technisch notwendigen Cookies</td>
            </tr>
            <tr>
              <td class="header-column text-bold">B.3</td>
              <td>Registrierung/Login</td>
            </tr>
            <tr>
              <td class="header-column text-bold">B.4</td>
              <td>
                Verschlüsselung von Datenübermittlungen über den Cloud-Dienst
              </td>
            </tr>
            <tr>
              <td class="header-column text-bold">B.5</td>
              <td>
                Übermittlung personenbezogener Daten an ein Drittland
                (EU-/EWR-Ausland)
              </td>
            </tr>
          </table>
          <br />

          <!--C.-->
          <h3 class="underline">C. Ihre Rechte als Betroffener</h3>
          <table>
            <tr>
              <td class="header-column text-bold">C.1</td>
              <td>Recht auf Auskunft</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.2</td>
              <td>Recht auf Berichtigung</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.3</td>
              <td>Recht auf Löschung</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.4</td>
              <td>Recht auf Einschränkung der Verarbeitung</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.5</td>
              <td>Recht auf Unterrichtung</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.6</td>
              <td>Recht auf Datenübertragbarkeit</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.7</td>
              <td>
                Widerspruchsrecht bei Verarbeitung wegen berechtigtem Interesse,
                sowie gegen Direktwerbung
              </td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.8</td>
              <td>Widerrufsrecht bei erteilter Einwilligung</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.9</td>
              <td>
                Automatisierte Entscheidungsfindung einschließlich Profiling
              </td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.10</td>
              <td>Freiwilligkeit der Bereitstellung der Daten</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.11</td>
              <td>Beschwerderecht bei einer Aufsichtsbehörde</td>
            </tr>
          </table>
          <br />
          <!--A. content-->
          <h2>
            A. Unsere Kontaktdaten und allgemeines zur Datenverarbeitung durch
            uns
          </h2>
          <br />
          <!--A.1 content-->
          <h3>A.1 Name und Kontaktdaten des Verantwortlichen</h3>
          <p>
            Verantwortlich im Sinne des Datenschutzrechts für die Erhebung und
            Nutzung personenbezogener Daten ist die
          </p>
          <p>
            HQS Quantum Simulations GmbH <br />
            Rintheimer Straße 23 <br />
            76131 Karlsruhe
            <br />
          </p>
          <p>
            vertreten durch die Geschäftsführer Michael Marthaler und Iris
            Schwenk
          </p>

          <p>
            E-Mail:
            <a href="mailto:info@quantumsimulations.de">
              info@quantumsimulations.de
            </a>
            <br />
            Website:
            <a href="http://www.quantumsimulations.de/">
              www.quantumsimulations.de</a
            ><br />
          </p>

          <!--A.2 content-->
          <h3>A.2 Name und Kontaktdaten des Verantwortlichen</h3>
          <p>Unseren Datenschutzbeauftragten können Sie wie folgt erreichen:</p>

          <p>
            MeinDatenschutzPartner.de GbR <br />
            Herr Timo Schutt <br />
            E-Mail:
            <a href="mailto:dsb@meindatenschutzpartner.de"
              >dsb@meindatenschutzpartner.de</a
            >
            <br />
          </p>

          <!--A.3 content-->
          <h3>
            A.3 Allgemeines zu den Rechtsgrundlagen für die Verarbeitung
            personenbezogener Daten
          </h3>
          <p>
            Ganz allgemein gilt für die Verarbeitung personenbezogener Daten
            durch uns folgendes:
          </p>

          <ul>
            <li>
              Soweit wir für Verarbeitungsvorgänge personenbezogener Daten Ihre
              Einwilligung einholen, dient Art. 6 Abs. 1 Buchstabea)
              EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage für die
              Verarbeitung personenbezogener Daten.
            </li>
            <li>
              Bei der Verarbeitung personenbezogener Daten, die zur Erfüllung
              eines Vertrages mit Ihnen erforderlich ist, dient Art. 6 Abs. 1
              Buchstabe b) DSGVO als Rechtsgrundlage. Das gilt auch schon, wenn
              die Verarbeitung zur Durchführung vorvertraglicher Maßnahmen
              erforderlich ist, also beispielsweise bei Bestellungen, Angeboten,
              Vertragsverhandlungen.
            </li>
            <li>
              Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung
              einer rechtlichen Verpflichtung erforderlich ist, der wir
              unterliegen, dient Art. 6 Abs. 1 Buchstabe c) DSGVO als
              Rechtsgrundlage.
            </li>
            <li>
              Für den Fall, dass Ihre lebenswichtigen Interessen oder die einer
              anderen natürlichen Person eine Verarbeitung personenbezogener
              Daten erforderlich machen, dient Art. 6 Abs. 1 Buchstabe d) DSGVO
              als Rechtsgrundlage.
            </li>
            <li>
              Falls die Verarbeitung Ihrer personenbezogenen Daten für die
              Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
              Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die
              uns übertragen wurde, geschieht dies aufgrund der Rechtsgrundlage
              des Art. 6 Abs. 1 Buchstabe e) DSGVO.
            </li>
            <li>
              Ist die Verarbeitung zur Wahrung eines berechtigten Interesses von
              uns oder eines Dritten erforderlich und überwiegen Ihre
              Interessen, Grundrechte und Grundfreiheiten dieses Interesse
              nicht, dient Art. 6 Abs. 1 Buchstabe f) DSGVO als Rechtsgrundlage
              für die Verarbeitung.
            </li>
          </ul>
          <br />

          <!--A.4 content-->
          <h3>A.4 Allgemeines zu Datenlöschung und Speicherdauer</h3>
          <p>
            Die personenbezogenen Daten werden von uns allgemein dann gelöscht
            oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
            Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den
            europäischen oder nationalen Gesetzgeber in unionsrechtlichen
            Verordnungen, Gesetzen oder sonstigen Vorschriften, denen wir als
            Verantwortlicher unterliegen, vorgesehen wurde. Eine Sperrung oder
            Löschung der Daten erfolgt auch dann, wenn eine durch die genannten
            Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
            Erforderlichkeit zur weiteren Speicherung der Daten für einen
            Vertragsabschluss oder eine Vertragserfüllung besteht.
          </p>

          <p>
            <span class="underline">Konkret bedeutet das:</span><br />
            Verarbeiten wir die personenbezogenen Daten aufgrund einer
            <b>Einwilligung</b> zur Datenverarbeitung (Art. 6 Absatz 1 Buchstabe
            a) Datenschutzgrundverordnung, kurz: DSGVO), so endet die
            Verarbeitung mit Ihrem Widerruf, es sei denn es existiert ein
            weiterer Rechtsgrund zur Verarbeitung der Daten, was beispielsweise
            dann der Fall ist, wenn wir zum Zeitpunkt des Widerrufs noch
            berechtigt sind Ihre Daten zum Zwecke der Vertragserfüllung zu
            verarbeiten (vgl. hierzu jeweils unten).
          </p>
          <p>
            Verarbeiten wir die Daten aufgrund unserer
            <b>berechtigten Interessen</b>
            (Art. 6 Absatz 1 Buchstabe f) DSGVO) im Rahmen einer zuvor
            vorgenommenen Abwägung, so speichern wir diese, bis das berechtigte
            Interesse nicht mehr besteht, die Abwägung zu einem anderen Ergebnis
            kommt oder Sie nach Art. 21 DSGVO wirksam Widerspruch eingelegt
            haben (vgl. hierzu den optisch hervorgehobenen „Hinweis auf
            besonderes Widerspruchsrecht“ unten unter C.).
          </p>
          <p>
            Verarbeiten wir die Daten zur <b>Vertragserfüllung</b>, dann
            speichern wir die Daten so lange, bis der Vertrag endgültig erfüllt
            und abgewickelt ist und keine Ansprüche mehr aus dem Vertrag geltend
            gemacht werden können, also bis zum Eintritt der Verjährung. Die
            allgemeine Verjährungsfrist nach § 195 BGB beträgt drei (3) Jahre.
            Bestimmte Ansprüche, wie beispielsweise Schadensersatzansprüche,
            verjähren jedoch erst in 30 Jahren (vgl. § 197 BGB). Besteht
            begründeter Anlass anzunehmen, dass dies im Einzelfall relevant ist,
            so speichern wir die personenbezogenen Daten über diesen Zeitraum.
            Die genannten Verjährungsfristen beginnen mit dem Ende des Jahres
            (also am 31.12.) in dem der Anspruch entstanden ist und der
            Gläubiger von den den Anspruch begründenden Umständen und der Person
            des Schuldners Kenntnis erlangt oder ohne grobe Fahrlässigkeit
            erlangen müsste.
          </p>
          <p>
            Wir weisen darauf hin, dass wir daneben auch gesetzlichen
            <b>Aufbewahrungspflichten</b> aus handelsrechtlichen, steuerlichen
            und buchhalterischen Gründen unterliegen. Diese verpflichten uns als
            Nachweis für unsere ordnungsgemäße Geschäftstätigkeit bzw.
            Buchführung bestimmte Daten, zu denen auch personenbezogene Daten
            gehören können, über einen Zeitraum von sechs (6) bis zu zehn (10)
            Jahren aufzubewahren. Diese Aufbewahrungsfristen gehen den oben
            genannten Löschungspflichten vor. Auch die Aufbewahrungsfristen
            beginnen jeweils mit Schluss des betreffenden Jahres, also am 31.12.
          </p>

          <!--A.5 content-->
          <h3>A.5 Allgemeines zu den Quellen der personenbezogenen Daten</h3>
          <p>
            Die von uns verarbeiteten personenbezogenen Daten stammen in erster
            Linie von den betroffenen Personen selbst, beispielsweise indem
            diese
          </p>
          <ul>
            <li>
              als Nutzer unserer Webseite über den Webbrowser und ihr Endgerät
              (beispielsweise ein PC, ein Smartphone, ein Tablet oder ein
              Notebook) Informationen, wie beispielsweise die IP-Adresse, an uns
              bzw. unseren Webserver übermitteln,
            </li>
            <li>
              als Interessenten Infomaterial oder ein Angebot bei uns anfordern,
            </li>
            <li>
              als Kunden uns einen Auftrag erteilen bzw. einen Vertrag mit uns
              schließen,
            </li>
            <li>
              als Pressevertreter Infomaterial, Pressemeldungen, Stellungnahmen
              o.ä. anfordern,
            </li>
          </ul>
          <p>
            Nur ausnahmsweise können die von uns verarbeiteten personenbezogenen
            Daten auch von Dritten stammen, beispielsweise dann, wenn eine
            Person im Namen eines Dritten handelt.
          </p>

          <!--A.6 content-->
          <h3>A.6 Kategorien von Empfängern der personenbezogenen Daten</h3>
          <p>
            Ihre personenbezogenen Daten werden an Dritte nur dann weitergegeben
            oder übermittelt, wenn dies zum jeweiligen Zweck zwingend
            erforderlich und zulässig ist. An wen wir Daten zu welchem Zweck
            weitergeben, erläutern wir jeweils im Zusammenhang mit den unten
            beschriebenen Datenverarbeitungen bzw. bei Übermittlungen ins
            EU-Ausland zusätzlich am Ende von Abschnitt B. dieser
            Datenschutzhinweise.
          </p>
          <p>Kategorien von Empfängern können grundsätzlich sein:</p>
          <ul>
            <li>Dienstleister,</li>
            <li>Lieferanten,</li>
            <li>Geschäftspartner,</li>
            <li>Steuerberater.</li>
          </ul>
          <br />

          <!--A.7 content-->
          <h3>A.7 Kontaktaufnahme über E-Mail und Telefon mit uns</h3>
          <p>
            Sie können sich über verschiedene Kontaktwege an uns wenden, wenn
            Sie möchten, beispielsweise per E-Mail und Telefon. Auch dann, wenn
            Sie uns eine E-Mail schreiben oder uns anrufen, verarbeiten wir
            zwangsläufig personenbezogene Daten von Ihnen. Denn es werden
            zumindest die mit der E-Mail, oder Ihrem Telefon übermittelten
            personenbezogenen Daten von uns bzw. unseren Systemen gespeichert.
          </p>
          <p>
            Eine Weitergabe der Daten an Dritte erfolgt in diesem Zusammenhang
            nicht. Die Daten werden ausschließlich zum Zweck der Abwicklung der
            Konversation verwendet.
          </p>
          <p>
            <span class="underline">Zwecke der Datenverarbeitung:</span> Die
            Verarbeitung der personenbezogenen Daten bei Kontaktaufnahme per
            E-Mail oder Telefon, dient uns zur Bearbeitung Ihres Anliegens. Wir
            benötigen dabei zwingend Ihre E-Mailadresse oder Ihre Telefonnummer,
            um überhaupt antworten zu können.
          </p>
          <p>
            <span class="underline"
              >Rechtsgrundlage für die Datenverarbeitung:</span
            >
            Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
            einer Einwilligung nach Art. 6 Abs. 1 Buchstabe a) DSGVO, die Sie
            durch die aktive Kontaktaufnahme mit uns erteilt haben. Zielt der
            Kontakt bzw. Ihre Anfrage auf den Abschluss eines Vertrages ab, so
            ist Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 Buchstabe b)
            DSGVO (Durchführung vorvertraglicher Maßnahmen).
          </p>
          <p>
            <span class="underline">Dauer der Speicherung:</span> Die Daten
            werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
            Erhebung nicht mehr erforderlich sind. <br />
            Für die personenbezogenen Daten, die per E-Mail übersandt wurden,
            ist dies dann der Fall, wenn die jeweilige Konversation mit Ihnen
            beendet ist und wir danach eine Karenzzeit von bis zu 6 Monaten
            abgewartet haben, ob wir ggf. auf Ihr Anliegen bzw. die Details der
            Kommunikation nochmals zurückgreifen müssen. Beendet ist die
            Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass
            der betroffene Sachverhalt abschließend geklärt ist. <br />
            Bei einem eingehenden Telefonanruf oder bei einem ausgehenden Anruf
            bei uns wird Ihre Telefonnummer oder Ihr bei Ihrem Telefonanbieter
            hinterlegter Name/Firmenname sowie Datum und Uhrzeit des Anrufs in
            unserer Telefonanlage in einem sog. Ringspeicher gespeichert, der
            die ältesten Daten mit neuen Daten überschreibt. Im Regelfall führt
            dies in der Telefonanlage zu einer automatischen Löschung der Daten
            nach ca. 3-4 Monaten. <br />
            Die Kommunikation kann ggf. aus handelsrechtlicher oder
            steuerrechtlicher Sicht einer Aufbewahrungspflicht unterliegen, die
            sodann vorgeht (vgl. die Ausführungen oben zu „Datenlöschung und
            Speicherdauer“).
          </p>
          <p>
            <span class="underline"
              >Widerspruchs- und Beseitigungsmöglichkeit:</span
            >
            Sie haben jederzeit die Möglichkeit, eine erteilte Einwilligung zur
            Verarbeitung der personenbezogenen Daten zu widerrufen bzw. der
            weiteren Datenverarbeitung wegen berechtigten Interesses zu
            widersprechen (vgl. den Hinweis auf das besondere Widerspruchsrecht
            unter C. dieser Datenschutzhinweise). In einem solchen Fall kann die
            Konversation nicht fortgeführt werden. <br />
            Der Widerruf der Einwilligung bzw. der Widerspruch der weiteren
            Datenverarbeitung werden durch formlose Mitteilung an uns (bspw. per
            E-Mail) ermöglicht. <br />
            Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme
            gespeichert wurden, werden in diesem Fall gelöscht.
          </p>
          <br />

          <!--B. content-->
          <h2>
            B. Umfang der Verarbeitung personenbezogener Daten über unseren
            Cloud-Dienst (cloud.quantumsimulations.de)
          </h2>
          <p>
            Wir erheben und verwenden personenbezogene Daten der Nutzer im
            Rahmen der Nutzung unseres Cloud-Dienstes grundsätzlich nur, soweit
            dies zur Bereitstellung einer funktionsfähigen Website sowie unserer
            Inhalte und Leistungen erforderlich bzw. sinnvoll ist. Die Erhebung
            und Verwendung personenbezogener Daten unserer Nutzer erfolgt in der
            Regel nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in
            solchen Fällen, in denen eine vorherige Einholung einer Einwilligung
            aus tatsächlichen Gründen nicht möglich ist und/oder die
            Verarbeitung der Daten durch gesetzliche Vorschriften erlaubt ist.
          </p>
          <br />

          <!--B.1 content-->
          <h3>
            B.1 Hosting und Bereitstellung der Website, Erstellung von Log-Files
          </h3>
          <p>
            Unser Cloud-Dienst wird gehostet von AWS, einem Dienst der Amazon
            Web Services, Inc., USA, wobei die Daten ausschließlich auf
            AWS-Servern der Region EU-Central-1 im Großraum Frankfurt/Main
            gehostet werden, sich also ausschließlich innerhalb der EU bzw. des
            EWR befinden und verarbeitet werden.
          </p>
          <p>
            Bei jedem Aufruf unseres Cloud-Dienstes erfasst unser bei AWS
            gehosteter Webserver aus technischen Gründen automatisch Daten und
            Informationen. Diese werden in den Logfiles des Servers gespeichert.
            Es handelt sich dabei um:
          </p>
          <ul>
            <li>Datum und Uhrzeit des Zugriffs,</li>
            <li>URL (Adresse) der verweisenden Website (Referrer),</li>
            <li>
              Webseiten, die vom System des Nutzers über unsere Website
              aufgerufen werden,
            </li>
            <li>Bildschirmauflösung des Nutzers,</li>
            <li>
              abgerufene Datei(en) und Meldung über den Erfolg des Abrufs,
            </li>
            <li>Menge der gesendeten Daten,</li>
            <li>den Internet-Service Provider des Nutzers,</li>
            <li>
              Browser, Browsertyp und Browserversion, Browser-Engine und
              Engine-Version,
            </li>
            <li>Betriebssystem, Betriebssystem-Version, Betriebssystem-Typ,</li>
            <li>
              die anonymisierte IP-Adresse und den Internet-Service-Provider des
              Nutzers, sowie
            </li>
            <li>Herkunftsland der IP-Adresse.</li>
          </ul>
          <p>
            Die Daten werden über den Hostprovider vollständig getrennt von
            anderen Daten verarbeitet. Eine Verarbeitung dieser Daten zusammen
            mit anderen personenbezogenen Daten des Nutzers findet nicht statt.
            Eine Zuordnung dieser Daten zu einer bestimmten Person ist uns nicht
            möglich. Die Daten werden ausschließlich in entpersonalisierter Form
            verarbeitet.
          </p>
          <p>
            <span class="underline">Zwecke der Datenverarbeitung:</span> Die
            vorübergehende Verarbeitung der Daten durch das System ist
            notwendig, um eine Auslieferung der Inhalte unseres Cloud-Dienstes
            an den Rechner des Nutzers zu ermöglichen. Hierfür muss die
            IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert
            bleiben. Die Speicherung in Logfiles erfolgt, um die
            Funktionsfähigkeit des Cloud-Dienstes sicherzustellen. Zudem dienen
            uns die Daten zur Optimierung unseres Angebots und zur
            Sicherstellung der Sicherheit unserer informationstechnischen
            Systeme, wie auch zur Betrugsprävention. Eine Auswertung der Daten
            zu Marketingzwecken findet in diesem Zusammenhang nicht statt.
          </p>
          <p>
            <span class="underline"
              >Rechtsgrundlage der Datenverarbeitung:</span
            >
            Die vorübergehende Speicherung der Daten und den Logfiles erfolgt
            auf Basis der Rechtsgrundlage des Art. 6 Absatz 1 Buchstabe f)
            DSGVO. Unser überwiegendes berechtigtes Interesse an dieser
            Datenverarbeitung liegt in den zuvor genannten Zwecken.
          </p>
          <p>
            <span class="underline">Dauer der Speicherung:</span> Die Daten
            werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
            Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der
            Daten zur Bereitstellung der Website ist dies der Fall, wenn die
            jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in
            Logfiles ist dies in der Regel nach sieben Tagen der Fall. Eine
            darüberhinausgehende Speicherung ist möglich. In diesem Fall werden
            die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine
            Zuordnung des aufrufenden Clients nicht mehr möglich ist.
          </p>
          <p>
            <span class="underline"
              >Widerspruchs- und Beseitigungsmöglichkeit:</span
            >
            Die Erfassung der Daten zur Bereitstellung des Clous-Dienstes und
            die Speicherung der Daten in Logfiles ist für den Betrieb der
            Internetseite zwingend erforderlich. Es besteht folglich seitens des
            Nutzers hier keine Widerspruchsmöglichkeit. Der Nutzer kann jedoch
            jederzeit die Nutzung der Website beenden und damit die weitere
            Erhebung der genannten Daten verhindern.
          </p>
          <br />

          <!--B.2 content-->
          <h3>B.2 Nutzung von technisch notwendigen Cookies</h3>
          <p>
            Beim Aufruf einzelner Seiten werden von uns bzw. vom Hostprovider so
            genannte Cookies verwendet. Das sind kleine Textdateien, die auf
            Ihrem Endgerät (PC, Smartphone, Tablet etc.) abgelegt werden. Rufen
            Sie eine solche Seite auf, kann ein Cookie in Ihrem Browser
            gespeichert werden. Dieses Cookie enthält eine charakteristische
            Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
            erneuten Aufrufen der Seite ermöglicht.
          </p>
          <p>
            Es werden nur solche Cookies eingesetzt, die technisch notwendig
            sind, um die Funktionen des Cloud-Dienstes überhaupt nutzbar zu
            machen (Zwischenspeicherung von Authentifikations-Tokens und Daten
            des Benutzerkontos, während der Nutzer eingeloggt ist, was angelegte
            Dokumente, User-ID und E-Mailadresse beinhaltet).
          </p>
          <p>
            <span class="underline">Zweck der Datenverarbeitung:</span> Zweck
            der Verwendung technisch notwendiger Cookies ist, die Nutzung des
            Cloud-Dienstes für Nutzer zu ermöglichen. Einige Funktionen können
            ohne den Einsatz solcher Cookies nicht angeboten werden. Für diese
            ist es erforderlich, dass der Browser auch nach einem Seitenwechsel
            wiedererkannt wird. Die durch technisch notwendige Cookies erhobenen
            Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen
            verwendet.
          </p>
          <p>
            <span class="underline"
              >Rechtsgrundlage der Datenverarbeitung:</span
            >
            Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
            Verwendung von technisch notwendigen Cookies ist § 25 Absatz 2 Nr. 2
            TTDSG i.V.m. Art. 6 Abs. 1 Buchstabe f) DSGVO, also ein berechtigtes
            Interesse unsererseits. Unser berechtigtes Interesse liegt in den
            oben genannten Zwecken.
          </p>
          <p>
            <span class="underline">Dauer der Speicherung:</span> Die von uns
            verwendeten technisch notwendigen Cookies werden nach dem Ende der
            Browser-Sitzung, also nach dem Schließen Ihres Browsers, wieder
            gelöscht (so genannte Sitzungs-Cookies).
          </p>
          <p>
            <span class="underline"
              >Widerspruchs- und Beseitigungsmöglichkeit:</span
            >
            Cookies werden auf Ihrem Rechner gespeichert und von diesem an
            unsere Seite übermittelt. Daher haben Sie die volle Kontrolle über
            die Verwendung von Cookies. Durch eine Änderung der Einstellungen in
            Ihrem Internetbrowser können Sie die Übertragung von Cookies
            deaktivieren oder einschränken. Bereits gespeicherte Cookies können
            jederzeit gelöscht werden. Hinweis: Werden Cookies für unsere
            Website deaktiviert, können möglicherweise nicht mehr alle
            Funktionen der Website vollumfänglich genutzt werden.
          </p>

          <!--B.3 content-->
          <h3>B.3 Registrierung/Login</h3>
          <p>
            Nutzer können sich bei unserem Cloud-Dienst unter Angabe
            personenbezogener Daten registrieren und in einem geschützten
            Bereich anmelden. Die Daten werden dabei in eine Eingabemaske
            eingegeben und an uns übermittelt und gespeichert. Eine Weitergabe
            der Daten an Dritte findet nicht statt. Folgende Daten werden im
            Rahmen des Registrierungsprozesses erhoben:
          </p>
          <ul>
            <li>E-Mailadresse,</li>
            <li>Vorname und Name</li>
            <li>Firma bzw. Institution</li>
            <li>Position in der Firma bzw. Institution</li>
            <li>Registrierungsgrund.</li>
          </ul>
          <br />
          <p>
            Im Zeitpunkt der Registrierung werden zudem folgende Daten
            gespeichert:
          </p>
          <ul>
            <li>IP-Adresse des Nutzers,</li>
            <li>Datum und Uhrzeit der Registrierung.</li>
          </ul>
          <br />
          <p>
            Im Rahmen des Registrierungsprozesses wird eine Einwilligung des
            Nutzers zur Verarbeitung dieser Daten eingeholt.
          </p>
          <p>
            <span class="underline">Zwecke der Datenverarbeitung:</span> Die
            Registrierung des Nutzers ist zwingend erforderlich, damit unsere
            Angebote im Rahmen des Cloud-Dienstes in Anspruch genommen werden
            können. Im Falle einer zunächst kostenlosen Nutzung durch
            ausgewählte Nutzer dient die Registrierung der Sicherstellung, dass
            nur diese den geschützten Bereich des Dienstes erreichen können und
            der Vorbereitung des Angebots der kostenpflichtigen Nutzung. Im
            Falle der kostenpflichtigen Nutzung dient die Registrierung darüber
            hinaus der Vertragsabwicklung und Abrechnung. Die Registrierung des
            Nutzers ist mithin notwendige Voraussetzung für einen
            Vertragsschluss und dient als Durchführung einer vorvertraglichen
            Maßnahme.
          </p>
          <p>
            <span class="underline"
              >Rechtsgrundlage für die Datenverarbeitung:</span
            >
            Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
            einer Einwilligung des Nutzers Art. 6 Abs. 1 Buchstabe a) DSGVO.
            Dient die Registrierung der Erfüllung eines Vertrages, dessen
            Vertragspartei der Nutzer ist oder der Durchführung vorvertraglicher
            Maßnahmen, so ist Rechtsgrundlage für die Verarbeitung der Daten
            Art. 6 Abs. 1 Buchstabe b) DSGVO.
          </p>
          <p>
            <span class="underline">Dauer der Speicherung:</span> Die Daten
            werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
            Erhebung nicht mehr erforderlich sind. <br />
            Dient die Registrierung nicht zum Abschluss eines Vertrages mit dem
            Nutzer, dann ist dies für die während des Registrierungsvorgangs
            erhobenen Daten dann der Fall, wenn die Registrierung aufgehoben
            oder abgeändert wird bzw. der Nutzer uns gegenüber wirksam den
            Widerspruch seiner Einwilligung erklärt. <br />
            Dient die Registrierung zum Abschluss eines Vertrages mit dem
            Nutzer, dann ist dies dann der Fall, wenn die Daten für die
            Durchführung des Vertrages nicht mehr erforderlich sind. Auch nach
            Beendigung des Vertrags kann eine Erforderlichkeit, personenbezogene
            Daten des Vertragspartners zu speichern, bestehen, um vertraglichen
            oder gesetzlichen Verpflichtungen nachzukommen. Wir weisen auf die
            Ausführungen oben unter „Datenlöschung und Speicherdauer“ hin.
          </p>
          <p>
            <span class="underline"
              >Widerspruchs- und Beseitigungsmöglichkeit:</span
            >
            Sie haben jederzeit die Möglichkeit, die Registrierung aufzulösen.
            Dies können Sie über Ihren Nutzer-Account oder durch entsprechende
            Mitteilung an uns veranlassen. <br />
            Sind die Daten zur Erfüllung eines Vertrages oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich, ist eine vorzeitige
            Löschung der Daten nur möglich, soweit nicht vertragliche oder
            gesetzliche Verpflichtungen einer Löschung entgegenstehen. <br />
            Die über Sie gespeicherten Daten können Sie überdies jederzeit
            abändern/berichtigen.
          </p>
          <br />

          <!--B.4 content-->
          <h3>
            B.4 Verschlüsselung von Datenübermittlungen über den Cloud-Dienst
          </h3>
          <p>
            Datenübermittlungen über unseren Cloud-Dienst sind mit TLSv1.3,
            SHA-256 mit RSA-2048 verschlüsselt.
          </p>
          <br />

          <!--B.5 content-->
          <h3>
            B.5 Übermittlung personenbezogener Daten an ein Drittland
            (EU-/EWR-Ausland)
          </h3>
          <p>
            Es ist von uns nicht beabsichtigt und wird von uns nicht angenommen,
            aber es können ggf. dennoch personenbezogene Daten außerhalb der EU
            bzw. des EWR verarbeitet werden.
          </p>
          <p>
            Dabei kann eine Datenverarbeitung u.U. auch in den USA erfolgen. Die
            USA werden vom Europäischen Gerichtshof als ein Land mit einem nach
            EU-Standards unzureichendem Datenschutzniveau eingeschätzt. Es
            besteht insbesondere das Risiko, dass Ihre Daten durch US-Behörden,
            zu Kontroll- und zu Überwachungszwecken, möglicherweise auch ohne
            Rechtsbehelfsmöglichkeiten, verarbeitet werden können.
          </p>
          <p>
            Soweit ein solcher Drittstaatentransfer in Betracht kommt, haben
            sich die entsprechenden Dienstleister/Anbieter/Dritte durch
            verbindliche Vereinbarung der EU-Standarddatenschutzklauseln (EU
            Standard Contractual Clauses, SCC, vgl. Artikel 46 an Absatz 2 c)
            DSGVO) einem dem EU-Datenschutzniveau vergleichbaren Regularium
            unterworfen. Die Datenübermittlung ist daher grundsätzlich zulässig.
            <br />
            Überdies wurden im Falle von Auftragsverarbeitungen mit diesen
            Unternehmen entsprechende Auftragsverarbeitungsverträge zur sicheren
            Verarbeitung der Daten und Gewährleistung unserer Weisungsrechte
            geschlossen.
          </p>
          <br />

          <!--C. content-->
          <h2>C. Betroffenenrechte</h2>
          <p>
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
            „Betroffener“ und es stehen Ihnen folgende Rechte gegenüber uns als
            Verantwortlichen zu:
          </p>
          <br />

          <!--C.1 content-->
          <h3>C.1 Recht auf Auskunft</h3>
          <p>
            Sie haben das Recht, von uns unentgeltlich eine Bestätigung darüber
            zu erhalten, ob wir Sie betreffende personenbezogene Daten
            verarbeiten. Ist dies der Fall, dann haben Sie ein Recht auf
            Auskunft über diese personenbezogenen Daten und auf weitere
            Informationen, die Sie Art. 15 DSGVO entnehmen können. Sie können
            sich hierfür per Post oder per E-Mail an uns wenden.
          </p>
          <br />

          <!--C.2 content-->
          <h3>C.2 Recht auf Berichtigung</h3>
          <p>
            Sie haben das Recht, von uns unverzüglich die Berichtigung Sie
            betreffender unrichtiger personenbezogener Daten zu verlangen.
            Ebenso haben Sie das Recht – unter Berücksichtigung der oben
            genannten Zwecke der Verarbeitung – die Vervollständigung
            unvollständiger personenbezogener Daten – auch mittels einer
            ergänzenden Erklärung – zu verlangen. Sie können sich hierfür per
            Post oder per E-Mail an uns wenden.
          </p>
          <br />

          <!--C.3 content-->
          <h3>C.3 Recht auf Löschung</h3>
          <p>
            Sie haben das Recht, die unverzügliche Löschung der Sie betreffenden
            personenbezogenen Daten zu verlangen, wenn eine der Voraussetzungen
            des Art. 17 DSGVO vorliegt. Sie können sich hierfür per Post oder
            per E-Mail an uns wenden.
          </p>
          <br />

          <!--C.4 content-->
          <h3>C.4 Recht auf Einschränkung der Verarbeitung</h3>
          <p>
            Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu
            verlangen, wenn eine der Voraussetzungen des Art. 18 DSGVO vorliegt.
            Sie können sich hierfür per Post oder per E-Mail an uns wenden.
          </p>
          <br />

          <!--C.5 content-->
          <h3>C.5 Recht auf Unterrichtung</h3>
          <p>
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
            der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist
            dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
            personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
            Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen,
            es sei denn, dies erweist sich als unmöglich oder ist mit einem
            unverhältnismäßigen Aufwand verbunden. <br />
            Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese
            Empfänger unterrichtet zu werden.
          </p>
          <br />

          <!--C.6 content-->
          <h3>C.6 Recht auf Datenübertragbarkeit</h3>
          <p>
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
            die Sie uns bereitgestellt haben, in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten und Sie haben das Recht,
            diese Daten einem anderen Verantwortlichen ohne Behinderung durch
            uns zu übermitteln, wenn die Voraussetzungen des Art. 20 DSGVO
            vorliegen. Sie können sich hierfür per Post oder per E-Mail an uns
            wenden.
          </p>
          <br />

          <!--C.7 content-->
          <h3>
            C.7 Widerspruchsrecht bei Verarbeitung wegen berechtigtem Interesse,
            sowie gegen Direktwerbung
          </h3>
          <p class="frame">
            Soweit wir ausnahmsweise personenbezogene Daten auf der Basis des
            Art. 6 Absatz 1 Buchstabe f) DSGVO verarbeiten (also wegen
            berechtigten Interessen), haben Sie das Recht <b>jederzeit</b> aus
            Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die
            Verarbeitung der sie betreffenden personenbezogenen Daten bei uns
            <b>Widerspruch</b> einzulegen. Wenn wir keine zwingenden
            schutzwürdigen Gründe für die weitere Verarbeitung nachweisen
            können, die Ihre Interessen, Rechte und Freiheiten überwiegen oder
            aber, wenn wir die betreffenden Daten von Ihnen zum Zwecke der
            Direktwerbung verarbeiten, so werden wir Ihre Daten dann nicht mehr
            verarbeiten (vgl. Art. 21 DSGVO). Sie können sich hierfür per Post
            oder per E-Mail an uns wenden. <br /><br />
            Als Widerspruch in diese Sinne gilt auch ein technisches Verfahren,
            das Sie einsetzen, bspw. eine eindeutige technische Information, die
            Ihr Webbrowser uns übermittelt („Do-Not-Track“-Mitteilung).
            <br /><br />
            Werden personenbezogene Daten verarbeitet, um Direktwerbung zu
            betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
            Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
            derartiger Werbung einzulegen; dies gilt auch für das Profiling,
            soweit es mit solcher Direktwerbung in Verbindung steht.
          </p>
          <br />

          <!--C.8 content-->
          <h3>C.8 Widerrufsrecht bei erteilter Einwilligung</h3>
          <p>
            Sie haben das Recht, eine erteilte Einwilligung in die Erhebung und
            Verwendung personenbezogener Daten mit Wirkung für die Zukunft
            jederzeit zu widerrufen. Sie können sich hierfür per Post oder per
            E-Mail an uns wenden. Die Rechtmäßigkeit der aufgrund der
            Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch
            nicht berührt.
          </p>
          <br />

          <!--C.9 content-->
          <h3>
            C.9 Automatisierte Entscheidungsfindung einschließlich Profiling
          </h3>
          <p>
            Sie haben das Recht, nicht einer ausschließlich auf einer
            automatisierten Verarbeitung – einschließlich Profiling – beruhenden
            Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
            Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
            beeinträchtigt. Es sei denn die Entscheidung ist für den Abschluss
            oder die Erfüllung eines Vertrags zwischen Ihnen und uns
            erforderlich, sie ist aufgrund von Rechtsvorschriften der Union oder
            der Mitgliedstaaten, denen wir unterliegen, zulässig und diese
            Rechtsvorschriften enthalten angemessene Maßnahmen zur Wahrung Ihrer
            Rechte und Freiheiten sowie Ihrer berechtigten Interessen oder die
            Entscheidung erfolgt mit Ihrer ausdrücklichen Einwilligung. <br />
            Eine solche automatisierte Entscheidungsfindung findet durch uns
            nicht statt.
          </p>
          <br />

          <!--C.10 content-->
          <h3>C.10 Freiwilligkeit der Bereitstellung der Daten</h3>
          <p>
            Ist die Bereitstellung der personenbezogenen Daten gesetzlich oder
            vertraglich vorgeschrieben, so weisen wir grundsätzlich bei der
            Erhebung der Daten darauf hin. Teilweise sind die von uns erhobenen
            Daten für einen Vertragsabschluss erforderlich, nämlich dann, wenn
            wir anderweitig unsere vertragliche Verpflichtung Ihnen gegenüber
            nicht oder nicht ausreichend erfüllen könnten. Es besteht für Sie
            keine Verpflichtung die personenbezogenen Daten bereitzustellen.
            Jedoch kann die Nichtbereitstellung dazu führen, dass wir eine von
            Ihnen gewünschte Leistung, Handlung, Maßnahme o.ä., nicht
            durchführen oder anbieten können oder ein Vertragsschluss mit Ihnen
            nicht möglich ist.
          </p>
          <br />

          <!--C.11 content-->
          <h3>C.11 Beschwerderecht bei einer Aufsichtsbehörde</h3>
          <p>
            Sie haben jederzeit unbeschadet anderweitiger Rechte das Recht auf
            Beschwerde bei einer Aufsichtsbehörde für den Datenschutz,
            insbesondere in dem Mitgliedsstaat Ihres Aufenthaltsorts, Ihres
            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie
            der Ansicht sind, dass die Verarbeitung der sie betreffenden
            personenbezogenen Daten gegen das Datenschutzrecht verstößt.
          </p>
          <p>
            Für uns ist zuständig: <br /><br />
            Der Landesbeauftragte für den Datenschutz und die
            Informationsfreiheit Baden-Württemberg, <br />
            Königstraße 10A <br />
            70173 Stuttgart <br />
            Webseite:
            <a href="https://www.baden-wuerttemberg.datenschutz.de/"
              >www.baden-wuerttemberg.datenschutz.de</a
            >. <br />
          </p>
          <br />

          <p><i>Stand unserer Datenschutzhinweise: 07.04.2022</i></p>
        </v-col>
      </v-row>
    </div>

    <!-- english version-->
    <div v-if="toggleLanguage === 'eng'">
      <v-row>
        <v-col>
          <h2 class="text-bold header">Data Protection Information</h2>
          <h2 class="text-bold header">
            Cloud Service cloud.quantumsimulations.de
          </h2>
          <br />
          <p>
            When visiting and using various functions of our cloud service,
            personal data is processed. Personal data means any information
            relating to an identified or identifiable natural person. With the
            following information, we would like to inform you about the scope,
            type and purpose of data processing and how we handle this data.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>Overview / Contents</h2>
          <br />
          <p>
            You will find the following information in our Data Protection
            Information
          </p>

          <!--A.-->
          <h3 class="underline">
            A. Our contact data and general matters relating to our data
            processing
          </h3>
          <table>
            <tr>
              <td class="header-column text-bold">A.1</td>
              <td>Name and contact data of the controller</td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.2</td>
              <td>Contact data of the data protection officer</td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.3</td>
              <td>
                General information about legal basis for the processing of
                personal data
              </td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.4</td>
              <td>
                General information about data deletion and duration of
                archiving
              </td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.5</td>
              <td>Sources of personal data</td>
            </tr>
            <tr>
              <td class="header-column text-bold">A.6</td>
              <td>
                Recipients and categories of recipients of the personal data
              </td>
            </tr>
            <br />
            <tr>
              <td class="header-column text-bold">A.7</td>
              <td>Contacting by email, fax and phone call</td>
            </tr>
          </table>
          <br />

          <!--B.-->
          <h3 class="underline">
            B. The scope of the processing of personal data via our cloud
            service
          </h3>
          <table>
            <tr>
              <td class="header-column text-bold">B.1</td>
              <td>
                Hosting and provision of the website, creation of log files
              </td>
            </tr>
            <tr>
              <td class="header-column text-bold">B.2</td>
              <td>Use of technically necessary cookies</td>
            </tr>
            <tr>
              <td class="header-column text-bold">B.3</td>
              <td>Registration/Login</td>
            </tr>
            <tr>
              <td class="header-column text-bold">B.4</td>
              <td>Encryption of data transfers via the cloud service</td>
            </tr>
            <tr>
              <td class="header-column text-bold">B.5</td>
              <td>
                Transmission of personal data to a third country (countries
                outside the EU/EEA)
              </td>
            </tr>
          </table>
          <br />

          <!--C.-->
          <h3 class="underline">C. Your rights as the data subject</h3>
          <table>
            <tr>
              <td class="header-column text-bold">C.1</td>
              <td>The right to be informed</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.2</td>
              <td>The right to rectification</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.3</td>
              <td>The right to erasure</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.4</td>
              <td>The right to restrict processing</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.5</td>
              <td>The right to information</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.6</td>
              <td>The right to data portability</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.7</td>
              <td>
                The right to object to processing because of a legitimate
                interest and direct mail
              </td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.8</td>
              <td>The right to revoke consent</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.9</td>
              <td>Automatic decision-making including profiling</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.10</td>
              <td>Voluntary provision of data</td>
            </tr>
            <tr>
              <td class="header-column text-bold">C.11</td>
              <td>The right to complain to a supervisory authority</td>
            </tr>
          </table>
          <br />

          <!--A. content-->
          <h2>
            A. Our contact data and general matters relating to our data
            processing
          </h2>
          <!--A.1 content-->
          <h3>A.1 Name and contact data of the controller</h3>
          <p>
            The controller for the collection and use of personal data within
            the meaning of data protection legislation is
          </p>
          <p>
            HQS Quantum Simulations GmbH <br />
            Rintheimer Straße 23 <br />
            76131 Karlsruhe
            <br />
          </p>
          <p>
            represented by the managing directors Michael Marthaler and Iris
            Schwenk
          </p>

          <p>
            Email address:
            <a href="mailto:info@quantumsimulations.de">
              info@quantumsimulations.de
            </a>
            <br />
            Website:
            <a href="http://www.quantumsimulations.de/">
              www.quantumsimulations.de</a
            ><br />
          </p>

          <!--A.2 content-->
          <h3>A.2 Contact data of the controller’s Data Protection Officer</h3>
          <p>You can reach our Data Protection Officer as follows:</p>
          <p>
            MeinDatenschutzPartner.de GbR <br />
            Mr Timo Schutt <br />
            E-Mail:
            <a href="mailto:dsb@meindatenschutzpartner.de"
              >dsb@meindatenschutzpartner.de</a
            >
            <br />
          </p>

          <!--A.3 content-->
          <h3>
            A.3 General information about legal basis for the processing of
            personal data
          </h3>
          <p>
            In general, the following applies when we process personal data:
          </p>

          <ul>
            <li>
              In so far as we obtain your consent for processing procedures of
              personal data, Art. 6 para. 1 letter a) of the EU General Data
              Processing Regulation (GDPR) acts as the legal basis for the
              processing of personal data.
            </li>
            <li>
              In the case of the processing of personal data which is needed for
              the performance of a contract with you, Art.6 para.1 letter b) of
              the GDPR acts as the legal basis. This also applies already if the
              processing for the performance of pre-contractual measures is
              necessary, also e.g. for orders, quotations, contractual
              negotiations.
            </li>
            <li>
              In so far as the processing of personal data is necessary for the
              performance of a legal obligation to which we are bound, Art. 6
              para. 1 letter c) of the GDPR acts as the legal basis.
            </li>
            <li>
              In the event that the vital interests of yours or another natural
              person render the processing of personal data necessary, Art. 6
              para. 1 letter d) of the GDPR acts as the legal basis.
            </li>
            <li>
              If it is necessary to process your personal data for the
              performance of a task carried out in the public interest or in the
              exercise of any official authority vested in us, this is done on
              the basis of Art. 6 para. 1 letter e) of the GDPR.
            </li>
            <li>
              If the processing is necessary for the protection of a legitimate
              interest of us or of a third party and your interests, fundamental
              rights and freedoms do not override this interest, Art. 6 para. 1
              letter f) of the GDPR acts as the legal basis.
            </li>
          </ul>
          <br />

          <!--A.4 content-->
          <h3>
            A.4 General information about Data deletion and duration of
            archiving
          </h3>
          <p>
            Generally we delete or block the personal data as soon as the
            purpose of the archiving no longer applies. Data can also be
            archived if this was stipulated by the European or national
            legislative body in EU regulations, laws or other provisions to
            which we, as the controller, are subject. Data is also blocked or
            deleted if a retention period required by the above-mentioned
            regulations etc. expires unless it is necessary that the data
            continues to be archived for the conclusion or performance of a
            contract.
          </p>

          <p>
            <span class="underline">In specific terms this means:</span><br />
            If we are processing the personal data on the basis of
            <b>consent</b> for data processing (Art. 6 para. 1 letter a) of the
            General Data Protection Regulation (GDPR), the processing is ended
            when you revoke your consent unless a further legal basis for
            processing the data exists. This is e.g. the case if, at the time of
            the revocation, we are still entitled to process your data for the
            purpose of the performance of a contract (on this point see also
            below).
          </p>
          <p>
            If we are processing the data by reason of our
            <b>legitimate interests</b>
            (Art. 6 para. 1 letter f) of the GDPR as part of a previous
            assessment, we will save this data until the legitimate interest no
            longer exists, the assessment comes to a different conclusion, or
            you have lodged a valid objection pursuant to Art. 21 of the GDPR
            (on this point see the highlighted “Note on a particular right to
            object” below under C.).
          </p>
          <p>
            If we are processing the data for the purpose of the
            <b>performance of a contract</b> we will save the data until the
            contract has been finally performed and brought to a conclusion and
            no further claims can asserted under the contract, in other words
            until the matter becomes time-barred. The general period of
            prescription according to § 195 of the German Civil Code is three
            (3) years. However, certain claims, for example claims for
            compensation, only become time barred after 30 years (cf. § 197
            German Civil Code). If there is a legitimate reason for assuming
            that this is relevant in a particular case, we will save the
            personal data during this period of time. The above-mentioned
            periods of prescription commence at the end of the year (therefore
            on December 31) in which the claim arose and the obligee becomes
            aware or should have become aware of the circumstances giving rise
            to the claim and the person of the liable party becomes or should
            have become aware of the foregoing without gross negligence.
          </p>
          <p>
            We wish to point out that we are also subject to statutory retention
            obligations for reasons associated with commercial law, taxation and
            book-keeping. These oblige us to archive certain data as evidence
            for our orderly business activity respectively book-keeping which
            can include personal data for a period which can range from six (6)
            to ten (10) years. These retention periods take precedence over the
            above-mentioned deletion obligations. The retention periods also
            commence at the end of the year in question, and therefore December
            31.
          </p>

          <!--A.5 content-->
          <h3>A.5 General information about the sources of personal data</h3>
          <p>
            The personal data we process originates primarily from the data
            subject himself or herself, for example by these persons
          </p>
          <ul>
            <li>
              as users of our web-site via their browser and terminal (e.g. a
              PC, smartphone, tablet or notebook) transmitting information such
              as their IP address to us respectively our web-server,
            </li>
            <li>
              as interested parties requesting information material or
              quotation,
            </li>
            <li>
              as clients confirm an order with us close a contract with us,
            </li>
            <li>
              as representatives of the press asking for press releases, a
              statement or similar,
            </li>
          </ul>
          <p>
            As a rare exception, the personal data we process may also come from
            third parties, for example if a person is acting on behalf of
            another person.
          </p>

          <!--A.6 content-->
          <h3>A.6 Categories of recipients of the personal data</h3>
          <p>
            Your personal data is only passed or transmitted to third parties if
            this is absolutely essential for the relevant purpose and is
            permissible. We explain to whom and why we pass data in connection
            with the data processing described below; at the end of Section B of
            this Data Protection Information we also provide further information
            on data transmitted to EU countries outside Germany.
          </p>
          <p>Categories of recipients can basically be:</p>
          <ul>
            <li>service providers,</li>
            <li>suppliers,</li>
            <li>business partners,</li>
            <li>tax advisers.</li>
          </ul>
          <br />

          <!--A.7 content-->
          <h3>A.7 Contacting us by Email and phone call</h3>
          <p>
            If you wish you can contact us in several ways, for example by email
            and phone call. If you send us an email or call us we will also
            inevitably process your personal data. As a minimum we save or our
            system saves the personal data transmitted to us by email or your
            phone call.
          </p>
          <p>
            The data is not passed to third parties in this context. The data is
            only used for the processing of the conversation.
          </p>
          <p>
            <span class="underline">The purposes of data processing:</span> The
            processing of the personal data when contacting us by email or phone
            call is so that we can deal with your request and the approach you
            made to us. It is also essential that we have your email address or
            phone number so that we can respond.
          </p>
          <p>
            <span class="underline">The legal basis for data processing:</span>
            The legal basis for the processing of the data is the consent as
            required in Art. 6 para. 1 letter a) of the GDPR which you have
            given by actively making contact with us. <br />
            If the purpose of the contact or your request is the conclusion of a
            contract, the legal basis for the processing is Art. 6 para. 1
            letter b) of the GDPR (execution of pre-contractual measures).
          </p>
          <p>
            <span class="underline">Duration of the archiving:</span>The data is
            deleted as soon as it is no longer needed to achieve the purpose for
            which it was collected. <br />
            For the personal data which was sent by email, this is the case if
            the relevant exchange with you is at an end and we have then waited
            for a period of up to 6 months to establish whether we must refer
            again to your request and the details of the exchange. The
            conversation is at an end if it can be gathered from the
            circumstances that the matter in question has been definitely
            settled. In the case of an incoming or outgoing phone call your
            phone number or your name / company name which you have registered
            with your telephone provider as well as the date and time of the
            call are stored in what is called a “ring memory” in our phone
            system. This memory overwrites the oldest data with the new data. In
            normal circumstances this means that the data is automatically
            deleted in the phone system after about 3-4 months. <br />
            It may happen that due to commercial or fiscal law the exchange is
            subject to a retention obligation which then comes into play (cf.
            the information above in the section “Data deletion and retention
            period”).
          </p>
          <p>
            <span class="underline"
              >The right to object and the right to erasure:</span
            >
            You may at any time revoke consent given for the processing of the
            personal data and object to further data processing because of a
            legitimate interest (cf. the advice on a particular right to object
            under C of this Data Protection Information). In such a case the
            conversation cannot be continued. <br />
            You can revoke the consent and object to further data processing
            without any need for a specific form (e.g. you can use email).
            <br />
            In this case all personal data which was saved in the course of the
            contact with you is deleted.
          </p>
          <br />

          <!--B. content-->
          <h2>
            B. Scope of the processing of personal data via our cloud service
            (cloud.quantumsimulations.de)
          </h2>
          <p>
            As a matter of principle we only collect and use the personal data
            of users during the use of our cloud service in so far as this is
            necessary and reasonable for the provision of a functioning
            web-site. its content and our services. Normally the personal data
            of our users is collected and used only after the user has granted
            his/her consent. The exception is such cases in which it is not
            factually possible to obtain consent in advance and/or the
            processing is permitted by the provisions of law.
          </p>
          <br />

          <!--B.1 content-->
          <h3>
            B.1 Hosting and provision of the website, creation of log files
          </h3>
          <p>
            Our cloud service is hosted by AWS, a service of Amazon Web
            Services, Inc., USA, whereby the data is hosted exclusively on AWS
            servers of the EU-Central-1 region in the Frankfurt/Main area, i.e.
            is located and processed exclusively within the EU or EEA.
          </p>
          <p>
            Every time you access our cloud service, our web server hosted by
            AWS automatically collects data and information for technical
            reasons. This is saved in the server's log files. This information
            is:
          </p>
          <ul>
            <li>the data and time of access,</li>
            <li>
              the URL of the web-site from which access was made (the referrer),
            </li>
            <li>
              the web-sites which were accessed by the user's system via our
              web-site,
            </li>
            <li>the user's screen resolution,</li>
            <li>
              the file(s) accessed and a report of the success of the access,
            </li>
            <li>the amount of data sent,</li>
            <li>the user's Internet service-provider,</li>
            <li>
              the browser, browser type and version, the browser engine and
              engine version,
            </li>
            <li>the operating system, operating system version and type,</li>
            <li>
              the user's anonymised IP address and Internet service-provider,
              and
            </li>
            <li>home country of the IP address.</li>
          </ul>
          <p>
            The data is processed completely separately from other data via the
            host provider. This data is not processed in combination with the
            user's other personal data. We cannot attribute this data to a
            particular person. The data is processed exclusively in a
            depersonalised form.
          </p>
          <p>
            <span class="underline">The purposes of data processing:</span> The
            temporary processing of the data by the system is necessary so that
            it is possible to send the contents of our cloud service to the
            user's computer. The user's IP address must be saved for the
            duration of the session to achieve this. Data is saved in log files
            to ensure the functionality of the cloud service. The data also
            enables us to optimise our offering and to protect the security of
            our computer system and also prevent the fraud. The data is not
            evaluated for marketing purposes in this connection.
          </p>
          <p>
            <span class="underline"
              >The legal basis for the data processing:</span
            >
            The data and the log files are temporarily saved on the legal basis
            of Art. 6 para. 1 letter f) of the GDPR Our overriding legitimate
            interest in this data processing is to be found in the purposes
            stated above.
          </p>
          <p>
            <span class="underline">Duration of the archiving:</span> The data
            is deleted as soon as it is no longer needed to achieve the purpose
            for which it was collected. In the case of data capture for the
            provision of the web-site, the data is deleted when the session is
            terminated. In the case of storage of data in log files, this is
            usually the case after seven days. It is not possible to save the
            data for longer. In this case the users' IP addresses are deleted or
            distorted so that it is no longer possible to attribute them to the
            client accessing the web-site.
          </p>
          <p>
            <span class="underline"
              >The right to object and the right to erasure:</span
            >
            The capture of data is essential for the provision of the cloud
            service, and the saving of data in log files is necessary for the
            operation of the web-site. As a consequence the user has no right to
            object to this practice. However, the user may terminate the use of
            the web-site at any time and therefore prevent the continued
            collection of the data specified above.
          </p>
          <br />

          <!--B.2 content-->
          <h3>B.2 Use of technically necessary cookies</h3>
          <p>
            When accessing our individual pages we or the host provider use
            so-called cookies. Cookies are small text files which are installed
            on the terminal (PC, smartphone, tablet etc.). If you access such a
            site a cookie can be saved in your browser. This cookie includes a
            characteristic sequence of characters which enable the browser to be
            unmistakeably identified if the page is accessed again.
          </p>
          <p>
            Only those cookies are used that are technically necessary to make
            the functions of the cloud service usable at all (caching of
            authentication tokens and user account data while the user is logged
            in, which includes created documents, user ID and email address).
          </p>
          <p>
            <span class="underline">The purposes of data processing:</span> The
            purpose of the use of technically necessary cookies is to enable the
            use of the cloud service for users. Some of the functions cannot be
            provided without the use of cookies. For these it is necessary that
            the browser is re-identified after switching to a different page.
            The user data collected by the cookies needed for technical purpose
            is not used for creating user profiles.
          </p>
          <p>
            <span class="underline"
              >The legal basis for the data processing:</span
            >
            The legal basis for the processing of personal data using
            technically necessary cookies is Art. 25 para. 2 No. 2 TTDPA in
            conjunction with Art. 6 para. 1 letter f) GDPR, i.e. a legitimate
            interest on our part. Our legitimate interest is to be found in the
            purposes stated above.
          </p>
          <p>
            <span class="underline">Duration of the archiving:</span> The
            technically necessary cookies we use are deleted again at the end of
            the browser session, in other words when you close your browser
            (these are called “session cookies”).
          </p>
          <p>
            <span class="underline"
              >The right to object and right of erasure:</span
            >
            Cookies are saved on your computer and transmitted from there to our
            site. You therefore have full control over the use of cookies. You
            can deactivate or restrict the transmission of cookies by changing
            the settings in your Internet browser. Cookies which have already
            been saved can be deleted at any time. Note: If cookies for our
            web-site are deactivated, it is possible that all functions of the
            web-site can no longer be used to their full extent.
          </p>

          <!--B.3 content-->
          <h3>B.3 Registration/Login</h3>
          <p>
            Users can register with our cloud service by providing personal data
            and logging into a protected area. This process involves entering
            data in an entry mask which is sent to us and saved. The data is not
            passed to third parties. The following data is collected during the
            registration process:
          </p>
          <ul>
            <li>E-mail address,</li>
            <li>First name and surname,</li>
            <li>Company or institution,</li>
            <li>Position in the company or institution,</li>
            <li>Registration reason.</li>
          </ul>
          <br />
          <p>The following data is also saved at the time of registration:</p>
          <ul>
            <li>user’s IP address,</li>
            <li>the data and time of the subscription.</li>
          </ul>
          <br />
          <p>
            The user’s consent for processing this data is obtained as part of
            the registration process.
          </p>
          <p>
            <span class="underline">The purposes of data processing:</span> The
            registration of the user is mandatory in order to be able to make
            use of our offers within the framework of the cloud service. In the
            case of initially free use by selected users, registration serves to
            ensure that only these users can access the protected area of the
            service and to prepare the offer of paid use. In the case of
            chargeable use, the registration also serves the purpose of contract
            processing and billing. The registration of the user is therefore a
            necessary prerequisite for the conclusion of a contract and serves
            as the implementation of a pre-contractual measure.
          </p>
          <p>
            <span class="underline">The legal basis for data processing:</span>
            The legal basis for processing the data is the user’s consent as
            described in Art. 6 para. 1 letter a) of the GDPR. <br />
            If the purpose of the registration is the performance of a contract
            and the contractual party is the user, or if the purpose is the
            execution of pre-contractual activities, there exists a legal basis
            for the processing of the data in the form of Art. 6 para. 1 letter
            b) of the GDPR.
          </p>
          <p>
            <span class="underline">Duration of the archiving:</span> The data
            is deleted as soon as it is no longer needed to achieve the purpose
            for which it was collected. <br />
            If the registration does not serve to conclude a contract with the
            user, this is the case for the data collected during the
            registration process if the registration is cancelled or modified or
            the user effectively declares to us that he/she objects to his/her
            consent. <br />
            If the registration results in the conclusion of a contract with the
            user, this applies if the data is no longer necessary for the
            performance of the contract. The need to save the personal data of a
            contractual partner can exist after even after the termination of
            the contract in order to comply with contractual or legal
            obligations. We draw the reader’s attention to the statements above
            in the section “Data deletion and retention period“.
          </p>
          <p>
            <span class="underline"
              >The right to object and the right to erasure:</span
            >
            You have the right to terminate the registration at any time. You
            can do this via your user account or by notifying us accordingly.
            <br />
            If the data is necessary for the performance of a contract or the
            execution of pre-contractual activities, premature deletion of the
            data is only possible in so far as non-contractual or legal
            obligations do not conflict with premature deletion of the data.
            <br />
            You can also change/correct the data stored about you at any time.
          </p>
          <br />

          <!--B.4 content-->
          <h3>B.4 Encryption of data transfers via the cloud service</h3>
          <p>
            Data transmissions via our cloud service are encrypted using
            TLSv1.3, SHA-256 with RSA-2048.
          </p>
          <br />

          <!--B.5 content-->
          <h3>
            B.5 Transmission of personal data to a third country (countries
            outside the EU/EEA)
          </h3>
          <p>
            It is not intended or assumed by us, but personal data may still be
            processed outside the EU or EEA.
          </p>
          <p>
            Data processing may also take place in the USA. The USA is assessed
            by the European Court of Justice as a country with an insufficient
            level of data protection according to EU standards. There is a
            particular risk that your data may be processed by US authorities,
            for control and for monitoring purposes, possibly also without any
            legal remedy.
          </p>
          <p>
            Insofar as such a third country transfer comes into consideration,
            the corresponding service providers/providers/third parties have
            subjected themselves to a regulation comparable to the EU data
            protection level by binding agreement of the EU Standard Contractual
            Clauses (SCC, cf. Art. 46 an (2) c) GDPR). The transmission of data
            is therefore unquestionably permitted. <br />
            Furthermore, in the case of commissioned processing, corresponding
            commissioning contracts have been concluded with these companies for
            the secure processing of the data and guaranteeing our rights to
            issue instructions.
          </p>
          <br />

          <!--C. content-->
          <h2>C. Rights of data subjects</h2>
          <p>
            If your personal data is processed you are a "data subject" and you
            are entitled to the following rights in respect of us as the
            controller.
          </p>
          <br />

          <!--C.1 content-->
          <h3>C.1 The right to be informed</h3>
          <p>
            You have the right to receive a confirmation from us free of charge
            whether we are processing personal data relating to you. In this
            case you have the right to information about this personal data and
            other information which you can see in Art. 14 of the GDPR. You can
            contact us for this purpose by post or email.
          </p>
          <br />

          <!--C.2 content-->
          <h3>C.2 The right to rectification</h3>
          <p>
            You have the right to require that we immediately correct inaccurate
            personal data relating to you. You also have the right, for the
            purposes set out above, to require additions to incomplete personal
            data, including by means of a supplementary declaration. You can
            contact us for this purpose by post or email.
          </p>
          <br />

          <!--C.3 content-->
          <h3>C.3 The right to erasure</h3>
          <p>
            You have the right to require the immediate deletion of personal
            data relating to you if one of the conditions of Art. 17 of the GDPR
            is met. You can contact us for this purpose by post or email.
          </p>
          <br />

          <!--C.4 content-->
          <h3>C.4 The right to restrict processing</h3>
          <p>
            You have the right to require the restriction of processing if one
            of the conditions of Art. 18 of the GDPR is met. You can contact us
            for this purpose by post or email.
          </p>
          <br />

          <!--C.5 content-->
          <h3>C.5 The right to information</h3>
          <p>
            If you have asserted the right to the correction, deletion or
            restriction of the processing to the controller, the latter is
            obliged to inform all recipients to which the personal data relating
            to you was disclosed about the correction or deletion of the data or
            about the restriction of the processing unless this proves to be
            impossible or is associated with disproportionate effort. You have
            the right to be informed by the Controller about these recipients.
          </p>
          <br />

          <!--C.6 content-->
          <h3>C.6 The right to data portability</h3>
          <p>
            You have the right to receive the personal data you sent to us
            relating to you in a structured, commonly used and machine-readable
            format and have the right to transmit this data to another
            controller without hindrance from us if the conditions of Art. 20 of
            the GDPR are met. You can contact us for this purpose by post or
            email.
          </p>
          <br />

          <!--C.7 content-->
          <h3>
            C.7 The right to object to processing because of a legitimate
            interest and direct mail
          </h3>
          <p class="frame">
            In so far as we process personal data on by way of exception the
            basis of Art. 6 para. 1 letter f) of the GDPR (therefore for reason
            of a legitimate interest,) you have the right, for reasons arising
            from your particular situation, to <b>object at any time</b> to our
            processing of the personal data relating to you. We will cease
            processing your data if we can demonstrate no compelling reasons
            worthy of protection for the further processing which override your
            interests, rights and freedoms or if we are processing your data for
            the purposes of direct advertising (cf. Art. 21 of the GDPR). You
            can contact us for this purpose by post or email. <br /><br />
            A technical process which you use, for example an unambiguous
            statement sent by technical means by your browser (a "do not track"
            message) is also deemed to be objections in within these meanings.
            <br /><br />
            If personal data is processed for the purpose of direct marketing,
            you have the right at any time to lodge an objection to the
            processing of personal data relating to yourself for the purposes of
            this type of advertising; this also applies to profiling insofar as
            it is associated with such direct marketing.
          </p>
          <br />

          <!--C.8 content-->
          <h3>C.8 The right to revoke consent</h3>
          <p>
            You have the right at any time to revoke an agreement you have given
            for the collection and use of personal data with effect for the
            future. You can contact us for this purpose by post or email. The
            lawfulness of the processing undertaken by reason of the consent you
            gave up to the time of its revocation is not affected.
          </p>
          <br />

          <!--C.9 content-->
          <h3>C.9 Automatic decision-making including profiling</h3>
          <p>
            You have the right not to be subject to a decision based exclusively
            on automated processing (including profiling) which has a legal
            effect on you or which is significantly to your detriment in a
            similar manner unless the decision is necessary for the conclusion
            of an agreement between you and us, is admissible by reasons of
            provisions of law of the European Union or member states to which we
            are subject and these provisions of law contain reasonable measures
            to protect your rights, freedoms and legitimate interests, or the
            decision is taken with your express consent. We do not take
            automated decisions of this nature.
          </p>
          <br />

          <!--C.10 content-->
          <h3>C.10 Voluntary provision of data</h3>
          <p>
            If the provision of the personal data is stipulated by law or a
            contract, we will always point this out when the data is collected.
            The data we collect is sometimes necessary for the conclusion of a
            contract, to be specific, if we are unable to meet our contractual
            obligation to you or cannot adequately meet them in any other way.
            You are under no obligation to provide personal data. However, the
            failure to provide such information can mean that we are unable to
            perform or offer the service, action, measure or similar you
            require, or that it is impossible to conclude a contract with you.
          </p>
          <br />

          <!--C.11 content-->
          <h3>C.11 The right to complain to a supervisory authority</h3>
          <p>
            Notwithstanding other rights, if you are of the opinion that the
            processing of personal data relating to you infringes data
            protection law, you have the right at all times to complain to a
            supervisory authority for data protection, particularly in the
            member state where you reside, where you work or the place of the
            alleged infringement.
          </p>
          <p>
            The supervisory body responsible for us is: <br /><br />
            The Baden-Württemberg State Commissioner for Data Protection and
            Freedom of Information, <br />
            Königstraße 10A <br />
            70173 Stuttgart <br />
            Webseite:
            <a href="https://www.baden-wuerttemberg.datenschutz.de/"
              >www.baden-wuerttemberg.datenschutz.de</a
            >. <br />
          </p>
          <br />

          <p><i>Data privacy statement version: 07.04.2022</i></p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    toggleLanguage: 'de',
  }),
}
</script>

<style scoped type="text/css">
h1 {
  text-align: center;
}

p,
table,
ul {
  margin-left: 40px;
}

.header {
  text-align: center;
}

.toggle-button {
  position: absolute;
  right: 25px;
}

td {
  vertical-align: top;
  padding-bottom: 0.6em;
}

.header-column {
  width: 10%;
}

.underline {
  text-decoration: underline;
}

.frame {
  border: 2px solid;
  padding: 5px;
  border-radius: 10px;
  border-color: #4d4d4d;
}
</style>
