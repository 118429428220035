<template>
  <div id="register">
    <a class="anchor" href="#register">Register</a>
    <v-container class="fill-height" fluid>
      <h1 class="anchor">Register</h1>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-form ref="form" v-model="valid">
            <v-card class="elevation-12">
              <v-toolbar class="hqsOrange--text text mt-15" flat>
                <v-toolbar-title>Register new Account</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-alert type="error" v-if="error">
                  An error has occured, please check your email and password.
                </v-alert>

                <v-text-field
                  label="Your Email Address"
                  name="email"
                  prepend-icon="mdi-email"
                  type="text"
                  v-model="email"
                  :rules="[rules.required]"
                  autofocus
                />

                <v-text-field
                  label="Your Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="password"
                  hint="At least 8 characters"
                  :rules="[rules.required, rules.min]"
                />

                <v-text-field
                  label="Confirm Your Password"
                  name="passwordConfirmationField"
                  prepend-icon="mdi-lock"
                  type="password"
                  hint="At least 8 characters"
                  v-model="passwordConfirmation"
                  :rules="[rules.required, rules.min]"
                />

                <v-text-field
                  label="Your Name"
                  name="name"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="name"
                  :rules="[rules.required]"
                />

                <v-text-field
                  label="Your Company/Institution"
                  name="institution"
                  prepend-icon="mdi-domain"
                  type="text"
                  v-model="institution"
                  :rules="[rules.required]"
                />

                <v-text-field
                  label="Your Position"
                  name="position"
                  prepend-icon="mdi-briefcase"
                  type="text"
                  v-model="position"
                  :rules="[rules.required]"
                />

                <v-text-field
                  label="Why Are You Joining?"
                  name="reason"
                  prepend-icon="mdi-help"
                  type="text"
                  v-model="reason"
                  :rules="[rules.required]"
                />

                <v-checkbox v-model="terms" :rules="[rules.checked]">
                  <span slot="label">
                    Accept
                    <a @click.stop href="/agb.pdf" target="_blank"
                      >Terms of Use</a
                    >
                    <!-- <a @click.stop target="_blank" href="/terms">Terms of Use</a> -->
                  </span>
                </v-checkbox>
                <p>
                  By sending us your data, you consent to us processing it to
                  the extent stated in our data protection information for the
                  purpose of processing your inquiry. If you make use of
                  services from us, we will process the data for the purpose of
                  fulfilling the contract. We delete the data when you revoke
                  your consent or the contract is finally terminated and claims
                  are time-barred. You can find more information on this in our
                  <router-link :to="{ name: 'privacy' }"
                    >"Privacy Policy"</router-link
                  >.
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  rounded
                  outlined
                  :dark="valid"
                  color="hqsOrange"
                  :disabled="!valid"
                  @click.prevent="register()"
                  type="submit"
                  >Register</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import auth from '../../views/lib/auth'

export default {
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      name: '',
      institution: '',
      position: '',
      reason: '',
      valid: false,
      terms: false,
      error: false,
      loading: false,
      rules: {
        checked: (value) => value || 'Required.',
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
      },
    }
  },
  methods: {
    ...mapMutations('auth', ['setEmail']),

    async register() {
      this.loading = true
      this.error = false
      if (this.password !== this.passwordConfirmation) {
        this.error = true
        return
      }
      try {
        await auth.signUp({
          username: this.email,
          password: this.password,
          attributes: {
            email: this.email,
            name: this.name,
            institution: this.institution,
            position: this.position,
            reason: this.reason,
          },
        })
        this.setEmail(this.email)
        this.$router.push('/auth/confirm')
      } catch (e) {
        this.error = true
      }
      this.loading = false
    },
  },
}
</script>
