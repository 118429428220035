import Vue from 'vue'

import vuetify from './plugins/vuetify'
import store from './store/index'
import router from './plugins/router'
import './plugins/bus'
import '@/plugins/apexcharts'
import './assets/css/main.css';
import App from './App.vue'

Vue.config.productionTip = false

window.app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
