<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-form ref="form" v-model="valid">
          <v-card class="elevation-12">
            <v-toolbar color="hqsOrange" dark flat>
              <v-toolbar-title>Reset Password</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert type="error" v-if="errorRequest"
                >Password reset failed. Please check your email.</v-alert
              >
              <v-alert type="error" v-if="errorReset">
                Password reset failed. Please check your code and both
                passwords.
              </v-alert>
              <v-alert type="success" v-if="codeSent"
                >A new code has been sent to your email address.
              </v-alert>
              Please enter your email address and request a reset code. You will
              only receive a code if you already have an account.
              <v-text-field
                data-cy="email_code"
                label="Your Email Address"
                name="email"
                prepend-icon="mdi-email"
                type="text"
                v-model="email"
                :rules="[rules.required]"
                autofocus
              />

              <v-text-field
                label="Your Reset Code"
                name="code"
                prepend-icon="mdi-numeric"
                type="text"
                v-model="code"
                :rules="[rules.required, rules.exact]"
                v-if="codeSent"
              />

              <v-text-field
                label="Your Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
                v-if="codeSent"
                :rules="[rules.required, rules.min]"
              />

              <v-text-field
                label="Confirm Your Password"
                name="passwordConfirmationField"
                prepend-icon="mdi-lock"
                type="password"
                hint="At least 8 characters"
                v-model="passwordConfirmation"
                v-if="codeSent"
                :rules="[rules.required, rules.min]"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                data-cy="request_code"
                color="hqsOrange"
                @click.prevent="requestCode()"
                :disabled="!valid"
                v-if="!codeSent"
                type="submit"
                >Request Code</v-btn
              >
              <v-btn
                color="hqsOrange"
                @click.prevent="resetPassword()"
                :disabled="!valid"
                v-if="codeSent"
                type="submit"
                >Reset Password</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '../../views/lib/auth'

export default {
  data() {
    return {
      email: '',
      code: '',
      password: '',
      passwordConfirmation: '',
      valid: false,
      errorRequest: false,
      errorReset: false,
      codeSent: false,
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        exact: (v) => v.length == 6 || 'Should be 6 characters',
      },
    }
  },
  methods: {
    async requestCode() {
      this.errorRequest = false

      try {
        await auth.forgotPassword(this.email)
        this.codeSent = true
      } catch (e) {
        this.errorRequest = true
      }
    },
    async resetPassword() {
      this.errorReset = false

      if (this.password !== this.passwordConfirmation) {
        this.errorReset = true
        return
      }

      try {
        await auth.forgotPasswordSubmit({
          username: this.email,
          code: this.code,
          password: this.password,
        })

        this.$router.push('/auth/login')
      } catch (e) {
        this.errorReset = true
      }
    },
  },
}
</script>
