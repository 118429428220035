<template>
  <v-container>
    <v-img
      data-cy="success"
      class="image"
      :src="require('../../assets/success-g13c12807a_1920.jpg')"
    >
    </v-img>
    <div>
      <v-sheet class="text center-text mt-15 text-h5"
        >Thank you for your purchase!
      </v-sheet>
    </div>
    <div>
      <span class="center">
        <router-link data-cy="continue_shopping" class="font-railway" :to="{ name: 'web-shop' }"
          >continue shopping </router-link
        >
        <pre>|</pre>
        <router-link data-cy="home" class="font-railway" :to="{ name: 'home' }"
          > home</router-link
        ></span
      >
    </div>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data() {
    return {}
  },

  methods: {
    ...mapMutations('payment', ['resetShoppingCart']),
  },

  created(){
    this.resetShoppingCart()
  }
}
</script>

<style lang="css" scoped>
.image {
  margin-top: 75px;
  margin-inline: 40px;
  width: 80%;
  margin-inline: auto;
  border-radius: 10px;
}

.center-text {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
}
</style>
