import { cloneDeep, uniq } from 'lodash'
import utils from '../../appUtils/utils.js'

export default {

    createPermissionsAsObjects(userPermissions) {
        let permissions = cloneDeep(userPermissions)
        let permissionObjects = []
        for (let permission of permissions) {
            if (permission !== '_read-transaction') {
                let start = permission.indexOf('-')
                let type = permission.slice(start + 1, permission.length)
                let typedPermission = {
                    type: type,
                    read: false,
                    write: false,
                    delete: false
                }
                let group = permissions.filter(e => e.includes(type))
                typedPermission.read = utils.hasSubstring(group, 'read')
                typedPermission.write = utils.hasSubstring(group, 'write')
                typedPermission.delete = utils.hasSubstring(group, 'delete')
                permissionObjects.push(typedPermission)
            }
        }
        return utils.filterArrayOfObjectsForDuplicates(permissionObjects)
    },

    createTablePermissions(permissions) {
        let items = cloneDeep(permissions)
        let tablePermissions = []
        let types = []
        for (let item of items) {
            let start = item.indexOf('-')
            types.push(item.slice(start + 1, item.length))
        }
        types = uniq(types)
        for (let type of types) {
            let permission = {}
            let filteredPermissions = permissions.filter(e => e.includes(type))
            permission.type = type
            permission.permissions = []
            for (let item of filteredPermissions) {
                item = item.slice(1, item.indexOf('-'))
                permission.permissions.push(item)
            }
            permission.permissions.sort()
            tablePermissions.push(permission)
        }
        return tablePermissions
    },
}