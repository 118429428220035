import axios from 'axios'
import auth from './auth'

const baseURL = 'https://api.' + process.env.VUE_APP_SUBDOMAIN + 'cloud.quantumsimulations.de/'

// todo: check if try catch can be used here for recognizing if token is expired
const restClient = async () => {
  let token = await auth.getToken()
  return axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

export default {
  get: async (path, config) => {
    let cli = await restClient()
    return cli.get(path, config)
  },
  post: async (path, data, config) => {
    let cli = await restClient()
    return cli.post(path, data, config)
  },
  put: async (path, data, config) => {
    let cli = await restClient()
    return cli.put(path, data, config)
  },
  delete: async (path, config) => {
    let cli = await restClient()
    return cli.delete(path, config)
  }
}
