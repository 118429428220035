<template>
  <div>
    <v-card class="elevation-12">
      <v-toolbar class="hqsOrange--text text" flat>
        <v-toolbar-title>Change Your Details</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-alert type="success" v-if="success"
          >Your details have been changed.</v-alert
        >
        <v-alert type="error" v-if="error"
          >An error has occured, please check your details.</v-alert
        >
        <v-form ref="form" v-model="valid">
          <v-text-field
            data-cy="email"
            label="Your Email"
            name="email"
            prepend-icon="mdi-account"
            type="text"
            v-model="email"
            :rules="[rules.required]"
          />

          <v-text-field
            data-cy="name"
            label="Your Name"
            name="name"
            prepend-icon="mdi-account"
            type="text"
            v-model="name"
            :rules="[rules.required]"
          />

          <v-text-field
            data-cy="company"
            label="Your Company/Institution"
            name="institution"
            prepend-icon="mdi-account"
            type="text"
            v-model="institution"
            :rules="[rules.required]"
          />

          <v-text-field
            data-cy="position"
            label="Your Position"
            name="position"
            prepend-icon="mdi-account"
            type="text"
            v-model="position"
            :rules="[rules.required]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn dark color="hqsOrange" :disabled="!valid" @click="change()"
          >Change Details</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title class="text">
          <span class="headline">Please Confirm Your Email Address</span>
        </v-card-title>

        <v-card-text>
          <v-alert type="error" v-if="errorDialog">
            An error has occured while confirming your email address. Please try
            again..
          </v-alert>
          <v-form ref="form" v-model="dialogValid">
            We have sent you a confirmation code to
            <strong>{{ this.email }}</strong>
            . Please enter the received code to confirm you email address.
            <v-text-field
              label="Your Confirmation Code"
              name="code"
              prepend-icon="mdi-numeric"
              type="text"
              v-model="code"
              :rules="[rules.required, rules.exact]"
              autofocus
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="hqsOrange"
            text
            :disabled="!dialogValid"
            @click="confirm()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import auth from '../../views/lib/auth'

export default {
  data() {
    return {
      email: '',
      name: '',
      institution: '',
      position: '',
      dialog: '',
      code: '',
      oldEmail: '',
      valid: false,
      dialogValid: false,
      success: false,
      error: false,
      errorDialog: false,
      rules: {
        required: (value) => !!value || 'Required.',
        exact: (v) => v.length == 6 || 'Should be 6 characters',
      },
    }
  },
  methods: {
    async change() {
      this.success = false
      this.error = false
      try {
        await auth.updateUserAttributes({
          email: this.email,
          name: this.name,
          institution: this.institution,
          position: this.position,
        })
        if (this.email !== this.oldEmail) {
          this.dialog = true
        }
        this.success = true
        this.$bus.$emit('user:update')
      } catch (e) {
        this.error = true
      }
    },
    async confirm() {
      this.errorDialog = false

      try {
        await auth.verifyEmail(this.code)

        this.dialog = false
        this.oldEmail = this.email
      } catch (e) {
        this.errorDialog = true
      }

      this.code = ''
    },
  },
  created() {
    auth.getUser().then((user) => {
      this.email = user.email
      this.oldEmail = user.email
      this.name = user.name
      this.institution = user.institution
      this.position = user.position
    })
  },
}
</script>
