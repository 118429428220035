import axios from 'axios'
import auth from './auth'

//const baseURL = 'https://rest-api.' + process.env.VUE_APP_SUBDOMAIN + '.cloud.quantumsimulations.de/'

const restClient = async () => {
    let token = await auth.getToken()
    return axios.create({
        //baseURL: baseURL,
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
}

export default {
    get: async (path, config) => {
        let cli = await restClient()
        return cli.get(path, config)
    },
    post: async (path, data, config) => {
        let cli = await restClient()
        return cli.post(path, data, config)
    },
    put: async (path, data, config) => {
        let cli = await restClient()
        return cli.put(path, data, config)
    },
    delete: async (path, config) => {
        let cli = await restClient()
        return cli.delete(path, config)
    },
    patch: async (path, data, config) => {
        let cli = await restClient()
        return cli.patch(path, data, config)
    },
}
