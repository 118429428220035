export default {
    namespaced: true,
    state: {
        email: ''
    },
    getters: {
        get(state) {
            return state
        }
    },
    mutations: {

        setEmail(state, email) {
            state.email = email
        }
    }
}
