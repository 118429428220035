export default{
    dataset: [
        {
            x: [
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ], 
                [
                    2, 
                    3
                ]
            ], 
            y: [
                [
                    800, 
                    800
                ], 
                [
                    799, 
                    799
                ], 
                [
                    798, 
                    798
                ], 
                [
                    797, 
                    797
                ], 
                [
                    796, 
                    796
                ], 
                [
                    795, 
                    795
                ], 
                [
                    794, 
                    794
                ], 
                [
                    793, 
                    793
                ], 
                [
                    792, 
                    792
                ], 
                [
                    791, 
                    791
                ], 
                [
                    790, 
                    790
                ], 
                [
                    789, 
                    789
                ], 
                [
                    788, 
                    788
                ], 
                [
                    787, 
                    787
                ], 
                [
                    786, 
                    786
                ], 
                [
                    785, 
                    785
                ], 
                [
                    784, 
                    784
                ], 
                [
                    783, 
                    783
                ], 
                [
                    782, 
                    782
                ], 
                [
                    781, 
                    781
                ], 
                [
                    780, 
                    780
                ], 
                [
                    779, 
                    779
                ], 
                [
                    778, 
                    778
                ], 
                [
                    777, 
                    777
                ], 
                [
                    776, 
                    776
                ], 
                [
                    775, 
                    775
                ], 
                [
                    774, 
                    774
                ], 
                [
                    773, 
                    773
                ], 
                [
                    772, 
                    772
                ], 
                [
                    771, 
                    771
                ], 
                [
                    770, 
                    770
                ], 
                [
                    769, 
                    769
                ], 
                [
                    768, 
                    768
                ], 
                [
                    767, 
                    767
                ], 
                [
                    766, 
                    766
                ], 
                [
                    765, 
                    765
                ], 
                [
                    764, 
                    764
                ], 
                [
                    763, 
                    763
                ], 
                [
                    762, 
                    762
                ], 
                [
                    761, 
                    761
                ], 
                [
                    760, 
                    760
                ], 
                [
                    759, 
                    759
                ], 
                [
                    758, 
                    758
                ], 
                [
                    757, 
                    757
                ], 
                [
                    756, 
                    756
                ], 
                [
                    755, 
                    755
                ], 
                [
                    754, 
                    754
                ], 
                [
                    753, 
                    753
                ], 
                [
                    752, 
                    752
                ], 
                [
                    751, 
                    751
                ], 
                [
                    750, 
                    750
                ], 
                [
                    749, 
                    749
                ], 
                [
                    748, 
                    748
                ], 
                [
                    747, 
                    747
                ], 
                [
                    746, 
                    746
                ], 
                [
                    745, 
                    745
                ], 
                [
                    744, 
                    744
                ], 
                [
                    743, 
                    743
                ], 
                [
                    742, 
                    742
                ], 
                [
                    741, 
                    741
                ], 
                [
                    740, 
                    740
                ], 
                [
                    739, 
                    739
                ], 
                [
                    738, 
                    738
                ], 
                [
                    737, 
                    737
                ], 
                [
                    736, 
                    736
                ], 
                [
                    735, 
                    735
                ], 
                [
                    734, 
                    734
                ], 
                [
                    733, 
                    733
                ], 
                [
                    732, 
                    732
                ], 
                [
                    731, 
                    731
                ], 
                [
                    730, 
                    730
                ], 
                [
                    729, 
                    729
                ], 
                [
                    728, 
                    728
                ], 
                [
                    727, 
                    727
                ], 
                [
                    726, 
                    726
                ], 
                [
                    725, 
                    725
                ], 
                [
                    724, 
                    724
                ], 
                [
                    723, 
                    723
                ], 
                [
                    722, 
                    722
                ], 
                [
                    721, 
                    721
                ], 
                [
                    720, 
                    720
                ], 
                [
                    719, 
                    719
                ], 
                [
                    718, 
                    718
                ], 
                [
                    717, 
                    717
                ], 
                [
                    716, 
                    716
                ], 
                [
                    715, 
                    715
                ], 
                [
                    714, 
                    714
                ], 
                [
                    713, 
                    713
                ], 
                [
                    712, 
                    712
                ], 
                [
                    711, 
                    711
                ], 
                [
                    710, 
                    710
                ], 
                [
                    709, 
                    709
                ], 
                [
                    708, 
                    708
                ], 
                [
                    707, 
                    707
                ], 
                [
                    706, 
                    706
                ], 
                [
                    705, 
                    705
                ], 
                [
                    704, 
                    704
                ], 
                [
                    703, 
                    703
                ], 
                [
                    702, 
                    702
                ], 
                [
                    701, 
                    701
                ], 
                [
                    700, 
                    700
                ], 
                [
                    699, 
                    699
                ], 
                [
                    698, 
                    698
                ], 
                [
                    697, 
                    697
                ], 
                [
                    696, 
                    696
                ], 
                [
                    695, 
                    695
                ], 
                [
                    694, 
                    694
                ], 
                [
                    693, 
                    693
                ], 
                [
                    692, 
                    692
                ], 
                [
                    691, 
                    691
                ], 
                [
                    690, 
                    690
                ], 
                [
                    689, 
                    689
                ], 
                [
                    688, 
                    688
                ], 
                [
                    687, 
                    687
                ], 
                [
                    686, 
                    686
                ], 
                [
                    685, 
                    685
                ], 
                [
                    684, 
                    684
                ], 
                [
                    683, 
                    683
                ], 
                [
                    682, 
                    682
                ], 
                [
                    681, 
                    681
                ], 
                [
                    680, 
                    680
                ], 
                [
                    679, 
                    679
                ], 
                [
                    678, 
                    678
                ], 
                [
                    677, 
                    677
                ], 
                [
                    676, 
                    676
                ], 
                [
                    675, 
                    675
                ], 
                [
                    674, 
                    674
                ], 
                [
                    673, 
                    673
                ], 
                [
                    672, 
                    672
                ], 
                [
                    671, 
                    671
                ], 
                [
                    670, 
                    670
                ], 
                [
                    669, 
                    669
                ], 
                [
                    668, 
                    668
                ], 
                [
                    667, 
                    667
                ], 
                [
                    666, 
                    666
                ], 
                [
                    665, 
                    665
                ], 
                [
                    664, 
                    664
                ], 
                [
                    663, 
                    663
                ], 
                [
                    662, 
                    662
                ], 
                [
                    661, 
                    661
                ], 
                [
                    660, 
                    660
                ], 
                [
                    659, 
                    659
                ], 
                [
                    658, 
                    658
                ], 
                [
                    657, 
                    657
                ], 
                [
                    656, 
                    656
                ], 
                [
                    655, 
                    655
                ], 
                [
                    654, 
                    654
                ], 
                [
                    653, 
                    653
                ], 
                [
                    652, 
                    652
                ], 
                [
                    651, 
                    651
                ], 
                [
                    650, 
                    650
                ], 
                [
                    649, 
                    649
                ], 
                [
                    648, 
                    648
                ], 
                [
                    647, 
                    647
                ], 
                [
                    646, 
                    646
                ], 
                [
                    645, 
                    645
                ], 
                [
                    644, 
                    644
                ], 
                [
                    643, 
                    643
                ], 
                [
                    642, 
                    642
                ], 
                [
                    641, 
                    641
                ], 
                [
                    640, 
                    640
                ], 
                [
                    639, 
                    639
                ], 
                [
                    638, 
                    638
                ], 
                [
                    637, 
                    637
                ], 
                [
                    636, 
                    636
                ], 
                [
                    635, 
                    635
                ], 
                [
                    634, 
                    634
                ], 
                [
                    633, 
                    633
                ], 
                [
                    632, 
                    632
                ], 
                [
                    631, 
                    631
                ], 
                [
                    630, 
                    630
                ], 
                [
                    629, 
                    629
                ], 
                [
                    628, 
                    628
                ], 
                [
                    627, 
                    627
                ], 
                [
                    626, 
                    626
                ], 
                [
                    625, 
                    625
                ], 
                [
                    624, 
                    624
                ], 
                [
                    623, 
                    623
                ], 
                [
                    622, 
                    622
                ], 
                [
                    621, 
                    621
                ], 
                [
                    620, 
                    620
                ], 
                [
                    619, 
                    619
                ], 
                [
                    618, 
                    618
                ], 
                [
                    617, 
                    617
                ], 
                [
                    616, 
                    616
                ], 
                [
                    615, 
                    615
                ], 
                [
                    614, 
                    614
                ], 
                [
                    613, 
                    613
                ], 
                [
                    612, 
                    612
                ], 
                [
                    611, 
                    611
                ], 
                [
                    610, 
                    610
                ], 
                [
                    609, 
                    609
                ], 
                [
                    608, 
                    608
                ], 
                [
                    607, 
                    607
                ], 
                [
                    606, 
                    606
                ], 
                [
                    605, 
                    605
                ], 
                [
                    604, 
                    604
                ], 
                [
                    603, 
                    603
                ], 
                [
                    602, 
                    602
                ], 
                [
                    601, 
                    601
                ], 
                [
                    600, 
                    600
                ], 
                [
                    599, 
                    599
                ], 
                [
                    598, 
                    598
                ], 
                [
                    597, 
                    597
                ], 
                [
                    596, 
                    596
                ], 
                [
                    595, 
                    595
                ], 
                [
                    594, 
                    594
                ], 
                [
                    593, 
                    593
                ], 
                [
                    592, 
                    592
                ], 
                [
                    591, 
                    591
                ], 
                [
                    590, 
                    590
                ], 
                [
                    589, 
                    589
                ], 
                [
                    588, 
                    588
                ], 
                [
                    587, 
                    587
                ], 
                [
                    586, 
                    586
                ], 
                [
                    585, 
                    585
                ], 
                [
                    584, 
                    584
                ], 
                [
                    583, 
                    583
                ], 
                [
                    582, 
                    582
                ], 
                [
                    581, 
                    581
                ], 
                [
                    580, 
                    580
                ], 
                [
                    579, 
                    579
                ], 
                [
                    578, 
                    578
                ], 
                [
                    577, 
                    577
                ], 
                [
                    576, 
                    576
                ], 
                [
                    575, 
                    575
                ], 
                [
                    574, 
                    574
                ], 
                [
                    573, 
                    573
                ], 
                [
                    572, 
                    572
                ], 
                [
                    571, 
                    571
                ], 
                [
                    570, 
                    570
                ], 
                [
                    569, 
                    569
                ], 
                [
                    568, 
                    568
                ], 
                [
                    567, 
                    567
                ], 
                [
                    566, 
                    566
                ], 
                [
                    565, 
                    565
                ], 
                [
                    564, 
                    564
                ], 
                [
                    563, 
                    563
                ], 
                [
                    562, 
                    562
                ], 
                [
                    561, 
                    561
                ], 
                [
                    560, 
                    560
                ], 
                [
                    559, 
                    559
                ], 
                [
                    558, 
                    558
                ], 
                [
                    557, 
                    557
                ], 
                [
                    556, 
                    556
                ], 
                [
                    555, 
                    555
                ], 
                [
                    554, 
                    554
                ], 
                [
                    553, 
                    553
                ], 
                [
                    552, 
                    552
                ], 
                [
                    551, 
                    551
                ], 
                [
                    550, 
                    550
                ], 
                [
                    549, 
                    549
                ], 
                [
                    548, 
                    548
                ], 
                [
                    547, 
                    547
                ], 
                [
                    546, 
                    546
                ], 
                [
                    545, 
                    545
                ], 
                [
                    544, 
                    544
                ], 
                [
                    543, 
                    543
                ], 
                [
                    542, 
                    542
                ], 
                [
                    541, 
                    541
                ], 
                [
                    540, 
                    540
                ], 
                [
                    539, 
                    539
                ], 
                [
                    538, 
                    538
                ], 
                [
                    537, 
                    537
                ], 
                [
                    536, 
                    536
                ], 
                [
                    535, 
                    535
                ], 
                [
                    534, 
                    534
                ], 
                [
                    533, 
                    533
                ], 
                [
                    532, 
                    532
                ], 
                [
                    531, 
                    531
                ], 
                [
                    530, 
                    530
                ], 
                [
                    529, 
                    529
                ], 
                [
                    528, 
                    528
                ], 
                [
                    527, 
                    527
                ], 
                [
                    526, 
                    526
                ], 
                [
                    525, 
                    525
                ], 
                [
                    524, 
                    524
                ], 
                [
                    523, 
                    523
                ], 
                [
                    522, 
                    522
                ], 
                [
                    521, 
                    521
                ], 
                [
                    520, 
                    520
                ], 
                [
                    519, 
                    519
                ], 
                [
                    518, 
                    518
                ], 
                [
                    517, 
                    517
                ], 
                [
                    516, 
                    516
                ], 
                [
                    515, 
                    515
                ], 
                [
                    514, 
                    514
                ], 
                [
                    513, 
                    513
                ], 
                [
                    512, 
                    512
                ], 
                [
                    511, 
                    511
                ], 
                [
                    510, 
                    510
                ], 
                [
                    509, 
                    509
                ], 
                [
                    508, 
                    508
                ], 
                [
                    507, 
                    507
                ], 
                [
                    506, 
                    506
                ], 
                [
                    505, 
                    505
                ], 
                [
                    504, 
                    504
                ], 
                [
                    503, 
                    503
                ], 
                [
                    502, 
                    502
                ], 
                [
                    501, 
                    501
                ], 
                [
                    500, 
                    500
                ], 
                [
                    499, 
                    499
                ], 
                [
                    498, 
                    498
                ], 
                [
                    497, 
                    497
                ], 
                [
                    496, 
                    496
                ], 
                [
                    495, 
                    495
                ], 
                [
                    494, 
                    494
                ], 
                [
                    493, 
                    493
                ], 
                [
                    492, 
                    492
                ], 
                [
                    491, 
                    491
                ], 
                [
                    490, 
                    490
                ], 
                [
                    489, 
                    489
                ], 
                [
                    488, 
                    488
                ], 
                [
                    487, 
                    487
                ], 
                [
                    486, 
                    486
                ], 
                [
                    485, 
                    485
                ], 
                [
                    484, 
                    484
                ], 
                [
                    483, 
                    483
                ], 
                [
                    482, 
                    482
                ], 
                [
                    481, 
                    481
                ], 
                [
                    480, 
                    480
                ], 
                [
                    479, 
                    479
                ], 
                [
                    478, 
                    478
                ], 
                [
                    477, 
                    477
                ], 
                [
                    476, 
                    476
                ], 
                [
                    475, 
                    475
                ], 
                [
                    474, 
                    474
                ], 
                [
                    473, 
                    473
                ], 
                [
                    472, 
                    472
                ], 
                [
                    471, 
                    471
                ], 
                [
                    470, 
                    470
                ], 
                [
                    469, 
                    469
                ], 
                [
                    468, 
                    468
                ], 
                [
                    467, 
                    467
                ], 
                [
                    466, 
                    466
                ], 
                [
                    465, 
                    465
                ], 
                [
                    464, 
                    464
                ], 
                [
                    463, 
                    463
                ], 
                [
                    462, 
                    462
                ], 
                [
                    461, 
                    461
                ], 
                [
                    460, 
                    460
                ], 
                [
                    459, 
                    459
                ], 
                [
                    458, 
                    458
                ], 
                [
                    457, 
                    457
                ], 
                [
                    456, 
                    456
                ], 
                [
                    455, 
                    455
                ], 
                [
                    454, 
                    454
                ], 
                [
                    453, 
                    453
                ], 
                [
                    452, 
                    452
                ], 
                [
                    451, 
                    451
                ], 
                [
                    450, 
                    450
                ], 
                [
                    449, 
                    449
                ], 
                [
                    448, 
                    448
                ], 
                [
                    447, 
                    447
                ], 
                [
                    446, 
                    446
                ], 
                [
                    445, 
                    445
                ], 
                [
                    444, 
                    444
                ], 
                [
                    443, 
                    443
                ], 
                [
                    442, 
                    442
                ], 
                [
                    441, 
                    441
                ], 
                [
                    440, 
                    440
                ], 
                [
                    439, 
                    439
                ], 
                [
                    438, 
                    438
                ], 
                [
                    437, 
                    437
                ], 
                [
                    436, 
                    436
                ], 
                [
                    435, 
                    435
                ], 
                [
                    434, 
                    434
                ], 
                [
                    433, 
                    433
                ], 
                [
                    432, 
                    432
                ], 
                [
                    431, 
                    431
                ], 
                [
                    430, 
                    430
                ], 
                [
                    429, 
                    429
                ], 
                [
                    428, 
                    428
                ], 
                [
                    427, 
                    427
                ], 
                [
                    426, 
                    426
                ], 
                [
                    425, 
                    425
                ], 
                [
                    424, 
                    424
                ], 
                [
                    423, 
                    423
                ], 
                [
                    422, 
                    422
                ], 
                [
                    421, 
                    421
                ], 
                [
                    420, 
                    420
                ], 
                [
                    419, 
                    419
                ], 
                [
                    418, 
                    418
                ], 
                [
                    417, 
                    417
                ], 
                [
                    416, 
                    416
                ], 
                [
                    415, 
                    415
                ], 
                [
                    414, 
                    414
                ], 
                [
                    413, 
                    413
                ], 
                [
                    412, 
                    412
                ], 
                [
                    411, 
                    411
                ], 
                [
                    410, 
                    410
                ], 
                [
                    409, 
                    409
                ], 
                [
                    408, 
                    408
                ], 
                [
                    407, 
                    407
                ], 
                [
                    406, 
                    406
                ], 
                [
                    405, 
                    405
                ], 
                [
                    404, 
                    404
                ], 
                [
                    403, 
                    403
                ], 
                [
                    402, 
                    402
                ], 
                [
                    401, 
                    401
                ], 
                [
                    400, 
                    400
                ]
            ], 
            z: [
                [
                    0.891326202, 
                    0.891326202
                ], 
                [
                    0.888733027, 
                    0.888733027
                ], 
                [
                    0.896350752, 
                    0.896350752
                ], 
                [
                    0.916247792, 
                    0.916247792
                ], 
                [
                    0.900480784, 
                    0.900480784
                ], 
                [
                    0.903346541, 
                    0.903346541
                ], 
                [
                    0.906652511, 
                    0.906652511
                ], 
                [
                    0.907801632, 
                    0.907801632
                ], 
                [
                    0.907902211, 
                    0.907902211
                ], 
                [
                    0.90792116, 
                    0.90792116
                ], 
                [
                    0.90648731, 
                    0.90648731
                ], 
                [
                    0.907132081, 
                    0.907132081
                ], 
                [
                    0.90998812, 
                    0.90998812
                ], 
                [
                    0.913874724, 
                    0.913874724
                ], 
                [
                    0.914356723, 
                    0.914356723
                ], 
                [
                    0.915044252, 
                    0.915044252
                ], 
                [
                    0.914528241, 
                    0.914528241
                ], 
                [
                    0.917514012, 
                    0.917514012
                ], 
                [
                    0.920208737, 
                    0.920208737
                ], 
                [
                    0.92396318, 
                    0.92396318
                ], 
                [
                    0.926304665, 
                    0.926304665
                ], 
                [
                    0.927970769, 
                    0.927970769
                ], 
                [
                    0.929858437, 
                    0.929858437
                ], 
                [
                    0.932269404, 
                    0.932269404
                ], 
                [
                    0.935102121, 
                    0.935102121
                ], 
                [
                    0.9423496, 
                    0.9423496
                ], 
                [
                    0.941887036, 
                    0.941887036
                ], 
                [
                    0.945884908, 
                    0.945884908
                ], 
                [
                    0.949546547, 
                    0.949546547
                ], 
                [
                    0.954419985, 
                    0.954419985
                ], 
                [
                    0.956261494, 
                    0.956261494
                ], 
                [
                    0.95760254, 
                    0.95760254
                ], 
                [
                    0.96268734, 
                    0.96268734
                ], 
                [
                    0.96486168, 
                    0.96486168
                ], 
                [
                    0.965474383, 
                    0.965474383
                ], 
                [
                    0.970029566, 
                    0.970029566
                ], 
                [
                    0.970871121, 
                    0.970871121
                ], 
                [
                    0.970170473, 
                    0.970170473
                ], 
                [
                    0.970992107, 
                    0.970992107
                ], 
                [
                    0.975447683, 
                    0.975447683
                ], 
                [
                    0.978198285, 
                    0.978198285
                ], 
                [
                    0.977591899, 
                    0.977591899
                ], 
                [
                    0.979052959, 
                    0.979052959
                ], 
                [
                    0.981108258, 
                    0.981108258
                ], 
                [
                    0.983116426, 
                    0.983116426
                ], 
                [
                    0.987696389, 
                    0.987696389
                ], 
                [
                    0.992085885, 
                    0.992085885
                ], 
                [
                    0.99436955, 
                    0.99436955
                ], 
                [
                    0.994006108, 
                    0.994006108
                ], 
                [
                    0.997205182, 
                    0.997205182
                ], 
                [
                    1, 
                    1
                ], 
                [
                    1.002954188, 
                    1.002954188
                ], 
                [
                    1.005568742, 
                    1.005568742
                ], 
                [
                    1.006355392, 
                    1.006355392
                ], 
                [
                    1.008086604, 
                    1.008086604
                ], 
                [
                    1.00929063, 
                    1.00929063
                ], 
                [
                    1.011744842, 
                    1.011744842
                ], 
                [
                    1.013603357, 
                    1.013603357
                ], 
                [
                    1.015250025, 
                    1.015250025
                ], 
                [
                    1.019026333, 
                    1.019026333
                ], 
                [
                    1.020356689, 
                    1.020356689
                ], 
                [
                    1.023789475, 
                    1.023789475
                ], 
                [
                    1.025430799, 
                    1.025430799
                ], 
                [
                    1.027631378, 
                    1.027631378
                ], 
                [
                    1.029846047, 
                    1.029846047
                ], 
                [
                    1.030845029, 
                    1.030845029
                ], 
                [
                    1.03473552, 
                    1.03473552
                ], 
                [
                    1.03841708, 
                    1.03841708
                ], 
                [
                    1.039862591, 
                    1.039862591
                ], 
                [
                    1.040651671, 
                    1.040651671
                ], 
                [
                    1.040450028, 
                    1.040450028
                ], 
                [
                    1.046480362, 
                    1.046480362
                ], 
                [
                    1.048040056, 
                    1.048040056
                ], 
                [
                    1.050019557, 
                    1.050019557
                ], 
                [
                    1.052573861, 
                    1.052573861
                ], 
                [
                    1.053171987, 
                    1.053171987
                ], 
                [
                    1.055543597, 
                    1.055543597
                ], 
                [
                    1.055666041, 
                    1.055666041
                ], 
                [
                    1.061234297, 
                    1.061234297
                ], 
                [
                    1.065562571, 
                    1.065562571
                ], 
                [
                    1.066367199, 
                    1.066367199
                ], 
                [
                    1.070142049, 
                    1.070142049
                ], 
                [
                    1.074449916, 
                    1.074449916
                ], 
                [
                    1.080189204, 
                    1.080189204
                ], 
                [
                    1.085065072, 
                    1.085065072
                ], 
                [
                    1.08726808, 
                    1.08726808
                ], 
                [
                    1.092957808, 
                    1.092957808
                ], 
                [
                    1.094502926, 
                    1.094502926
                ], 
                [
                    1.096392538, 
                    1.096392538
                ], 
                [
                    1.100003158, 
                    1.100003158
                ], 
                [
                    1.104833839, 
                    1.104833839
                ], 
                [
                    1.108571276, 
                    1.108571276
                ], 
                [
                    1.11251813, 
                    1.11251813
                ], 
                [
                    1.118616973, 
                    1.118616973
                ], 
                [
                    1.124853323, 
                    1.124853323
                ], 
                [
                    1.127624818, 
                    1.127624818
                ], 
                [
                    1.133717345, 
                    1.133717345
                ], 
                [
                    1.140322969, 
                    1.140322969
                ], 
                [
                    1.145517092, 
                    1.145517092
                ], 
                [
                    1.150495968, 
                    1.150495968
                ], 
                [
                    1.157351823, 
                    1.157351823
                ], 
                [
                    1.160311356, 
                    1.160311356
                ], 
                [
                    1.163008996, 
                    1.163008996
                ], 
                [
                    1.168280376, 
                    1.168280376
                ], 
                [
                    1.176922834, 
                    1.176922834
                ], 
                [
                    1.184745116, 
                    1.184745116
                ], 
                [
                    1.19210775, 
                    1.19210775
                ], 
                [
                    1.197939357, 
                    1.197939357
                ], 
                [
                    1.202257913, 
                    1.202257913
                ], 
                [
                    1.204712125, 
                    1.204712125
                ], 
                [
                    1.20987175, 
                    1.20987175
                ], 
                [
                    1.214072237, 
                    1.214072237
                ], 
                [
                    1.214900187, 
                    1.214900187
                ], 
                [
                    1.214264162, 
                    1.214264162
                ], 
                [
                    1.210780358, 
                    1.210780358
                ], 
                [
                    1.20624461, 
                    1.20624461
                ], 
                [
                    1.203964346, 
                    1.203964346
                ], 
                [
                    1.198601134, 
                    1.198601134
                ], 
                [
                    1.194751457, 
                    1.194751457
                ], 
                [
                    1.18684706, 
                    1.18684706
                ], 
                [
                    1.180615083, 
                    1.180615083
                ], 
                [
                    1.176154648, 
                    1.176154648
                ], 
                [
                    1.168729335, 
                    1.168729335
                ], 
                [
                    1.165194027, 
                    1.165194027
                ], 
                [
                    1.160056266, 
                    1.160056266
                ], 
                [
                    1.155265913, 
                    1.155265913
                ], 
                [
                    1.151437616, 
                    1.151437616
                ], 
                [
                    1.146708971, 
                    1.146708971
                ], 
                [
                    1.143748466, 
                    1.143748466
                ], 
                [
                    1.141012441, 
                    1.141012441
                ], 
                [
                    1.13942991, 
                    1.13942991
                ], 
                [
                    1.137251682, 
                    1.137251682
                ], 
                [
                    1.133502584, 
                    1.133502584
                ], 
                [
                    1.13415027, 
                    1.13415027
                ], 
                [
                    1.134286318, 
                    1.134286318
                ], 
                [
                    1.132811168, 
                    1.132811168
                ], 
                [
                    1.132622644, 
                    1.132622644
                ], 
                [
                    1.136078267, 
                    1.136078267
                ], 
                [
                    1.138604875, 
                    1.138604875
                ], 
                [
                    1.137214269, 
                    1.137214269
                ], 
                [
                    1.142888934, 
                    1.142888934
                ], 
                [
                    1.149857514, 
                    1.149857514
                ], 
                [
                    1.15209259, 
                    1.15209259
                ], 
                [
                    1.157077783, 
                    1.157077783
                ], 
                [
                    1.164599788, 
                    1.164599788
                ], 
                [
                    1.170061149, 
                    1.170061149
                ], 
                [
                    1.175569641, 
                    1.175569641
                ], 
                [
                    1.178670567, 
                    1.178670567
                ], 
                [
                    1.183631951, 
                    1.183631951
                ], 
                [
                    1.187432553, 
                    1.187432553
                ], 
                [
                    1.192167028, 
                    1.192167028
                ], 
                [
                    1.19771925, 
                    1.19771925
                ], 
                [
                    1.199329963, 
                    1.199329963
                ], 
                [
                    1.202978483, 
                    1.202978483
                ], 
                [
                    1.208468511, 
                    1.208468511
                ], 
                [
                    1.210865388, 
                    1.210865388
                ], 
                [
                    1.216061941, 
                    1.216061941
                ], 
                [
                    1.221179294, 
                    1.221179294
                ], 
                [
                    1.224923534, 
                    1.224923534
                ], 
                [
                    1.22563147, 
                    1.22563147
                ], 
                [
                    1.227159096, 
                    1.227159096
                ], 
                [
                    1.229282905, 
                    1.229282905
                ], 
                [
                    1.230288203, 
                    1.230288203
                ], 
                [
                    1.230953382, 
                    1.230953382
                ], 
                [
                    1.230440286, 
                    1.230440286
                ], 
                [
                    1.230511711, 
                    1.230511711
                ], 
                [
                    1.230569046, 
                    1.230569046
                ], 
                [
                    1.226759698, 
                    1.226759698
                ], 
                [
                    1.224733552, 
                    1.224733552
                ], 
                [
                    1.223330313, 
                    1.223330313
                ], 
                [
                    1.220274088, 
                    1.220274088
                ], 
                [
                    1.216803889, 
                    1.216803889
                ], 
                [
                    1.214387577, 
                    1.214387577
                ], 
                [
                    1.213825892, 
                    1.213825892
                ], 
                [
                    1.210624388, 
                    1.210624388
                ], 
                [
                    1.205724711, 
                    1.205724711
                ], 
                [
                    1.204426423, 
                    1.204426423
                ], 
                [
                    1.202051412, 
                    1.202051412
                ], 
                [
                    1.198832901, 
                    1.198832901
                ], 
                [
                    1.195728088, 
                    1.195728088
                ], 
                [
                    1.194814136, 
                    1.194814136
                ], 
                [
                    1.190840559, 
                    1.190840559
                ], 
                [
                    1.186127948, 
                    1.186127948
                ], 
                [
                    1.182094121, 
                    1.182094121
                ], 
                [
                    1.180273991, 
                    1.180273991
                ], 
                [
                    1.177857679, 
                    1.177857679
                ], 
                [
                    1.174938475, 
                    1.174938475
                ], 
                [
                    1.172105272, 
                    1.172105272
                ], 
                [
                    1.170485327, 
                    1.170485327
                ], 
                [
                    1.168087479, 
                    1.168087479
                ], 
                [
                    1.167892639, 
                    1.167892639
                ], 
                [
                    1.167132713, 
                    1.167132713
                ], 
                [
                    1.166274637, 
                    1.166274637
                ], 
                [
                    1.166959737, 
                    1.166959737
                ], 
                [
                    1.166912606, 
                    1.166912606
                ], 
                [
                    1.166003999, 
                    1.166003999
                ], 
                [
                    1.168564619, 
                    1.168564619
                ], 
                [
                    1.171227762, 
                    1.171227762
                ], 
                [
                    1.170858974, 
                    1.170858974
                ], 
                [
                    1.171600436, 
                    1.171600436
                ], 
                [
                    1.174965684, 
                    1.174965684
                ], 
                [
                    1.17573387, 
                    1.17573387
                ], 
                [
                    1.175687711, 
                    1.175687711
                ], 
                [
                    1.176977739, 
                    1.176977739
                ], 
                [
                    1.17845289, 
                    1.17845289
                ], 
                [
                    1.176727022, 
                    1.176727022
                ], 
                [
                    1.176929151, 
                    1.176929151
                ], 
                [
                    1.178996596, 
                    1.178996596
                ], 
                [
                    1.179454301, 
                    1.179454301
                ], 
                [
                    1.179423204, 
                    1.179423204
                ], 
                [
                    1.180734611, 
                    1.180734611
                ], 
                [
                    1.182385167, 
                    1.182385167
                ], 
                [
                    1.185220799, 
                    1.185220799
                ], 
                [
                    1.186808189, 
                    1.186808189
                ], 
                [
                    1.190061197, 
                    1.190061197
                ], 
                [
                    1.191939633, 
                    1.191939633
                ], 
                [
                    1.193008583, 
                    1.193008583
                ], 
                [
                    1.193665501, 
                    1.193665501
                ], 
                [
                    1.193811753, 
                    1.193811753
                ], 
                [
                    1.196227579, 
                    1.196227579
                ], 
                [
                    1.198543313, 
                    1.198543313
                ], 
                [
                    1.197380587, 
                    1.197380587
                ], 
                [
                    1.198150231, 
                    1.198150231
                ], 
                [
                    1.198330495, 
                    1.198330495
                ], 
                [
                    1.19874447, 
                    1.19874447
                ], 
                [
                    1.200815317, 
                    1.200815317
                ], 
                [
                    1.204214577, 
                    1.204214577
                ], 
                [
                    1.205422976, 
                    1.205422976
                ], 
                [
                    1.205999723, 
                    1.205999723
                ], 
                [
                    1.207841232, 
                    1.207841232
                ], 
                [
                    1.21096791, 
                    1.21096791
                ], 
                [
                    1.217531261, 
                    1.217531261
                ], 
                [
                    1.221427096, 
                    1.221427096
                ], 
                [
                    1.224336097, 
                    1.224336097
                ], 
                [
                    1.226368559, 
                    1.226368559
                ], 
                [
                    1.227603196, 
                    1.227603196
                ], 
                [
                    1.230395584, 
                    1.230395584
                ], 
                [
                    1.235235011, 
                    1.235235011
                ], 
                [
                    1.237606622, 
                    1.237606622
                ], 
                [
                    1.241404794, 
                    1.241404794
                ], 
                [
                    1.242821638, 
                    1.242821638
                ], 
                [
                    1.24613927, 
                    1.24613927
                ], 
                [
                    1.250000121, 
                    1.250000121
                ], 
                [
                    1.254688438, 
                    1.254688438
                ], 
                [
                    1.258099359, 
                    1.258099359
                ], 
                [
                    1.262152136, 
                    1.262152136
                ], 
                [
                    1.264957643, 
                    1.264957643
                ], 
                [
                    1.269931174, 
                    1.269931174
                ], 
                [
                    1.274920254, 
                    1.274920254
                ], 
                [
                    1.278033813, 
                    1.278033813
                ], 
                [
                    1.281363105, 
                    1.281363105
                ], 
                [
                    1.287720926, 
                    1.287720926
                ], 
                [
                    1.291040501, 
                    1.291040501
                ], 
                [
                    1.294655009, 
                    1.294655009
                ], 
                [
                    1.30010568, 
                    1.30010568
                ], 
                [
                    1.303570535, 
                    1.303570535
                ], 
                [
                    1.306707902, 
                    1.306707902
                ], 
                [
                    1.30888856, 
                    1.30888856
                ], 
                [
                    1.311648879, 
                    1.311648879
                ], 
                [
                    1.316696751, 
                    1.316696751
                ], 
                [
                    1.318312809, 
                    1.318312809
                ], 
                [
                    1.322347122, 
                    1.322347122
                ], 
                [
                    1.328086896, 
                    1.328086896
                ], 
                [
                    1.330989094, 
                    1.330989094
                ], 
                [
                    1.336315379, 
                    1.336315379
                ], 
                [
                    1.339991594, 
                    1.339991594
                ], 
                [
                    1.344417046, 
                    1.344417046
                ], 
                [
                    1.349031021, 
                    1.349031021
                ], 
                [
                    1.349816699, 
                    1.349816699
                ], 
                [
                    1.351448305, 
                    1.351448305
                ], 
                [
                    1.353927783, 
                    1.353927783
                ], 
                [
                    1.357514595, 
                    1.357514595
                ], 
                [
                    1.362884123, 
                    1.362884123
                ], 
                [
                    1.364553628, 
                    1.364553628
                ], 
                [
                    1.36862001, 
                    1.36862001
                ], 
                [
                    1.371192778, 
                    1.371192778
                ], 
                [
                    1.375673135, 
                    1.375673135
                ], 
                [
                    1.381152474, 
                    1.381152474
                ], 
                [
                    1.3878475, 
                    1.3878475
                ], 
                [
                    1.393135885, 
                    1.393135885
                ], 
                [
                    1.395908838, 
                    1.395908838
                ], 
                [
                    1.39936106, 
                    1.39936106
                ], 
                [
                    1.40249211, 
                    1.40249211
                ], 
                [
                    1.404444401, 
                    1.404444401
                ], 
                [
                    1.40690833, 
                    1.40690833
                ], 
                [
                    1.410636535, 
                    1.410636535
                ], 
                [
                    1.41238524, 
                    1.41238524
                ], 
                [
                    1.412627697, 
                    1.412627697
                ], 
                [
                    1.412264254, 
                    1.412264254
                ], 
                [
                    1.413175777, 
                    1.413175777
                ], 
                [
                    1.413576147, 
                    1.413576147
                ], 
                [
                    1.414807868, 
                    1.414807868
                ], 
                [
                    1.418254745, 
                    1.418254745
                ], 
                [
                    1.423498915, 
                    1.423498915
                ], 
                [
                    1.425786468, 
                    1.425786468
                ], 
                [
                    1.430461179, 
                    1.430461179
                ], 
                [
                    1.434381309, 
                    1.434381309
                ], 
                [
                    1.438312128, 
                    1.438312128
                ], 
                [
                    1.44122453, 
                    1.44122453
                ], 
                [
                    1.441736169, 
                    1.441736169
                ], 
                [
                    1.446164536, 
                    1.446164536
                ], 
                [
                    1.448616318, 
                    1.448616318
                ], 
                [
                    1.448042486, 
                    1.448042486
                ], 
                [
                    1.450035591, 
                    1.450035591
                ], 
                [
                    1.451714814, 
                    1.451714814
                ], 
                [
                    1.451970876, 
                    1.451970876
                ], 
                [
                    1.454501371, 
                    1.454501371
                ], 
                [
                    1.457605698, 
                    1.457605698
                ], 
                [
                    1.461359655, 
                    1.461359655
                ], 
                [
                    1.463336241, 
                    1.463336241
                ], 
                [
                    1.465683557, 
                    1.465683557
                ], 
                [
                    1.467956533, 
                    1.467956533
                ], 
                [
                    1.468952113, 
                    1.468952113
                ], 
                [
                    1.470577403, 
                    1.470577403
                ], 
                [
                    1.473606903, 
                    1.473606903
                ], 
                [
                    1.475710791, 
                    1.475710791
                ], 
                [
                    1.477895335, 
                    1.477895335
                ], 
                [
                    1.480644965, 
                    1.480644965
                ], 
                [
                    1.484803666, 
                    1.484803666
                ], 
                [
                    1.485386243, 
                    1.485386243
                ], 
                [
                    1.487724328, 
                    1.487724328
                ], 
                [
                    1.490915142, 
                    1.490915142
                ], 
                [
                    1.492831964, 
                    1.492831964
                ], 
                [
                    1.49607574, 
                    1.49607574
                ], 
                [
                    1.499912298, 
                    1.499912298
                ], 
                [
                    1.504499063, 
                    1.504499063
                ], 
                [
                    1.50604904, 
                    1.50604904
                ], 
                [
                    1.507396889, 
                    1.507396889
                ], 
                [
                    1.510530369, 
                    1.510530369
                ], 
                [
                    1.513348995, 
                    1.513348995
                ], 
                [
                    1.513332961, 
                    1.513332961
                ], 
                [
                    1.517996983, 
                    1.517996983
                ], 
                [
                    1.519975997, 
                    1.519975997
                ], 
                [
                    1.521403045, 
                    1.521403045
                ], 
                [
                    1.522414174, 
                    1.522414174
                ], 
                [
                    1.525944624, 
                    1.525944624
                ], 
                [
                    1.529541153, 
                    1.529541153
                ], 
                [
                    1.533000663, 
                    1.533000663
                ], 
                [
                    1.53677114, 
                    1.53677114
                ], 
                [
                    1.540406541, 
                    1.540406541
                ], 
                [
                    1.543072599, 
                    1.543072599
                ], 
                [
                    1.546584098, 
                    1.546584098
                ], 
                [
                    1.550240392, 
                    1.550240392
                ], 
                [
                    1.552063437, 
                    1.552063437
                ], 
                [
                    1.552802956, 
                    1.552802956
                ], 
                [
                    1.555245992, 
                    1.555245992
                ], 
                [
                    1.55804081, 
                    1.55804081
                ], 
                [
                    1.560377922, 
                    1.560377922
                ], 
                [
                    1.56193373, 
                    1.56193373
                ], 
                [
                    1.561493031, 
                    1.561493031
                ], 
                [
                    1.562391435, 
                    1.562391435
                ], 
                [
                    1.561307909, 
                    1.561307909
                ], 
                [
                    1.560510083, 
                    1.560510083
                ], 
                [
                    1.562267534, 
                    1.562267534
                ], 
                [
                    1.560023225, 
                    1.560023225
                ], 
                [
                    1.556931045, 
                    1.556931045
                ], 
                [
                    1.551861795, 
                    1.551861795
                ], 
                [
                    1.550052354, 
                    1.550052354
                ], 
                [
                    1.546457282, 
                    1.546457282
                ], 
                [
                    1.540818573, 
                    1.540818573
                ], 
                [
                    1.537041779, 
                    1.537041779
                ], 
                [
                    1.533139627, 
                    1.533139627
                ], 
                [
                    1.529845804, 
                    1.529845804
                ], 
                [
                    1.526246845, 
                    1.526246845
                ], 
                [
                    1.525412092, 
                    1.525412092
                ], 
                [
                    1.525161375, 
                    1.525161375
                ], 
                [
                    1.523441338, 
                    1.523441338
                ], 
                [
                    1.523203253, 
                    1.523203253
                ], 
                [
                    1.522831065, 
                    1.522831065
                ], 
                [
                    1.523360681, 
                    1.523360681
                ], 
                [
                    1.524095826, 
                    1.524095826
                ], 
                [
                    1.523177502, 
                    1.523177502
                ], 
                [
                    1.524674517, 
                    1.524674517
                ], 
                [
                    1.524486965, 
                    1.524486965
                ], 
                [
                    1.525861051, 
                    1.525861051
                ], 
                [
                    1.526740991, 
                    1.526740991
                ], 
                [
                    1.525903323, 
                    1.525903323
                ], 
                [
                    1.528388145, 
                    1.528388145
                ], 
                [
                    1.52871952, 
                    1.52871952
                ], 
                [
                    1.530361329, 
                    1.530361329
                ], 
                [
                    1.530094092, 
                    1.530094092
                ], 
                [
                    1.527375558, 
                    1.527375558
                ], 
                [
                    1.526855174, 
                    1.526855174
                ], 
                [
                    1.524982083, 
                    1.524982083
                ], 
                [
                    1.52513951, 
                    1.52513951
                ], 
                [
                    1.525833841, 
                    1.525833841
                ], 
                [
                    1.526047631, 
                    1.526047631
                ], 
                [
                    1.52834976, 
                    1.52834976
                ], 
                [
                    1.52941871, 
                    1.52941871
                ], 
                [
                    1.532760635, 
                    1.532760635
                ], 
                [
                    1.537068017, 
                    1.537068017
                ], 
                [
                    1.538793399, 
                    1.538793399
                ], 
                [
                    1.542721303, 
                    1.542721303
                ], 
                [
                    1.544430165, 
                    1.544430165
                ], 
                [
                    1.547997056, 
                    1.547997056
                ], 
                [
                    1.551526047, 
                    1.551526047
                ], 
                [
                    1.554899069, 
                    1.554899069
                ], 
                [
                    1.556161219, 
                    1.556161219
                ], 
                [
                    1.557196756, 
                    1.557196756
                ], 
                [
                    1.559046837, 
                    1.559046837
                ], 
                [
                    1.561837233, 
                    1.561837233
                ]
            ],
            cmax: 1.562391435, 
            cmin: 0.888733027, 
            colorscale: [
                [
                    "0", 
                    "rgb(31,31,255)"
                ], 
                [
                    "0.1", 
                    "rgb(31,31,255)"
                ], 
                [
                    "0.2", 
                    "rgb(31,31,255)"
                ], 
                [
                    "0.3", 
                    "rgb(31,31,255)"
                ], 
                [
                    "0.4", 
                    "rgb(31,31,255)"
                ], 
                [
                    "0.5", 
                    "rgb(31,31,255)"
                ], 
                [
                    "0.6", 
                    "rgb(31,31,255)"
                ], 
                [
                    "0.7", 
                    "rgb(31,31,255)"
                ], 
                [
                    "0.8", 
                    "rgb(31,31,255)"
                ], 
                [
                    "0.9", 
                    "rgb(31,31,255)"
                ], 
                [
                    "1", 
                    "rgb(31,31,255)"
                ]
            ], 
            name: "", 
            showscale: false, 
            type: "surface", 
            uid: "fef431", 
            xsrc: "Diksha_Gabha:2343:325c8f,8486f3", 
            ysrc: "Diksha_Gabha:2343:807139,59d0b7", 
            zsrc: "Diksha_Gabha:2343:237e77,1269f0"
        }, 
        {
            x: [
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ], 
                [
                    4, 
                    5
                ]
            ], 
            y: [
                [
                    800, 
                    800
                ], 
                [
                    799, 
                    799
                ], 
                [
                    798, 
                    798
                ], 
                [
                    797, 
                    797
                ], 
                [
                    796, 
                    796
                ], 
                [
                    795, 
                    795
                ], 
                [
                    794, 
                    794
                ], 
                [
                    793, 
                    793
                ], 
                [
                    792, 
                    792
                ], 
                [
                    791, 
                    791
                ], 
                [
                    790, 
                    790
                ], 
                [
                    789, 
                    789
                ], 
                [
                    788, 
                    788
                ], 
                [
                    787, 
                    787
                ], 
                [
                    786, 
                    786
                ], 
                [
                    785, 
                    785
                ], 
                [
                    784, 
                    784
                ], 
                [
                    783, 
                    783
                ], 
                [
                    782, 
                    782
                ], 
                [
                    781, 
                    781
                ], 
                [
                    780, 
                    780
                ], 
                [
                    779, 
                    779
                ], 
                [
                    778, 
                    778
                ], 
                [
                    777, 
                    777
                ], 
                [
                    776, 
                    776
                ], 
                [
                    775, 
                    775
                ], 
                [
                    774, 
                    774
                ], 
                [
                    773, 
                    773
                ], 
                [
                    772, 
                    772
                ], 
                [
                    771, 
                    771
                ], 
                [
                    770, 
                    770
                ], 
                [
                    769, 
                    769
                ], 
                [
                    768, 
                    768
                ], 
                [
                    767, 
                    767
                ], 
                [
                    766, 
                    766
                ], 
                [
                    765, 
                    765
                ], 
                [
                    764, 
                    764
                ], 
                [
                    763, 
                    763
                ], 
                [
                    762, 
                    762
                ], 
                [
                    761, 
                    761
                ], 
                [
                    760, 
                    760
                ], 
                [
                    759, 
                    759
                ], 
                [
                    758, 
                    758
                ], 
                [
                    757, 
                    757
                ], 
                [
                    756, 
                    756
                ], 
                [
                    755, 
                    755
                ], 
                [
                    754, 
                    754
                ], 
                [
                    753, 
                    753
                ], 
                [
                    752, 
                    752
                ], 
                [
                    751, 
                    751
                ], 
                [
                    750, 
                    750
                ], 
                [
                    749, 
                    749
                ], 
                [
                    748, 
                    748
                ], 
                [
                    747, 
                    747
                ], 
                [
                    746, 
                    746
                ], 
                [
                    745, 
                    745
                ], 
                [
                    744, 
                    744
                ], 
                [
                    743, 
                    743
                ], 
                [
                    742, 
                    742
                ], 
                [
                    741, 
                    741
                ], 
                [
                    740, 
                    740
                ], 
                [
                    739, 
                    739
                ], 
                [
                    738, 
                    738
                ], 
                [
                    737, 
                    737
                ], 
                [
                    736, 
                    736
                ], 
                [
                    735, 
                    735
                ], 
                [
                    734, 
                    734
                ], 
                [
                    733, 
                    733
                ], 
                [
                    732, 
                    732
                ], 
                [
                    731, 
                    731
                ], 
                [
                    730, 
                    730
                ], 
                [
                    729, 
                    729
                ], 
                [
                    728, 
                    728
                ], 
                [
                    727, 
                    727
                ], 
                [
                    726, 
                    726
                ], 
                [
                    725, 
                    725
                ], 
                [
                    724, 
                    724
                ], 
                [
                    723, 
                    723
                ], 
                [
                    722, 
                    722
                ], 
                [
                    721, 
                    721
                ], 
                [
                    720, 
                    720
                ], 
                [
                    719, 
                    719
                ], 
                [
                    718, 
                    718
                ], 
                [
                    717, 
                    717
                ], 
                [
                    716, 
                    716
                ], 
                [
                    715, 
                    715
                ], 
                [
                    714, 
                    714
                ], 
                [
                    713, 
                    713
                ], 
                [
                    712, 
                    712
                ], 
                [
                    711, 
                    711
                ], 
                [
                    710, 
                    710
                ], 
                [
                    709, 
                    709
                ], 
                [
                    708, 
                    708
                ], 
                [
                    707, 
                    707
                ], 
                [
                    706, 
                    706
                ], 
                [
                    705, 
                    705
                ], 
                [
                    704, 
                    704
                ], 
                [
                    703, 
                    703
                ], 
                [
                    702, 
                    702
                ], 
                [
                    701, 
                    701
                ], 
                [
                    700, 
                    700
                ], 
                [
                    699, 
                    699
                ], 
                [
                    698, 
                    698
                ], 
                [
                    697, 
                    697
                ], 
                [
                    696, 
                    696
                ], 
                [
                    695, 
                    695
                ], 
                [
                    694, 
                    694
                ], 
                [
                    693, 
                    693
                ], 
                [
                    692, 
                    692
                ], 
                [
                    691, 
                    691
                ], 
                [
                    690, 
                    690
                ], 
                [
                    689, 
                    689
                ], 
                [
                    688, 
                    688
                ], 
                [
                    687, 
                    687
                ], 
                [
                    686, 
                    686
                ], 
                [
                    685, 
                    685
                ], 
                [
                    684, 
                    684
                ], 
                [
                    683, 
                    683
                ], 
                [
                    682, 
                    682
                ], 
                [
                    681, 
                    681
                ], 
                [
                    680, 
                    680
                ], 
                [
                    679, 
                    679
                ], 
                [
                    678, 
                    678
                ], 
                [
                    677, 
                    677
                ], 
                [
                    676, 
                    676
                ], 
                [
                    675, 
                    675
                ], 
                [
                    674, 
                    674
                ], 
                [
                    673, 
                    673
                ], 
                [
                    672, 
                    672
                ], 
                [
                    671, 
                    671
                ], 
                [
                    670, 
                    670
                ], 
                [
                    669, 
                    669
                ], 
                [
                    668, 
                    668
                ], 
                [
                    667, 
                    667
                ], 
                [
                    666, 
                    666
                ], 
                [
                    665, 
                    665
                ], 
                [
                    664, 
                    664
                ], 
                [
                    663, 
                    663
                ], 
                [
                    662, 
                    662
                ], 
                [
                    661, 
                    661
                ], 
                [
                    660, 
                    660
                ], 
                [
                    659, 
                    659
                ], 
                [
                    658, 
                    658
                ], 
                [
                    657, 
                    657
                ], 
                [
                    656, 
                    656
                ], 
                [
                    655, 
                    655
                ], 
                [
                    654, 
                    654
                ], 
                [
                    653, 
                    653
                ], 
                [
                    652, 
                    652
                ], 
                [
                    651, 
                    651
                ], 
                [
                    650, 
                    650
                ], 
                [
                    649, 
                    649
                ], 
                [
                    648, 
                    648
                ], 
                [
                    647, 
                    647
                ], 
                [
                    646, 
                    646
                ], 
                [
                    645, 
                    645
                ], 
                [
                    644, 
                    644
                ], 
                [
                    643, 
                    643
                ], 
                [
                    642, 
                    642
                ], 
                [
                    641, 
                    641
                ], 
                [
                    640, 
                    640
                ], 
                [
                    639, 
                    639
                ], 
                [
                    638, 
                    638
                ], 
                [
                    637, 
                    637
                ], 
                [
                    636, 
                    636
                ], 
                [
                    635, 
                    635
                ], 
                [
                    634, 
                    634
                ], 
                [
                    633, 
                    633
                ], 
                [
                    632, 
                    632
                ], 
                [
                    631, 
                    631
                ], 
                [
                    630, 
                    630
                ], 
                [
                    629, 
                    629
                ], 
                [
                    628, 
                    628
                ], 
                [
                    627, 
                    627
                ], 
                [
                    626, 
                    626
                ], 
                [
                    625, 
                    625
                ], 
                [
                    624, 
                    624
                ], 
                [
                    623, 
                    623
                ], 
                [
                    622, 
                    622
                ], 
                [
                    621, 
                    621
                ], 
                [
                    620, 
                    620
                ], 
                [
                    619, 
                    619
                ], 
                [
                    618, 
                    618
                ], 
                [
                    617, 
                    617
                ], 
                [
                    616, 
                    616
                ], 
                [
                    615, 
                    615
                ], 
                [
                    614, 
                    614
                ], 
                [
                    613, 
                    613
                ], 
                [
                    612, 
                    612
                ], 
                [
                    611, 
                    611
                ], 
                [
                    610, 
                    610
                ], 
                [
                    609, 
                    609
                ], 
                [
                    608, 
                    608
                ], 
                [
                    607, 
                    607
                ], 
                [
                    606, 
                    606
                ], 
                [
                    605, 
                    605
                ], 
                [
                    604, 
                    604
                ], 
                [
                    603, 
                    603
                ], 
                [
                    602, 
                    602
                ], 
                [
                    601, 
                    601
                ], 
                [
                    600, 
                    600
                ], 
                [
                    599, 
                    599
                ], 
                [
                    598, 
                    598
                ], 
                [
                    597, 
                    597
                ], 
                [
                    596, 
                    596
                ], 
                [
                    595, 
                    595
                ], 
                [
                    594, 
                    594
                ], 
                [
                    593, 
                    593
                ], 
                [
                    592, 
                    592
                ], 
                [
                    591, 
                    591
                ], 
                [
                    590, 
                    590
                ], 
                [
                    589, 
                    589
                ], 
                [
                    588, 
                    588
                ], 
                [
                    587, 
                    587
                ], 
                [
                    586, 
                    586
                ], 
                [
                    585, 
                    585
                ], 
                [
                    584, 
                    584
                ], 
                [
                    583, 
                    583
                ], 
                [
                    582, 
                    582
                ], 
                [
                    581, 
                    581
                ], 
                [
                    580, 
                    580
                ], 
                [
                    579, 
                    579
                ], 
                [
                    578, 
                    578
                ], 
                [
                    577, 
                    577
                ], 
                [
                    576, 
                    576
                ], 
                [
                    575, 
                    575
                ], 
                [
                    574, 
                    574
                ], 
                [
                    573, 
                    573
                ], 
                [
                    572, 
                    572
                ], 
                [
                    571, 
                    571
                ], 
                [
                    570, 
                    570
                ], 
                [
                    569, 
                    569
                ], 
                [
                    568, 
                    568
                ], 
                [
                    567, 
                    567
                ], 
                [
                    566, 
                    566
                ], 
                [
                    565, 
                    565
                ], 
                [
                    564, 
                    564
                ], 
                [
                    563, 
                    563
                ], 
                [
                    562, 
                    562
                ], 
                [
                    561, 
                    561
                ], 
                [
                    560, 
                    560
                ], 
                [
                    559, 
                    559
                ], 
                [
                    558, 
                    558
                ], 
                [
                    557, 
                    557
                ], 
                [
                    556, 
                    556
                ], 
                [
                    555, 
                    555
                ], 
                [
                    554, 
                    554
                ], 
                [
                    553, 
                    553
                ], 
                [
                    552, 
                    552
                ], 
                [
                    551, 
                    551
                ], 
                [
                    550, 
                    550
                ], 
                [
                    549, 
                    549
                ], 
                [
                    548, 
                    548
                ], 
                [
                    547, 
                    547
                ], 
                [
                    546, 
                    546
                ], 
                [
                    545, 
                    545
                ], 
                [
                    544, 
                    544
                ], 
                [
                    543, 
                    543
                ], 
                [
                    542, 
                    542
                ], 
                [
                    541, 
                    541
                ], 
                [
                    540, 
                    540
                ], 
                [
                    539, 
                    539
                ], 
                [
                    538, 
                    538
                ], 
                [
                    537, 
                    537
                ], 
                [
                    536, 
                    536
                ], 
                [
                    535, 
                    535
                ], 
                [
                    534, 
                    534
                ], 
                [
                    533, 
                    533
                ], 
                [
                    532, 
                    532
                ], 
                [
                    531, 
                    531
                ], 
                [
                    530, 
                    530
                ], 
                [
                    529, 
                    529
                ], 
                [
                    528, 
                    528
                ], 
                [
                    527, 
                    527
                ], 
                [
                    526, 
                    526
                ], 
                [
                    525, 
                    525
                ], 
                [
                    524, 
                    524
                ], 
                [
                    523, 
                    523
                ], 
                [
                    522, 
                    522
                ], 
                [
                    521, 
                    521
                ], 
                [
                    520, 
                    520
                ], 
                [
                    519, 
                    519
                ], 
                [
                    518, 
                    518
                ], 
                [
                    517, 
                    517
                ], 
                [
                    516, 
                    516
                ], 
                [
                    515, 
                    515
                ], 
                [
                    514, 
                    514
                ], 
                [
                    513, 
                    513
                ], 
                [
                    512, 
                    512
                ], 
                [
                    511, 
                    511
                ], 
                [
                    510, 
                    510
                ], 
                [
                    509, 
                    509
                ], 
                [
                    508, 
                    508
                ], 
                [
                    507, 
                    507
                ], 
                [
                    506, 
                    506
                ], 
                [
                    505, 
                    505
                ], 
                [
                    504, 
                    504
                ], 
                [
                    503, 
                    503
                ], 
                [
                    502, 
                    502
                ], 
                [
                    501, 
                    501
                ], 
                [
                    500, 
                    500
                ], 
                [
                    499, 
                    499
                ], 
                [
                    498, 
                    498
                ], 
                [
                    497, 
                    497
                ], 
                [
                    496, 
                    496
                ], 
                [
                    495, 
                    495
                ], 
                [
                    494, 
                    494
                ], 
                [
                    493, 
                    493
                ], 
                [
                    492, 
                    492
                ], 
                [
                    491, 
                    491
                ], 
                [
                    490, 
                    490
                ], 
                [
                    489, 
                    489
                ], 
                [
                    488, 
                    488
                ], 
                [
                    487, 
                    487
                ], 
                [
                    486, 
                    486
                ], 
                [
                    485, 
                    485
                ], 
                [
                    484, 
                    484
                ], 
                [
                    483, 
                    483
                ], 
                [
                    482, 
                    482
                ], 
                [
                    481, 
                    481
                ], 
                [
                    480, 
                    480
                ], 
                [
                    479, 
                    479
                ], 
                [
                    478, 
                    478
                ], 
                [
                    477, 
                    477
                ], 
                [
                    476, 
                    476
                ], 
                [
                    475, 
                    475
                ], 
                [
                    474, 
                    474
                ], 
                [
                    473, 
                    473
                ], 
                [
                    472, 
                    472
                ], 
                [
                    471, 
                    471
                ], 
                [
                    470, 
                    470
                ], 
                [
                    469, 
                    469
                ], 
                [
                    468, 
                    468
                ], 
                [
                    467, 
                    467
                ], 
                [
                    466, 
                    466
                ], 
                [
                    465, 
                    465
                ], 
                [
                    464, 
                    464
                ], 
                [
                    463, 
                    463
                ], 
                [
                    462, 
                    462
                ], 
                [
                    461, 
                    461
                ], 
                [
                    460, 
                    460
                ], 
                [
                    459, 
                    459
                ], 
                [
                    458, 
                    458
                ], 
                [
                    457, 
                    457
                ], 
                [
                    456, 
                    456
                ], 
                [
                    455, 
                    455
                ], 
                [
                    454, 
                    454
                ], 
                [
                    453, 
                    453
                ], 
                [
                    452, 
                    452
                ], 
                [
                    451, 
                    451
                ], 
                [
                    450, 
                    450
                ], 
                [
                    449, 
                    449
                ], 
                [
                    448, 
                    448
                ], 
                [
                    447, 
                    447
                ], 
                [
                    446, 
                    446
                ], 
                [
                    445, 
                    445
                ], 
                [
                    444, 
                    444
                ], 
                [
                    443, 
                    443
                ], 
                [
                    442, 
                    442
                ], 
                [
                    441, 
                    441
                ], 
                [
                    440, 
                    440
                ], 
                [
                    439, 
                    439
                ], 
                [
                    438, 
                    438
                ], 
                [
                    437, 
                    437
                ], 
                [
                    436, 
                    436
                ], 
                [
                    435, 
                    435
                ], 
                [
                    434, 
                    434
                ], 
                [
                    433, 
                    433
                ], 
                [
                    432, 
                    432
                ], 
                [
                    431, 
                    431
                ], 
                [
                    430, 
                    430
                ], 
                [
                    429, 
                    429
                ], 
                [
                    428, 
                    428
                ], 
                [
                    427, 
                    427
                ], 
                [
                    426, 
                    426
                ], 
                [
                    425, 
                    425
                ], 
                [
                    424, 
                    424
                ], 
                [
                    423, 
                    423
                ], 
                [
                    422, 
                    422
                ], 
                [
                    421, 
                    421
                ], 
                [
                    420, 
                    420
                ], 
                [
                    419, 
                    419
                ], 
                [
                    418, 
                    418
                ], 
                [
                    417, 
                    417
                ], 
                [
                    416, 
                    416
                ], 
                [
                    415, 
                    415
                ], 
                [
                    414, 
                    414
                ], 
                [
                    413, 
                    413
                ], 
                [
                    412, 
                    412
                ], 
                [
                    411, 
                    411
                ], 
                [
                    410, 
                    410
                ], 
                [
                    409, 
                    409
                ], 
                [
                    408, 
                    408
                ], 
                [
                    407, 
                    407
                ], 
                [
                    406, 
                    406
                ], 
                [
                    405, 
                    405
                ], 
                [
                    404, 
                    404
                ], 
                [
                    403, 
                    403
                ], 
                [
                    402, 
                    402
                ], 
                [
                    401, 
                    401
                ], 
                [
                    400, 
                    400
                ]
            ], 
            z: [
                [
                    0.896217503, 
                    0.896217503
                ], 
                [
                    0.885106891, 
                    0.885106891
                ], 
                [
                    0.888106757, 
                    0.888106757
                ], 
                [
                    0.905443755, 
                    0.905443755
                ], 
                [
                    0.896616992, 
                    0.896616992
                ], 
                [
                    0.898221858, 
                    0.898221858
                ], 
                [
                    0.901004942, 
                    0.901004942
                ], 
                [
                    0.903102626, 
                    0.903102626
                ], 
                [
                    0.903840371, 
                    0.903840371
                ], 
                [
                    0.906079776, 
                    0.906079776
                ], 
                [
                    0.907775996, 
                    0.907775996
                ], 
                [
                    0.90857596, 
                    0.90857596
                ], 
                [
                    0.911343243, 
                    0.911343243
                ], 
                [
                    0.914738646, 
                    0.914738646
                ], 
                [
                    0.914142624, 
                    0.914142624
                ], 
                [
                    0.917051629, 
                    0.917051629
                ], 
                [
                    0.918383421, 
                    0.918383421
                ], 
                [
                    0.921979309, 
                    0.921979309
                ], 
                [
                    0.924211801, 
                    0.924211801
                ], 
                [
                    0.926208254, 
                    0.926208254
                ], 
                [
                    0.929065904, 
                    0.929065904
                ], 
                [
                    0.931389256, 
                    0.931389256
                ], 
                [
                    0.933214359, 
                    0.933214359
                ], 
                [
                    0.938283761, 
                    0.938283761
                ], 
                [
                    0.938637819, 
                    0.938637819
                ], 
                [
                    0.943074163, 
                    0.943074163
                ], 
                [
                    0.942109762, 
                    0.942109762
                ], 
                [
                    0.946448332, 
                    0.946448332
                ], 
                [
                    0.949093152, 
                    0.949093152
                ], 
                [
                    0.951934506, 
                    0.951934506
                ], 
                [
                    0.953591221, 
                    0.953591221
                ], 
                [
                    0.955588169, 
                    0.955588169
                ], 
                [
                    0.96009562, 
                    0.96009562
                ], 
                [
                    0.963592747, 
                    0.963592747
                ], 
                [
                    0.965269709, 
                    0.965269709
                ], 
                [
                    0.97162251, 
                    0.97162251
                ], 
                [
                    0.973772043, 
                    0.973772043
                ], 
                [
                    0.974806564, 
                    0.974806564
                ], 
                [
                    0.97614527, 
                    0.97614527
                ], 
                [
                    0.977717051, 
                    0.977717051
                ], 
                [
                    0.980452236, 
                    0.980452236
                ], 
                [
                    0.977720014, 
                    0.977720014
                ], 
                [
                    0.977361017, 
                    0.977361017
                ], 
                [
                    0.978879961, 
                    0.978879961
                ], 
                [
                    0.981595889, 
                    0.981595889
                ], 
                [
                    0.98466143, 
                    0.98466143
                ], 
                [
                    0.987804005, 
                    0.987804005
                ], 
                [
                    0.990265129, 
                    0.990265129
                ], 
                [
                    0.990909544, 
                    0.990909544
                ], 
                [
                    0.993799291, 
                    0.993799291
                ], 
                [
                    1, 
                    1
                ], 
                [
                    1.004737565, 
                    1.004737565
                ], 
                [
                    1.00693796, 
                    1.00693796
                ], 
                [
                    1.007473244, 
                    1.007473244
                ], 
                [
                    1.009649443, 
                    1.009649443
                ], 
                [
                    1.01182416, 
                    1.01182416
                ], 
                [
                    1.012650296, 
                    1.012650296
                ], 
                [
                    1.018438184, 
                    1.018438184
                ], 
                [
                    1.018303375, 
                    1.018303375
                ], 
                [
                    1.019182842, 
                    1.019182842
                ], 
                [
                    1.022152585, 
                    1.022152585
                ], 
                [
                    1.026546955, 
                    1.026546955
                ], 
                [
                    1.025888713, 
                    1.025888713
                ], 
                [
                    1.027160261, 
                    1.027160261
                ], 
                [
                    1.027773567, 
                    1.027773567
                ], 
                [
                    1.032308178, 
                    1.032308178
                ], 
                [
                    1.034003904, 
                    1.034003904
                ], 
                [
                    1.036388488, 
                    1.036388488
                ], 
                [
                    1.037890149, 
                    1.037890149
                ], 
                [
                    1.038598265, 
                    1.038598265
                ], 
                [
                    1.037779537, 
                    1.037779537
                ], 
                [
                    1.042087985, 
                    1.042087985
                ], 
                [
                    1.042833137, 
                    1.042833137
                ], 
                [
                    1.043788649, 
                    1.043788649
                ], 
                [
                    1.043522489, 
                    1.043522489
                ], 
                [
                    1.044667622, 
                    1.044667622
                ], 
                [
                    1.047289233, 
                    1.047289233
                ], 
                [
                    1.050985857, 
                    1.050985857
                ], 
                [
                    1.054603472, 
                    1.054603472
                ], 
                [
                    1.057458159, 
                    1.057458159
                ], 
                [
                    1.058907476, 
                    1.058907476
                ], 
                [
                    1.063269749, 
                    1.063269749
                ], 
                [
                    1.0687737, 
                    1.0687737
                ], 
                [
                    1.073644098, 
                    1.073644098
                ], 
                [
                    1.078274013, 
                    1.078274013
                ], 
                [
                    1.082941458, 
                    1.082941458
                ], 
                [
                    1.086053911, 
                    1.086053911
                ], 
                [
                    1.087941233, 
                    1.087941233
                ], 
                [
                    1.091932659, 
                    1.091932659
                ], 
                [
                    1.095697922, 
                    1.095697922
                ], 
                [
                    1.101010276, 
                    1.101010276
                ], 
                [
                    1.105074784, 
                    1.105074784
                ], 
                [
                    1.108540801, 
                    1.108540801
                ], 
                [
                    1.113382559, 
                    1.113382559
                ], 
                [
                    1.118938853, 
                    1.118938853
                ], 
                [
                    1.121326893, 
                    1.121326893
                ], 
                [
                    1.125935081, 
                    1.125935081
                ], 
                [
                    1.132003945, 
                    1.132003945
                ], 
                [
                    1.138733025, 
                    1.138733025
                ], 
                [
                    1.143167887, 
                    1.143167887
                ], 
                [
                    1.148430367, 
                    1.148430367
                ], 
                [
                    1.153736301, 
                    1.153736301
                ], 
                [
                    1.157413173, 
                    1.157413173
                ], 
                [
                    1.163083042, 
                    1.163083042
                ], 
                [
                    1.170298026, 
                    1.170298026
                ], 
                [
                    1.179307004, 
                    1.179307004
                ], 
                [
                    1.184111726, 
                    1.184111726
                ], 
                [
                    1.187916, 
                    1.187916
                ], 
                [
                    1.191489666, 
                    1.191489666
                ], 
                [
                    1.193373532, 
                    1.193373532
                ], 
                [
                    1.195600099, 
                    1.195600099
                ], 
                [
                    1.199707569, 
                    1.199707569
                ], 
                [
                    1.199945583, 
                    1.199945583
                ], 
                [
                    1.199611771, 
                    1.199611771
                ], 
                [
                    1.19480853, 
                    1.19480853
                ], 
                [
                    1.192067418, 
                    1.192067418
                ], 
                [
                    1.189470498, 
                    1.189470498
                ], 
                [
                    1.182850055, 
                    1.182850055
                ], 
                [
                    1.179381569, 
                    1.179381569
                ], 
                [
                    1.174847451, 
                    1.174847451
                ], 
                [
                    1.166929289, 
                    1.166929289
                ], 
                [
                    1.159056556, 
                    1.159056556
                ], 
                [
                    1.151100864, 
                    1.151100864
                ], 
                [
                    1.147401771, 
                    1.147401771
                ], 
                [
                    1.140129011, 
                    1.140129011
                ], 
                [
                    1.134436922, 
                    1.134436922
                ], 
                [
                    1.129894903, 
                    1.129894903
                ], 
                [
                    1.122801889, 
                    1.122801889
                ], 
                [
                    1.118057411, 
                    1.118057411
                ], 
                [
                    1.114153882, 
                    1.114153882
                ], 
                [
                    1.112262116, 
                    1.112262116
                ], 
                [
                    1.10922324, 
                    1.10922324
                ], 
                [
                    1.104554808, 
                    1.104554808
                ], 
                [
                    1.104886151, 
                    1.104886151
                ], 
                [
                    1.102858094, 
                    1.102858094
                ], 
                [
                    1.100999412, 
                    1.100999412
                ], 
                [
                    1.104074335, 
                    1.104074335
                ], 
                [
                    1.106544348, 
                    1.106544348
                ], 
                [
                    1.107967988, 
                    1.107967988
                ], 
                [
                    1.108428708, 
                    1.108428708
                ], 
                [
                    1.114521273, 
                    1.114521273
                ], 
                [
                    1.120211882, 
                    1.120211882
                ], 
                [
                    1.125209188, 
                    1.125209188
                ], 
                [
                    1.131223733, 
                    1.131223733
                ], 
                [
                    1.139729523, 
                    1.139729523
                ], 
                [
                    1.142760004, 
                    1.142760004
                ], 
                [
                    1.147282764, 
                    1.147282764
                ], 
                [
                    1.152031193, 
                    1.152031193
                ], 
                [
                    1.157873399, 
                    1.157873399
                ], 
                [
                    1.160953261, 
                    1.160953261
                ], 
                [
                    1.166207346, 
                    1.166207346
                ], 
                [
                    1.171283661, 
                    1.171283661
                ], 
                [
                    1.173002596, 
                    1.173002596
                ], 
                [
                    1.174894363, 
                    1.174894363
                ], 
                [
                    1.178438401, 
                    1.178438401
                ], 
                [
                    1.180163756, 
                    1.180163756
                ], 
                [
                    1.183733472, 
                    1.183733472
                ], 
                [
                    1.187449354, 
                    1.187449354
                ], 
                [
                    1.191748914, 
                    1.191748914
                ], 
                [
                    1.192835779, 
                    1.192835779
                ], 
                [
                    1.193330571, 
                    1.193330571
                ], 
                [
                    1.196402038, 
                    1.196402038
                ], 
                [
                    1.197721979, 
                    1.197721979
                ], 
                [
                    1.202067956, 
                    1.202067956
                ], 
                [
                    1.203884171, 
                    1.203884171
                ], 
                [
                    1.204208107, 
                    1.204208107
                ], 
                [
                    1.203719734, 
                    1.203719734
                ], 
                [
                    1.20157267, 
                    1.20157267
                ], 
                [
                    1.200660612, 
                    1.200660612
                ], 
                [
                    1.198654283, 
                    1.198654283
                ], 
                [
                    1.197384216, 
                    1.197384216
                ], 
                [
                    1.196574376, 
                    1.196574376
                ], 
                [
                    1.191071414, 
                    1.191071414
                ], 
                [
                    1.188793491, 
                    1.188793491
                ], 
                [
                    1.185730419, 
                    1.185730419
                ], 
                [
                    1.183127573, 
                    1.183127573
                ], 
                [
                    1.18131679, 
                    1.18131679
                ], 
                [
                    1.177713002, 
                    1.177713002
                ], 
                [
                    1.176377259, 
                    1.176377259
                ], 
                [
                    1.174112176, 
                    1.174112176
                ], 
                [
                    1.172135474, 
                    1.172135474
                ], 
                [
                    1.171378965, 
                    1.171378965
                ], 
                [
                    1.168330213, 
                    1.168330213
                ], 
                [
                    1.166911512, 
                    1.166911512
                ], 
                [
                    1.164660255, 
                    1.164660255
                ], 
                [
                    1.162471711, 
                    1.162471711
                ], 
                [
                    1.161543852, 
                    1.161543852
                ], 
                [
                    1.156553952, 
                    1.156553952
                ], 
                [
                    1.154807364, 
                    1.154807364
                ], 
                [
                    1.15367902, 
                    1.15367902
                ], 
                [
                    1.154615274, 
                    1.154615274
                ], 
                [
                    1.154849831, 
                    1.154849831
                ], 
                [
                    1.154597991, 
                    1.154597991
                ], 
                [
                    1.156434452, 
                    1.156434452
                ], 
                [
                    1.159493573, 
                    1.159493573
                ], 
                [
                    1.160404644, 
                    1.160404644
                ], 
                [
                    1.165839955, 
                    1.165839955
                ], 
                [
                    1.167432476, 
                    1.167432476
                ], 
                [
                    1.169407202, 
                    1.169407202
                ], 
                [
                    1.169145979, 
                    1.169145979
                ], 
                [
                    1.171774009, 
                    1.171774009
                ], 
                [
                    1.172951734, 
                    1.172951734
                ], 
                [
                    1.173638617, 
                    1.173638617
                ], 
                [
                    1.172993214, 
                    1.172993214
                ], 
                [
                    1.172840134, 
                    1.172840134
                ], 
                [
                    1.171790305, 
                    1.171790305
                ], 
                [
                    1.171753763, 
                    1.171753763
                ], 
                [
                    1.172076218, 
                    1.172076218
                ], 
                [
                    1.174026254, 
                    1.174026254
                ], 
                [
                    1.174292415, 
                    1.174292415
                ], 
                [
                    1.175141265, 
                    1.175141265
                ], 
                [
                    1.175113118, 
                    1.175113118
                ], 
                [
                    1.176170849, 
                    1.176170849
                ], 
                [
                    1.178542594, 
                    1.178542594
                ], 
                [
                    1.17943885, 
                    1.17943885
                ], 
                [
                    1.182956223, 
                    1.182956223
                ], 
                [
                    1.18357496, 
                    1.18357496
                ], 
                [
                    1.183229791, 
                    1.183229791
                ], 
                [
                    1.183810012, 
                    1.183810012
                ], 
                [
                    1.185785725, 
                    1.185785725
                ], 
                [
                    1.1895979, 
                    1.1895979
                ], 
                [
                    1.189429512, 
                    1.189429512
                ], 
                [
                    1.190704516, 
                    1.190704516
                ], 
                [
                    1.191582008, 
                    1.191582008
                ], 
                [
                    1.19210791, 
                    1.19210791
                ], 
                [
                    1.194835195, 
                    1.194835195
                ], 
                [
                    1.198153564, 
                    1.198153564
                ], 
                [
                    1.200911959, 
                    1.200911959
                ], 
                [
                    1.204385877, 
                    1.204385877
                ], 
                [
                    1.205305836, 
                    1.205305836
                ], 
                [
                    1.20828743, 
                    1.20828743
                ], 
                [
                    1.212426009, 
                    1.212426009
                ], 
                [
                    1.216588292, 
                    1.216588292
                ], 
                [
                    1.219697781, 
                    1.219697781
                ], 
                [
                    1.221482886, 
                    1.221482886
                ], 
                [
                    1.222529753, 
                    1.222529753
                ], 
                [
                    1.223794388, 
                    1.223794388
                ], 
                [
                    1.226513771, 
                    1.226513771
                ], 
                [
                    1.229641532, 
                    1.229641532
                ], 
                [
                    1.234402306, 
                    1.234402306
                ], 
                [
                    1.237320199, 
                    1.237320199
                ], 
                [
                    1.240104766, 
                    1.240104766
                ], 
                [
                    1.24418952, 
                    1.24418952
                ], 
                [
                    1.247207656, 
                    1.247207656
                ], 
                [
                    1.250924033, 
                    1.250924033
                ], 
                [
                    1.257303005, 
                    1.257303005
                ], 
                [
                    1.261502323, 
                    1.261502323
                ], 
                [
                    1.266463087, 
                    1.266463087
                ], 
                [
                    1.271628781, 
                    1.271628781
                ], 
                [
                    1.275492311, 
                    1.275492311
                ], 
                [
                    1.280192347, 
                    1.280192347
                ], 
                [
                    1.285246441, 
                    1.285246441
                ], 
                [
                    1.290024498, 
                    1.290024498
                ], 
                [
                    1.295929417, 
                    1.295929417
                ], 
                [
                    1.300459584, 
                    1.300459584
                ], 
                [
                    1.304034238, 
                    1.304034238
                ], 
                [
                    1.307933816, 
                    1.307933816
                ], 
                [
                    1.310627029, 
                    1.310627029
                ], 
                [
                    1.314142426, 
                    1.314142426
                ], 
                [
                    1.319755013, 
                    1.319755013
                ], 
                [
                    1.322827962, 
                    1.322827962
                ], 
                [
                    1.326700874, 
                    1.326700874
                ], 
                [
                    1.331534237, 
                    1.331534237
                ], 
                [
                    1.332049276, 
                    1.332049276
                ], 
                [
                    1.335272341, 
                    1.335272341
                ], 
                [
                    1.339263273, 
                    1.339263273
                ], 
                [
                    1.343725788, 
                    1.343725788
                ], 
                [
                    1.348661863, 
                    1.348661863
                ], 
                [
                    1.348636185, 
                    1.348636185
                ], 
                [
                    1.349414421, 
                    1.349414421
                ], 
                [
                    1.353875455, 
                    1.353875455
                ], 
                [
                    1.355655129, 
                    1.355655129
                ], 
                [
                    1.362227179, 
                    1.362227179
                ], 
                [
                    1.367109922, 
                    1.367109922
                ], 
                [
                    1.370760129, 
                    1.370760129
                ], 
                [
                    1.372459806, 
                    1.372459806
                ], 
                [
                    1.375115982, 
                    1.375115982
                ], 
                [
                    1.381606555, 
                    1.381606555
                ], 
                [
                    1.388095646, 
                    1.388095646
                ], 
                [
                    1.390300979, 
                    1.390300979
                ], 
                [
                    1.393279117, 
                    1.393279117
                ], 
                [
                    1.396307623, 
                    1.396307623
                ], 
                [
                    1.39751942, 
                    1.39751942
                ], 
                [
                    1.398280867, 
                    1.398280867
                ], 
                [
                    1.401656024, 
                    1.401656024
                ], 
                [
                    1.404196651, 
                    1.404196651
                ], 
                [
                    1.405138831, 
                    1.405138831
                ], 
                [
                    1.405499802, 
                    1.405499802
                ], 
                [
                    1.405029206, 
                    1.405029206
                ], 
                [
                    1.406696292, 
                    1.406696292
                ], 
                [
                    1.408927303, 
                    1.408927303
                ], 
                [
                    1.409527276, 
                    1.409527276
                ], 
                [
                    1.411618046, 
                    1.411618046
                ], 
                [
                    1.413240195, 
                    1.413240195
                ], 
                [
                    1.414408044, 
                    1.414408044
                ], 
                [
                    1.419004874, 
                    1.419004874
                ], 
                [
                    1.423780462, 
                    1.423780462
                ], 
                [
                    1.427762505, 
                    1.427762505
                ], 
                [
                    1.430225111, 
                    1.430225111
                ], 
                [
                    1.429981665, 
                    1.429981665
                ], 
                [
                    1.435991272, 
                    1.435991272
                ], 
                [
                    1.438549181, 
                    1.438549181
                ], 
                [
                    1.439181745, 
                    1.439181745
                ], 
                [
                    1.441152027, 
                    1.441152027
                ], 
                [
                    1.441320909, 
                    1.441320909
                ], 
                [
                    1.440516006, 
                    1.440516006
                ], 
                [
                    1.444249172, 
                    1.444249172
                ], 
                [
                    1.446519687, 
                    1.446519687
                ], 
                [
                    1.449692384, 
                    1.449692384
                ], 
                [
                    1.44993188, 
                    1.44993188
                ], 
                [
                    1.452957916, 
                    1.452957916
                ], 
                [
                    1.457423395, 
                    1.457423395
                ], 
                [
                    1.460525478, 
                    1.460525478
                ], 
                [
                    1.463200419, 
                    1.463200419
                ], 
                [
                    1.464283333, 
                    1.464283333
                ], 
                [
                    1.466258553, 
                    1.466258553
                ], 
                [
                    1.469136449, 
                    1.469136449
                ], 
                [
                    1.472011381, 
                    1.472011381
                ], 
                [
                    1.474139681, 
                    1.474139681
                ], 
                [
                    1.474597438, 
                    1.474597438
                ], 
                [
                    1.475690228, 
                    1.475690228
                ], 
                [
                    1.478280729, 
                    1.478280729
                ], 
                [
                    1.480260887, 
                    1.480260887
                ], 
                [
                    1.484984625, 
                    1.484984625
                ], 
                [
                    1.488784948, 
                    1.488784948
                ], 
                [
                    1.49207764, 
                    1.49207764
                ], 
                [
                    1.494256801, 
                    1.494256801
                ], 
                [
                    1.496898164, 
                    1.496898164
                ], 
                [
                    1.500783422, 
                    1.500783422
                ], 
                [
                    1.504142777, 
                    1.504142777
                ], 
                [
                    1.506039482, 
                    1.506039482
                ], 
                [
                    1.509850175, 
                    1.509850175
                ], 
                [
                    1.511886626, 
                    1.511886626
                ], 
                [
                    1.513519639, 
                    1.513519639
                ], 
                [
                    1.514098379, 
                    1.514098379
                ], 
                [
                    1.516268158, 
                    1.516268158
                ], 
                [
                    1.520504511, 
                    1.520504511
                ], 
                [
                    1.523213525, 
                    1.523213525
                ], 
                [
                    1.525952661, 
                    1.525952661
                ], 
                [
                    1.52884537, 
                    1.52884537
                ], 
                [
                    1.531293162, 
                    1.531293162
                ], 
                [
                    1.536102822, 
                    1.536102822
                ], 
                [
                    1.538380744, 
                    1.538380744
                ], 
                [
                    1.542538582, 
                    1.542538582
                ], 
                [
                    1.545825348, 
                    1.545825348
                ], 
                [
                    1.547957598, 
                    1.547957598
                ], 
                [
                    1.550334775, 
                    1.550334775
                ], 
                [
                    1.553169709, 
                    1.553169709
                ], 
                [
                    1.553583024, 
                    1.553583024
                ], 
                [
                    1.552787504, 
                    1.552787504
                ], 
                [
                    1.550604886, 
                    1.550604886
                ], 
                [
                    1.551039435, 
                    1.551039435
                ], 
                [
                    1.550453288, 
                    1.550453288
                ], 
                [
                    1.547241087, 
                    1.547241087
                ], 
                [
                    1.543366199, 
                    1.543366199
                ], 
                [
                    1.540754959, 
                    1.540754959
                ], 
                [
                    1.535445568, 
                    1.535445568
                ], 
                [
                    1.530849231, 
                    1.530849231
                ], 
                [
                    1.527223221, 
                    1.527223221
                ], 
                [
                    1.524238664, 
                    1.524238664
                ], 
                [
                    1.51842411, 
                    1.51842411
                ], 
                [
                    1.512730539, 
                    1.512730539
                ], 
                [
                    1.51154195, 
                    1.51154195
                ], 
                [
                    1.507520897, 
                    1.507520897
                ], 
                [
                    1.50489336, 
                    1.50489336
                ], 
                [
                    1.502349277, 
                    1.502349277
                ], 
                [
                    1.501590299, 
                    1.501590299
                ], 
                [
                    1.501612026, 
                    1.501612026
                ], 
                [
                    1.499362745, 
                    1.499362745
                ], 
                [
                    1.498711416, 
                    1.498711416
                ], 
                [
                    1.500029381, 
                    1.500029381
                ], 
                [
                    1.500208139, 
                    1.500208139
                ], 
                [
                    1.503069739, 
                    1.503069739
                ], 
                [
                    1.503288494, 
                    1.503288494
                ], 
                [
                    1.505199519, 
                    1.505199519
                ], 
                [
                    1.507681383, 
                    1.507681383
                ], 
                [
                    1.507401396, 
                    1.507401396
                ], 
                [
                    1.510535082, 
                    1.510535082
                ], 
                [
                    1.51283967, 
                    1.51283967
                ], 
                [
                    1.515377334, 
                    1.515377334
                ], 
                [
                    1.51555856, 
                    1.51555856
                ], 
                [
                    1.515260302, 
                    1.515260302
                ], 
                [
                    1.516107671, 
                    1.516107671
                ], 
                [
                    1.51682517, 
                    1.51682517
                ], 
                [
                    1.518235971, 
                    1.518235971
                ], 
                [
                    1.520053173, 
                    1.520053173
                ], 
                [
                    1.520240325, 
                    1.520240325
                ], 
                [
                    1.522160239, 
                    1.522160239
                ], 
                [
                    1.522219989, 
                    1.522219989
                ], 
                [
                    1.523086123, 
                    1.523086123
                ], 
                [
                    1.52660794, 
                    1.52660794
                ], 
                [
                    1.529079434, 
                    1.529079434
                ], 
                [
                    1.532333609, 
                    1.532333609
                ], 
                [
                    1.532911854, 
                    1.532911854
                ], 
                [
                    1.536322565, 
                    1.536322565
                ], 
                [
                    1.539721425, 
                    1.539721425
                ], 
                [
                    1.545154761, 
                    1.545154761
                ], 
                [
                    1.546713271, 
                    1.546713271
                ], 
                [
                    1.550057327, 
                    1.550057327
                ], 
                [
                    1.550588838, 
                    1.550588838
                ], 
                [
                    1.553174647, 
                    1.553174647
                ]
            ], 
            cmax: 1.553583024, 
            cmin: 0.885106891, 
            colorscale: [
                [
                    "0", 
                    "rgb(62,62,255)"
                ], 
                [
                    "0.1", 
                    "rgb(62,62,255)"
                ], 
                [
                    "0.2", 
                    "rgb(62,62,255)"
                ], 
                [
                    "0.3", 
                    "rgb(62,62,255)"
                ], 
                [
                    "0.4", 
                    "rgb(62,62,255)"
                ], 
                [
                    "0.5", 
                    "rgb(62,62,255)"
                ], 
                [
                    "0.6", 
                    "rgb(62,62,255)"
                ], 
                [
                    "0.7", 
                    "rgb(62,62,255)"
                ], 
                [
                    "0.8", 
                    "rgb(62,62,255)"
                ], 
                [
                    "0.9", 
                    "rgb(62,62,255)"
                ], 
                [
                    "1", 
                    "rgb(62,62,255)"
                ]
            ], 
            name: "", 
            showscale: false, 
            type: "surface", 
            uid: "473451", 
            xsrc: "Diksha_Gabha:2343:edb88a,d50025", 
            ysrc: "Diksha_Gabha:2343:807139,59d0b7", 
            zsrc: "Diksha_Gabha:2343:b9dea4,690692"
        }, 
        {
            x: [
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ], 
                [
                    6, 
                    7
                ]
            ], 
            y: [
                [
                    800, 
                    800
                ], 
                [
                    799, 
                    799
                ], 
                [
                    798, 
                    798
                ], 
                [
                    797, 
                    797
                ], 
                [
                    796, 
                    796
                ], 
                [
                    795, 
                    795
                ], 
                [
                    794, 
                    794
                ], 
                [
                    793, 
                    793
                ], 
                [
                    792, 
                    792
                ], 
                [
                    791, 
                    791
                ], 
                [
                    790, 
                    790
                ], 
                [
                    789, 
                    789
                ], 
                [
                    788, 
                    788
                ], 
                [
                    787, 
                    787
                ], 
                [
                    786, 
                    786
                ], 
                [
                    785, 
                    785
                ], 
                [
                    784, 
                    784
                ], 
                [
                    783, 
                    783
                ], 
                [
                    782, 
                    782
                ], 
                [
                    781, 
                    781
                ], 
                [
                    780, 
                    780
                ], 
                [
                    779, 
                    779
                ], 
                [
                    778, 
                    778
                ], 
                [
                    777, 
                    777
                ], 
                [
                    776, 
                    776
                ], 
                [
                    775, 
                    775
                ], 
                [
                    774, 
                    774
                ], 
                [
                    773, 
                    773
                ], 
                [
                    772, 
                    772
                ], 
                [
                    771, 
                    771
                ], 
                [
                    770, 
                    770
                ], 
                [
                    769, 
                    769
                ], 
                [
                    768, 
                    768
                ], 
                [
                    767, 
                    767
                ], 
                [
                    766, 
                    766
                ], 
                [
                    765, 
                    765
                ], 
                [
                    764, 
                    764
                ], 
                [
                    763, 
                    763
                ], 
                [
                    762, 
                    762
                ], 
                [
                    761, 
                    761
                ], 
                [
                    760, 
                    760
                ], 
                [
                    759, 
                    759
                ], 
                [
                    758, 
                    758
                ], 
                [
                    757, 
                    757
                ], 
                [
                    756, 
                    756
                ], 
                [
                    755, 
                    755
                ], 
                [
                    754, 
                    754
                ], 
                [
                    753, 
                    753
                ], 
                [
                    752, 
                    752
                ], 
                [
                    751, 
                    751
                ], 
                [
                    750, 
                    750
                ], 
                [
                    749, 
                    749
                ], 
                [
                    748, 
                    748
                ], 
                [
                    747, 
                    747
                ], 
                [
                    746, 
                    746
                ], 
                [
                    745, 
                    745
                ], 
                [
                    744, 
                    744
                ], 
                [
                    743, 
                    743
                ], 
                [
                    742, 
                    742
                ], 
                [
                    741, 
                    741
                ], 
                [
                    740, 
                    740
                ], 
                [
                    739, 
                    739
                ], 
                [
                    738, 
                    738
                ], 
                [
                    737, 
                    737
                ], 
                [
                    736, 
                    736
                ], 
                [
                    735, 
                    735
                ], 
                [
                    734, 
                    734
                ], 
                [
                    733, 
                    733
                ], 
                [
                    732, 
                    732
                ], 
                [
                    731, 
                    731
                ], 
                [
                    730, 
                    730
                ], 
                [
                    729, 
                    729
                ], 
                [
                    728, 
                    728
                ], 
                [
                    727, 
                    727
                ], 
                [
                    726, 
                    726
                ], 
                [
                    725, 
                    725
                ], 
                [
                    724, 
                    724
                ], 
                [
                    723, 
                    723
                ], 
                [
                    722, 
                    722
                ], 
                [
                    721, 
                    721
                ], 
                [
                    720, 
                    720
                ], 
                [
                    719, 
                    719
                ], 
                [
                    718, 
                    718
                ], 
                [
                    717, 
                    717
                ], 
                [
                    716, 
                    716
                ], 
                [
                    715, 
                    715
                ], 
                [
                    714, 
                    714
                ], 
                [
                    713, 
                    713
                ], 
                [
                    712, 
                    712
                ], 
                [
                    711, 
                    711
                ], 
                [
                    710, 
                    710
                ], 
                [
                    709, 
                    709
                ], 
                [
                    708, 
                    708
                ], 
                [
                    707, 
                    707
                ], 
                [
                    706, 
                    706
                ], 
                [
                    705, 
                    705
                ], 
                [
                    704, 
                    704
                ], 
                [
                    703, 
                    703
                ], 
                [
                    702, 
                    702
                ], 
                [
                    701, 
                    701
                ], 
                [
                    700, 
                    700
                ], 
                [
                    699, 
                    699
                ], 
                [
                    698, 
                    698
                ], 
                [
                    697, 
                    697
                ], 
                [
                    696, 
                    696
                ], 
                [
                    695, 
                    695
                ], 
                [
                    694, 
                    694
                ], 
                [
                    693, 
                    693
                ], 
                [
                    692, 
                    692
                ], 
                [
                    691, 
                    691
                ], 
                [
                    690, 
                    690
                ], 
                [
                    689, 
                    689
                ], 
                [
                    688, 
                    688
                ], 
                [
                    687, 
                    687
                ], 
                [
                    686, 
                    686
                ], 
                [
                    685, 
                    685
                ], 
                [
                    684, 
                    684
                ], 
                [
                    683, 
                    683
                ], 
                [
                    682, 
                    682
                ], 
                [
                    681, 
                    681
                ], 
                [
                    680, 
                    680
                ], 
                [
                    679, 
                    679
                ], 
                [
                    678, 
                    678
                ], 
                [
                    677, 
                    677
                ], 
                [
                    676, 
                    676
                ], 
                [
                    675, 
                    675
                ], 
                [
                    674, 
                    674
                ], 
                [
                    673, 
                    673
                ], 
                [
                    672, 
                    672
                ], 
                [
                    671, 
                    671
                ], 
                [
                    670, 
                    670
                ], 
                [
                    669, 
                    669
                ], 
                [
                    668, 
                    668
                ], 
                [
                    667, 
                    667
                ], 
                [
                    666, 
                    666
                ], 
                [
                    665, 
                    665
                ], 
                [
                    664, 
                    664
                ], 
                [
                    663, 
                    663
                ], 
                [
                    662, 
                    662
                ], 
                [
                    661, 
                    661
                ], 
                [
                    660, 
                    660
                ], 
                [
                    659, 
                    659
                ], 
                [
                    658, 
                    658
                ], 
                [
                    657, 
                    657
                ], 
                [
                    656, 
                    656
                ], 
                [
                    655, 
                    655
                ], 
                [
                    654, 
                    654
                ], 
                [
                    653, 
                    653
                ], 
                [
                    652, 
                    652
                ], 
                [
                    651, 
                    651
                ], 
                [
                    650, 
                    650
                ], 
                [
                    649, 
                    649
                ], 
                [
                    648, 
                    648
                ], 
                [
                    647, 
                    647
                ], 
                [
                    646, 
                    646
                ], 
                [
                    645, 
                    645
                ], 
                [
                    644, 
                    644
                ], 
                [
                    643, 
                    643
                ], 
                [
                    642, 
                    642
                ], 
                [
                    641, 
                    641
                ], 
                [
                    640, 
                    640
                ], 
                [
                    639, 
                    639
                ], 
                [
                    638, 
                    638
                ], 
                [
                    637, 
                    637
                ], 
                [
                    636, 
                    636
                ], 
                [
                    635, 
                    635
                ], 
                [
                    634, 
                    634
                ], 
                [
                    633, 
                    633
                ], 
                [
                    632, 
                    632
                ], 
                [
                    631, 
                    631
                ], 
                [
                    630, 
                    630
                ], 
                [
                    629, 
                    629
                ], 
                [
                    628, 
                    628
                ], 
                [
                    627, 
                    627
                ], 
                [
                    626, 
                    626
                ], 
                [
                    625, 
                    625
                ], 
                [
                    624, 
                    624
                ], 
                [
                    623, 
                    623
                ], 
                [
                    622, 
                    622
                ], 
                [
                    621, 
                    621
                ], 
                [
                    620, 
                    620
                ], 
                [
                    619, 
                    619
                ], 
                [
                    618, 
                    618
                ], 
                [
                    617, 
                    617
                ], 
                [
                    616, 
                    616
                ], 
                [
                    615, 
                    615
                ], 
                [
                    614, 
                    614
                ], 
                [
                    613, 
                    613
                ], 
                [
                    612, 
                    612
                ], 
                [
                    611, 
                    611
                ], 
                [
                    610, 
                    610
                ], 
                [
                    609, 
                    609
                ], 
                [
                    608, 
                    608
                ], 
                [
                    607, 
                    607
                ], 
                [
                    606, 
                    606
                ], 
                [
                    605, 
                    605
                ], 
                [
                    604, 
                    604
                ], 
                [
                    603, 
                    603
                ], 
                [
                    602, 
                    602
                ], 
                [
                    601, 
                    601
                ], 
                [
                    600, 
                    600
                ], 
                [
                    599, 
                    599
                ], 
                [
                    598, 
                    598
                ], 
                [
                    597, 
                    597
                ], 
                [
                    596, 
                    596
                ], 
                [
                    595, 
                    595
                ], 
                [
                    594, 
                    594
                ], 
                [
                    593, 
                    593
                ], 
                [
                    592, 
                    592
                ], 
                [
                    591, 
                    591
                ], 
                [
                    590, 
                    590
                ], 
                [
                    589, 
                    589
                ], 
                [
                    588, 
                    588
                ], 
                [
                    587, 
                    587
                ], 
                [
                    586, 
                    586
                ], 
                [
                    585, 
                    585
                ], 
                [
                    584, 
                    584
                ], 
                [
                    583, 
                    583
                ], 
                [
                    582, 
                    582
                ], 
                [
                    581, 
                    581
                ], 
                [
                    580, 
                    580
                ], 
                [
                    579, 
                    579
                ], 
                [
                    578, 
                    578
                ], 
                [
                    577, 
                    577
                ], 
                [
                    576, 
                    576
                ], 
                [
                    575, 
                    575
                ], 
                [
                    574, 
                    574
                ], 
                [
                    573, 
                    573
                ], 
                [
                    572, 
                    572
                ], 
                [
                    571, 
                    571
                ], 
                [
                    570, 
                    570
                ], 
                [
                    569, 
                    569
                ], 
                [
                    568, 
                    568
                ], 
                [
                    567, 
                    567
                ], 
                [
                    566, 
                    566
                ], 
                [
                    565, 
                    565
                ], 
                [
                    564, 
                    564
                ], 
                [
                    563, 
                    563
                ], 
                [
                    562, 
                    562
                ], 
                [
                    561, 
                    561
                ], 
                [
                    560, 
                    560
                ], 
                [
                    559, 
                    559
                ], 
                [
                    558, 
                    558
                ], 
                [
                    557, 
                    557
                ], 
                [
                    556, 
                    556
                ], 
                [
                    555, 
                    555
                ], 
                [
                    554, 
                    554
                ], 
                [
                    553, 
                    553
                ], 
                [
                    552, 
                    552
                ], 
                [
                    551, 
                    551
                ], 
                [
                    550, 
                    550
                ], 
                [
                    549, 
                    549
                ], 
                [
                    548, 
                    548
                ], 
                [
                    547, 
                    547
                ], 
                [
                    546, 
                    546
                ], 
                [
                    545, 
                    545
                ], 
                [
                    544, 
                    544
                ], 
                [
                    543, 
                    543
                ], 
                [
                    542, 
                    542
                ], 
                [
                    541, 
                    541
                ], 
                [
                    540, 
                    540
                ], 
                [
                    539, 
                    539
                ], 
                [
                    538, 
                    538
                ], 
                [
                    537, 
                    537
                ], 
                [
                    536, 
                    536
                ], 
                [
                    535, 
                    535
                ], 
                [
                    534, 
                    534
                ], 
                [
                    533, 
                    533
                ], 
                [
                    532, 
                    532
                ], 
                [
                    531, 
                    531
                ], 
                [
                    530, 
                    530
                ], 
                [
                    529, 
                    529
                ], 
                [
                    528, 
                    528
                ], 
                [
                    527, 
                    527
                ], 
                [
                    526, 
                    526
                ], 
                [
                    525, 
                    525
                ], 
                [
                    524, 
                    524
                ], 
                [
                    523, 
                    523
                ], 
                [
                    522, 
                    522
                ], 
                [
                    521, 
                    521
                ], 
                [
                    520, 
                    520
                ], 
                [
                    519, 
                    519
                ], 
                [
                    518, 
                    518
                ], 
                [
                    517, 
                    517
                ], 
                [
                    516, 
                    516
                ], 
                [
                    515, 
                    515
                ], 
                [
                    514, 
                    514
                ], 
                [
                    513, 
                    513
                ], 
                [
                    512, 
                    512
                ], 
                [
                    511, 
                    511
                ], 
                [
                    510, 
                    510
                ], 
                [
                    509, 
                    509
                ], 
                [
                    508, 
                    508
                ], 
                [
                    507, 
                    507
                ], 
                [
                    506, 
                    506
                ], 
                [
                    505, 
                    505
                ], 
                [
                    504, 
                    504
                ], 
                [
                    503, 
                    503
                ], 
                [
                    502, 
                    502
                ], 
                [
                    501, 
                    501
                ], 
                [
                    500, 
                    500
                ], 
                [
                    499, 
                    499
                ], 
                [
                    498, 
                    498
                ], 
                [
                    497, 
                    497
                ], 
                [
                    496, 
                    496
                ], 
                [
                    495, 
                    495
                ], 
                [
                    494, 
                    494
                ], 
                [
                    493, 
                    493
                ], 
                [
                    492, 
                    492
                ], 
                [
                    491, 
                    491
                ], 
                [
                    490, 
                    490
                ], 
                [
                    489, 
                    489
                ], 
                [
                    488, 
                    488
                ], 
                [
                    487, 
                    487
                ], 
                [
                    486, 
                    486
                ], 
                [
                    485, 
                    485
                ], 
                [
                    484, 
                    484
                ], 
                [
                    483, 
                    483
                ], 
                [
                    482, 
                    482
                ], 
                [
                    481, 
                    481
                ], 
                [
                    480, 
                    480
                ], 
                [
                    479, 
                    479
                ], 
                [
                    478, 
                    478
                ], 
                [
                    477, 
                    477
                ], 
                [
                    476, 
                    476
                ], 
                [
                    475, 
                    475
                ], 
                [
                    474, 
                    474
                ], 
                [
                    473, 
                    473
                ], 
                [
                    472, 
                    472
                ], 
                [
                    471, 
                    471
                ], 
                [
                    470, 
                    470
                ], 
                [
                    469, 
                    469
                ], 
                [
                    468, 
                    468
                ], 
                [
                    467, 
                    467
                ], 
                [
                    466, 
                    466
                ], 
                [
                    465, 
                    465
                ], 
                [
                    464, 
                    464
                ], 
                [
                    463, 
                    463
                ], 
                [
                    462, 
                    462
                ], 
                [
                    461, 
                    461
                ], 
                [
                    460, 
                    460
                ], 
                [
                    459, 
                    459
                ], 
                [
                    458, 
                    458
                ], 
                [
                    457, 
                    457
                ], 
                [
                    456, 
                    456
                ], 
                [
                    455, 
                    455
                ], 
                [
                    454, 
                    454
                ], 
                [
                    453, 
                    453
                ], 
                [
                    452, 
                    452
                ], 
                [
                    451, 
                    451
                ], 
                [
                    450, 
                    450
                ], 
                [
                    449, 
                    449
                ], 
                [
                    448, 
                    448
                ], 
                [
                    447, 
                    447
                ], 
                [
                    446, 
                    446
                ], 
                [
                    445, 
                    445
                ], 
                [
                    444, 
                    444
                ], 
                [
                    443, 
                    443
                ], 
                [
                    442, 
                    442
                ], 
                [
                    441, 
                    441
                ], 
                [
                    440, 
                    440
                ], 
                [
                    439, 
                    439
                ], 
                [
                    438, 
                    438
                ], 
                [
                    437, 
                    437
                ], 
                [
                    436, 
                    436
                ], 
                [
                    435, 
                    435
                ], 
                [
                    434, 
                    434
                ], 
                [
                    433, 
                    433
                ], 
                [
                    432, 
                    432
                ], 
                [
                    431, 
                    431
                ], 
                [
                    430, 
                    430
                ], 
                [
                    429, 
                    429
                ], 
                [
                    428, 
                    428
                ], 
                [
                    427, 
                    427
                ], 
                [
                    426, 
                    426
                ], 
                [
                    425, 
                    425
                ], 
                [
                    424, 
                    424
                ], 
                [
                    423, 
                    423
                ], 
                [
                    422, 
                    422
                ], 
                [
                    421, 
                    421
                ], 
                [
                    420, 
                    420
                ], 
                [
                    419, 
                    419
                ], 
                [
                    418, 
                    418
                ], 
                [
                    417, 
                    417
                ], 
                [
                    416, 
                    416
                ], 
                [
                    415, 
                    415
                ], 
                [
                    414, 
                    414
                ], 
                [
                    413, 
                    413
                ], 
                [
                    412, 
                    412
                ], 
                [
                    411, 
                    411
                ], 
                [
                    410, 
                    410
                ], 
                [
                    409, 
                    409
                ], 
                [
                    408, 
                    408
                ], 
                [
                    407, 
                    407
                ], 
                [
                    406, 
                    406
                ], 
                [
                    405, 
                    405
                ], 
                [
                    404, 
                    404
                ], 
                [
                    403, 
                    403
                ], 
                [
                    402, 
                    402
                ], 
                [
                    401, 
                    401
                ], 
                [
                    400, 
                    400
                ]
            ], 
            z: [
                [
                    0.8931178, 
                    0.8931178
                ], 
                [
                    0.900841433, 
                    0.900841433
                ], 
                [
                    0.884316855, 
                    0.884316855
                ], 
                [
                    0.923703907, 
                    0.923703907
                ], 
                [
                    0.902208345, 
                    0.902208345
                ], 
                [
                    0.903870649, 
                    0.903870649
                ], 
                [
                    0.905416631, 
                    0.905416631
                ], 
                [
                    0.908972919, 
                    0.908972919
                ], 
                [
                    0.908749444, 
                    0.908749444
                ], 
                [
                    0.907831412, 
                    0.907831412
                ], 
                [
                    0.90837586, 
                    0.90837586
                ], 
                [
                    0.910509247, 
                    0.910509247
                ], 
                [
                    0.913082826, 
                    0.913082826
                ], 
                [
                    0.918597776, 
                    0.918597776
                ], 
                [
                    0.919249376, 
                    0.919249376
                ], 
                [
                    0.921171355, 
                    0.921171355
                ], 
                [
                    0.92185819, 
                    0.92185819
                ], 
                [
                    0.923222206, 
                    0.923222206
                ], 
                [
                    0.926658793, 
                    0.926658793
                ], 
                [
                    0.929734345, 
                    0.929734345
                ], 
                [
                    0.930661065, 
                    0.930661065
                ], 
                [
                    0.932268345, 
                    0.932268345
                ], 
                [
                    0.932020737, 
                    0.932020737
                ], 
                [
                    0.93679962, 
                    0.93679962
                ], 
                [
                    0.937206991, 
                    0.937206991
                ], 
                [
                    0.94159588, 
                    0.94159588
                ], 
                [
                    0.943415533, 
                    0.943415533
                ], 
                [
                    0.947219429, 
                    0.947219429
                ], 
                [
                    0.951266107, 
                    0.951266107
                ], 
                [
                    0.954963334, 
                    0.954963334
                ], 
                [
                    0.956959644, 
                    0.956959644
                ], 
                [
                    0.959478681, 
                    0.959478681
                ], 
                [
                    0.962212988, 
                    0.962212988
                ], 
                [
                    0.966495207, 
                    0.966495207
                ], 
                [
                    0.966338341, 
                    0.966338341
                ], 
                [
                    0.970527405, 
                    0.970527405
                ], 
                [
                    0.972224944, 
                    0.972224944
                ], 
                [
                    0.974857408, 
                    0.974857408
                ], 
                [
                    0.974350608, 
                    0.974350608
                ], 
                [
                    0.976645206, 
                    0.976645206
                ], 
                [
                    0.980493025, 
                    0.980493025
                ], 
                [
                    0.980674507, 
                    0.980674507
                ], 
                [
                    0.980571217, 
                    0.980571217
                ], 
                [
                    0.98220987, 
                    0.98220987
                ], 
                [
                    0.985942815, 
                    0.985942815
                ], 
                [
                    0.98758002, 
                    0.98758002
                ], 
                [
                    0.988616306, 
                    0.988616306
                ], 
                [
                    0.991966495, 
                    0.991966495
                ], 
                [
                    0.995156922, 
                    0.995156922
                ], 
                [
                    0.996388205, 
                    0.996388205
                ], 
                [
                    1, 
                    1
                ], 
                [
                    1.005215214, 
                    1.005215214
                ], 
                [
                    1.008593398, 
                    1.008593398
                ], 
                [
                    1.007897393, 
                    1.007897393
                ], 
                [
                    1.011088302, 
                    1.011088302
                ], 
                [
                    1.012819145, 
                    1.012819145
                ], 
                [
                    1.015261922, 
                    1.015261922
                ], 
                [
                    1.017770823, 
                    1.017770823
                ], 
                [
                    1.018316236, 
                    1.018316236
                ], 
                [
                    1.021264365, 
                    1.021264365
                ], 
                [
                    1.021683802, 
                    1.021683802
                ], 
                [
                    1.025575061, 
                    1.025575061
                ], 
                [
                    1.025684627, 
                    1.025684627
                ], 
                [
                    1.026560184, 
                    1.026560184
                ], 
                [
                    1.025938992, 
                    1.025938992
                ], 
                [
                    1.028685848, 
                    1.028685848
                ], 
                [
                    1.031004096, 
                    1.031004096
                ], 
                [
                    1.033603257, 
                    1.033603257
                ], 
                [
                    1.033566091, 
                    1.033566091
                ], 
                [
                    1.036666742, 
                    1.036666742
                ], 
                [
                    1.036712113, 
                    1.036712113
                ], 
                [
                    1.040701353, 
                    1.040701353
                ], 
                [
                    1.043288447, 
                    1.043288447
                ], 
                [
                    1.045040527, 
                    1.045040527
                ], 
                [
                    1.046603885, 
                    1.046603885
                ], 
                [
                    1.047536397, 
                    1.047536397
                ], 
                [
                    1.051299749, 
                    1.051299749
                ], 
                [
                    1.053710669, 
                    1.053710669
                ], 
                [
                    1.056610048, 
                    1.056610048
                ], 
                [
                    1.058889683, 
                    1.058889683
                ], 
                [
                    1.060964185, 
                    1.060964185
                ], 
                [
                    1.064704852, 
                    1.064704852
                ], 
                [
                    1.069749684, 
                    1.069749684
                ], 
                [
                    1.07402225, 
                    1.07402225
                ], 
                [
                    1.07816884, 
                    1.07816884
                ], 
                [
                    1.080168045, 
                    1.080168045
                ], 
                [
                    1.083175542, 
                    1.083175542
                ], 
                [
                    1.085129859, 
                    1.085129859
                ], 
                [
                    1.088659601, 
                    1.088659601
                ], 
                [
                    1.091119753, 
                    1.091119753
                ], 
                [
                    1.093029183, 
                    1.093029183
                ], 
                [
                    1.096626498, 
                    1.096626498
                ], 
                [
                    1.100149482, 
                    1.100149482
                ], 
                [
                    1.104730472, 
                    1.104730472
                ], 
                [
                    1.11038636, 
                    1.11038636
                ], 
                [
                    1.114696091, 
                    1.114696091
                ], 
                [
                    1.119778572, 
                    1.119778572
                ], 
                [
                    1.127075045, 
                    1.127075045
                ], 
                [
                    1.132064853, 
                    1.132064853
                ], 
                [
                    1.136636672, 
                    1.136636672
                ], 
                [
                    1.143496331, 
                    1.143496331
                ], 
                [
                    1.148750641, 
                    1.148750641
                ], 
                [
                    1.154478447, 
                    1.154478447
                ], 
                [
                    1.159107221, 
                    1.159107221
                ], 
                [
                    1.165091323, 
                    1.165091323
                ], 
                [
                    1.171379988, 
                    1.171379988
                ], 
                [
                    1.174530836, 
                    1.174530836
                ], 
                [
                    1.180950786, 
                    1.180950786
                ], 
                [
                    1.187146296, 
                    1.187146296
                ], 
                [
                    1.188732339, 
                    1.188732339
                ], 
                [
                    1.190967568, 
                    1.190967568
                ], 
                [
                    1.191860502, 
                    1.191860502
                ], 
                [
                    1.19356528, 
                    1.19356528
                ], 
                [
                    1.1936956, 
                    1.1936956
                ], 
                [
                    1.191328603, 
                    1.191328603
                ], 
                [
                    1.191062654, 
                    1.191062654
                ], 
                [
                    1.188523827, 
                    1.188523827
                ], 
                [
                    1.18183117, 
                    1.18183117
                ], 
                [
                    1.177672514, 
                    1.177672514
                ], 
                [
                    1.172201486, 
                    1.172201486
                ], 
                [
                    1.166527256, 
                    1.166527256
                ], 
                [
                    1.159085984, 
                    1.159085984
                ], 
                [
                    1.151013866, 
                    1.151013866
                ], 
                [
                    1.146939193, 
                    1.146939193
                ], 
                [
                    1.140794846, 
                    1.140794846
                ], 
                [
                    1.136661771, 
                    1.136661771
                ], 
                [
                    1.133734397, 
                    1.133734397
                ], 
                [
                    1.128049549, 
                    1.128049549
                ], 
                [
                    1.123102697, 
                    1.123102697
                ], 
                [
                    1.117600297, 
                    1.117600297
                ], 
                [
                    1.115122769, 
                    1.115122769
                ], 
                [
                    1.112824792, 
                    1.112824792
                ], 
                [
                    1.110098691, 
                    1.110098691
                ], 
                [
                    1.108648277, 
                    1.108648277
                ], 
                [
                    1.106231082, 
                    1.106231082
                ], 
                [
                    1.104054738, 
                    1.104054738
                ], 
                [
                    1.106712301, 
                    1.106712301
                ], 
                [
                    1.109406547, 
                    1.109406547
                ], 
                [
                    1.114122683, 
                    1.114122683
                ], 
                [
                    1.116749356, 
                    1.116749356
                ], 
                [
                    1.123258116, 
                    1.123258116
                ], 
                [
                    1.128443405, 
                    1.128443405
                ], 
                [
                    1.133411493, 
                    1.133411493
                ], 
                [
                    1.140595022, 
                    1.140595022
                ], 
                [
                    1.148859241, 
                    1.148859241
                ], 
                [
                    1.153839397, 
                    1.153839397
                ], 
                [
                    1.159397304, 
                    1.159397304
                ], 
                [
                    1.162890845, 
                    1.162890845
                ], 
                [
                    1.166936558, 
                    1.166936558
                ], 
                [
                    1.170219657, 
                    1.170219657
                ], 
                [
                    1.176074404, 
                    1.176074404
                ], 
                [
                    1.18197597, 
                    1.18197597
                ], 
                [
                    1.183967935, 
                    1.183967935
                ], 
                [
                    1.187820581, 
                    1.187820581
                ], 
                [
                    1.1918856, 
                    1.1918856
                ], 
                [
                    1.196958427, 
                    1.196958427
                ], 
                [
                    1.202008569, 
                    1.202008569
                ], 
                [
                    1.206814964, 
                    1.206814964
                ], 
                [
                    1.212442858, 
                    1.212442858
                ], 
                [
                    1.212936626, 
                    1.212936626
                ], 
                [
                    1.212536496, 
                    1.212536496
                ], 
                [
                    1.214553077, 
                    1.214553077
                ], 
                [
                    1.215713891, 
                    1.215713891
                ], 
                [
                    1.217256011, 
                    1.217256011
                ], 
                [
                    1.216301296, 
                    1.216301296
                ], 
                [
                    1.216538285, 
                    1.216538285
                ], 
                [
                    1.216538768, 
                    1.216538768
                ], 
                [
                    1.213189061, 
                    1.213189061
                ], 
                [
                    1.212730528, 
                    1.212730528
                ], 
                [
                    1.211481869, 
                    1.211481869
                ], 
                [
                    1.210136194, 
                    1.210136194
                ], 
                [
                    1.206518607, 
                    1.206518607
                ], 
                [
                    1.201101156, 
                    1.201101156
                ], 
                [
                    1.198134204, 
                    1.198134204
                ], 
                [
                    1.194293625, 
                    1.194293625
                ], 
                [
                    1.190727683, 
                    1.190727683
                ], 
                [
                    1.188005443, 
                    1.188005443
                ], 
                [
                    1.183346261, 
                    1.183346261
                ], 
                [
                    1.179654343, 
                    1.179654343
                ], 
                [
                    1.176792612, 
                    1.176792612
                ], 
                [
                    1.174866289, 
                    1.174866289
                ], 
                [
                    1.175330132, 
                    1.175330132
                ], 
                [
                    1.172553833, 
                    1.172553833
                ], 
                [
                    1.171388676, 
                    1.171388676
                ], 
                [
                    1.167768193, 
                    1.167768193
                ], 
                [
                    1.166106854, 
                    1.166106854
                ], 
                [
                    1.166901323, 
                    1.166901323
                ], 
                [
                    1.166412382, 
                    1.166412382
                ], 
                [
                    1.165304179, 
                    1.165304179
                ], 
                [
                    1.163595539, 
                    1.163595539
                ], 
                [
                    1.16394692, 
                    1.16394692
                ], 
                [
                    1.165105803, 
                    1.165105803
                ], 
                [
                    1.164543014, 
                    1.164543014
                ], 
                [
                    1.168572798, 
                    1.168572798
                ], 
                [
                    1.169550198, 
                    1.169550198
                ], 
                [
                    1.167505622, 
                    1.167505622
                ], 
                [
                    1.168287059, 
                    1.168287059
                ], 
                [
                    1.169047742, 
                    1.169047742
                ], 
                [
                    1.171440321, 
                    1.171440321
                ], 
                [
                    1.172563969, 
                    1.172563969
                ], 
                [
                    1.17504198, 
                    1.17504198
                ], 
                [
                    1.175196433, 
                    1.175196433
                ], 
                [
                    1.173916884, 
                    1.173916884
                ], 
                [
                    1.174756724, 
                    1.174756724
                ], 
                [
                    1.177355401, 
                    1.177355401
                ], 
                [
                    1.177733812, 
                    1.177733812
                ], 
                [
                    1.178014242, 
                    1.178014242
                ], 
                [
                    1.179816519, 
                    1.179816519
                ], 
                [
                    1.179508095, 
                    1.179508095
                ], 
                [
                    1.179302479, 
                    1.179302479
                ], 
                [
                    1.180365794, 
                    1.180365794
                ], 
                [
                    1.183030114, 
                    1.183030114
                ], 
                [
                    1.18512537, 
                    1.18512537
                ], 
                [
                    1.18625481, 
                    1.18625481
                ], 
                [
                    1.187922424, 
                    1.187922424
                ], 
                [
                    1.190412019, 
                    1.190412019
                ], 
                [
                    1.189596795, 
                    1.189596795
                ], 
                [
                    1.191037555, 
                    1.191037555
                ], 
                [
                    1.191051552, 
                    1.191051552
                ], 
                [
                    1.192914646, 
                    1.192914646
                ], 
                [
                    1.193057515, 
                    1.193057515
                ], 
                [
                    1.19336063, 
                    1.19336063
                ], 
                [
                    1.193182526, 
                    1.193182526
                ], 
                [
                    1.192475902, 
                    1.192475902
                ], 
                [
                    1.194155582, 
                    1.194155582
                ], 
                [
                    1.197097918, 
                    1.197097918
                ], 
                [
                    1.199406996, 
                    1.199406996
                ], 
                [
                    1.202566532, 
                    1.202566532
                ], 
                [
                    1.204628484, 
                    1.204628484
                ], 
                [
                    1.206362706, 
                    1.206362706
                ], 
                [
                    1.208371565, 
                    1.208371565
                ], 
                [
                    1.213257117, 
                    1.213257117
                ], 
                [
                    1.219785184, 
                    1.219785184
                ], 
                [
                    1.222000142, 
                    1.222000142
                ], 
                [
                    1.224093467, 
                    1.224093467
                ], 
                [
                    1.224992676, 
                    1.224992676
                ], 
                [
                    1.227501577, 
                    1.227501577
                ], 
                [
                    1.231275065, 
                    1.231275065
                ], 
                [
                    1.23432938, 
                    1.23432938
                ], 
                [
                    1.237973031, 
                    1.237973031
                ], 
                [
                    1.239682154, 
                    1.239682154
                ], 
                [
                    1.242048187, 
                    1.242048187
                ], 
                [
                    1.246345851, 
                    1.246345851
                ], 
                [
                    1.249888142, 
                    1.249888142
                ], 
                [
                    1.255203751, 
                    1.255203751
                ], 
                [
                    1.259048674, 
                    1.259048674
                ], 
                [
                    1.26325077, 
                    1.26325077
                ], 
                [
                    1.269035531, 
                    1.269035531
                ], 
                [
                    1.27409436, 
                    1.27409436
                ], 
                [
                    1.27934867, 
                    1.27934867
                ], 
                [
                    1.282991839, 
                    1.282991839
                ], 
                [
                    1.286943914, 
                    1.286943914
                ], 
                [
                    1.291864701, 
                    1.291864701
                ], 
                [
                    1.295286325, 
                    1.295286325
                ], 
                [
                    1.298896672, 
                    1.298896672
                ], 
                [
                    1.301826942, 
                    1.301826942
                ], 
                [
                    1.304581038, 
                    1.304581038
                ], 
                [
                    1.307811526, 
                    1.307811526
                ], 
                [
                    1.310975407, 
                    1.310975407
                ], 
                [
                    1.315373466, 
                    1.315373466
                ], 
                [
                    1.319112685, 
                    1.319112685
                ], 
                [
                    1.323110613, 
                    1.323110613
                ], 
                [
                    1.32633724, 
                    1.32633724
                ], 
                [
                    1.329657987, 
                    1.329657987
                ], 
                [
                    1.334502513, 
                    1.334502513
                ], 
                [
                    1.337473809, 
                    1.337473809
                ], 
                [
                    1.342499335, 
                    1.342499335
                ], 
                [
                    1.345574887, 
                    1.345574887
                ], 
                [
                    1.347886861, 
                    1.347886861
                ], 
                [
                    1.348866192, 
                    1.348866192
                ], 
                [
                    1.351409363, 
                    1.351409363
                ], 
                [
                    1.356950377, 
                    1.356950377
                ], 
                [
                    1.362145801, 
                    1.362145801
                ], 
                [
                    1.364879143, 
                    1.364879143
                ], 
                [
                    1.368400196, 
                    1.368400196
                ], 
                [
                    1.370831871, 
                    1.370831871
                ], 
                [
                    1.375305709, 
                    1.375305709
                ], 
                [
                    1.3796806, 
                    1.3796806
                ], 
                [
                    1.386228939, 
                    1.386228939
                ], 
                [
                    1.389972985, 
                    1.389972985
                ], 
                [
                    1.390807516, 
                    1.390807516
                ], 
                [
                    1.394138882, 
                    1.394138882
                ], 
                [
                    1.396980341, 
                    1.396980341
                ], 
                [
                    1.400613373, 
                    1.400613373
                ], 
                [
                    1.402210517, 
                    1.402210517
                ], 
                [
                    1.404791819, 
                    1.404791819
                ], 
                [
                    1.407282379, 
                    1.407282379
                ], 
                [
                    1.409156574, 
                    1.409156574
                ], 
                [
                    1.410385443, 
                    1.410385443
                ], 
                [
                    1.413714878, 
                    1.413714878
                ], 
                [
                    1.416454012, 
                    1.416454012
                ], 
                [
                    1.418125004, 
                    1.418125004
                ], 
                [
                    1.419848607, 
                    1.419848607
                ], 
                [
                    1.422981114, 
                    1.422981114
                ], 
                [
                    1.426439903, 
                    1.426439903
                ], 
                [
                    1.431133355, 
                    1.431133355
                ], 
                [
                    1.433783195, 
                    1.433783195
                ], 
                [
                    1.43573172, 
                    1.43573172
                ], 
                [
                    1.437542686, 
                    1.437542686
                ], 
                [
                    1.437645011, 
                    1.437645011
                ], 
                [
                    1.440169841, 
                    1.440169841
                ], 
                [
                    1.442372732, 
                    1.442372732
                ], 
                [
                    1.442107265, 
                    1.442107265
                ], 
                [
                    1.443216916, 
                    1.443216916
                ], 
                [
                    1.444283127, 
                    1.444283127
                ], 
                [
                    1.445780359, 
                    1.445780359
                ], 
                [
                    1.449243975, 
                    1.449243975
                ], 
                [
                    1.452570031, 
                    1.452570031
                ], 
                [
                    1.455079416, 
                    1.455079416
                ], 
                [
                    1.457329125, 
                    1.457329125
                ], 
                [
                    1.45965944, 
                    1.45965944
                ], 
                [
                    1.464359648, 
                    1.464359648
                ], 
                [
                    1.465916249, 
                    1.465916249
                ], 
                [
                    1.467891803, 
                    1.467891803
                ], 
                [
                    1.468271662, 
                    1.468271662
                ], 
                [
                    1.470773324, 
                    1.470773324
                ], 
                [
                    1.472439007, 
                    1.472439007
                ], 
                [
                    1.475649223, 
                    1.475649223
                ], 
                [
                    1.478384013, 
                    1.478384013
                ], 
                [
                    1.480555047, 
                    1.480555047
                ], 
                [
                    1.482374218, 
                    1.482374218
                ], 
                [
                    1.484663989, 
                    1.484663989
                ], 
                [
                    1.486307469, 
                    1.486307469
                ], 
                [
                    1.491607633, 
                    1.491607633
                ], 
                [
                    1.495785113, 
                    1.495785113
                ], 
                [
                    1.499245833, 
                    1.499245833
                ], 
                [
                    1.500877729, 
                    1.500877729
                ], 
                [
                    1.503489922, 
                    1.503489922
                ], 
                [
                    1.506827079, 
                    1.506827079
                ], 
                [
                    1.508733613, 
                    1.508733613
                ], 
                [
                    1.511047034, 
                    1.511047034
                ], 
                [
                    1.513796787, 
                    1.513796787
                ], 
                [
                    1.515977475, 
                    1.515977475
                ], 
                [
                    1.516996384, 
                    1.516996384
                ], 
                [
                    1.519450745, 
                    1.519450745
                ], 
                [
                    1.522209668, 
                    1.522209668
                ], 
                [
                    1.526956695, 
                    1.526956695
                ], 
                [
                    1.52977933, 
                    1.52977933
                ], 
                [
                    1.534461197, 
                    1.534461197
                ], 
                [
                    1.537124552, 
                    1.537124552
                ], 
                [
                    1.541484963, 
                    1.541484963
                ], 
                [
                    1.545038838, 
                    1.545038838
                ], 
                [
                    1.548144315, 
                    1.548144315
                ], 
                [
                    1.551810652, 
                    1.551810652
                ], 
                [
                    1.554361545, 
                    1.554361545
                ], 
                [
                    1.554524204, 
                    1.554524204
                ], 
                [
                    1.557007042, 
                    1.557007042
                ], 
                [
                    1.560138101, 
                    1.560138101
                ], 
                [
                    1.56086017, 
                    1.56086017
                ], 
                [
                    1.560782943, 
                    1.560782943
                ], 
                [
                    1.559375487, 
                    1.559375487
                ], 
                [
                    1.559067546, 
                    1.559067546
                ], 
                [
                    1.555741007, 
                    1.555741007
                ], 
                [
                    1.555250617, 
                    1.555250617
                ], 
                [
                    1.553413105, 
                    1.553413105
                ], 
                [
                    1.548555547, 
                    1.548555547
                ], 
                [
                    1.54133968, 
                    1.54133968
                ], 
                [
                    1.539927397, 
                    1.539927397
                ], 
                [
                    1.534853122, 
                    1.534853122
                ], 
                [
                    1.530896703, 
                    1.530896703
                ], 
                [
                    1.525869247, 
                    1.525869247
                ], 
                [
                    1.521890625, 
                    1.521890625
                ], 
                [
                    1.518482515, 
                    1.518482515
                ], 
                [
                    1.515023725, 
                    1.515023725
                ], 
                [
                    1.514746675, 
                    1.514746675
                ], 
                [
                    1.513908765, 
                    1.513908765
                ], 
                [
                    1.510268976, 
                    1.510268976
                ], 
                [
                    1.50990408, 
                    1.50990408
                ], 
                [
                    1.507115231, 
                    1.507115231
                ], 
                [
                    1.507294783, 
                    1.507294783
                ], 
                [
                    1.509792584, 
                    1.509792584
                ], 
                [
                    1.510154584, 
                    1.510154584
                ], 
                [
                    1.511576037, 
                    1.511576037
                ], 
                [
                    1.512269629, 
                    1.512269629
                ], 
                [
                    1.513870635, 
                    1.513870635
                ], 
                [
                    1.516133376, 
                    1.516133376
                ], 
                [
                    1.515446059, 
                    1.515446059
                ], 
                [
                    1.518645174, 
                    1.518645174
                ], 
                [
                    1.518826174, 
                    1.518826174
                ], 
                [
                    1.518609939, 
                    1.518609939
                ], 
                [
                    1.517565448, 
                    1.517565448
                ], 
                [
                    1.516931224, 
                    1.516931224
                ], 
                [
                    1.517275848, 
                    1.517275848
                ], 
                [
                    1.517856496, 
                    1.517856496
                ], 
                [
                    1.518516784, 
                    1.518516784
                ], 
                [
                    1.521200411, 
                    1.521200411
                ], 
                [
                    1.52189159, 
                    1.52189159
                ], 
                [
                    1.525103254, 
                    1.525103254
                ], 
                [
                    1.526392457, 
                    1.526392457
                ], 
                [
                    1.52933962, 
                    1.52933962
                ], 
                [
                    1.53346449, 
                    1.53346449
                ], 
                [
                    1.534743557, 
                    1.534743557
                ], 
                [
                    1.537281901, 
                    1.537281901
                ], 
                [
                    1.538191245, 
                    1.538191245
                ], 
                [
                    1.541075661, 
                    1.541075661
                ], 
                [
                    1.542966267, 
                    1.542966267
                ], 
                [
                    1.546716587, 
                    1.546716587
                ], 
                [
                    1.548000843, 
                    1.548000843
                ], 
                [
                    1.549445378, 
                    1.549445378
                ], 
                [
                    1.549272549, 
                    1.549272549
                ], 
                [
                    1.551821174, 
                    1.551821174
                ]
            ], 
            cmax: 1.56086017, 
            cmin: 0.884316855, 
            colorscale: [
                [
                    "0", 
                    "rgb(93,93,255)"
                ], 
                [
                    "0.1", 
                    "rgb(93,93,255)"
                ], 
                [
                    "0.2", 
                    "rgb(93,93,255)"
                ], 
                [
                    "0.3", 
                    "rgb(93,93,255)"
                ], 
                [
                    "0.4", 
                    "rgb(93,93,255)"
                ], 
                [
                    "0.5", 
                    "rgb(93,93,255)"
                ], 
                [
                    "0.6", 
                    "rgb(93,93,255)"
                ], 
                [
                    "0.7", 
                    "rgb(93,93,255)"
                ], 
                [
                    "0.8", 
                    "rgb(93,93,255)"
                ], 
                [
                    "0.9", 
                    "rgb(93,93,255)"
                ], 
                [
                    "1", 
                    "rgb(93,93,255)"
                ]
            ], 
            name: "", 
            showscale: false, 
            type: "surface", 
            uid: "a53345", 
            xsrc: "Diksha_Gabha:2343:64b63e,9a4afb", 
            ysrc: "Diksha_Gabha:2343:807139,59d0b7", 
            zsrc: "Diksha_Gabha:2343:371610,1c40bd"
        }, 
        {
            x: [
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ], 
                [
                    8, 
                    9
                ]
            ], 
            y: [
                [
                    800, 
                    800
                ], 
                [
                    799, 
                    799
                ], 
                [
                    798, 
                    798
                ], 
                [
                    797, 
                    797
                ], 
                [
                    796, 
                    796
                ], 
                [
                    795, 
                    795
                ], 
                [
                    794, 
                    794
                ], 
                [
                    793, 
                    793
                ], 
                [
                    792, 
                    792
                ], 
                [
                    791, 
                    791
                ], 
                [
                    790, 
                    790
                ], 
                [
                    789, 
                    789
                ], 
                [
                    788, 
                    788
                ], 
                [
                    787, 
                    787
                ], 
                [
                    786, 
                    786
                ], 
                [
                    785, 
                    785
                ], 
                [
                    784, 
                    784
                ], 
                [
                    783, 
                    783
                ], 
                [
                    782, 
                    782
                ], 
                [
                    781, 
                    781
                ], 
                [
                    780, 
                    780
                ], 
                [
                    779, 
                    779
                ], 
                [
                    778, 
                    778
                ], 
                [
                    777, 
                    777
                ], 
                [
                    776, 
                    776
                ], 
                [
                    775, 
                    775
                ], 
                [
                    774, 
                    774
                ], 
                [
                    773, 
                    773
                ], 
                [
                    772, 
                    772
                ], 
                [
                    771, 
                    771
                ], 
                [
                    770, 
                    770
                ], 
                [
                    769, 
                    769
                ], 
                [
                    768, 
                    768
                ], 
                [
                    767, 
                    767
                ], 
                [
                    766, 
                    766
                ], 
                [
                    765, 
                    765
                ], 
                [
                    764, 
                    764
                ], 
                [
                    763, 
                    763
                ], 
                [
                    762, 
                    762
                ], 
                [
                    761, 
                    761
                ], 
                [
                    760, 
                    760
                ], 
                [
                    759, 
                    759
                ], 
                [
                    758, 
                    758
                ], 
                [
                    757, 
                    757
                ], 
                [
                    756, 
                    756
                ], 
                [
                    755, 
                    755
                ], 
                [
                    754, 
                    754
                ], 
                [
                    753, 
                    753
                ], 
                [
                    752, 
                    752
                ], 
                [
                    751, 
                    751
                ], 
                [
                    750, 
                    750
                ], 
                [
                    749, 
                    749
                ], 
                [
                    748, 
                    748
                ], 
                [
                    747, 
                    747
                ], 
                [
                    746, 
                    746
                ], 
                [
                    745, 
                    745
                ], 
                [
                    744, 
                    744
                ], 
                [
                    743, 
                    743
                ], 
                [
                    742, 
                    742
                ], 
                [
                    741, 
                    741
                ], 
                [
                    740, 
                    740
                ], 
                [
                    739, 
                    739
                ], 
                [
                    738, 
                    738
                ], 
                [
                    737, 
                    737
                ], 
                [
                    736, 
                    736
                ], 
                [
                    735, 
                    735
                ], 
                [
                    734, 
                    734
                ], 
                [
                    733, 
                    733
                ], 
                [
                    732, 
                    732
                ], 
                [
                    731, 
                    731
                ], 
                [
                    730, 
                    730
                ], 
                [
                    729, 
                    729
                ], 
                [
                    728, 
                    728
                ], 
                [
                    727, 
                    727
                ], 
                [
                    726, 
                    726
                ], 
                [
                    725, 
                    725
                ], 
                [
                    724, 
                    724
                ], 
                [
                    723, 
                    723
                ], 
                [
                    722, 
                    722
                ], 
                [
                    721, 
                    721
                ], 
                [
                    720, 
                    720
                ], 
                [
                    719, 
                    719
                ], 
                [
                    718, 
                    718
                ], 
                [
                    717, 
                    717
                ], 
                [
                    716, 
                    716
                ], 
                [
                    715, 
                    715
                ], 
                [
                    714, 
                    714
                ], 
                [
                    713, 
                    713
                ], 
                [
                    712, 
                    712
                ], 
                [
                    711, 
                    711
                ], 
                [
                    710, 
                    710
                ], 
                [
                    709, 
                    709
                ], 
                [
                    708, 
                    708
                ], 
                [
                    707, 
                    707
                ], 
                [
                    706, 
                    706
                ], 
                [
                    705, 
                    705
                ], 
                [
                    704, 
                    704
                ], 
                [
                    703, 
                    703
                ], 
                [
                    702, 
                    702
                ], 
                [
                    701, 
                    701
                ], 
                [
                    700, 
                    700
                ], 
                [
                    699, 
                    699
                ], 
                [
                    698, 
                    698
                ], 
                [
                    697, 
                    697
                ], 
                [
                    696, 
                    696
                ], 
                [
                    695, 
                    695
                ], 
                [
                    694, 
                    694
                ], 
                [
                    693, 
                    693
                ], 
                [
                    692, 
                    692
                ], 
                [
                    691, 
                    691
                ], 
                [
                    690, 
                    690
                ], 
                [
                    689, 
                    689
                ], 
                [
                    688, 
                    688
                ], 
                [
                    687, 
                    687
                ], 
                [
                    686, 
                    686
                ], 
                [
                    685, 
                    685
                ], 
                [
                    684, 
                    684
                ], 
                [
                    683, 
                    683
                ], 
                [
                    682, 
                    682
                ], 
                [
                    681, 
                    681
                ], 
                [
                    680, 
                    680
                ], 
                [
                    679, 
                    679
                ], 
                [
                    678, 
                    678
                ], 
                [
                    677, 
                    677
                ], 
                [
                    676, 
                    676
                ], 
                [
                    675, 
                    675
                ], 
                [
                    674, 
                    674
                ], 
                [
                    673, 
                    673
                ], 
                [
                    672, 
                    672
                ], 
                [
                    671, 
                    671
                ], 
                [
                    670, 
                    670
                ], 
                [
                    669, 
                    669
                ], 
                [
                    668, 
                    668
                ], 
                [
                    667, 
                    667
                ], 
                [
                    666, 
                    666
                ], 
                [
                    665, 
                    665
                ], 
                [
                    664, 
                    664
                ], 
                [
                    663, 
                    663
                ], 
                [
                    662, 
                    662
                ], 
                [
                    661, 
                    661
                ], 
                [
                    660, 
                    660
                ], 
                [
                    659, 
                    659
                ], 
                [
                    658, 
                    658
                ], 
                [
                    657, 
                    657
                ], 
                [
                    656, 
                    656
                ], 
                [
                    655, 
                    655
                ], 
                [
                    654, 
                    654
                ], 
                [
                    653, 
                    653
                ], 
                [
                    652, 
                    652
                ], 
                [
                    651, 
                    651
                ], 
                [
                    650, 
                    650
                ], 
                [
                    649, 
                    649
                ], 
                [
                    648, 
                    648
                ], 
                [
                    647, 
                    647
                ], 
                [
                    646, 
                    646
                ], 
                [
                    645, 
                    645
                ], 
                [
                    644, 
                    644
                ], 
                [
                    643, 
                    643
                ], 
                [
                    642, 
                    642
                ], 
                [
                    641, 
                    641
                ], 
                [
                    640, 
                    640
                ], 
                [
                    639, 
                    639
                ], 
                [
                    638, 
                    638
                ], 
                [
                    637, 
                    637
                ], 
                [
                    636, 
                    636
                ], 
                [
                    635, 
                    635
                ], 
                [
                    634, 
                    634
                ], 
                [
                    633, 
                    633
                ], 
                [
                    632, 
                    632
                ], 
                [
                    631, 
                    631
                ], 
                [
                    630, 
                    630
                ], 
                [
                    629, 
                    629
                ], 
                [
                    628, 
                    628
                ], 
                [
                    627, 
                    627
                ], 
                [
                    626, 
                    626
                ], 
                [
                    625, 
                    625
                ], 
                [
                    624, 
                    624
                ], 
                [
                    623, 
                    623
                ], 
                [
                    622, 
                    622
                ], 
                [
                    621, 
                    621
                ], 
                [
                    620, 
                    620
                ], 
                [
                    619, 
                    619
                ], 
                [
                    618, 
                    618
                ], 
                [
                    617, 
                    617
                ], 
                [
                    616, 
                    616
                ], 
                [
                    615, 
                    615
                ], 
                [
                    614, 
                    614
                ], 
                [
                    613, 
                    613
                ], 
                [
                    612, 
                    612
                ], 
                [
                    611, 
                    611
                ], 
                [
                    610, 
                    610
                ], 
                [
                    609, 
                    609
                ], 
                [
                    608, 
                    608
                ], 
                [
                    607, 
                    607
                ], 
                [
                    606, 
                    606
                ], 
                [
                    605, 
                    605
                ], 
                [
                    604, 
                    604
                ], 
                [
                    603, 
                    603
                ], 
                [
                    602, 
                    602
                ], 
                [
                    601, 
                    601
                ], 
                [
                    600, 
                    600
                ], 
                [
                    599, 
                    599
                ], 
                [
                    598, 
                    598
                ], 
                [
                    597, 
                    597
                ], 
                [
                    596, 
                    596
                ], 
                [
                    595, 
                    595
                ], 
                [
                    594, 
                    594
                ], 
                [
                    593, 
                    593
                ], 
                [
                    592, 
                    592
                ], 
                [
                    591, 
                    591
                ], 
                [
                    590, 
                    590
                ], 
                [
                    589, 
                    589
                ], 
                [
                    588, 
                    588
                ], 
                [
                    587, 
                    587
                ], 
                [
                    586, 
                    586
                ], 
                [
                    585, 
                    585
                ], 
                [
                    584, 
                    584
                ], 
                [
                    583, 
                    583
                ], 
                [
                    582, 
                    582
                ], 
                [
                    581, 
                    581
                ], 
                [
                    580, 
                    580
                ], 
                [
                    579, 
                    579
                ], 
                [
                    578, 
                    578
                ], 
                [
                    577, 
                    577
                ], 
                [
                    576, 
                    576
                ], 
                [
                    575, 
                    575
                ], 
                [
                    574, 
                    574
                ], 
                [
                    573, 
                    573
                ], 
                [
                    572, 
                    572
                ], 
                [
                    571, 
                    571
                ], 
                [
                    570, 
                    570
                ], 
                [
                    569, 
                    569
                ], 
                [
                    568, 
                    568
                ], 
                [
                    567, 
                    567
                ], 
                [
                    566, 
                    566
                ], 
                [
                    565, 
                    565
                ], 
                [
                    564, 
                    564
                ], 
                [
                    563, 
                    563
                ], 
                [
                    562, 
                    562
                ], 
                [
                    561, 
                    561
                ], 
                [
                    560, 
                    560
                ], 
                [
                    559, 
                    559
                ], 
                [
                    558, 
                    558
                ], 
                [
                    557, 
                    557
                ], 
                [
                    556, 
                    556
                ], 
                [
                    555, 
                    555
                ], 
                [
                    554, 
                    554
                ], 
                [
                    553, 
                    553
                ], 
                [
                    552, 
                    552
                ], 
                [
                    551, 
                    551
                ], 
                [
                    550, 
                    550
                ], 
                [
                    549, 
                    549
                ], 
                [
                    548, 
                    548
                ], 
                [
                    547, 
                    547
                ], 
                [
                    546, 
                    546
                ], 
                [
                    545, 
                    545
                ], 
                [
                    544, 
                    544
                ], 
                [
                    543, 
                    543
                ], 
                [
                    542, 
                    542
                ], 
                [
                    541, 
                    541
                ], 
                [
                    540, 
                    540
                ], 
                [
                    539, 
                    539
                ], 
                [
                    538, 
                    538
                ], 
                [
                    537, 
                    537
                ], 
                [
                    536, 
                    536
                ], 
                [
                    535, 
                    535
                ], 
                [
                    534, 
                    534
                ], 
                [
                    533, 
                    533
                ], 
                [
                    532, 
                    532
                ], 
                [
                    531, 
                    531
                ], 
                [
                    530, 
                    530
                ], 
                [
                    529, 
                    529
                ], 
                [
                    528, 
                    528
                ], 
                [
                    527, 
                    527
                ], 
                [
                    526, 
                    526
                ], 
                [
                    525, 
                    525
                ], 
                [
                    524, 
                    524
                ], 
                [
                    523, 
                    523
                ], 
                [
                    522, 
                    522
                ], 
                [
                    521, 
                    521
                ], 
                [
                    520, 
                    520
                ], 
                [
                    519, 
                    519
                ], 
                [
                    518, 
                    518
                ], 
                [
                    517, 
                    517
                ], 
                [
                    516, 
                    516
                ], 
                [
                    515, 
                    515
                ], 
                [
                    514, 
                    514
                ], 
                [
                    513, 
                    513
                ], 
                [
                    512, 
                    512
                ], 
                [
                    511, 
                    511
                ], 
                [
                    510, 
                    510
                ], 
                [
                    509, 
                    509
                ], 
                [
                    508, 
                    508
                ], 
                [
                    507, 
                    507
                ], 
                [
                    506, 
                    506
                ], 
                [
                    505, 
                    505
                ], 
                [
                    504, 
                    504
                ], 
                [
                    503, 
                    503
                ], 
                [
                    502, 
                    502
                ], 
                [
                    501, 
                    501
                ], 
                [
                    500, 
                    500
                ], 
                [
                    499, 
                    499
                ], 
                [
                    498, 
                    498
                ], 
                [
                    497, 
                    497
                ], 
                [
                    496, 
                    496
                ], 
                [
                    495, 
                    495
                ], 
                [
                    494, 
                    494
                ], 
                [
                    493, 
                    493
                ], 
                [
                    492, 
                    492
                ], 
                [
                    491, 
                    491
                ], 
                [
                    490, 
                    490
                ], 
                [
                    489, 
                    489
                ], 
                [
                    488, 
                    488
                ], 
                [
                    487, 
                    487
                ], 
                [
                    486, 
                    486
                ], 
                [
                    485, 
                    485
                ], 
                [
                    484, 
                    484
                ], 
                [
                    483, 
                    483
                ], 
                [
                    482, 
                    482
                ], 
                [
                    481, 
                    481
                ], 
                [
                    480, 
                    480
                ], 
                [
                    479, 
                    479
                ], 
                [
                    478, 
                    478
                ], 
                [
                    477, 
                    477
                ], 
                [
                    476, 
                    476
                ], 
                [
                    475, 
                    475
                ], 
                [
                    474, 
                    474
                ], 
                [
                    473, 
                    473
                ], 
                [
                    472, 
                    472
                ], 
                [
                    471, 
                    471
                ], 
                [
                    470, 
                    470
                ], 
                [
                    469, 
                    469
                ], 
                [
                    468, 
                    468
                ], 
                [
                    467, 
                    467
                ], 
                [
                    466, 
                    466
                ], 
                [
                    465, 
                    465
                ], 
                [
                    464, 
                    464
                ], 
                [
                    463, 
                    463
                ], 
                [
                    462, 
                    462
                ], 
                [
                    461, 
                    461
                ], 
                [
                    460, 
                    460
                ], 
                [
                    459, 
                    459
                ], 
                [
                    458, 
                    458
                ], 
                [
                    457, 
                    457
                ], 
                [
                    456, 
                    456
                ], 
                [
                    455, 
                    455
                ], 
                [
                    454, 
                    454
                ], 
                [
                    453, 
                    453
                ], 
                [
                    452, 
                    452
                ], 
                [
                    451, 
                    451
                ], 
                [
                    450, 
                    450
                ], 
                [
                    449, 
                    449
                ], 
                [
                    448, 
                    448
                ], 
                [
                    447, 
                    447
                ], 
                [
                    446, 
                    446
                ], 
                [
                    445, 
                    445
                ], 
                [
                    444, 
                    444
                ], 
                [
                    443, 
                    443
                ], 
                [
                    442, 
                    442
                ], 
                [
                    441, 
                    441
                ], 
                [
                    440, 
                    440
                ], 
                [
                    439, 
                    439
                ], 
                [
                    438, 
                    438
                ], 
                [
                    437, 
                    437
                ], 
                [
                    436, 
                    436
                ], 
                [
                    435, 
                    435
                ], 
                [
                    434, 
                    434
                ], 
                [
                    433, 
                    433
                ], 
                [
                    432, 
                    432
                ], 
                [
                    431, 
                    431
                ], 
                [
                    430, 
                    430
                ], 
                [
                    429, 
                    429
                ], 
                [
                    428, 
                    428
                ], 
                [
                    427, 
                    427
                ], 
                [
                    426, 
                    426
                ], 
                [
                    425, 
                    425
                ], 
                [
                    424, 
                    424
                ], 
                [
                    423, 
                    423
                ], 
                [
                    422, 
                    422
                ], 
                [
                    421, 
                    421
                ], 
                [
                    420, 
                    420
                ], 
                [
                    419, 
                    419
                ], 
                [
                    418, 
                    418
                ], 
                [
                    417, 
                    417
                ], 
                [
                    416, 
                    416
                ], 
                [
                    415, 
                    415
                ], 
                [
                    414, 
                    414
                ], 
                [
                    413, 
                    413
                ], 
                [
                    412, 
                    412
                ], 
                [
                    411, 
                    411
                ], 
                [
                    410, 
                    410
                ], 
                [
                    409, 
                    409
                ], 
                [
                    408, 
                    408
                ], 
                [
                    407, 
                    407
                ], 
                [
                    406, 
                    406
                ], 
                [
                    405, 
                    405
                ], 
                [
                    404, 
                    404
                ], 
                [
                    403, 
                    403
                ], 
                [
                    402, 
                    402
                ], 
                [
                    401, 
                    401
                ], 
                [
                    400, 
                    400
                ]
            ], 
            z: [
                [
                    0.884641593, 
                    0.884641593
                ], 
                [
                    0.8890654, 
                    0.8890654
                ], 
                [
                    0.884714879, 
                    0.884714879
                ], 
                [
                    0.904572045, 
                    0.904572045
                ], 
                [
                    0.894529647, 
                    0.894529647
                ], 
                [
                    0.897962983, 
                    0.897962983
                ], 
                [
                    0.900328862, 
                    0.900328862
                ], 
                [
                    0.903703717, 
                    0.903703717
                ], 
                [
                    0.904594993, 
                    0.904594993
                ], 
                [
                    0.906598143, 
                    0.906598143
                ], 
                [
                    0.908414007, 
                    0.908414007
                ], 
                [
                    0.908850391, 
                    0.908850391
                ], 
                [
                    0.911672271, 
                    0.911672271
                ], 
                [
                    0.913444829, 
                    0.913444829
                ], 
                [
                    0.913722057, 
                    0.913722057
                ], 
                [
                    0.915360628, 
                    0.915360628
                ], 
                [
                    0.915871779, 
                    0.915871779
                ], 
                [
                    0.918536353, 
                    0.918536353
                ], 
                [
                    0.920523958, 
                    0.920523958
                ], 
                [
                    0.922798414, 
                    0.922798414
                ], 
                [
                    0.92625951, 
                    0.92625951
                ], 
                [
                    0.927869951, 
                    0.927869951
                ], 
                [
                    0.930334284, 
                    0.930334284
                ], 
                [
                    0.932924832, 
                    0.932924832
                ], 
                [
                    0.934799176, 
                    0.934799176
                ], 
                [
                    0.938469767, 
                    0.938469767
                ], 
                [
                    0.939021262, 
                    0.939021262
                ], 
                [
                    0.943960292, 
                    0.943960292
                ], 
                [
                    0.946874705, 
                    0.946874705
                ], 
                [
                    0.949587766, 
                    0.949587766
                ], 
                [
                    0.952003983, 
                    0.952003983
                ], 
                [
                    0.954772564, 
                    0.954772564
                ], 
                [
                    0.958787376, 
                    0.958787376
                ], 
                [
                    0.962736306, 
                    0.962736306
                ], 
                [
                    0.964340084, 
                    0.964340084
                ], 
                [
                    0.969462699, 
                    0.969462699
                ], 
                [
                    0.969808772, 
                    0.969808772
                ], 
                [
                    0.972414125, 
                    0.972414125
                ], 
                [
                    0.974098222, 
                    0.974098222
                ], 
                [
                    0.978345477, 
                    0.978345477
                ], 
                [
                    0.978795186, 
                    0.978795186
                ], 
                [
                    0.97959689, 
                    0.97959689
                ], 
                [
                    0.979584306, 
                    0.979584306
                ], 
                [
                    0.981960548, 
                    0.981960548
                ], 
                [
                    0.983286728, 
                    0.983286728
                ], 
                [
                    0.986818519, 
                    0.986818519
                ], 
                [
                    0.989697769, 
                    0.989697769
                ], 
                [
                    0.992225395, 
                    0.992225395
                ], 
                [
                    0.992242421, 
                    0.992242421
                ], 
                [
                    0.996102149, 
                    0.996102149
                ], 
                [
                    1, 
                    1
                ], 
                [
                    1.002884432, 
                    1.002884432
                ], 
                [
                    1.006867043, 
                    1.006867043
                ], 
                [
                    1.010126788, 
                    1.010126788
                ], 
                [
                    1.012488965, 
                    1.012488965
                ], 
                [
                    1.015587704, 
                    1.015587704
                ], 
                [
                    1.019223872, 
                    1.019223872
                ], 
                [
                    1.022727163, 
                    1.022727163
                ], 
                [
                    1.025250718, 
                    1.025250718
                ], 
                [
                    1.027236471, 
                    1.027236471
                ], 
                [
                    1.031005887, 
                    1.031005887
                ], 
                [
                    1.031451525, 
                    1.031451525
                ], 
                [
                    1.031874955, 
                    1.031874955
                ], 
                [
                    1.033312174, 
                    1.033312174
                ], 
                [
                    1.035179856, 
                    1.035179856
                ], 
                [
                    1.037248889, 
                    1.037248889
                ], 
                [
                    1.041303306, 
                    1.041303306
                ], 
                [
                    1.044199952, 
                    1.044199952
                ], 
                [
                    1.046566941, 
                    1.046566941
                ], 
                [
                    1.048438324, 
                    1.048438324
                ], 
                [
                    1.051145834, 
                    1.051145834
                ], 
                [
                    1.05452328, 
                    1.05452328
                ], 
                [
                    1.057282238, 
                    1.057282238
                ], 
                [
                    1.058934133, 
                    1.058934133
                ], 
                [
                    1.061102732, 
                    1.061102732
                ], 
                [
                    1.061744169, 
                    1.061744169
                ], 
                [
                    1.064166677, 
                    1.064166677
                ], 
                [
                    1.06753265, 
                    1.06753265
                ], 
                [
                    1.072002724, 
                    1.072002724
                ], 
                [
                    1.076750397, 
                    1.076750397
                ], 
                [
                    1.079592634, 
                    1.079592634
                ], 
                [
                    1.083908363, 
                    1.083908363
                ], 
                [
                    1.089203459, 
                    1.089203459
                ], 
                [
                    1.09372128, 
                    1.09372128
                ], 
                [
                    1.099779587, 
                    1.099779587
                ], 
                [
                    1.104435837, 
                    1.104435837
                ], 
                [
                    1.108538741, 
                    1.108538741
                ], 
                [
                    1.112166026, 
                    1.112166026
                ], 
                [
                    1.116342956, 
                    1.116342956
                ], 
                [
                    1.121219064, 
                    1.121219064
                ], 
                [
                    1.126671836, 
                    1.126671836
                ], 
                [
                    1.12930643, 
                    1.12930643
                ], 
                [
                    1.13407557, 
                    1.13407557
                ], 
                [
                    1.139183009, 
                    1.139183009
                ], 
                [
                    1.145146193, 
                    1.145146193
                ], 
                [
                    1.149083278, 
                    1.149083278
                ], 
                [
                    1.155462858, 
                    1.155462858
                ], 
                [
                    1.162089686, 
                    1.162089686
                ], 
                [
                    1.168581047, 
                    1.168581047
                ], 
                [
                    1.173907604, 
                    1.173907604
                ], 
                [
                    1.1817418, 
                    1.1817418
                ], 
                [
                    1.187858958, 
                    1.187858958
                ], 
                [
                    1.193270645, 
                    1.193270645
                ], 
                [
                    1.199271582, 
                    1.199271582
                ], 
                [
                    1.207812728, 
                    1.207812728
                ], 
                [
                    1.214961442, 
                    1.214961442
                ], 
                [
                    1.222740488, 
                    1.222740488
                ], 
                [
                    1.229877357, 
                    1.229877357
                ], 
                [
                    1.235703592, 
                    1.235703592
                ], 
                [
                    1.240265088, 
                    1.240265088
                ], 
                [
                    1.244410187, 
                    1.244410187
                ], 
                [
                    1.247233177, 
                    1.247233177
                ], 
                [
                    1.251476731, 
                    1.251476731
                ], 
                [
                    1.251142872, 
                    1.251142872
                ], 
                [
                    1.250525494, 
                    1.250525494
                ], 
                [
                    1.247095858, 
                    1.247095858
                ], 
                [
                    1.243285358, 
                    1.243285358
                ], 
                [
                    1.238135723, 
                    1.238135723
                ], 
                [
                    1.232966842, 
                    1.232966842
                ], 
                [
                    1.226489547, 
                    1.226489547
                ], 
                [
                    1.220860592, 
                    1.220860592
                ], 
                [
                    1.211544761, 
                    1.211544761
                ], 
                [
                    1.205536792, 
                    1.205536792
                ], 
                [
                    1.199067269, 
                    1.199067269
                ], 
                [
                    1.191988141, 
                    1.191988141
                ], 
                [
                    1.186658252, 
                    1.186658252
                ], 
                [
                    1.182584219, 
                    1.182584219
                ], 
                [
                    1.176257937, 
                    1.176257937
                ], 
                [
                    1.172453729, 
                    1.172453729
                ], 
                [
                    1.167566517, 
                    1.167566517
                ], 
                [
                    1.16464063, 
                    1.16464063
                ], 
                [
                    1.159875562, 
                    1.159875562
                ], 
                [
                    1.155800418, 
                    1.155800418
                ], 
                [
                    1.154157036, 
                    1.154157036
                ], 
                [
                    1.151435091, 
                    1.151435091
                ], 
                [
                    1.149511519, 
                    1.149511519
                ], 
                [
                    1.150018229, 
                    1.150018229
                ], 
                [
                    1.152459984, 
                    1.152459984
                ], 
                [
                    1.154419459, 
                    1.154419459
                ], 
                [
                    1.155550209, 
                    1.155550209
                ], 
                [
                    1.160568077, 
                    1.160568077
                ], 
                [
                    1.165490081, 
                    1.165490081
                ], 
                [
                    1.167889272, 
                    1.167889272
                ], 
                [
                    1.172353423, 
                    1.172353423
                ], 
                [
                    1.178082313, 
                    1.178082313
                ], 
                [
                    1.183308936, 
                    1.183308936
                ], 
                [
                    1.186443576, 
                    1.186443576
                ], 
                [
                    1.18969888, 
                    1.18969888
                ], 
                [
                    1.194608299, 
                    1.194608299
                ], 
                [
                    1.198459514, 
                    1.198459514
                ], 
                [
                    1.202824101, 
                    1.202824101
                ], 
                [
                    1.209992431, 
                    1.209992431
                ], 
                [
                    1.213277345, 
                    1.213277345
                ], 
                [
                    1.217473152, 
                    1.217473152
                ], 
                [
                    1.222001336, 
                    1.222001336
                ], 
                [
                    1.225381374, 
                    1.225381374
                ], 
                [
                    1.229932877, 
                    1.229932877
                ], 
                [
                    1.235288675, 
                    1.235288675
                ], 
                [
                    1.238145717, 
                    1.238145717
                ], 
                [
                    1.23984758, 
                    1.23984758
                ], 
                [
                    1.239464864, 
                    1.239464864
                ], 
                [
                    1.24261357, 
                    1.24261357
                ], 
                [
                    1.242248621, 
                    1.242248621
                ], 
                [
                    1.243009981, 
                    1.243009981
                ], 
                [
                    1.242605427, 
                    1.242605427
                ], 
                [
                    1.242685005, 
                    1.242685005
                ], 
                [
                    1.240295069, 
                    1.240295069
                ], 
                [
                    1.237891807, 
                    1.237891807
                ], 
                [
                    1.235851274, 
                    1.235851274
                ], 
                [
                    1.236022274, 
                    1.236022274
                ], 
                [
                    1.232302086, 
                    1.232302086
                ], 
                [
                    1.230445138, 
                    1.230445138
                ], 
                [
                    1.226885957, 
                    1.226885957
                ], 
                [
                    1.22505899, 
                    1.22505899
                ], 
                [
                    1.221452432, 
                    1.221452432
                ], 
                [
                    1.216999754, 
                    1.216999754
                ], 
                [
                    1.214050549, 
                    1.214050549
                ], 
                [
                    1.20866514, 
                    1.20866514
                ], 
                [
                    1.203142414, 
                    1.203142414
                ], 
                [
                    1.199839363, 
                    1.199839363
                ], 
                [
                    1.195028028, 
                    1.195028028
                ], 
                [
                    1.190834442, 
                    1.190834442
                ], 
                [
                    1.185303572, 
                    1.185303572
                ], 
                [
                    1.180874213, 
                    1.180874213
                ], 
                [
                    1.177063342, 
                    1.177063342
                ], 
                [
                    1.173226933, 
                    1.173226933
                ], 
                [
                    1.170222208, 
                    1.170222208
                ], 
                [
                    1.167399218, 
                    1.167399218
                ], 
                [
                    1.164955242, 
                    1.164955242
                ], 
                [
                    1.16179025, 
                    1.16179025
                ], 
                [
                    1.160464811, 
                    1.160464811
                ], 
                [
                    1.159356638, 
                    1.159356638
                ], 
                [
                    1.157147325, 
                    1.157147325
                ], 
                [
                    1.157833178, 
                    1.157833178
                ], 
                [
                    1.157920159, 
                    1.157920159
                ], 
                [
                    1.159095696, 
                    1.159095696
                ], 
                [
                    1.158803662, 
                    1.158803662
                ], 
                [
                    1.158568629, 
                    1.158568629
                ], 
                [
                    1.160895643, 
                    1.160895643
                ], 
                [
                    1.160714279, 
                    1.160714279
                ], 
                [
                    1.162114855, 
                    1.162114855
                ], 
                [
                    1.163424379, 
                    1.163424379
                ], 
                [
                    1.163128275, 
                    1.163128275
                ], 
                [
                    1.164565494, 
                    1.164565494
                ], 
                [
                    1.164661358, 
                    1.164661358
                ], 
                [
                    1.165369789, 
                    1.165369789
                ], 
                [
                    1.165568919, 
                    1.165568919
                ], 
                [
                    1.166421331, 
                    1.166421331
                ], 
                [
                    1.166992444, 
                    1.166992444
                ], 
                [
                    1.16743438, 
                    1.16743438
                ], 
                [
                    1.168283461, 
                    1.168283461
                ], 
                [
                    1.169413842, 
                    1.169413842
                ], 
                [
                    1.170195929, 
                    1.170195929
                ], 
                [
                    1.172222767, 
                    1.172222767
                ], 
                [
                    1.173823955, 
                    1.173823955
                ], 
                [
                    1.175537292, 
                    1.175537292
                ], 
                [
                    1.17705594, 
                    1.17705594
                ], 
                [
                    1.177166979, 
                    1.177166979
                ], 
                [
                    1.177427551, 
                    1.177427551
                ], 
                [
                    1.179409234, 
                    1.179409234
                ], 
                [
                    1.181942781, 
                    1.181942781
                ], 
                [
                    1.183635021, 
                    1.183635021
                ], 
                [
                    1.183917061, 
                    1.183917061
                ], 
                [
                    1.184693966, 
                    1.184693966
                ], 
                [
                    1.186647148, 
                    1.186647148
                ], 
                [
                    1.187338183, 
                    1.187338183
                ], 
                [
                    1.192827969, 
                    1.192827969
                ], 
                [
                    1.19618913, 
                    1.19618913
                ], 
                [
                    1.197694823, 
                    1.197694823
                ], 
                [
                    1.199673544, 
                    1.199673544
                ], 
                [
                    1.201943559, 
                    1.201943559
                ], 
                [
                    1.206780803, 
                    1.206780803
                ], 
                [
                    1.210493959, 
                    1.210493959
                ], 
                [
                    1.212750648, 
                    1.212750648
                ], 
                [
                    1.215290488, 
                    1.215290488
                ], 
                [
                    1.216957559, 
                    1.216957559
                ], 
                [
                    1.218849669, 
                    1.218849669
                ], 
                [
                    1.222728274, 
                    1.222728274
                ], 
                [
                    1.225913993, 
                    1.225913993
                ], 
                [
                    1.228518606, 
                    1.228518606
                ], 
                [
                    1.230849321, 
                    1.230849321
                ], 
                [
                    1.233519818, 
                    1.233519818
                ], 
                [
                    1.238613193, 
                    1.238613193
                ], 
                [
                    1.24309252, 
                    1.24309252
                ], 
                [
                    1.246325986, 
                    1.246325986
                ], 
                [
                    1.252399838, 
                    1.252399838
                ], 
                [
                    1.257019815, 
                    1.257019815
                ], 
                [
                    1.262511081, 
                    1.262511081
                ], 
                [
                    1.26863231, 
                    1.26863231
                ], 
                [
                    1.274049549, 
                    1.274049549
                ], 
                [
                    1.279150697, 
                    1.279150697
                ], 
                [
                    1.284047902, 
                    1.284047902
                ], 
                [
                    1.288802607, 
                    1.288802607
                ], 
                [
                    1.294216145, 
                    1.294216145
                ], 
                [
                    1.299176643, 
                    1.299176643
                ], 
                [
                    1.303939861, 
                    1.303939861
                ], 
                [
                    1.309422614, 
                    1.309422614
                ], 
                [
                    1.312924055, 
                    1.312924055
                ], 
                [
                    1.316666451, 
                    1.316666451
                ], 
                [
                    1.322009294, 
                    1.322009294
                ], 
                [
                    1.326857272, 
                    1.326857272
                ], 
                [
                    1.332012088, 
                    1.332012088
                ], 
                [
                    1.336928541, 
                    1.336928541
                ], 
                [
                    1.34042702, 
                    1.34042702
                ], 
                [
                    1.346398346, 
                    1.346398346
                ], 
                [
                    1.349119921, 
                    1.349119921
                ], 
                [
                    1.355457676, 
                    1.355457676
                ], 
                [
                    1.359338131, 
                    1.359338131
                ], 
                [
                    1.361840588, 
                    1.361840588
                ], 
                [
                    1.364206837, 
                    1.364206837
                ], 
                [
                    1.36748842, 
                    1.36748842
                ], 
                [
                    1.371396634, 
                    1.371396634
                ], 
                [
                    1.376731705, 
                    1.376731705
                ], 
                [
                    1.379307077, 
                    1.379307077
                ], 
                [
                    1.383598748, 
                    1.383598748
                ], 
                [
                    1.386807785, 
                    1.386807785
                ], 
                [
                    1.392582572, 
                    1.392582572
                ], 
                [
                    1.399279355, 
                    1.399279355
                ], 
                [
                    1.406158612, 
                    1.406158612
                ], 
                [
                    1.410133821, 
                    1.410133821
                ], 
                [
                    1.413507566, 
                    1.413507566
                ], 
                [
                    1.416870948, 
                    1.416870948
                ], 
                [
                    1.42000744, 
                    1.42000744
                ], 
                [
                    1.42331049, 
                    1.42331049
                ], 
                [
                    1.425322893, 
                    1.425322893
                ], 
                [
                    1.42869923, 
                    1.42869923
                ], 
                [
                    1.432053728, 
                    1.432053728
                ], 
                [
                    1.433872923, 
                    1.433872923
                ], 
                [
                    1.436228067, 
                    1.436228067
                ], 
                [
                    1.439687683, 
                    1.439687683
                ], 
                [
                    1.442929662, 
                    1.442929662
                ], 
                [
                    1.445092709, 
                    1.445092709
                ], 
                [
                    1.447645873, 
                    1.447645873
                ], 
                [
                    1.451665498, 
                    1.451665498
                ], 
                [
                    1.454153519, 
                    1.454153519
                ], 
                [
                    1.456644132, 
                    1.456644132
                ], 
                [
                    1.460836237, 
                    1.460836237
                ], 
                [
                    1.464195918, 
                    1.464195918
                ], 
                [
                    1.466546621, 
                    1.466546621
                ], 
                [
                    1.467300208, 
                    1.467300208
                ], 
                [
                    1.47148417, 
                    1.47148417
                ], 
                [
                    1.473879289, 
                    1.473879289
                ], 
                [
                    1.474885306, 
                    1.474885306
                ], 
                [
                    1.477339645, 
                    1.477339645
                ], 
                [
                    1.479980901, 
                    1.479980901
                ], 
                [
                    1.48068341, 
                    1.48068341
                ], 
                [
                    1.483686284, 
                    1.483686284
                ], 
                [
                    1.486815743, 
                    1.486815743
                ], 
                [
                    1.490072527, 
                    1.490072527
                ], 
                [
                    1.492428042, 
                    1.492428042
                ], 
                [
                    1.496213743, 
                    1.496213743
                ], 
                [
                    1.499189968, 
                    1.499189968
                ], 
                [
                    1.501063942, 
                    1.501063942
                ], 
                [
                    1.502812442, 
                    1.502812442
                ], 
                [
                    1.505189424, 
                    1.505189424
                ], 
                [
                    1.508609806, 
                    1.508609806
                ], 
                [
                    1.51150016, 
                    1.51150016
                ], 
                [
                    1.514454547, 
                    1.514454547
                ], 
                [
                    1.517802013, 
                    1.517802013
                ], 
                [
                    1.519775923, 
                    1.519775923
                ], 
                [
                    1.52369191, 
                    1.52369191
                ], 
                [
                    1.527028643, 
                    1.527028643
                ], 
                [
                    1.530579681, 
                    1.530579681
                ], 
                [
                    1.534597455, 
                    1.534597455
                ], 
                [
                    1.538493085, 
                    1.538493085
                ], 
                [
                    1.541556661, 
                    1.541556661
                ], 
                [
                    1.544718321, 
                    1.544718321
                ], 
                [
                    1.546653737, 
                    1.546653737
                ], 
                [
                    1.550509023, 
                    1.550509023
                ], 
                [
                    1.553570748, 
                    1.553570748
                ], 
                [
                    1.555659398, 
                    1.555659398
                ], 
                [
                    1.55913567, 
                    1.55913567
                ], 
                [
                    1.562446863, 
                    1.562446863
                ], 
                [
                    1.564789053, 
                    1.564789053
                ], 
                [
                    1.568291604, 
                    1.568291604
                ], 
                [
                    1.571050932, 
                    1.571050932
                ], 
                [
                    1.575752338, 
                    1.575752338
                ], 
                [
                    1.578878095, 
                    1.578878095
                ], 
                [
                    1.582868109, 
                    1.582868109
                ], 
                [
                    1.587742737, 
                    1.587742737
                ], 
                [
                    1.59178679, 
                    1.59178679
                ], 
                [
                    1.596213928, 
                    1.596213928
                ], 
                [
                    1.59937966, 
                    1.59937966
                ], 
                [
                    1.603577688, 
                    1.603577688
                ], 
                [
                    1.608246152, 
                    1.608246152
                ], 
                [
                    1.612761752, 
                    1.612761752
                ], 
                [
                    1.616265784, 
                    1.616265784
                ], 
                [
                    1.619799426, 
                    1.619799426
                ], 
                [
                    1.621458354, 
                    1.621458354
                ], 
                [
                    1.622140876, 
                    1.622140876
                ], 
                [
                    1.622820436, 
                    1.622820436
                ], 
                [
                    1.624183259, 
                    1.624183259
                ], 
                [
                    1.624031876, 
                    1.624031876
                ], 
                [
                    1.623548484, 
                    1.623548484
                ], 
                [
                    1.621455763, 
                    1.621455763
                ], 
                [
                    1.618002069, 
                    1.618002069
                ], 
                [
                    1.614430674, 
                    1.614430674
                ], 
                [
                    1.611209052, 
                    1.611209052
                ], 
                [
                    1.606695302, 
                    1.606695302
                ], 
                [
                    1.601434258, 
                    1.601434258
                ], 
                [
                    1.595500316, 
                    1.595500316
                ], 
                [
                    1.589519366, 
                    1.589519366
                ], 
                [
                    1.585498261, 
                    1.585498261
                ], 
                [
                    1.58083868, 
                    1.58083868
                ], 
                [
                    1.578406548, 
                    1.578406548
                ], 
                [
                    1.573741785, 
                    1.573741785
                ], 
                [
                    1.570511281, 
                    1.570511281
                ], 
                [
                    1.569920921, 
                    1.569920921
                ], 
                [
                    1.568066935, 
                    1.568066935
                ], 
                [
                    1.56763055, 
                    1.56763055
                ], 
                [
                    1.568178344, 
                    1.568178344
                ], 
                [
                    1.567534316, 
                    1.567534316
                ], 
                [
                    1.568403384, 
                    1.568403384
                ], 
                [
                    1.569173997, 
                    1.569173997
                ], 
                [
                    1.571238959, 
                    1.571238959
                ], 
                [
                    1.573094796, 
                    1.573094796
                ], 
                [
                    1.573511194, 
                    1.573511194
                ], 
                [
                    1.574595678, 
                    1.574595678
                ], 
                [
                    1.575045387, 
                    1.575045387
                ], 
                [
                    1.575104978, 
                    1.575104978
                ], 
                [
                    1.574791477, 
                    1.574791477
                ], 
                [
                    1.574485749, 
                    1.574485749
                ], 
                [
                    1.575020589, 
                    1.575020589
                ], 
                [
                    1.5746586, 
                    1.5746586
                ], 
                [
                    1.575001342, 
                    1.575001342
                ], 
                [
                    1.575325947, 
                    1.575325947
                ], 
                [
                    1.576611412, 
                    1.576611412
                ], 
                [
                    1.578690069, 
                    1.578690069
                ], 
                [
                    1.580598465, 
                    1.580598465
                ], 
                [
                    1.58382897, 
                    1.58382897
                ], 
                [
                    1.584448199, 
                    1.584448199
                ], 
                [
                    1.585165884, 
                    1.585165884
                ], 
                [
                    1.586610876, 
                    1.586610876
                ], 
                [
                    1.588420077, 
                    1.588420077
                ], 
                [
                    1.590174128, 
                    1.590174128
                ], 
                [
                    1.59305819, 
                    1.59305819
                ], 
                [
                    1.596269818, 
                    1.596269818
                ], 
                [
                    1.596872947, 
                    1.596872947
                ], 
                [
                    1.596474395, 
                    1.596474395
                ], 
                [
                    1.598808363, 
                    1.598808363
                ], 
                [
                    1.600790748, 
                    1.600790748
                ]
            ], 
            cmax: 1.624183259, 
            cmin: 0.884641593, 
            colorscale: [
                [
                    "0", 
                    "rgb(124,124,255)"
                ], 
                [
                    "0.1", 
                    "rgb(124,124,255)"
                ], 
                [
                    "0.2", 
                    "rgb(124,124,255)"
                ], 
                [
                    "0.3", 
                    "rgb(124,124,255)"
                ], 
                [
                    "0.4", 
                    "rgb(124,124,255)"
                ], 
                [
                    "0.5", 
                    "rgb(124,124,255)"
                ], 
                [
                    "0.6", 
                    "rgb(124,124,255)"
                ], 
                [
                    "0.7", 
                    "rgb(124,124,255)"
                ], 
                [
                    "0.8", 
                    "rgb(124,124,255)"
                ], 
                [
                    "0.9", 
                    "rgb(124,124,255)"
                ], 
                [
                    "1", 
                    "rgb(124,124,255)"
                ]
            ], 
            name: "", 
            showscale: false, 
            type: "surface", 
            "uid": "afa138", 
            "xsrc": "Diksha_Gabha:2343:0b1880,442c83", 
            "ysrc": "Diksha_Gabha:2343:807139,59d0b7", 
            "zsrc": "Diksha_Gabha:2343:ab7812,fed99d"
        }, 
        {
            x: [
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ], 
                [
                    10, 
                    11
                ]
            ], 
            y: [
                [
                    800, 
                    800
                ], 
                [
                    799, 
                    799
                ], 
                [
                    798, 
                    798
                ], 
                [
                    797, 
                    797
                ], 
                [
                    796, 
                    796
                ], 
                [
                    795, 
                    795
                ], 
                [
                    794, 
                    794
                ], 
                [
                    793, 
                    793
                ], 
                [
                    792, 
                    792
                ], 
                [
                    791, 
                    791
                ], 
                [
                    790, 
                    790
                ], 
                [
                    789, 
                    789
                ], 
                [
                    788, 
                    788
                ], 
                [
                    787, 
                    787
                ], 
                [
                    786, 
                    786
                ], 
                [
                    785, 
                    785
                ], 
                [
                    784, 
                    784
                ], 
                [
                    783, 
                    783
                ], 
                [
                    782, 
                    782
                ], 
                [
                    781, 
                    781
                ], 
                [
                    780, 
                    780
                ], 
                [
                    779, 
                    779
                ], 
                [
                    778, 
                    778
                ], 
                [
                    777, 
                    777
                ], 
                [
                    776, 
                    776
                ], 
                [
                    775, 
                    775
                ], 
                [
                    774, 
                    774
                ], 
                [
                    773, 
                    773
                ], 
                [
                    772, 
                    772
                ], 
                [
                    771, 
                    771
                ], 
                [
                    770, 
                    770
                ], 
                [
                    769, 
                    769
                ], 
                [
                    768, 
                    768
                ], 
                [
                    767, 
                    767
                ], 
                [
                    766, 
                    766
                ], 
                [
                    765, 
                    765
                ], 
                [
                    764, 
                    764
                ], 
                [
                    763, 
                    763
                ], 
                [
                    762, 
                    762
                ], 
                [
                    761, 
                    761
                ], 
                [
                    760, 
                    760
                ], 
                [
                    759, 
                    759
                ], 
                [
                    758, 
                    758
                ], 
                [
                    757, 
                    757
                ], 
                [
                    756, 
                    756
                ], 
                [
                    755, 
                    755
                ], 
                [
                    754, 
                    754
                ], 
                [
                    753, 
                    753
                ], 
                [
                    752, 
                    752
                ], 
                [
                    751, 
                    751
                ], 
                [
                    750, 
                    750
                ], 
                [
                    749, 
                    749
                ], 
                [
                    748, 
                    748
                ], 
                [
                    747, 
                    747
                ], 
                [
                    746, 
                    746
                ], 
                [
                    745, 
                    745
                ], 
                [
                    744, 
                    744
                ], 
                [
                    743, 
                    743
                ], 
                [
                    742, 
                    742
                ], 
                [
                    741, 
                    741
                ], 
                [
                    740, 
                    740
                ], 
                [
                    739, 
                    739
                ], 
                [
                    738, 
                    738
                ], 
                [
                    737, 
                    737
                ], 
                [
                    736, 
                    736
                ], 
                [
                    735, 
                    735
                ], 
                [
                    734, 
                    734
                ], 
                [
                    733, 
                    733
                ], 
                [
                    732, 
                    732
                ], 
                [
                    731, 
                    731
                ], 
                [
                    730, 
                    730
                ], 
                [
                    729, 
                    729
                ], 
                [
                    728, 
                    728
                ], 
                [
                    727, 
                    727
                ], 
                [
                    726, 
                    726
                ], 
                [
                    725, 
                    725
                ], 
                [
                    724, 
                    724
                ], 
                [
                    723, 
                    723
                ], 
                [
                    722, 
                    722
                ], 
                [
                    721, 
                    721
                ], 
                [
                    720, 
                    720
                ], 
                [
                    719, 
                    719
                ], 
                [
                    718, 
                    718
                ], 
                [
                    717, 
                    717
                ], 
                [
                    716, 
                    716
                ], 
                [
                    715, 
                    715
                ], 
                [
                    714, 
                    714
                ], 
                [
                    713, 
                    713
                ], 
                [
                    712, 
                    712
                ], 
                [
                    711, 
                    711
                ], 
                [
                    710, 
                    710
                ], 
                [
                    709, 
                    709
                ], 
                [
                    708, 
                    708
                ], 
                [
                    707, 
                    707
                ], 
                [
                    706, 
                    706
                ], 
                [
                    705, 
                    705
                ], 
                [
                    704, 
                    704
                ], 
                [
                    703, 
                    703
                ], 
                [
                    702, 
                    702
                ], 
                [
                    701, 
                    701
                ], 
                [
                    700, 
                    700
                ], 
                [
                    699, 
                    699
                ], 
                [
                    698, 
                    698
                ], 
                [
                    697, 
                    697
                ], 
                [
                    696, 
                    696
                ], 
                [
                    695, 
                    695
                ], 
                [
                    694, 
                    694
                ], 
                [
                    693, 
                    693
                ], 
                [
                    692, 
                    692
                ], 
                [
                    691, 
                    691
                ], 
                [
                    690, 
                    690
                ], 
                [
                    689, 
                    689
                ], 
                [
                    688, 
                    688
                ], 
                [
                    687, 
                    687
                ], 
                [
                    686, 
                    686
                ], 
                [
                    685, 
                    685
                ], 
                [
                    684, 
                    684
                ], 
                [
                    683, 
                    683
                ], 
                [
                    682, 
                    682
                ], 
                [
                    681, 
                    681
                ], 
                [
                    680, 
                    680
                ], 
                [
                    679, 
                    679
                ], 
                [
                    678, 
                    678
                ], 
                [
                    677, 
                    677
                ], 
                [
                    676, 
                    676
                ], 
                [
                    675, 
                    675
                ], 
                [
                    674, 
                    674
                ], 
                [
                    673, 
                    673
                ], 
                [
                    672, 
                    672
                ], 
                [
                    671, 
                    671
                ], 
                [
                    670, 
                    670
                ], 
                [
                    669, 
                    669
                ], 
                [
                    668, 
                    668
                ], 
                [
                    667, 
                    667
                ], 
                [
                    666, 
                    666
                ], 
                [
                    665, 
                    665
                ], 
                [
                    664, 
                    664
                ], 
                [
                    663, 
                    663
                ], 
                [
                    662, 
                    662
                ], 
                [
                    661, 
                    661
                ], 
                [
                    660, 
                    660
                ], 
                [
                    659, 
                    659
                ], 
                [
                    658, 
                    658
                ], 
                [
                    657, 
                    657
                ], 
                [
                    656, 
                    656
                ], 
                [
                    655, 
                    655
                ], 
                [
                    654, 
                    654
                ], 
                [
                    653, 
                    653
                ], 
                [
                    652, 
                    652
                ], 
                [
                    651, 
                    651
                ], 
                [
                    650, 
                    650
                ], 
                [
                    649, 
                    649
                ], 
                [
                    648, 
                    648
                ], 
                [
                    647, 
                    647
                ], 
                [
                    646, 
                    646
                ], 
                [
                    645, 
                    645
                ], 
                [
                    644, 
                    644
                ], 
                [
                    643, 
                    643
                ], 
                [
                    642, 
                    642
                ], 
                [
                    641, 
                    641
                ], 
                [
                    640, 
                    640
                ], 
                [
                    639, 
                    639
                ], 
                [
                    638, 
                    638
                ], 
                [
                    637, 
                    637
                ], 
                [
                    636, 
                    636
                ], 
                [
                    635, 
                    635
                ], 
                [
                    634, 
                    634
                ], 
                [
                    633, 
                    633
                ], 
                [
                    632, 
                    632
                ], 
                [
                    631, 
                    631
                ], 
                [
                    630, 
                    630
                ], 
                [
                    629, 
                    629
                ], 
                [
                    628, 
                    628
                ], 
                [
                    627, 
                    627
                ], 
                [
                    626, 
                    626
                ], 
                [
                    625, 
                    625
                ], 
                [
                    624, 
                    624
                ], 
                [
                    623, 
                    623
                ], 
                [
                    622, 
                    622
                ], 
                [
                    621, 
                    621
                ], 
                [
                    620, 
                    620
                ], 
                [
                    619, 
                    619
                ], 
                [
                    618, 
                    618
                ], 
                [
                    617, 
                    617
                ], 
                [
                    616, 
                    616
                ], 
                [
                    615, 
                    615
                ], 
                [
                    614, 
                    614
                ], 
                [
                    613, 
                    613
                ], 
                [
                    612, 
                    612
                ], 
                [
                    611, 
                    611
                ], 
                [
                    610, 
                    610
                ], 
                [
                    609, 
                    609
                ], 
                [
                    608, 
                    608
                ], 
                [
                    607, 
                    607
                ], 
                [
                    606, 
                    606
                ], 
                [
                    605, 
                    605
                ], 
                [
                    604, 
                    604
                ], 
                [
                    603, 
                    603
                ], 
                [
                    602, 
                    602
                ], 
                [
                    601, 
                    601
                ], 
                [
                    600, 
                    600
                ], 
                [
                    599, 
                    599
                ], 
                [
                    598, 
                    598
                ], 
                [
                    597, 
                    597
                ], 
                [
                    596, 
                    596
                ], 
                [
                    595, 
                    595
                ], 
                [
                    594, 
                    594
                ], 
                [
                    593, 
                    593
                ], 
                [
                    592, 
                    592
                ], 
                [
                    591, 
                    591
                ], 
                [
                    590, 
                    590
                ], 
                [
                    589, 
                    589
                ], 
                [
                    588, 
                    588
                ], 
                [
                    587, 
                    587
                ], 
                [
                    586, 
                    586
                ], 
                [
                    585, 
                    585
                ], 
                [
                    584, 
                    584
                ], 
                [
                    583, 
                    583
                ], 
                [
                    582, 
                    582
                ], 
                [
                    581, 
                    581
                ], 
                [
                    580, 
                    580
                ], 
                [
                    579, 
                    579
                ], 
                [
                    578, 
                    578
                ], 
                [
                    577, 
                    577
                ], 
                [
                    576, 
                    576
                ], 
                [
                    575, 
                    575
                ], 
                [
                    574, 
                    574
                ], 
                [
                    573, 
                    573
                ], 
                [
                    572, 
                    572
                ], 
                [
                    571, 
                    571
                ], 
                [
                    570, 
                    570
                ], 
                [
                    569, 
                    569
                ], 
                [
                    568, 
                    568
                ], 
                [
                    567, 
                    567
                ], 
                [
                    566, 
                    566
                ], 
                [
                    565, 
                    565
                ], 
                [
                    564, 
                    564
                ], 
                [
                    563, 
                    563
                ], 
                [
                    562, 
                    562
                ], 
                [
                    561, 
                    561
                ], 
                [
                    560, 
                    560
                ], 
                [
                    559, 
                    559
                ], 
                [
                    558, 
                    558
                ], 
                [
                    557, 
                    557
                ], 
                [
                    556, 
                    556
                ], 
                [
                    555, 
                    555
                ], 
                [
                    554, 
                    554
                ], 
                [
                    553, 
                    553
                ], 
                [
                    552, 
                    552
                ], 
                [
                    551, 
                    551
                ], 
                [
                    550, 
                    550
                ], 
                [
                    549, 
                    549
                ], 
                [
                    548, 
                    548
                ], 
                [
                    547, 
                    547
                ], 
                [
                    546, 
                    546
                ], 
                [
                    545, 
                    545
                ], 
                [
                    544, 
                    544
                ], 
                [
                    543, 
                    543
                ], 
                [
                    542, 
                    542
                ], 
                [
                    541, 
                    541
                ], 
                [
                    540, 
                    540
                ], 
                [
                    539, 
                    539
                ], 
                [
                    538, 
                    538
                ], 
                [
                    537, 
                    537
                ], 
                [
                    536, 
                    536
                ], 
                [
                    535, 
                    535
                ], 
                [
                    534, 
                    534
                ], 
                [
                    533, 
                    533
                ], 
                [
                    532, 
                    532
                ], 
                [
                    531, 
                    531
                ], 
                [
                    530, 
                    530
                ], 
                [
                    529, 
                    529
                ], 
                [
                    528, 
                    528
                ], 
                [
                    527, 
                    527
                ], 
                [
                    526, 
                    526
                ], 
                [
                    525, 
                    525
                ], 
                [
                    524, 
                    524
                ], 
                [
                    523, 
                    523
                ], 
                [
                    522, 
                    522
                ], 
                [
                    521, 
                    521
                ], 
                [
                    520, 
                    520
                ], 
                [
                    519, 
                    519
                ], 
                [
                    518, 
                    518
                ], 
                [
                    517, 
                    517
                ], 
                [
                    516, 
                    516
                ], 
                [
                    515, 
                    515
                ], 
                [
                    514, 
                    514
                ], 
                [
                    513, 
                    513
                ], 
                [
                    512, 
                    512
                ], 
                [
                    511, 
                    511
                ], 
                [
                    510, 
                    510
                ], 
                [
                    509, 
                    509
                ], 
                [
                    508, 
                    508
                ], 
                [
                    507, 
                    507
                ], 
                [
                    506, 
                    506
                ], 
                [
                    505, 
                    505
                ], 
                [
                    504, 
                    504
                ], 
                [
                    503, 
                    503
                ], 
                [
                    502, 
                    502
                ], 
                [
                    501, 
                    501
                ], 
                [
                    500, 
                    500
                ], 
                [
                    499, 
                    499
                ], 
                [
                    498, 
                    498
                ], 
                [
                    497, 
                    497
                ], 
                [
                    496, 
                    496
                ], 
                [
                    495, 
                    495
                ], 
                [
                    494, 
                    494
                ], 
                [
                    493, 
                    493
                ], 
                [
                    492, 
                    492
                ], 
                [
                    491, 
                    491
                ], 
                [
                    490, 
                    490
                ], 
                [
                    489, 
                    489
                ], 
                [
                    488, 
                    488
                ], 
                [
                    487, 
                    487
                ], 
                [
                    486, 
                    486
                ], 
                [
                    485, 
                    485
                ], 
                [
                    484, 
                    484
                ], 
                [
                    483, 
                    483
                ], 
                [
                    482, 
                    482
                ], 
                [
                    481, 
                    481
                ], 
                [
                    480, 
                    480
                ], 
                [
                    479, 
                    479
                ], 
                [
                    478, 
                    478
                ], 
                [
                    477, 
                    477
                ], 
                [
                    476, 
                    476
                ], 
                [
                    475, 
                    475
                ], 
                [
                    474, 
                    474
                ], 
                [
                    473, 
                    473
                ], 
                [
                    472, 
                    472
                ], 
                [
                    471, 
                    471
                ], 
                [
                    470, 
                    470
                ], 
                [
                    469, 
                    469
                ], 
                [
                    468, 
                    468
                ], 
                [
                    467, 
                    467
                ], 
                [
                    466, 
                    466
                ], 
                [
                    465, 
                    465
                ], 
                [
                    464, 
                    464
                ], 
                [
                    463, 
                    463
                ], 
                [
                    462, 
                    462
                ], 
                [
                    461, 
                    461
                ], 
                [
                    460, 
                    460
                ], 
                [
                    459, 
                    459
                ], 
                [
                    458, 
                    458
                ], 
                [
                    457, 
                    457
                ], 
                [
                    456, 
                    456
                ], 
                [
                    455, 
                    455
                ], 
                [
                    454, 
                    454
                ], 
                [
                    453, 
                    453
                ], 
                [
                    452, 
                    452
                ], 
                [
                    451, 
                    451
                ], 
                [
                    450, 
                    450
                ], 
                [
                    449, 
                    449
                ], 
                [
                    448, 
                    448
                ], 
                [
                    447, 
                    447
                ], 
                [
                    446, 
                    446
                ], 
                [
                    445, 
                    445
                ], 
                [
                    444, 
                    444
                ], 
                [
                    443, 
                    443
                ], 
                [
                    442, 
                    442
                ], 
                [
                    441, 
                    441
                ], 
                [
                    440, 
                    440
                ], 
                [
                    439, 
                    439
                ], 
                [
                    438, 
                    438
                ], 
                [
                    437, 
                    437
                ], 
                [
                    436, 
                    436
                ], 
                [
                    435, 
                    435
                ], 
                [
                    434, 
                    434
                ], 
                [
                    433, 
                    433
                ], 
                [
                    432, 
                    432
                ], 
                [
                    431, 
                    431
                ], 
                [
                    430, 
                    430
                ], 
                [
                    429, 
                    429
                ], 
                [
                    428, 
                    428
                ], 
                [
                    427, 
                    427
                ], 
                [
                    426, 
                    426
                ], 
                [
                    425, 
                    425
                ], 
                [
                    424, 
                    424
                ], 
                [
                    423, 
                    423
                ], 
                [
                    422, 
                    422
                ], 
                [
                    421, 
                    421
                ], 
                [
                    420, 
                    420
                ], 
                [
                    419, 
                    419
                ], 
                [
                    418, 
                    418
                ], 
                [
                    417, 
                    417
                ], 
                [
                    416, 
                    416
                ], 
                [
                    415, 
                    415
                ], 
                [
                    414, 
                    414
                ], 
                [
                    413, 
                    413
                ], 
                [
                    412, 
                    412
                ], 
                [
                    411, 
                    411
                ], 
                [
                    410, 
                    410
                ], 
                [
                    409, 
                    409
                ], 
                [
                    408, 
                    408
                ], 
                [
                    407, 
                    407
                ], 
                [
                    406, 
                    406
                ], 
                [
                    405, 
                    405
                ], 
                [
                    404, 
                    404
                ], 
                [
                    403, 
                    403
                ], 
                [
                    402, 
                    402
                ], 
                [
                    401, 
                    401
                ], 
                [
                    400, 
                    400
                ]
            ], 
            z: [
                [
                    0.883685904, 
                    0.883685904
                ], 
                [
                    0.891754021, 
                    0.891754021
                ], 
                [
                    0.890997162, 
                    0.890997162
                ], 
                [
                    0.906138127, 
                    0.906138127
                ], 
                [
                    0.894622937, 
                    0.894622937
                ], 
                [
                    0.897362767, 
                    0.897362767
                ], 
                [
                    0.899487859, 
                    0.899487859
                ], 
                [
                    0.901397246, 
                    0.901397246
                ], 
                [
                    0.901467466, 
                    0.901467466
                ], 
                [
                    0.901789131, 
                    0.901789131
                ], 
                [
                    0.904822874, 
                    0.904822874
                ], 
                [
                    0.904205613, 
                    0.904205613
                ], 
                [
                    0.907128771, 
                    0.907128771
                ], 
                [
                    0.911924314, 
                    0.911924314
                ], 
                [
                    0.911135078, 
                    0.911135078
                ], 
                [
                    0.912541575, 
                    0.912541575
                ], 
                [
                    0.914062441, 
                    0.914062441
                ], 
                [
                    0.918784821, 
                    0.918784821
                ], 
                [
                    0.921185746, 
                    0.921185746
                ], 
                [
                    0.92344455, 
                    0.92344455
                ], 
                [
                    0.925657942, 
                    0.925657942
                ], 
                [
                    0.927529486, 
                    0.927529486
                ], 
                [
                    0.928978451, 
                    0.928978451
                ], 
                [
                    0.933184064, 
                    0.933184064
                ], 
                [
                    0.934784821, 
                    0.934784821
                ], 
                [
                    0.938140229, 
                    0.938140229
                ], 
                [
                    0.937643225, 
                    0.937643225
                ], 
                [
                    0.943266267, 
                    0.943266267
                ], 
                [
                    0.94696899, 
                    0.94696899
                ], 
                [
                    0.950327342, 
                    0.950327342
                ], 
                [
                    0.953397666, 
                    0.953397666
                ], 
                [
                    0.957658783, 
                    0.957658783
                ], 
                [
                    0.961901819, 
                    0.961901819
                ], 
                [
                    0.965859771, 
                    0.965859771
                ], 
                [
                    0.967810785, 
                    0.967810785
                ], 
                [
                    0.971072848, 
                    0.971072848
                ], 
                [
                    0.971379796, 
                    0.971379796
                ], 
                [
                    0.971776306, 
                    0.971776306
                ], 
                [
                    0.974373174, 
                    0.974373174
                ], 
                [
                    0.976256912, 
                    0.976256912
                ], 
                [
                    0.976286345, 
                    0.976286345
                ], 
                [
                    0.976977189, 
                    0.976977189
                ], 
                [
                    0.978159992, 
                    0.978159992
                ], 
                [
                    0.979135499, 
                    0.979135499
                ], 
                [
                    0.982953012, 
                    0.982953012
                ], 
                [
                    0.986331126, 
                    0.986331126
                ], 
                [
                    0.989937559, 
                    0.989937559
                ], 
                [
                    0.990715863, 
                    0.990715863
                ], 
                [
                    0.993802165, 
                    0.993802165
                ], 
                [
                    0.996224535, 
                    0.996224535
                ], 
                [
                    1, 
                    1
                ], 
                [
                    1.002645643, 
                    1.002645643
                ], 
                [
                    1.007972669, 
                    1.007972669
                ], 
                [
                    1.008073163, 
                    1.008073163
                ], 
                [
                    1.011377694, 
                    1.011377694
                ], 
                [
                    1.012280038, 
                    1.012280038
                ], 
                [
                    1.015302428, 
                    1.015302428
                ], 
                [
                    1.016931778, 
                    1.016931778
                ], 
                [
                    1.020277515, 
                    1.020277515
                ], 
                [
                    1.021861873, 
                    1.021861873
                ], 
                [
                    1.024140439, 
                    1.024140439
                ], 
                [
                    1.02648502, 
                    1.02648502
                ], 
                [
                    1.027757385, 
                    1.027757385
                ], 
                [
                    1.028453274, 
                    1.028453274
                ], 
                [
                    1.030011143, 
                    1.030011143
                ], 
                [
                    1.032393987, 
                    1.032393987
                ], 
                [
                    1.033710501, 
                    1.033710501
                ], 
                [
                    1.034966046, 
                    1.034966046
                ], 
                [
                    1.036706402, 
                    1.036706402
                ], 
                [
                    1.039109429, 
                    1.039109429
                ], 
                [
                    1.038935352, 
                    1.038935352
                ], 
                [
                    1.043389467, 
                    1.043389467
                ], 
                [
                    1.046869337, 
                    1.046869337
                ], 
                [
                    1.04906633, 
                    1.04906633
                ], 
                [
                    1.050783559, 
                    1.050783559
                ], 
                [
                    1.054386629, 
                    1.054386629
                ], 
                [
                    1.057924945, 
                    1.057924945
                ], 
                [
                    1.060681173, 
                    1.060681173
                ], 
                [
                    1.064725744, 
                    1.064725744
                ], 
                [
                    1.068586566, 
                    1.068586566
                ], 
                [
                    1.071511826, 
                    1.071511826
                ], 
                [
                    1.073996005, 
                    1.073996005
                ], 
                [
                    1.079236413, 
                    1.079236413
                ], 
                [
                    1.083461369, 
                    1.083461369
                ], 
                [
                    1.088684537, 
                    1.088684537
                ], 
                [
                    1.092373804, 
                    1.092373804
                ], 
                [
                    1.097562493, 
                    1.097562493
                ], 
                [
                    1.100220751, 
                    1.100220751
                ], 
                [
                    1.102719226, 
                    1.102719226
                ], 
                [
                    1.105325765, 
                    1.105325765
                ], 
                [
                    1.110306318, 
                    1.110306318
                ], 
                [
                    1.115051403, 
                    1.115051403
                ], 
                [
                    1.119935667, 
                    1.119935667
                ], 
                [
                    1.124856092, 
                    1.124856092
                ], 
                [
                    1.13113634, 
                    1.13113634
                ], 
                [
                    1.135809524, 
                    1.135809524
                ], 
                [
                    1.141939662, 
                    1.141939662
                ], 
                [
                    1.148373384, 
                    1.148373384
                ], 
                [
                    1.153624304, 
                    1.153624304
                ], 
                [
                    1.159063597, 
                    1.159063597
                ], 
                [
                    1.164748029, 
                    1.164748029
                ], 
                [
                    1.170765479, 
                    1.170765479
                ], 
                [
                    1.174621676, 
                    1.174621676
                ], 
                [
                    1.180216966, 
                    1.180216966
                ], 
                [
                    1.186994218, 
                    1.186994218
                ], 
                [
                    1.195209082, 
                    1.195209082
                ], 
                [
                    1.20170083, 
                    1.20170083
                ], 
                [
                    1.209184484, 
                    1.209184484
                ], 
                [
                    1.2139733, 
                    1.2139733
                ], 
                [
                    1.216214023, 
                    1.216214023
                ], 
                [
                    1.21990329, 
                    1.21990329
                ], 
                [
                    1.223267949, 
                    1.223267949
                ], 
                [
                    1.224685798, 
                    1.224685798
                ], 
                [
                    1.223685904, 
                    1.223685904
                ], 
                [
                    1.220596657, 
                    1.220596657
                ], 
                [
                    1.217882056, 
                    1.217882056
                ], 
                [
                    1.215459687, 
                    1.215459687
                ], 
                [
                    1.210305477, 
                    1.210305477
                ], 
                [
                    1.206223484, 
                    1.206223484
                ], 
                [
                    1.199537475, 
                    1.199537475
                ], 
                [
                    1.193686955, 
                    1.193686955
                ], 
                [
                    1.185246715, 
                    1.185246715
                ], 
                [
                    1.178153684, 
                    1.178153684
                ], 
                [
                    1.17217618, 
                    1.17217618
                ], 
                [
                    1.16664354, 
                    1.16664354
                ], 
                [
                    1.159688006, 
                    1.159688006
                ], 
                [
                    1.155629139, 
                    1.155629139
                ], 
                [
                    1.150461894, 
                    1.150461894
                ], 
                [
                    1.145011248, 
                    1.145011248
                ], 
                [
                    1.141122674, 
                    1.141122674
                ], 
                [
                    1.138691054, 
                    1.138691054
                ], 
                [
                    1.135404604, 
                    1.135404604
                ], 
                [
                    1.131865447, 
                    1.131865447
                ], 
                [
                    1.131265426, 
                    1.131265426
                ], 
                [
                    1.130200778, 
                    1.130200778
                ], 
                [
                    1.128410386, 
                    1.128410386
                ], 
                [
                    1.129463261, 
                    1.129463261
                ], 
                [
                    1.132230842, 
                    1.132230842
                ], 
                [
                    1.134218017, 
                    1.134218017
                ], 
                [
                    1.136716493, 
                    1.136716493
                ], 
                [
                    1.141784085, 
                    1.141784085
                ], 
                [
                    1.146359718, 
                    1.146359718
                ], 
                [
                    1.150315568, 
                    1.150315568
                ], 
                [
                    1.15534784, 
                    1.15534784
                ], 
                [
                    1.161761379, 
                    1.161761379
                ], 
                [
                    1.165264375, 
                    1.165264375
                ], 
                [
                    1.169967833, 
                    1.169967833
                ], 
                [
                    1.17161274, 
                    1.17161274
                ], 
                [
                    1.175483654, 
                    1.175483654
                ], 
                [
                    1.178217176, 
                    1.178217176
                ], 
                [
                    1.183880584, 
                    1.183880584
                ], 
                [
                    1.188789236, 
                    1.188789236
                ], 
                [
                    1.191408809, 
                    1.191408809
                ], 
                [
                    1.194649427, 
                    1.194649427
                ], 
                [
                    1.199449595, 
                    1.199449595
                ], 
                [
                    1.202121728, 
                    1.202121728
                ], 
                [
                    1.207315043, 
                    1.207315043
                ], 
                [
                    1.210967728, 
                    1.210967728
                ], 
                [
                    1.214638495, 
                    1.214638495
                ], 
                [
                    1.215082098, 
                    1.215082098
                ], 
                [
                    1.214733943, 
                    1.214733943
                ], 
                [
                    1.217249238, 
                    1.217249238
                ], 
                [
                    1.21650205, 
                    1.21650205
                ], 
                [
                    1.218526648, 
                    1.218526648
                ], 
                [
                    1.2189425, 
                    1.2189425
                ], 
                [
                    1.220128666, 
                    1.220128666
                ], 
                [
                    1.218827709, 
                    1.218827709
                ], 
                [
                    1.217149585, 
                    1.217149585
                ], 
                [
                    1.215672869, 
                    1.215672869
                ], 
                [
                    1.213424997, 
                    1.213424997
                ], 
                [
                    1.209873226, 
                    1.209873226
                ], 
                [
                    1.207680437, 
                    1.207680437
                ], 
                [
                    1.202186902, 
                    1.202186902
                ], 
                [
                    1.199015242, 
                    1.199015242
                ], 
                [
                    1.193803847, 
                    1.193803847
                ], 
                [
                    1.189644486, 
                    1.189644486
                ], 
                [
                    1.185872385, 
                    1.185872385
                ], 
                [
                    1.181721434, 
                    1.181721434
                ], 
                [
                    1.177328708, 
                    1.177328708
                ], 
                [
                    1.174679281, 
                    1.174679281
                ], 
                [
                    1.17219426, 
                    1.17219426
                ], 
                [
                    1.169221066, 
                    1.169221066
                ], 
                [
                    1.16412362, 
                    1.16412362
                ], 
                [
                    1.16036119, 
                    1.16036119
                ], 
                [
                    1.157739514, 
                    1.157739514
                ], 
                [
                    1.154950068, 
                    1.154950068
                ], 
                [
                    1.15223757, 
                    1.15223757
                ], 
                [
                    1.149160517, 
                    1.149160517
                ], 
                [
                    1.146186061, 
                    1.146186061
                ], 
                [
                    1.14214948, 
                    1.14214948
                ], 
                [
                    1.141102491, 
                    1.141102491
                ], 
                [
                    1.141215179, 
                    1.141215179
                ], 
                [
                    1.1406694, 
                    1.1406694
                ], 
                [
                    1.140618942, 
                    1.140618942
                ], 
                [
                    1.140234206, 
                    1.140234206
                ], 
                [
                    1.138812572, 
                    1.138812572
                ], 
                [
                    1.141388416, 
                    1.141388416
                ], 
                [
                    1.141055818, 
                    1.141055818
                ], 
                [
                    1.143481972, 
                    1.143481972
                ], 
                [
                    1.143804478, 
                    1.143804478
                ], 
                [
                    1.144300221, 
                    1.144300221
                ], 
                [
                    1.145800904, 
                    1.145800904
                ], 
                [
                    1.145598234, 
                    1.145598234
                ], 
                [
                    1.146734364, 
                    1.146734364
                ], 
                [
                    1.14768296, 
                    1.14768296
                ], 
                [
                    1.146544728, 
                    1.146544728
                ], 
                [
                    1.147495848, 
                    1.147495848
                ], 
                [
                    1.149076422, 
                    1.149076422
                ], 
                [
                    1.15012341, 
                    1.15012341
                ], 
                [
                    1.15213245, 
                    1.15213245
                ], 
                [
                    1.151794807, 
                    1.151794807
                ], 
                [
                    1.153387575, 
                    1.153387575
                ], 
                [
                    1.154407232, 
                    1.154407232
                ], 
                [
                    1.156600442, 
                    1.156600442
                ], 
                [
                    1.159033323, 
                    1.159033323
                ], 
                [
                    1.160265742, 
                    1.160265742
                ], 
                [
                    1.160053401, 
                    1.160053401
                ], 
                [
                    1.15999958, 
                    1.15999958
                ], 
                [
                    1.159466835, 
                    1.159466835
                ], 
                [
                    1.160855251, 
                    1.160855251
                ], 
                [
                    1.162521602, 
                    1.162521602
                ], 
                [
                    1.163894881, 
                    1.163894881
                ], 
                [
                    1.164124882, 
                    1.164124882
                ], 
                [
                    1.16437843, 
                    1.16437843
                ], 
                [
                    1.167871334, 
                    1.167871334
                ], 
                [
                    1.169450646, 
                    1.169450646
                ], 
                [
                    1.173472091, 
                    1.173472091
                ], 
                [
                    1.175049721, 
                    1.175049721
                ], 
                [
                    1.177631031, 
                    1.177631031
                ], 
                [
                    1.181255125, 
                    1.181255125
                ], 
                [
                    1.182915169, 
                    1.182915169
                ], 
                [
                    1.186631347, 
                    1.186631347
                ], 
                [
                    1.191032482, 
                    1.191032482
                ], 
                [
                    1.194033428, 
                    1.194033428
                ], 
                [
                    1.197451067, 
                    1.197451067
                ], 
                [
                    1.199001787, 
                    1.199001787
                ], 
                [
                    1.201087354, 
                    1.201087354
                ], 
                [
                    1.20487249, 
                    1.20487249
                ], 
                [
                    1.206696941, 
                    1.206696941
                ], 
                [
                    1.210455167, 
                    1.210455167
                ], 
                [
                    1.213255966, 
                    1.213255966
                ], 
                [
                    1.216427625, 
                    1.216427625
                ], 
                [
                    1.218186061, 
                    1.218186061
                ], 
                [
                    1.222165037, 
                    1.222165037
                ], 
                [
                    1.226143173, 
                    1.226143173
                ], 
                [
                    1.231914643, 
                    1.231914643
                ], 
                [
                    1.23524188, 
                    1.23524188
                ], 
                [
                    1.240074845, 
                    1.240074845
                ], 
                [
                    1.24516304, 
                    1.24516304
                ], 
                [
                    1.251000105, 
                    1.251000105
                ], 
                [
                    1.254174708, 
                    1.254174708
                ], 
                [
                    1.260592032, 
                    1.260592032
                ], 
                [
                    1.262372333, 
                    1.262372333
                ], 
                [
                    1.266922317, 
                    1.266922317
                ], 
                [
                    1.271799853, 
                    1.271799853
                ], 
                [
                    1.275609377, 
                    1.275609377
                ], 
                [
                    1.279670766, 
                    1.279670766
                ], 
                [
                    1.282691054, 
                    1.282691054
                ], 
                [
                    1.286606538, 
                    1.286606538
                ], 
                [
                    1.292180805, 
                    1.292180805
                ], 
                [
                    1.294856302, 
                    1.294856302
                ], 
                [
                    1.301746662, 
                    1.301746662
                ], 
                [
                    1.305737832, 
                    1.305737832
                ], 
                [
                    1.309181121, 
                    1.309181121
                ], 
                [
                    1.314885315, 
                    1.314885315
                ], 
                [
                    1.318637654, 
                    1.318637654
                ], 
                [
                    1.323996216, 
                    1.323996216
                ], 
                [
                    1.327755703, 
                    1.327755703
                ], 
                [
                    1.330807947, 
                    1.330807947
                ], 
                [
                    1.334577525, 
                    1.334577525
                ], 
                [
                    1.337625145, 
                    1.337625145
                ], 
                [
                    1.342366025, 
                    1.342366025
                ], 
                [
                    1.346992116, 
                    1.346992116
                ], 
                [
                    1.350493851, 
                    1.350493851
                ], 
                [
                    1.354444234, 
                    1.354444234
                ], 
                [
                    1.358159571, 
                    1.358159571
                ], 
                [
                    1.36267003, 
                    1.36267003
                ], 
                [
                    1.367306213, 
                    1.367306213
                ], 
                [
                    1.372244718, 
                    1.372244718
                ], 
                [
                    1.37662525, 
                    1.37662525
                ], 
                [
                    1.37884579, 
                    1.37884579
                ], 
                [
                    1.383508462, 
                    1.383508462
                ], 
                [
                    1.386025859, 
                    1.386025859
                ], 
                [
                    1.388507937, 
                    1.388507937
                ], 
                [
                    1.390464417, 
                    1.390464417
                ], 
                [
                    1.394162514, 
                    1.394162514
                ], 
                [
                    1.396771576, 
                    1.396771576
                ], 
                [
                    1.398749921, 
                    1.398749921
                ], 
                [
                    1.400910754, 
                    1.400910754
                ], 
                [
                    1.406011984, 
                    1.406011984
                ], 
                [
                    1.407992011, 
                    1.407992011
                ], 
                [
                    1.410331546, 
                    1.410331546
                ], 
                [
                    1.414596867, 
                    1.414596867
                ], 
                [
                    1.41829034, 
                    1.41829034
                ], 
                [
                    1.419159886, 
                    1.419159886
                ], 
                [
                    1.422521602, 
                    1.422521602
                ], 
                [
                    1.426727215, 
                    1.426727215
                ], 
                [
                    1.428871229, 
                    1.428871229
                ], 
                [
                    1.428568065, 
                    1.428568065
                ], 
                [
                    1.429728161, 
                    1.429728161
                ], 
                [
                    1.433892148, 
                    1.433892148
                ], 
                [
                    1.435452959, 
                    1.435452959
                ], 
                [
                    1.436153474, 
                    1.436153474
                ], 
                [
                    1.439423105, 
                    1.439423105
                ], 
                [
                    1.442723431, 
                    1.442723431
                ], 
                [
                    1.443605592, 
                    1.443605592
                ], 
                [
                    1.445644486, 
                    1.445644486
                ], 
                [
                    1.449908967, 
                    1.449908967
                ], 
                [
                    1.453768107, 
                    1.453768107
                ], 
                [
                    1.45492484, 
                    1.45492484
                ], 
                [
                    1.458668769, 
                    1.458668769
                ], 
                [
                    1.462166299, 
                    1.462166299
                ], 
                [
                    1.465126879, 
                    1.465126879
                ], 
                [
                    1.467730474, 
                    1.467730474
                ], 
                [
                    1.470662041, 
                    1.470662041
                ], 
                [
                    1.474441711, 
                    1.474441711
                ], 
                [
                    1.476674866, 
                    1.476674866
                ], 
                [
                    1.480579418, 
                    1.480579418
                ], 
                [
                    1.484915379, 
                    1.484915379
                ], 
                [
                    1.486548092, 
                    1.486548092
                ], 
                [
                    1.489557868, 
                    1.489557868
                ], 
                [
                    1.492351519, 
                    1.492351519
                ], 
                [
                    1.494988332, 
                    1.494988332
                ], 
                [
                    1.498117944, 
                    1.498117944
                ], 
                [
                    1.502068748, 
                    1.502068748
                ], 
                [
                    1.505529276, 
                    1.505529276
                ], 
                [
                    1.50571807, 
                    1.50571807
                ], 
                [
                    1.507087144, 
                    1.507087144
                ], 
                [
                    1.509728161, 
                    1.509728161
                ], 
                [
                    1.513677284, 
                    1.513677284
                ], 
                [
                    1.515373909, 
                    1.515373909
                ], 
                [
                    1.519201934, 
                    1.519201934
                ], 
                [
                    1.522649007, 
                    1.522649007
                ], 
                [
                    1.526018291, 
                    1.526018291
                ], 
                [
                    1.528913697, 
                    1.528913697
                ], 
                [
                    1.533366131, 
                    1.533366131
                ], 
                [
                    1.538955955, 
                    1.538955955
                ], 
                [
                    1.543193104, 
                    1.543193104
                ], 
                [
                    1.547635867, 
                    1.547635867
                ], 
                [
                    1.551384842, 
                    1.551384842
                ], 
                [
                    1.55545338, 
                    1.55545338
                ], 
                [
                    1.559534532, 
                    1.559534532
                ], 
                [
                    1.562444234, 
                    1.562444234
                ], 
                [
                    1.566816357, 
                    1.566816357
                ], 
                [
                    1.570156207, 
                    1.570156207
                ], 
                [
                    1.571032482, 
                    1.571032482
                ], 
                [
                    1.57475749, 
                    1.57475749
                ], 
                [
                    1.576987701, 
                    1.576987701
                ], 
                [
                    1.577801325, 
                    1.577801325
                ], 
                [
                    1.57790308, 
                    1.57790308
                ], 
                [
                    1.578368969, 
                    1.578368969
                ], 
                [
                    1.577715547, 
                    1.577715547
                ], 
                [
                    1.575260801, 
                    1.575260801
                ], 
                [
                    1.57351498, 
                    1.57351498
                ], 
                [
                    1.572521392, 
                    1.572521392
                ], 
                [
                    1.568447808, 
                    1.568447808
                ], 
                [
                    1.563274256, 
                    1.563274256
                ], 
                [
                    1.562023336, 
                    1.562023336
                ], 
                [
                    1.556730789, 
                    1.556730789
                ], 
                [
                    1.551060654, 
                    1.551060654
                ], 
                [
                    1.546278987, 
                    1.546278987
                ], 
                [
                    1.543653106, 
                    1.543653106
                ], 
                [
                    1.540345212, 
                    1.540345212
                ], 
                [
                    1.536558394, 
                    1.536558394
                ], 
                [
                    1.533871965, 
                    1.533871965
                ], 
                [
                    1.530826868, 
                    1.530826868
                ], 
                [
                    1.527635446, 
                    1.527635446
                ], 
                [
                    1.526797856, 
                    1.526797856
                ], 
                [
                    1.525528855, 
                    1.525528855
                ], 
                [
                    1.525123095, 
                    1.525123095
                ], 
                [
                    1.524761905, 
                    1.524761905
                ], 
                [
                    1.526186902, 
                    1.526186902
                ], 
                [
                    1.527962578, 
                    1.527962578
                ], 
                [
                    1.528148428, 
                    1.528148428
                ], 
                [
                    1.529546936, 
                    1.529546936
                ], 
                [
                    1.529357721, 
                    1.529357721
                ], 
                [
                    1.530688111, 
                    1.530688111
                ], 
                [
                    1.531661936, 
                    1.531661936
                ], 
                [
                    1.533604541, 
                    1.533604541
                ], 
                [
                    1.534295385, 
                    1.534295385
                ], 
                [
                    1.533086093, 
                    1.533086093
                ], 
                [
                    1.531896563, 
                    1.531896563
                ], 
                [
                    1.533430884, 
                    1.533430884
                ], 
                [
                    1.535119941, 
                    1.535119941
                ], 
                [
                    1.536803952, 
                    1.536803952
                ], 
                [
                    1.537188689, 
                    1.537188689
                ], 
                [
                    1.539167876, 
                    1.539167876
                ], 
                [
                    1.540497845, 
                    1.540497845
                ], 
                [
                    1.542276043, 
                    1.542276043
                ], 
                [
                    1.54547167, 
                    1.54547167
                ], 
                [
                    1.548666036, 
                    1.548666036
                ], 
                [
                    1.551316724, 
                    1.551316724
                ], 
                [
                    1.553182382, 
                    1.553182382
                ], 
                [
                    1.556575633, 
                    1.556575633
                ], 
                [
                    1.558962683, 
                    1.558962683
                ], 
                [
                    1.562794492, 
                    1.562794492
                ], 
                [
                    1.566116262, 
                    1.566116262
                ], 
                [
                    1.567607852, 
                    1.567607852
                ], 
                [
                    1.568935262, 
                    1.568935262
                ], 
                [
                    1.57037843, 
                    1.57037843
                ], 
                [
                    1.571075118, 
                    1.571075118
                ]
            ], 
            cmax: 1.578368969, 
            cmin: 0.883685904, 
            colorscale: [
                [
                    "0", 
                    "rgb(155,155,255)"
                ], 
                [
                    "0.1", 
                    "rgb(155,155,255)"
                ], 
                [
                    "0.2", 
                    "rgb(155,155,255)"
                ], 
                [
                    "0.3", 
                    "rgb(155,155,255)"
                ], 
                [
                    "0.4", 
                    "rgb(155,155,255)"
                ], 
                [
                    "0.5", 
                    "rgb(155,155,255)"
                ], 
                [
                    "0.6", 
                    "rgb(155,155,255)"
                ], 
                [
                    "0.7", 
                    "rgb(155,155,255)"
                ], 
                [
                    "0.8", 
                    "rgb(155,155,255)"
                ], 
                [
                    "0.9", 
                    "rgb(155,155,255)"
                ], 
                [
                    "1", 
                    "rgb(155,155,255)"
                ]
            ], 
            name: "", 
            showscale: false, 
            type: "surface", 
            "uid": "8e9ebd", 
            "xsrc": "Diksha_Gabha:2343:eefe0b,d17c09", 
            "ysrc": "Diksha_Gabha:2343:807139,59d0b7", 
            "zsrc": "Diksha_Gabha:2343:0fcd0f,2d1da7"
        }, 
        {
            x: [
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ], 
                [
                    12, 
                    13
                ]
            ], 
            y: [
                [
                    800, 
                    800
                ], 
                [
                    799, 
                    799
                ], 
                [
                    798, 
                    798
                ], 
                [
                    797, 
                    797
                ], 
                [
                    796, 
                    796
                ], 
                [
                    795, 
                    795
                ], 
                [
                    794, 
                    794
                ], 
                [
                    793, 
                    793
                ], 
                [
                    792, 
                    792
                ], 
                [
                    791, 
                    791
                ], 
                [
                    790, 
                    790
                ], 
                [
                    789, 
                    789
                ], 
                [
                    788, 
                    788
                ], 
                [
                    787, 
                    787
                ], 
                [
                    786, 
                    786
                ], 
                [
                    785, 
                    785
                ], 
                [
                    784, 
                    784
                ], 
                [
                    783, 
                    783
                ], 
                [
                    782, 
                    782
                ], 
                [
                    781, 
                    781
                ], 
                [
                    780, 
                    780
                ], 
                [
                    779, 
                    779
                ], 
                [
                    778, 
                    778
                ], 
                [
                    777, 
                    777
                ], 
                [
                    776, 
                    776
                ], 
                [
                    775, 
                    775
                ], 
                [
                    774, 
                    774
                ], 
                [
                    773, 
                    773
                ], 
                [
                    772, 
                    772
                ], 
                [
                    771, 
                    771
                ], 
                [
                    770, 
                    770
                ], 
                [
                    769, 
                    769
                ], 
                [
                    768, 
                    768
                ], 
                [
                    767, 
                    767
                ], 
                [
                    766, 
                    766
                ], 
                [
                    765, 
                    765
                ], 
                [
                    764, 
                    764
                ], 
                [
                    763, 
                    763
                ], 
                [
                    762, 
                    762
                ], 
                [
                    761, 
                    761
                ], 
                [
                    760, 
                    760
                ], 
                [
                    759, 
                    759
                ], 
                [
                    758, 
                    758
                ], 
                [
                    757, 
                    757
                ], 
                [
                    756, 
                    756
                ], 
                [
                    755, 
                    755
                ], 
                [
                    754, 
                    754
                ], 
                [
                    753, 
                    753
                ], 
                [
                    752, 
                    752
                ], 
                [
                    751, 
                    751
                ], 
                [
                    750, 
                    750
                ], 
                [
                    749, 
                    749
                ], 
                [
                    748, 
                    748
                ], 
                [
                    747, 
                    747
                ], 
                [
                    746, 
                    746
                ], 
                [
                    745, 
                    745
                ], 
                [
                    744, 
                    744
                ], 
                [
                    743, 
                    743
                ], 
                [
                    742, 
                    742
                ], 
                [
                    741, 
                    741
                ], 
                [
                    740, 
                    740
                ], 
                [
                    739, 
                    739
                ], 
                [
                    738, 
                    738
                ], 
                [
                    737, 
                    737
                ], 
                [
                    736, 
                    736
                ], 
                [
                    735, 
                    735
                ], 
                [
                    734, 
                    734
                ], 
                [
                    733, 
                    733
                ], 
                [
                    732, 
                    732
                ], 
                [
                    731, 
                    731
                ], 
                [
                    730, 
                    730
                ], 
                [
                    729, 
                    729
                ], 
                [
                    728, 
                    728
                ], 
                [
                    727, 
                    727
                ], 
                [
                    726, 
                    726
                ], 
                [
                    725, 
                    725
                ], 
                [
                    724, 
                    724
                ], 
                [
                    723, 
                    723
                ], 
                [
                    722, 
                    722
                ], 
                [
                    721, 
                    721
                ], 
                [
                    720, 
                    720
                ], 
                [
                    719, 
                    719
                ], 
                [
                    718, 
                    718
                ], 
                [
                    717, 
                    717
                ], 
                [
                    716, 
                    716
                ], 
                [
                    715, 
                    715
                ], 
                [
                    714, 
                    714
                ], 
                [
                    713, 
                    713
                ], 
                [
                    712, 
                    712
                ], 
                [
                    711, 
                    711
                ], 
                [
                    710, 
                    710
                ], 
                [
                    709, 
                    709
                ], 
                [
                    708, 
                    708
                ], 
                [
                    707, 
                    707
                ], 
                [
                    706, 
                    706
                ], 
                [
                    705, 
                    705
                ], 
                [
                    704, 
                    704
                ], 
                [
                    703, 
                    703
                ], 
                [
                    702, 
                    702
                ], 
                [
                    701, 
                    701
                ], 
                [
                    700, 
                    700
                ], 
                [
                    699, 
                    699
                ], 
                [
                    698, 
                    698
                ], 
                [
                    697, 
                    697
                ], 
                [
                    696, 
                    696
                ], 
                [
                    695, 
                    695
                ], 
                [
                    694, 
                    694
                ], 
                [
                    693, 
                    693
                ], 
                [
                    692, 
                    692
                ], 
                [
                    691, 
                    691
                ], 
                [
                    690, 
                    690
                ], 
                [
                    689, 
                    689
                ], 
                [
                    688, 
                    688
                ], 
                [
                    687, 
                    687
                ], 
                [
                    686, 
                    686
                ], 
                [
                    685, 
                    685
                ], 
                [
                    684, 
                    684
                ], 
                [
                    683, 
                    683
                ], 
                [
                    682, 
                    682
                ], 
                [
                    681, 
                    681
                ], 
                [
                    680, 
                    680
                ], 
                [
                    679, 
                    679
                ], 
                [
                    678, 
                    678
                ], 
                [
                    677, 
                    677
                ], 
                [
                    676, 
                    676
                ], 
                [
                    675, 
                    675
                ], 
                [
                    674, 
                    674
                ], 
                [
                    673, 
                    673
                ], 
                [
                    672, 
                    672
                ], 
                [
                    671, 
                    671
                ], 
                [
                    670, 
                    670
                ], 
                [
                    669, 
                    669
                ], 
                [
                    668, 
                    668
                ], 
                [
                    667, 
                    667
                ], 
                [
                    666, 
                    666
                ], 
                [
                    665, 
                    665
                ], 
                [
                    664, 
                    664
                ], 
                [
                    663, 
                    663
                ], 
                [
                    662, 
                    662
                ], 
                [
                    661, 
                    661
                ], 
                [
                    660, 
                    660
                ], 
                [
                    659, 
                    659
                ], 
                [
                    658, 
                    658
                ], 
                [
                    657, 
                    657
                ], 
                [
                    656, 
                    656
                ], 
                [
                    655, 
                    655
                ], 
                [
                    654, 
                    654
                ], 
                [
                    653, 
                    653
                ], 
                [
                    652, 
                    652
                ], 
                [
                    651, 
                    651
                ], 
                [
                    650, 
                    650
                ], 
                [
                    649, 
                    649
                ], 
                [
                    648, 
                    648
                ], 
                [
                    647, 
                    647
                ], 
                [
                    646, 
                    646
                ], 
                [
                    645, 
                    645
                ], 
                [
                    644, 
                    644
                ], 
                [
                    643, 
                    643
                ], 
                [
                    642, 
                    642
                ], 
                [
                    641, 
                    641
                ], 
                [
                    640, 
                    640
                ], 
                [
                    639, 
                    639
                ], 
                [
                    638, 
                    638
                ], 
                [
                    637, 
                    637
                ], 
                [
                    636, 
                    636
                ], 
                [
                    635, 
                    635
                ], 
                [
                    634, 
                    634
                ], 
                [
                    633, 
                    633
                ], 
                [
                    632, 
                    632
                ], 
                [
                    631, 
                    631
                ], 
                [
                    630, 
                    630
                ], 
                [
                    629, 
                    629
                ], 
                [
                    628, 
                    628
                ], 
                [
                    627, 
                    627
                ], 
                [
                    626, 
                    626
                ], 
                [
                    625, 
                    625
                ], 
                [
                    624, 
                    624
                ], 
                [
                    623, 
                    623
                ], 
                [
                    622, 
                    622
                ], 
                [
                    621, 
                    621
                ], 
                [
                    620, 
                    620
                ], 
                [
                    619, 
                    619
                ], 
                [
                    618, 
                    618
                ], 
                [
                    617, 
                    617
                ], 
                [
                    616, 
                    616
                ], 
                [
                    615, 
                    615
                ], 
                [
                    614, 
                    614
                ], 
                [
                    613, 
                    613
                ], 
                [
                    612, 
                    612
                ], 
                [
                    611, 
                    611
                ], 
                [
                    610, 
                    610
                ], 
                [
                    609, 
                    609
                ], 
                [
                    608, 
                    608
                ], 
                [
                    607, 
                    607
                ], 
                [
                    606, 
                    606
                ], 
                [
                    605, 
                    605
                ], 
                [
                    604, 
                    604
                ], 
                [
                    603, 
                    603
                ], 
                [
                    602, 
                    602
                ], 
                [
                    601, 
                    601
                ], 
                [
                    600, 
                    600
                ], 
                [
                    599, 
                    599
                ], 
                [
                    598, 
                    598
                ], 
                [
                    597, 
                    597
                ], 
                [
                    596, 
                    596
                ], 
                [
                    595, 
                    595
                ], 
                [
                    594, 
                    594
                ], 
                [
                    593, 
                    593
                ], 
                [
                    592, 
                    592
                ], 
                [
                    591, 
                    591
                ], 
                [
                    590, 
                    590
                ], 
                [
                    589, 
                    589
                ], 
                [
                    588, 
                    588
                ], 
                [
                    587, 
                    587
                ], 
                [
                    586, 
                    586
                ], 
                [
                    585, 
                    585
                ], 
                [
                    584, 
                    584
                ], 
                [
                    583, 
                    583
                ], 
                [
                    582, 
                    582
                ], 
                [
                    581, 
                    581
                ], 
                [
                    580, 
                    580
                ], 
                [
                    579, 
                    579
                ], 
                [
                    578, 
                    578
                ], 
                [
                    577, 
                    577
                ], 
                [
                    576, 
                    576
                ], 
                [
                    575, 
                    575
                ], 
                [
                    574, 
                    574
                ], 
                [
                    573, 
                    573
                ], 
                [
                    572, 
                    572
                ], 
                [
                    571, 
                    571
                ], 
                [
                    570, 
                    570
                ], 
                [
                    569, 
                    569
                ], 
                [
                    568, 
                    568
                ], 
                [
                    567, 
                    567
                ], 
                [
                    566, 
                    566
                ], 
                [
                    565, 
                    565
                ], 
                [
                    564, 
                    564
                ], 
                [
                    563, 
                    563
                ], 
                [
                    562, 
                    562
                ], 
                [
                    561, 
                    561
                ], 
                [
                    560, 
                    560
                ], 
                [
                    559, 
                    559
                ], 
                [
                    558, 
                    558
                ], 
                [
                    557, 
                    557
                ], 
                [
                    556, 
                    556
                ], 
                [
                    555, 
                    555
                ], 
                [
                    554, 
                    554
                ], 
                [
                    553, 
                    553
                ], 
                [
                    552, 
                    552
                ], 
                [
                    551, 
                    551
                ], 
                [
                    550, 
                    550
                ], 
                [
                    549, 
                    549
                ], 
                [
                    548, 
                    548
                ], 
                [
                    547, 
                    547
                ], 
                [
                    546, 
                    546
                ], 
                [
                    545, 
                    545
                ], 
                [
                    544, 
                    544
                ], 
                [
                    543, 
                    543
                ], 
                [
                    542, 
                    542
                ], 
                [
                    541, 
                    541
                ], 
                [
                    540, 
                    540
                ], 
                [
                    539, 
                    539
                ], 
                [
                    538, 
                    538
                ], 
                [
                    537, 
                    537
                ], 
                [
                    536, 
                    536
                ], 
                [
                    535, 
                    535
                ], 
                [
                    534, 
                    534
                ], 
                [
                    533, 
                    533
                ], 
                [
                    532, 
                    532
                ], 
                [
                    531, 
                    531
                ], 
                [
                    530, 
                    530
                ], 
                [
                    529, 
                    529
                ], 
                [
                    528, 
                    528
                ], 
                [
                    527, 
                    527
                ], 
                [
                    526, 
                    526
                ], 
                [
                    525, 
                    525
                ], 
                [
                    524, 
                    524
                ], 
                [
                    523, 
                    523
                ], 
                [
                    522, 
                    522
                ], 
                [
                    521, 
                    521
                ], 
                [
                    520, 
                    520
                ], 
                [
                    519, 
                    519
                ], 
                [
                    518, 
                    518
                ], 
                [
                    517, 
                    517
                ], 
                [
                    516, 
                    516
                ], 
                [
                    515, 
                    515
                ], 
                [
                    514, 
                    514
                ], 
                [
                    513, 
                    513
                ], 
                [
                    512, 
                    512
                ], 
                [
                    511, 
                    511
                ], 
                [
                    510, 
                    510
                ], 
                [
                    509, 
                    509
                ], 
                [
                    508, 
                    508
                ], 
                [
                    507, 
                    507
                ], 
                [
                    506, 
                    506
                ], 
                [
                    505, 
                    505
                ], 
                [
                    504, 
                    504
                ], 
                [
                    503, 
                    503
                ], 
                [
                    502, 
                    502
                ], 
                [
                    501, 
                    501
                ], 
                [
                    500, 
                    500
                ], 
                [
                    499, 
                    499
                ], 
                [
                    498, 
                    498
                ], 
                [
                    497, 
                    497
                ], 
                [
                    496, 
                    496
                ], 
                [
                    495, 
                    495
                ], 
                [
                    494, 
                    494
                ], 
                [
                    493, 
                    493
                ], 
                [
                    492, 
                    492
                ], 
                [
                    491, 
                    491
                ], 
                [
                    490, 
                    490
                ], 
                [
                    489, 
                    489
                ], 
                [
                    488, 
                    488
                ], 
                [
                    487, 
                    487
                ], 
                [
                    486, 
                    486
                ], 
                [
                    485, 
                    485
                ], 
                [
                    484, 
                    484
                ], 
                [
                    483, 
                    483
                ], 
                [
                    482, 
                    482
                ], 
                [
                    481, 
                    481
                ], 
                [
                    480, 
                    480
                ], 
                [
                    479, 
                    479
                ], 
                [
                    478, 
                    478
                ], 
                [
                    477, 
                    477
                ], 
                [
                    476, 
                    476
                ], 
                [
                    475, 
                    475
                ], 
                [
                    474, 
                    474
                ], 
                [
                    473, 
                    473
                ], 
                [
                    472, 
                    472
                ], 
                [
                    471, 
                    471
                ], 
                [
                    470, 
                    470
                ], 
                [
                    469, 
                    469
                ], 
                [
                    468, 
                    468
                ], 
                [
                    467, 
                    467
                ], 
                [
                    466, 
                    466
                ], 
                [
                    465, 
                    465
                ], 
                [
                    464, 
                    464
                ], 
                [
                    463, 
                    463
                ], 
                [
                    462, 
                    462
                ], 
                [
                    461, 
                    461
                ], 
                [
                    460, 
                    460
                ], 
                [
                    459, 
                    459
                ], 
                [
                    458, 
                    458
                ], 
                [
                    457, 
                    457
                ], 
                [
                    456, 
                    456
                ], 
                [
                    455, 
                    455
                ], 
                [
                    454, 
                    454
                ], 
                [
                    453, 
                    453
                ], 
                [
                    452, 
                    452
                ], 
                [
                    451, 
                    451
                ], 
                [
                    450, 
                    450
                ], 
                [
                    449, 
                    449
                ], 
                [
                    448, 
                    448
                ], 
                [
                    447, 
                    447
                ], 
                [
                    446, 
                    446
                ], 
                [
                    445, 
                    445
                ], 
                [
                    444, 
                    444
                ], 
                [
                    443, 
                    443
                ], 
                [
                    442, 
                    442
                ], 
                [
                    441, 
                    441
                ], 
                [
                    440, 
                    440
                ], 
                [
                    439, 
                    439
                ], 
                [
                    438, 
                    438
                ], 
                [
                    437, 
                    437
                ], 
                [
                    436, 
                    436
                ], 
                [
                    435, 
                    435
                ], 
                [
                    434, 
                    434
                ], 
                [
                    433, 
                    433
                ], 
                [
                    432, 
                    432
                ], 
                [
                    431, 
                    431
                ], 
                [
                    430, 
                    430
                ], 
                [
                    429, 
                    429
                ], 
                [
                    428, 
                    428
                ], 
                [
                    427, 
                    427
                ], 
                [
                    426, 
                    426
                ], 
                [
                    425, 
                    425
                ], 
                [
                    424, 
                    424
                ], 
                [
                    423, 
                    423
                ], 
                [
                    422, 
                    422
                ], 
                [
                    421, 
                    421
                ], 
                [
                    420, 
                    420
                ], 
                [
                    419, 
                    419
                ], 
                [
                    418, 
                    418
                ], 
                [
                    417, 
                    417
                ], 
                [
                    416, 
                    416
                ], 
                [
                    415, 
                    415
                ], 
                [
                    414, 
                    414
                ], 
                [
                    413, 
                    413
                ], 
                [
                    412, 
                    412
                ], 
                [
                    411, 
                    411
                ], 
                [
                    410, 
                    410
                ], 
                [
                    409, 
                    409
                ], 
                [
                    408, 
                    408
                ], 
                [
                    407, 
                    407
                ], 
                [
                    406, 
                    406
                ], 
                [
                    405, 
                    405
                ], 
                [
                    404, 
                    404
                ], 
                [
                    403, 
                    403
                ], 
                [
                    402, 
                    402
                ], 
                [
                    401, 
                    401
                ], 
                [
                    400, 
                    400
                ]
            ], 
            z: [
                [
                    0.894839334, 
                    0.894839334
                ], 
                [
                    0.874247244, 
                    0.874247244
                ], 
                [
                    0.884246972, 
                    0.884246972
                ], 
                [
                    0.907675808, 
                    0.907675808
                ], 
                [
                    0.895428897, 
                    0.895428897
                ], 
                [
                    0.89782402, 
                    0.89782402
                ], 
                [
                    0.901786334, 
                    0.901786334
                ], 
                [
                    0.904736339, 
                    0.904736339
                ], 
                [
                    0.904163458, 
                    0.904163458
                ], 
                [
                    0.905794741, 
                    0.905794741
                ], 
                [
                    0.906940063, 
                    0.906940063
                ], 
                [
                    0.90950727, 
                    0.90950727
                ], 
                [
                    0.911041536, 
                    0.911041536
                ], 
                [
                    0.912278168, 
                    0.912278168
                ], 
                [
                    0.911434431, 
                    0.911434431
                ], 
                [
                    0.914353268, 
                    0.914353268
                ], 
                [
                    0.914907272, 
                    0.914907272
                ], 
                [
                    0.919229118, 
                    0.919229118
                ], 
                [
                    0.92231082, 
                    0.92231082
                ], 
                [
                    0.923947371, 
                    0.923947371
                ], 
                [
                    0.923433315, 
                    0.923433315
                ], 
                [
                    0.925507098, 
                    0.925507098
                ], 
                [
                    0.927496157, 
                    0.927496157
                ], 
                [
                    0.932119149, 
                    0.932119149
                ], 
                [
                    0.932760072, 
                    0.932760072
                ], 
                [
                    0.936048099, 
                    0.936048099
                ], 
                [
                    0.936921687, 
                    0.936921687
                ], 
                [
                    0.941756272, 
                    0.941756272
                ], 
                [
                    0.946905172, 
                    0.946905172
                ], 
                [
                    0.95131745, 
                    0.95131745
                ], 
                [
                    0.953882901, 
                    0.953882901
                ], 
                [
                    0.956778471, 
                    0.956778471
                ], 
                [
                    0.960592846, 
                    0.960592846
                ], 
                [
                    0.962859784, 
                    0.962859784
                ], 
                [
                    0.966137714, 
                    0.966137714
                ], 
                [
                    0.969448569, 
                    0.969448569
                ], 
                [
                    0.969271656, 
                    0.969271656
                ], 
                [
                    0.969862974, 
                    0.969862974
                ], 
                [
                    0.971577665, 
                    0.971577665
                ], 
                [
                    0.975089137, 
                    0.975089137
                ], 
                [
                    0.977011469, 
                    0.977011469
                ], 
                [
                    0.976389422, 
                    0.976389422
                ], 
                [
                    0.978710356, 
                    0.978710356
                ], 
                [
                    0.979613356, 
                    0.979613356
                ], 
                [
                    0.982462833, 
                    0.982462833
                ], 
                [
                    0.985706083, 
                    0.985706083
                ], 
                [
                    0.988587606, 
                    0.988587606
                ], 
                [
                    0.99130802, 
                    0.99130802
                ], 
                [
                    0.99187695, 
                    0.99187695
                ], 
                [
                    0.995982813, 
                    0.995982813
                ], 
                [
                    1, 
                    1
                ], 
                [
                    1.002729633, 
                    1.002729633
                ], 
                [
                    1.00517963, 
                    1.00517963
                ], 
                [
                    1.00621301, 
                    1.00621301
                ], 
                [
                    1.009454943, 
                    1.009454943
                ], 
                [
                    1.01067621, 
                    1.01067621
                ], 
                [
                    1.01443176, 
                    1.01443176
                ], 
                [
                    1.01889935, 
                    1.01889935
                ], 
                [
                    1.020378303, 
                    1.020378303
                ], 
                [
                    1.021505627, 
                    1.021505627
                ], 
                [
                    1.023794515, 
                    1.023794515
                ], 
                [
                    1.028153674, 
                    1.028153674
                ], 
                [
                    1.029091793, 
                    1.029091793
                ], 
                [
                    1.029519369, 
                    1.029519369
                ], 
                [
                    1.030856529, 
                    1.030856529
                ], 
                [
                    1.03049568, 
                    1.03049568
                ], 
                [
                    1.031553204, 
                    1.031553204
                ], 
                [
                    1.033268773, 
                    1.033268773
                ], 
                [
                    1.036322379, 
                    1.036322379
                ], 
                [
                    1.038913292, 
                    1.038913292
                ], 
                [
                    1.039115227, 
                    1.039115227
                ], 
                [
                    1.042585873, 
                    1.042585873
                ], 
                [
                    1.045093377, 
                    1.045093377
                ], 
                [
                    1.047607906, 
                    1.047607906
                ], 
                [
                    1.050144383, 
                    1.050144383
                ], 
                [
                    1.051503054, 
                    1.051503054
                ], 
                [
                    1.054352092, 
                    1.054352092
                ], 
                [
                    1.057015876, 
                    1.057015876
                ], 
                [
                    1.059306959, 
                    1.059306959
                ], 
                [
                    1.063124407, 
                    1.063124407
                ], 
                [
                    1.067689891, 
                    1.067689891
                ], 
                [
                    1.070809785, 
                    1.070809785
                ], 
                [
                    1.075358149, 
                    1.075358149
                ], 
                [
                    1.079600977, 
                    1.079600977
                ], 
                [
                    1.085903979, 
                    1.085903979
                ], 
                [
                    1.088896566, 
                    1.088896566
                ], 
                [
                    1.092213127, 
                    1.092213127
                ], 
                [
                    1.095187277, 
                    1.095187277
                ], 
                [
                    1.098010854, 
                    1.098010854
                ], 
                [
                    1.100578061, 
                    1.100578061
                ], 
                [
                    1.106572454, 
                    1.106572454
                ], 
                [
                    1.112175269, 
                    1.112175269
                ], 
                [
                    1.115706496, 
                    1.115706496
                ], 
                [
                    1.119662663, 
                    1.119662663
                ], 
                [
                    1.126085071, 
                    1.126085071
                ], 
                [
                    1.130734841, 
                    1.130734841
                ], 
                [
                    1.136556712, 
                    1.136556712
                ], 
                [
                    1.143983526, 
                    1.143983526
                ], 
                [
                    1.149814615, 
                    1.149814615
                ], 
                [
                    1.155689164, 
                    1.155689164
                ], 
                [
                    1.161293735, 
                    1.161293735
                ], 
                [
                    1.169379472, 
                    1.169379472
                ], 
                [
                    1.173466019, 
                    1.173466019
                ], 
                [
                    1.182490753, 
                    1.182490753
                ], 
                [
                    1.190255588, 
                    1.190255588
                ], 
                [
                    1.199534935, 
                    1.199534935
                ], 
                [
                    1.20502054, 
                    1.20502054
                ], 
                [
                    1.211285351, 
                    1.211285351
                ], 
                [
                    1.215500961, 
                    1.215500961
                ], 
                [
                    1.219393475, 
                    1.219393475
                ], 
                [
                    1.22243918, 
                    1.22243918
                ], 
                [
                    1.22670264, 
                    1.22670264
                ], 
                [
                    1.226084983, 
                    1.226084983
                ], 
                [
                    1.225723256, 
                    1.225723256
                ], 
                [
                    1.223306622, 
                    1.223306622
                ], 
                [
                    1.221192891, 
                    1.221192891
                ], 
                [
                    1.219201198, 
                    1.219201198
                ], 
                [
                    1.21307423, 
                    1.21307423
                ], 
                [
                    1.208716388, 
                    1.208716388
                ], 
                [
                    1.201066567, 
                    1.201066567
                ], 
                [
                    1.197660014, 
                    1.197660014
                ], 
                [
                    1.191742443, 
                    1.191742443
                ], 
                [
                    1.183869617, 
                    1.183869617
                ], 
                [
                    1.178606579, 
                    1.178606579
                ], 
                [
                    1.17226758, 
                    1.17226758
                ], 
                [
                    1.166279332, 
                    1.166279332
                ], 
                [
                    1.161737553, 
                    1.161737553
                ], 
                [
                    1.155545176, 
                    1.155545176
                ], 
                [
                    1.151775139, 
                    1.151775139
                ], 
                [
                    1.143936993, 
                    1.143936993
                ], 
                [
                    1.138587474, 
                    1.138587474
                ], 
                [
                    1.1359452, 
                    1.1359452
                ], 
                [
                    1.130375748, 
                    1.130375748
                ], 
                [
                    1.129193551, 
                    1.129193551
                ], 
                [
                    1.127926629, 
                    1.127926629
                ], 
                [
                    1.127052163, 
                    1.127052163
                ], 
                [
                    1.128276503, 
                    1.128276503
                ], 
                [
                    1.131002185, 
                    1.131002185
                ], 
                [
                    1.133338045, 
                    1.133338045
                ], 
                [
                    1.135770044, 
                    1.135770044
                ], 
                [
                    1.140618237, 
                    1.140618237
                ], 
                [
                    1.147428271, 
                    1.147428271
                ], 
                [
                    1.151039833, 
                    1.151039833
                ], 
                [
                    1.154433217, 
                    1.154433217
                ], 
                [
                    1.160833675, 
                    1.160833675
                ], 
                [
                    1.164393436, 
                    1.164393436
                ], 
                [
                    1.168933459, 
                    1.168933459
                ], 
                [
                    1.173235989, 
                    1.173235989
                ], 
                [
                    1.177359851, 
                    1.177359851
                ], 
                [
                    1.180144357, 
                    1.180144357
                ], 
                [
                    1.185010988, 
                    1.185010988
                ], 
                [
                    1.190786326, 
                    1.190786326
                ], 
                [
                    1.193859687, 
                    1.193859687
                ], 
                [
                    1.196834715, 
                    1.196834715
                ], 
                [
                    1.200811515, 
                    1.200811515
                ], 
                [
                    1.203625873, 
                    1.203625873
                ], 
                [
                    1.207810754, 
                    1.207810754
                ], 
                [
                    1.210342842, 
                    1.210342842
                ], 
                [
                    1.215367947, 
                    1.215367947
                ], 
                [
                    1.216241535, 
                    1.216241535
                ], 
                [
                    1.21728589, 
                    1.21728589
                ], 
                [
                    1.220465925, 
                    1.220465925
                ], 
                [
                    1.220658202, 
                    1.220658202
                ], 
                [
                    1.222340408, 
                    1.222340408
                ], 
                [
                    1.22212223, 
                    1.22212223
                ], 
                [
                    1.221954976, 
                    1.221954976
                ], 
                [
                    1.222553756, 
                    1.222553756
                ], 
                [
                    1.218984338, 
                    1.218984338
                ], 
                [
                    1.216050136, 
                    1.216050136
                ], 
                [
                    1.213490831, 
                    1.213490831
                ], 
                [
                    1.210633891, 
                    1.210633891
                ], 
                [
                    1.208527622, 
                    1.208527622
                ], 
                [
                    1.204161878, 
                    1.204161878
                ], 
                [
                    1.200355405, 
                    1.200355405
                ], 
                [
                    1.19700592, 
                    1.19700592
                ], 
                [
                    1.193874173, 
                    1.193874173
                ], 
                [
                    1.190963677, 
                    1.190963677
                ], 
                [
                    1.187039117, 
                    1.187039117
                ], 
                [
                    1.183605784, 
                    1.183605784
                ], 
                [
                    1.17896655, 
                    1.17896655
                ], 
                [
                    1.175721105, 
                    1.175721105
                ], 
                [
                    1.172878652, 
                    1.172878652
                ], 
                [
                    1.170156482, 
                    1.170156482
                ], 
                [
                    1.165918044, 
                    1.165918044
                ], 
                [
                    1.162449154, 
                    1.162449154
                ], 
                [
                    1.159201953, 
                    1.159201953
                ], 
                [
                    1.156146152, 
                    1.156146152
                ], 
                [
                    1.152895439, 
                    1.152895439
                ], 
                [
                    1.151157921, 
                    1.151157921
                ], 
                [
                    1.148632857, 
                    1.148632857
                ], 
                [
                    1.147686836, 
                    1.147686836
                ], 
                [
                    1.145545009, 
                    1.145545009
                ], 
                [
                    1.143770616, 
                    1.143770616
                ], 
                [
                    1.142421164, 
                    1.142421164
                ], 
                [
                    1.142670071, 
                    1.142670071
                ], 
                [
                    1.142685435, 
                    1.142685435
                ], 
                [
                    1.142799134, 
                    1.142799134
                ], 
                [
                    1.142029147, 
                    1.142029147
                ], 
                [
                    1.143138911, 
                    1.143138911
                ], 
                [
                    1.142065583, 
                    1.142065583
                ], 
                [
                    1.142968583, 
                    1.142968583
                ], 
                [
                    1.143945773, 
                    1.143945773
                ], 
                [
                    1.14479346, 
                    1.14479346
                ], 
                [
                    1.145368975, 
                    1.145368975
                ], 
                [
                    1.145981803, 
                    1.145981803
                ], 
                [
                    1.146031848, 
                    1.146031848
                ], 
                [
                    1.146743449, 
                    1.146743449
                ], 
                [
                    1.148498526, 
                    1.148498526
                ], 
                [
                    1.150078886, 
                    1.150078886
                ], 
                [
                    1.151871278, 
                    1.151871278
                ], 
                [
                    1.15155784, 
                    1.15155784
                ], 
                [
                    1.152226859, 
                    1.152226859
                ], 
                [
                    1.153100008, 
                    1.153100008
                ], 
                [
                    1.155042095, 
                    1.155042095
                ], 
                [
                    1.157259428, 
                    1.157259428
                ], 
                [
                    1.15847411, 
                    1.15847411
                ], 
                [
                    1.159104498, 
                    1.159104498
                ], 
                [
                    1.159344625, 
                    1.159344625
                ], 
                [
                    1.159873167, 
                    1.159873167
                ], 
                [
                    1.162869266, 
                    1.162869266
                ], 
                [
                    1.164108532, 
                    1.164108532
                ], 
                [
                    1.16529907, 
                    1.16529907
                ], 
                [
                    1.165964138, 
                    1.165964138
                ], 
                [
                    1.165806102, 
                    1.165806102
                ], 
                [
                    1.168718793, 
                    1.168718793
                ], 
                [
                    1.169020379, 
                    1.169020379
                ], 
                [
                    1.172563019, 
                    1.172563019
                ], 
                [
                    1.174477011, 
                    1.174477011
                ], 
                [
                    1.175774662, 
                    1.175774662
                ], 
                [
                    1.17830236, 
                    1.17830236
                ], 
                [
                    1.180475355, 
                    1.180475355
                ], 
                [
                    1.184609313, 
                    1.184609313
                ], 
                [
                    1.188241946, 
                    1.188241946
                ], 
                [
                    1.190285439, 
                    1.190285439
                ], 
                [
                    1.192749045, 
                    1.192749045
                ], 
                [
                    1.194606407, 
                    1.194606407
                ], 
                [
                    1.195960688, 
                    1.195960688
                ], 
                [
                    1.200422571, 
                    1.200422571
                ], 
                [
                    1.204362057, 
                    1.204362057
                ], 
                [
                    1.209372237, 
                    1.209372237
                ], 
                [
                    1.212126014, 
                    1.212126014
                ], 
                [
                    1.217083077, 
                    1.217083077
                ], 
                [
                    1.22141458, 
                    1.22141458
                ], 
                [
                    1.225750912, 
                    1.225750912
                ], 
                [
                    1.228614437, 
                    1.228614437
                ], 
                [
                    1.234069313, 
                    1.234069313
                ], 
                [
                    1.239331034, 
                    1.239331034
                ], 
                [
                    1.243151115, 
                    1.243151115
                ], 
                [
                    1.246869351, 
                    1.246869351
                ], 
                [
                    1.250966873, 
                    1.250966873
                ], 
                [
                    1.254025309, 
                    1.254025309
                ], 
                [
                    1.259702752, 
                    1.259702752
                ], 
                [
                    1.263362602, 
                    1.263362602
                ], 
                [
                    1.268440387, 
                    1.268440387
                ], 
                [
                    1.272936072, 
                    1.272936072
                ], 
                [
                    1.274926008, 
                    1.274926008
                ], 
                [
                    1.280472633, 
                    1.280472633
                ], 
                [
                    1.284399828, 
                    1.284399828
                ], 
                [
                    1.288636948, 
                    1.288636948
                ], 
                [
                    1.294299466, 
                    1.294299466
                ], 
                [
                    1.297895224, 
                    1.297895224
                ], 
                [
                    1.303838255, 
                    1.303838255
                ], 
                [
                    1.307283879, 
                    1.307283879
                ], 
                [
                    1.312595645, 
                    1.312595645
                ], 
                [
                    1.317525051, 
                    1.317525051
                ], 
                [
                    1.320934678, 
                    1.320934678
                ], 
                [
                    1.326240297, 
                    1.326240297
                ], 
                [
                    1.330965135, 
                    1.330965135
                ], 
                [
                    1.332598173, 
                    1.332598173
                ], 
                [
                    1.333617505, 
                    1.333617505
                ], 
                [
                    1.337313792, 
                    1.337313792
                ], 
                [
                    1.344373611, 
                    1.344373611
                ], 
                [
                    1.3492499, 
                    1.3492499
                ], 
                [
                    1.352542316, 
                    1.352542316
                ], 
                [
                    1.35623992, 
                    1.35623992
                ], 
                [
                    1.358909411, 
                    1.358909411
                ], 
                [
                    1.364066214, 
                    1.364066214
                ], 
                [
                    1.369638299, 
                    1.369638299
                ], 
                [
                    1.376134896, 
                    1.376134896
                ], 
                [
                    1.380944897, 
                    1.380944897
                ], 
                [
                    1.383682871, 
                    1.383682871
                ], 
                [
                    1.388015252, 
                    1.388015252
                ], 
                [
                    1.391846308, 
                    1.391846308
                ], 
                [
                    1.394351618, 
                    1.394351618
                ], 
                [
                    1.397430247, 
                    1.397430247
                ], 
                [
                    1.400793341, 
                    1.400793341
                ], 
                [
                    1.404303935, 
                    1.404303935
                ], 
                [
                    1.405529592, 
                    1.405529592
                ], 
                [
                    1.406933039, 
                    1.406933039
                ], 
                [
                    1.409052039, 
                    1.409052039
                ], 
                [
                    1.410969981, 
                    1.410969981
                ], 
                [
                    1.413678981, 
                    1.413678981
                ], 
                [
                    1.41752189, 
                    1.41752189
                ], 
                [
                    1.420839768, 
                    1.420839768
                ], 
                [
                    1.422268677, 
                    1.422268677
                ], 
                [
                    1.426516333, 
                    1.426516333
                ], 
                [
                    1.431283314, 
                    1.431283314
                ], 
                [
                    1.433960268, 
                    1.433960268
                ], 
                [
                    1.436188137, 
                    1.436188137
                ], 
                [
                    1.43570042, 
                    1.43570042
                ], 
                [
                    1.438934012, 
                    1.438934012
                ], 
                [
                    1.440729916, 
                    1.440729916
                ], 
                [
                    1.441233436, 
                    1.441233436
                ], 
                [
                    1.444065353, 
                    1.444065353
                ], 
                [
                    1.444732177, 
                    1.444732177
                ], 
                [
                    1.446062753, 
                    1.446062753
                ], 
                [
                    1.448301157, 
                    1.448301157
                ], 
                [
                    1.452349073, 
                    1.452349073
                ], 
                [
                    1.455670024, 
                    1.455670024
                ], 
                [
                    1.457310526, 
                    1.457310526
                ], 
                [
                    1.459726282, 
                    1.459726282
                ], 
                [
                    1.464502481, 
                    1.464502481
                ], 
                [
                    1.467681638, 
                    1.467681638
                ], 
                [
                    1.470328302, 
                    1.470328302
                ], 
                [
                    1.474107119, 
                    1.474107119
                ], 
                [
                    1.478144938, 
                    1.478144938
                ], 
                [
                    1.480474652, 
                    1.480474652
                ], 
                [
                    1.484802644, 
                    1.484802644
                ], 
                [
                    1.489094638, 
                    1.489094638
                ], 
                [
                    1.492230336, 
                    1.492230336
                ], 
                [
                    1.494909924, 
                    1.494909924
                ], 
                [
                    1.497275196, 
                    1.497275196
                ], 
                [
                    1.501208536, 
                    1.501208536
                ], 
                [
                    1.503570297, 
                    1.503570297
                ], 
                [
                    1.506915392, 
                    1.506915392
                ], 
                [
                    1.50983379, 
                    1.50983379
                ], 
                [
                    1.51133162, 
                    1.51133162
                ], 
                [
                    1.513748693, 
                    1.513748693
                ], 
                [
                    1.516365506, 
                    1.516365506
                ], 
                [
                    1.517622331, 
                    1.517622331
                ], 
                [
                    1.519041582, 
                    1.519041582
                ], 
                [
                    1.521538551, 
                    1.521538551
                ], 
                [
                    1.525174257, 
                    1.525174257
                ], 
                [
                    1.528353853, 
                    1.528353853
                ], 
                [
                    1.531641002, 
                    1.531641002
                ], 
                [
                    1.537164799, 
                    1.537164799
                ], 
                [
                    1.541638974, 
                    1.541638974
                ], 
                [
                    1.546315522, 
                    1.546315522
                ], 
                [
                    1.551942482, 
                    1.551942482
                ], 
                [
                    1.557317901, 
                    1.557317901
                ], 
                [
                    1.561745982, 
                    1.561745982
                ], 
                [
                    1.565817165, 
                    1.565817165
                ], 
                [
                    1.568538457, 
                    1.568538457
                ], 
                [
                    1.573729939, 
                    1.573729939
                ], 
                [
                    1.576331387, 
                    1.576331387
                ], 
                [
                    1.580708546, 
                    1.580708546
                ], 
                [
                    1.585097118, 
                    1.585097118
                ], 
                [
                    1.587463268, 
                    1.587463268
                ], 
                [
                    1.588527377, 
                    1.588527377
                ], 
                [
                    1.589160838, 
                    1.589160838
                ], 
                [
                    1.589377698, 
                    1.589377698
                ], 
                [
                    1.591195551, 
                    1.591195551
                ], 
                [
                    1.587878112, 
                    1.587878112
                ], 
                [
                    1.587186705, 
                    1.587186705
                ], 
                [
                    1.583592264, 
                    1.583592264
                ], 
                [
                    1.578762508, 
                    1.578762508
                ], 
                [
                    1.574527582, 
                    1.574527582
                ], 
                [
                    1.572005591, 
                    1.572005591
                ], 
                [
                    1.567581022, 
                    1.567581022
                ], 
                [
                    1.563161282, 
                    1.563161282
                ], 
                [
                    1.557193667, 
                    1.557193667
                ], 
                [
                    1.552748027, 
                    1.552748027
                ], 
                [
                    1.54938186, 
                    1.54938186
                ], 
                [
                    1.545332187, 
                    1.545332187
                ], 
                [
                    1.543176313, 
                    1.543176313
                ], 
                [
                    1.539867654, 
                    1.539867654
                ], 
                [
                    1.535863197, 
                    1.535863197
                ], 
                [
                    1.535129208, 
                    1.535129208
                ], 
                [
                    1.53410768, 
                    1.53410768
                ], 
                [
                    1.534955807, 
                    1.534955807
                ], 
                [
                    1.536558555, 
                    1.536558555
                ], 
                [
                    1.536102446, 
                    1.536102446
                ], 
                [
                    1.539075718, 
                    1.539075718
                ], 
                [
                    1.539816731, 
                    1.539816731
                ], 
                [
                    1.542231609, 
                    1.542231609
                ], 
                [
                    1.544754039, 
                    1.544754039
                ], 
                [
                    1.544290028, 
                    1.544290028
                ], 
                [
                    1.545635968, 
                    1.545635968
                ], 
                [
                    1.545696109, 
                    1.545696109
                ], 
                [
                    1.546348447, 
                    1.546348447
                ], 
                [
                    1.547030635, 
                    1.547030635
                ], 
                [
                    1.545365989, 
                    1.545365989
                ], 
                [
                    1.544927879, 
                    1.544927879
                ], 
                [
                    1.544886175, 
                    1.544886175
                ], 
                [
                    1.544930951, 
                    1.544930951
                ], 
                [
                    1.547132481, 
                    1.547132481
                ], 
                [
                    1.548443741, 
                    1.548443741
                ], 
                [
                    1.549626377, 
                    1.549626377
                ], 
                [
                    1.550535084, 
                    1.550535084
                ], 
                [
                    1.552037743, 
                    1.552037743
                ], 
                [
                    1.555097495, 
                    1.555097495
                ], 
                [
                    1.55810852, 
                    1.55810852
                ], 
                [
                    1.561756956, 
                    1.561756956
                ], 
                [
                    1.562708684, 
                    1.562708684
                ], 
                [
                    1.565681517, 
                    1.565681517
                ], 
                [
                    1.568767609, 
                    1.568767609
                ], 
                [
                    1.572589007, 
                    1.572589007
                ], 
                [
                    1.57457192, 
                    1.57457192
                ], 
                [
                    1.576711615, 
                    1.576711615
                ], 
                [
                    1.577464637, 
                    1.577464637
                ], 
                [
                    1.579098071, 
                    1.579098071
                ]
            ], 
            "cmax": 1.591195551, 
            "cmin": 0.874247244, 
            "colorscale": [
                [
                    "0", 
                    "rgb(186,186,255)"
                ], 
                [
                    "0.1", 
                    "rgb(186,186,255)"
                ], 
                [
                    "0.2", 
                    "rgb(186,186,255)"
                ], 
                [
                    "0.3", 
                    "rgb(186,186,255)"
                ], 
                [
                    "0.4", 
                    "rgb(186,186,255)"
                ], 
                [
                    "0.5", 
                    "rgb(186,186,255)"
                ], 
                [
                    "0.6", 
                    "rgb(186,186,255)"
                ], 
                [
                    "0.7", 
                    "rgb(186,186,255)"
                ], 
                [
                    "0.8", 
                    "rgb(186,186,255)"
                ], 
                [
                    "0.9", 
                    "rgb(186,186,255)"
                ], 
                [
                    "1", 
                    "rgb(186,186,255)"
                ]
            ], 
            name: "", 
            showscale: false, 
            type: "surface", 
            "uid": "fbb4a8", 
            "xsrc": "Diksha_Gabha:2343:0a5e4e,40627b", 
            "ysrc": "Diksha_Gabha:2343:807139,59d0b7", 
            "zsrc": "Diksha_Gabha:2343:bb5ec6,d68505"
        }, 
        {
            x: [
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ], 
                [
                    14, 
                    15
                ]
            ], 
            y: [
                [
                    800, 
                    800
                ], 
                [
                    799, 
                    799
                ], 
                [
                    798, 
                    798
                ], 
                [
                    797, 
                    797
                ], 
                [
                    796, 
                    796
                ], 
                [
                    795, 
                    795
                ], 
                [
                    794, 
                    794
                ], 
                [
                    793, 
                    793
                ], 
                [
                    792, 
                    792
                ], 
                [
                    791, 
                    791
                ], 
                [
                    790, 
                    790
                ], 
                [
                    789, 
                    789
                ], 
                [
                    788, 
                    788
                ], 
                [
                    787, 
                    787
                ], 
                [
                    786, 
                    786
                ], 
                [
                    785, 
                    785
                ], 
                [
                    784, 
                    784
                ], 
                [
                    783, 
                    783
                ], 
                [
                    782, 
                    782
                ], 
                [
                    781, 
                    781
                ], 
                [
                    780, 
                    780
                ], 
                [
                    779, 
                    779
                ], 
                [
                    778, 
                    778
                ], 
                [
                    777, 
                    777
                ], 
                [
                    776, 
                    776
                ], 
                [
                    775, 
                    775
                ], 
                [
                    774, 
                    774
                ], 
                [
                    773, 
                    773
                ], 
                [
                    772, 
                    772
                ], 
                [
                    771, 
                    771
                ], 
                [
                    770, 
                    770
                ], 
                [
                    769, 
                    769
                ], 
                [
                    768, 
                    768
                ], 
                [
                    767, 
                    767
                ], 
                [
                    766, 
                    766
                ], 
                [
                    765, 
                    765
                ], 
                [
                    764, 
                    764
                ], 
                [
                    763, 
                    763
                ], 
                [
                    762, 
                    762
                ], 
                [
                    761, 
                    761
                ], 
                [
                    760, 
                    760
                ], 
                [
                    759, 
                    759
                ], 
                [
                    758, 
                    758
                ], 
                [
                    757, 
                    757
                ], 
                [
                    756, 
                    756
                ], 
                [
                    755, 
                    755
                ], 
                [
                    754, 
                    754
                ], 
                [
                    753, 
                    753
                ], 
                [
                    752, 
                    752
                ], 
                [
                    751, 
                    751
                ], 
                [
                    750, 
                    750
                ], 
                [
                    749, 
                    749
                ], 
                [
                    748, 
                    748
                ], 
                [
                    747, 
                    747
                ], 
                [
                    746, 
                    746
                ], 
                [
                    745, 
                    745
                ], 
                [
                    744, 
                    744
                ], 
                [
                    743, 
                    743
                ], 
                [
                    742, 
                    742
                ], 
                [
                    741, 
                    741
                ], 
                [
                    740, 
                    740
                ], 
                [
                    739, 
                    739
                ], 
                [
                    738, 
                    738
                ], 
                [
                    737, 
                    737
                ], 
                [
                    736, 
                    736
                ], 
                [
                    735, 
                    735
                ], 
                [
                    734, 
                    734
                ], 
                [
                    733, 
                    733
                ], 
                [
                    732, 
                    732
                ], 
                [
                    731, 
                    731
                ], 
                [
                    730, 
                    730
                ], 
                [
                    729, 
                    729
                ], 
                [
                    728, 
                    728
                ], 
                [
                    727, 
                    727
                ], 
                [
                    726, 
                    726
                ], 
                [
                    725, 
                    725
                ], 
                [
                    724, 
                    724
                ], 
                [
                    723, 
                    723
                ], 
                [
                    722, 
                    722
                ], 
                [
                    721, 
                    721
                ], 
                [
                    720, 
                    720
                ], 
                [
                    719, 
                    719
                ], 
                [
                    718, 
                    718
                ], 
                [
                    717, 
                    717
                ], 
                [
                    716, 
                    716
                ], 
                [
                    715, 
                    715
                ], 
                [
                    714, 
                    714
                ], 
                [
                    713, 
                    713
                ], 
                [
                    712, 
                    712
                ], 
                [
                    711, 
                    711
                ], 
                [
                    710, 
                    710
                ], 
                [
                    709, 
                    709
                ], 
                [
                    708, 
                    708
                ], 
                [
                    707, 
                    707
                ], 
                [
                    706, 
                    706
                ], 
                [
                    705, 
                    705
                ], 
                [
                    704, 
                    704
                ], 
                [
                    703, 
                    703
                ], 
                [
                    702, 
                    702
                ], 
                [
                    701, 
                    701
                ], 
                [
                    700, 
                    700
                ], 
                [
                    699, 
                    699
                ], 
                [
                    698, 
                    698
                ], 
                [
                    697, 
                    697
                ], 
                [
                    696, 
                    696
                ], 
                [
                    695, 
                    695
                ], 
                [
                    694, 
                    694
                ], 
                [
                    693, 
                    693
                ], 
                [
                    692, 
                    692
                ], 
                [
                    691, 
                    691
                ], 
                [
                    690, 
                    690
                ], 
                [
                    689, 
                    689
                ], 
                [
                    688, 
                    688
                ], 
                [
                    687, 
                    687
                ], 
                [
                    686, 
                    686
                ], 
                [
                    685, 
                    685
                ], 
                [
                    684, 
                    684
                ], 
                [
                    683, 
                    683
                ], 
                [
                    682, 
                    682
                ], 
                [
                    681, 
                    681
                ], 
                [
                    680, 
                    680
                ], 
                [
                    679, 
                    679
                ], 
                [
                    678, 
                    678
                ], 
                [
                    677, 
                    677
                ], 
                [
                    676, 
                    676
                ], 
                [
                    675, 
                    675
                ], 
                [
                    674, 
                    674
                ], 
                [
                    673, 
                    673
                ], 
                [
                    672, 
                    672
                ], 
                [
                    671, 
                    671
                ], 
                [
                    670, 
                    670
                ], 
                [
                    669, 
                    669
                ], 
                [
                    668, 
                    668
                ], 
                [
                    667, 
                    667
                ], 
                [
                    666, 
                    666
                ], 
                [
                    665, 
                    665
                ], 
                [
                    664, 
                    664
                ], 
                [
                    663, 
                    663
                ], 
                [
                    662, 
                    662
                ], 
                [
                    661, 
                    661
                ], 
                [
                    660, 
                    660
                ], 
                [
                    659, 
                    659
                ], 
                [
                    658, 
                    658
                ], 
                [
                    657, 
                    657
                ], 
                [
                    656, 
                    656
                ], 
                [
                    655, 
                    655
                ], 
                [
                    654, 
                    654
                ], 
                [
                    653, 
                    653
                ], 
                [
                    652, 
                    652
                ], 
                [
                    651, 
                    651
                ], 
                [
                    650, 
                    650
                ], 
                [
                    649, 
                    649
                ], 
                [
                    648, 
                    648
                ], 
                [
                    647, 
                    647
                ], 
                [
                    646, 
                    646
                ], 
                [
                    645, 
                    645
                ], 
                [
                    644, 
                    644
                ], 
                [
                    643, 
                    643
                ], 
                [
                    642, 
                    642
                ], 
                [
                    641, 
                    641
                ], 
                [
                    640, 
                    640
                ], 
                [
                    639, 
                    639
                ], 
                [
                    638, 
                    638
                ], 
                [
                    637, 
                    637
                ], 
                [
                    636, 
                    636
                ], 
                [
                    635, 
                    635
                ], 
                [
                    634, 
                    634
                ], 
                [
                    633, 
                    633
                ], 
                [
                    632, 
                    632
                ], 
                [
                    631, 
                    631
                ], 
                [
                    630, 
                    630
                ], 
                [
                    629, 
                    629
                ], 
                [
                    628, 
                    628
                ], 
                [
                    627, 
                    627
                ], 
                [
                    626, 
                    626
                ], 
                [
                    625, 
                    625
                ], 
                [
                    624, 
                    624
                ], 
                [
                    623, 
                    623
                ], 
                [
                    622, 
                    622
                ], 
                [
                    621, 
                    621
                ], 
                [
                    620, 
                    620
                ], 
                [
                    619, 
                    619
                ], 
                [
                    618, 
                    618
                ], 
                [
                    617, 
                    617
                ], 
                [
                    616, 
                    616
                ], 
                [
                    615, 
                    615
                ], 
                [
                    614, 
                    614
                ], 
                [
                    613, 
                    613
                ], 
                [
                    612, 
                    612
                ], 
                [
                    611, 
                    611
                ], 
                [
                    610, 
                    610
                ], 
                [
                    609, 
                    609
                ], 
                [
                    608, 
                    608
                ], 
                [
                    607, 
                    607
                ], 
                [
                    606, 
                    606
                ], 
                [
                    605, 
                    605
                ], 
                [
                    604, 
                    604
                ], 
                [
                    603, 
                    603
                ], 
                [
                    602, 
                    602
                ], 
                [
                    601, 
                    601
                ], 
                [
                    600, 
                    600
                ], 
                [
                    599, 
                    599
                ], 
                [
                    598, 
                    598
                ], 
                [
                    597, 
                    597
                ], 
                [
                    596, 
                    596
                ], 
                [
                    595, 
                    595
                ], 
                [
                    594, 
                    594
                ], 
                [
                    593, 
                    593
                ], 
                [
                    592, 
                    592
                ], 
                [
                    591, 
                    591
                ], 
                [
                    590, 
                    590
                ], 
                [
                    589, 
                    589
                ], 
                [
                    588, 
                    588
                ], 
                [
                    587, 
                    587
                ], 
                [
                    586, 
                    586
                ], 
                [
                    585, 
                    585
                ], 
                [
                    584, 
                    584
                ], 
                [
                    583, 
                    583
                ], 
                [
                    582, 
                    582
                ], 
                [
                    581, 
                    581
                ], 
                [
                    580, 
                    580
                ], 
                [
                    579, 
                    579
                ], 
                [
                    578, 
                    578
                ], 
                [
                    577, 
                    577
                ], 
                [
                    576, 
                    576
                ], 
                [
                    575, 
                    575
                ], 
                [
                    574, 
                    574
                ], 
                [
                    573, 
                    573
                ], 
                [
                    572, 
                    572
                ], 
                [
                    571, 
                    571
                ], 
                [
                    570, 
                    570
                ], 
                [
                    569, 
                    569
                ], 
                [
                    568, 
                    568
                ], 
                [
                    567, 
                    567
                ], 
                [
                    566, 
                    566
                ], 
                [
                    565, 
                    565
                ], 
                [
                    564, 
                    564
                ], 
                [
                    563, 
                    563
                ], 
                [
                    562, 
                    562
                ], 
                [
                    561, 
                    561
                ], 
                [
                    560, 
                    560
                ], 
                [
                    559, 
                    559
                ], 
                [
                    558, 
                    558
                ], 
                [
                    557, 
                    557
                ], 
                [
                    556, 
                    556
                ], 
                [
                    555, 
                    555
                ], 
                [
                    554, 
                    554
                ], 
                [
                    553, 
                    553
                ], 
                [
                    552, 
                    552
                ], 
                [
                    551, 
                    551
                ], 
                [
                    550, 
                    550
                ], 
                [
                    549, 
                    549
                ], 
                [
                    548, 
                    548
                ], 
                [
                    547, 
                    547
                ], 
                [
                    546, 
                    546
                ], 
                [
                    545, 
                    545
                ], 
                [
                    544, 
                    544
                ], 
                [
                    543, 
                    543
                ], 
                [
                    542, 
                    542
                ], 
                [
                    541, 
                    541
                ], 
                [
                    540, 
                    540
                ], 
                [
                    539, 
                    539
                ], 
                [
                    538, 
                    538
                ], 
                [
                    537, 
                    537
                ], 
                [
                    536, 
                    536
                ], 
                [
                    535, 
                    535
                ], 
                [
                    534, 
                    534
                ], 
                [
                    533, 
                    533
                ], 
                [
                    532, 
                    532
                ], 
                [
                    531, 
                    531
                ], 
                [
                    530, 
                    530
                ], 
                [
                    529, 
                    529
                ], 
                [
                    528, 
                    528
                ], 
                [
                    527, 
                    527
                ], 
                [
                    526, 
                    526
                ], 
                [
                    525, 
                    525
                ], 
                [
                    524, 
                    524
                ], 
                [
                    523, 
                    523
                ], 
                [
                    522, 
                    522
                ], 
                [
                    521, 
                    521
                ], 
                [
                    520, 
                    520
                ], 
                [
                    519, 
                    519
                ], 
                [
                    518, 
                    518
                ], 
                [
                    517, 
                    517
                ], 
                [
                    516, 
                    516
                ], 
                [
                    515, 
                    515
                ], 
                [
                    514, 
                    514
                ], 
                [
                    513, 
                    513
                ], 
                [
                    512, 
                    512
                ], 
                [
                    511, 
                    511
                ], 
                [
                    510, 
                    510
                ], 
                [
                    509, 
                    509
                ], 
                [
                    508, 
                    508
                ], 
                [
                    507, 
                    507
                ], 
                [
                    506, 
                    506
                ], 
                [
                    505, 
                    505
                ], 
                [
                    504, 
                    504
                ], 
                [
                    503, 
                    503
                ], 
                [
                    502, 
                    502
                ], 
                [
                    501, 
                    501
                ], 
                [
                    500, 
                    500
                ], 
                [
                    499, 
                    499
                ], 
                [
                    498, 
                    498
                ], 
                [
                    497, 
                    497
                ], 
                [
                    496, 
                    496
                ], 
                [
                    495, 
                    495
                ], 
                [
                    494, 
                    494
                ], 
                [
                    493, 
                    493
                ], 
                [
                    492, 
                    492
                ], 
                [
                    491, 
                    491
                ], 
                [
                    490, 
                    490
                ], 
                [
                    489, 
                    489
                ], 
                [
                    488, 
                    488
                ], 
                [
                    487, 
                    487
                ], 
                [
                    486, 
                    486
                ], 
                [
                    485, 
                    485
                ], 
                [
                    484, 
                    484
                ], 
                [
                    483, 
                    483
                ], 
                [
                    482, 
                    482
                ], 
                [
                    481, 
                    481
                ], 
                [
                    480, 
                    480
                ], 
                [
                    479, 
                    479
                ], 
                [
                    478, 
                    478
                ], 
                [
                    477, 
                    477
                ], 
                [
                    476, 
                    476
                ], 
                [
                    475, 
                    475
                ], 
                [
                    474, 
                    474
                ], 
                [
                    473, 
                    473
                ], 
                [
                    472, 
                    472
                ], 
                [
                    471, 
                    471
                ], 
                [
                    470, 
                    470
                ], 
                [
                    469, 
                    469
                ], 
                [
                    468, 
                    468
                ], 
                [
                    467, 
                    467
                ], 
                [
                    466, 
                    466
                ], 
                [
                    465, 
                    465
                ], 
                [
                    464, 
                    464
                ], 
                [
                    463, 
                    463
                ], 
                [
                    462, 
                    462
                ], 
                [
                    461, 
                    461
                ], 
                [
                    460, 
                    460
                ], 
                [
                    459, 
                    459
                ], 
                [
                    458, 
                    458
                ], 
                [
                    457, 
                    457
                ], 
                [
                    456, 
                    456
                ], 
                [
                    455, 
                    455
                ], 
                [
                    454, 
                    454
                ], 
                [
                    453, 
                    453
                ], 
                [
                    452, 
                    452
                ], 
                [
                    451, 
                    451
                ], 
                [
                    450, 
                    450
                ], 
                [
                    449, 
                    449
                ], 
                [
                    448, 
                    448
                ], 
                [
                    447, 
                    447
                ], 
                [
                    446, 
                    446
                ], 
                [
                    445, 
                    445
                ], 
                [
                    444, 
                    444
                ], 
                [
                    443, 
                    443
                ], 
                [
                    442, 
                    442
                ], 
                [
                    441, 
                    441
                ], 
                [
                    440, 
                    440
                ], 
                [
                    439, 
                    439
                ], 
                [
                    438, 
                    438
                ], 
                [
                    437, 
                    437
                ], 
                [
                    436, 
                    436
                ], 
                [
                    435, 
                    435
                ], 
                [
                    434, 
                    434
                ], 
                [
                    433, 
                    433
                ], 
                [
                    432, 
                    432
                ], 
                [
                    431, 
                    431
                ], 
                [
                    430, 
                    430
                ], 
                [
                    429, 
                    429
                ], 
                [
                    428, 
                    428
                ], 
                [
                    427, 
                    427
                ], 
                [
                    426, 
                    426
                ], 
                [
                    425, 
                    425
                ], 
                [
                    424, 
                    424
                ], 
                [
                    423, 
                    423
                ], 
                [
                    422, 
                    422
                ], 
                [
                    421, 
                    421
                ], 
                [
                    420, 
                    420
                ], 
                [
                    419, 
                    419
                ], 
                [
                    418, 
                    418
                ], 
                [
                    417, 
                    417
                ], 
                [
                    416, 
                    416
                ], 
                [
                    415, 
                    415
                ], 
                [
                    414, 
                    414
                ], 
                [
                    413, 
                    413
                ], 
                [
                    412, 
                    412
                ], 
                [
                    411, 
                    411
                ], 
                [
                    410, 
                    410
                ], 
                [
                    409, 
                    409
                ], 
                [
                    408, 
                    408
                ], 
                [
                    407, 
                    407
                ], 
                [
                    406, 
                    406
                ], 
                [
                    405, 
                    405
                ], 
                [
                    404, 
                    404
                ], 
                [
                    403, 
                    403
                ], 
                [
                    402, 
                    402
                ], 
                [
                    401, 
                    401
                ], 
                [
                    400, 
                    400
                ]
            ], 
            z: [
                [
                    0.891326202, 
                    0.891326202
                ], 
                [
                    0.888733027, 
                    0.888733027
                ], 
                [
                    0.896350752, 
                    0.896350752
                ], 
                [
                    0.916247792, 
                    0.916247792
                ], 
                [
                    0.900480784, 
                    0.900480784
                ], 
                [
                    0.903346541, 
                    0.903346541
                ], 
                [
                    0.906652511, 
                    0.906652511
                ], 
                [
                    0.907801632, 
                    0.907801632
                ], 
                [
                    0.907902211, 
                    0.907902211
                ], 
                [
                    0.90792116, 
                    0.90792116
                ], 
                [
                    0.90648731, 
                    0.90648731
                ], 
                [
                    0.907132081, 
                    0.907132081
                ], 
                [
                    0.90998812, 
                    0.90998812
                ], 
                [
                    0.913874724, 
                    0.913874724
                ], 
                [
                    0.914356723, 
                    0.914356723
                ], 
                [
                    0.915044252, 
                    0.915044252
                ], 
                [
                    0.914528241, 
                    0.914528241
                ], 
                [
                    0.917514012, 
                    0.917514012
                ], 
                [
                    0.920208737, 
                    0.920208737
                ], 
                [
                    0.92396318, 
                    0.92396318
                ], 
                [
                    0.926304665, 
                    0.926304665
                ], 
                [
                    0.927970769, 
                    0.927970769
                ], 
                [
                    0.929858437, 
                    0.929858437
                ], 
                [
                    0.932269404, 
                    0.932269404
                ], 
                [
                    0.935102121, 
                    0.935102121
                ], 
                [
                    0.9423496, 
                    0.9423496
                ], 
                [
                    0.941887036, 
                    0.941887036
                ], 
                [
                    0.945884908, 
                    0.945884908
                ], 
                [
                    0.949546547, 
                    0.949546547
                ], 
                [
                    0.954419985, 
                    0.954419985
                ], 
                [
                    0.956261494, 
                    0.956261494
                ], 
                [
                    0.95760254, 
                    0.95760254
                ], 
                [
                    0.96268734, 
                    0.96268734
                ], 
                [
                    0.96486168, 
                    0.96486168
                ], 
                [
                    0.965474383, 
                    0.965474383
                ], 
                [
                    0.970029566, 
                    0.970029566
                ], 
                [
                    0.970871121, 
                    0.970871121
                ], 
                [
                    0.970170473, 
                    0.970170473
                ], 
                [
                    0.970992107, 
                    0.970992107
                ], 
                [
                    0.975447683, 
                    0.975447683
                ], 
                [
                    0.978198285, 
                    0.978198285
                ], 
                [
                    0.977591899, 
                    0.977591899
                ], 
                [
                    0.979052959, 
                    0.979052959
                ], 
                [
                    0.981108258, 
                    0.981108258
                ], 
                [
                    0.983116426, 
                    0.983116426
                ], 
                [
                    0.987696389, 
                    0.987696389
                ], 
                [
                    0.992085885, 
                    0.992085885
                ], 
                [
                    0.99436955, 
                    0.99436955
                ], 
                [
                    0.994006108, 
                    0.994006108
                ], 
                [
                    0.997205182, 
                    0.997205182
                ], 
                [
                    1, 
                    1
                ], 
                [
                    1.002954188, 
                    1.002954188
                ], 
                [
                    1.005568742, 
                    1.005568742
                ], 
                [
                    1.006355392, 
                    1.006355392
                ], 
                [
                    1.008086604, 
                    1.008086604
                ], 
                [
                    1.00929063, 
                    1.00929063
                ], 
                [
                    1.011744842, 
                    1.011744842
                ], 
                [
                    1.013603357, 
                    1.013603357
                ], 
                [
                    1.015250025, 
                    1.015250025
                ], 
                [
                    1.019026333, 
                    1.019026333
                ], 
                [
                    1.020356689, 
                    1.020356689
                ], 
                [
                    1.023789475, 
                    1.023789475
                ], 
                [
                    1.025430799, 
                    1.025430799
                ], 
                [
                    1.027631378, 
                    1.027631378
                ], 
                [
                    1.029846047, 
                    1.029846047
                ], 
                [
                    1.030845029, 
                    1.030845029
                ], 
                [
                    1.03473552, 
                    1.03473552
                ], 
                [
                    1.03841708, 
                    1.03841708
                ], 
                [
                    1.039862591, 
                    1.039862591
                ], 
                [
                    1.040651671, 
                    1.040651671
                ], 
                [
                    1.040450028, 
                    1.040450028
                ], 
                [
                    1.046480362, 
                    1.046480362
                ], 
                [
                    1.048040056, 
                    1.048040056
                ], 
                [
                    1.050019557, 
                    1.050019557
                ], 
                [
                    1.052573861, 
                    1.052573861
                ], 
                [
                    1.053171987, 
                    1.053171987
                ], 
                [
                    1.055543597, 
                    1.055543597
                ], 
                [
                    1.055666041, 
                    1.055666041
                ], 
                [
                    1.061234297, 
                    1.061234297
                ], 
                [
                    1.065562571, 
                    1.065562571
                ], 
                [
                    1.066367199, 
                    1.066367199
                ], 
                [
                    1.070142049, 
                    1.070142049
                ], 
                [
                    1.074449916, 
                    1.074449916
                ], 
                [
                    1.080189204, 
                    1.080189204
                ], 
                [
                    1.085065072, 
                    1.085065072
                ], 
                [
                    1.08726808, 
                    1.08726808
                ], 
                [
                    1.092957808, 
                    1.092957808
                ], 
                [
                    1.094502926, 
                    1.094502926
                ], 
                [
                    1.096392538, 
                    1.096392538
                ], 
                [
                    1.100003158, 
                    1.100003158
                ], 
                [
                    1.104833839, 
                    1.104833839
                ], 
                [
                    1.108571276, 
                    1.108571276
                ], 
                [
                    1.11251813, 
                    1.11251813
                ], 
                [
                    1.118616973, 
                    1.118616973
                ], 
                [
                    1.124853323, 
                    1.124853323
                ], 
                [
                    1.127624818, 
                    1.127624818
                ], 
                [
                    1.133717345, 
                    1.133717345
                ], 
                [
                    1.140322969, 
                    1.140322969
                ], 
                [
                    1.145517092, 
                    1.145517092
                ], 
                [
                    1.150495968, 
                    1.150495968
                ], 
                [
                    1.157351823, 
                    1.157351823
                ], 
                [
                    1.160311356, 
                    1.160311356
                ], 
                [
                    1.163008996, 
                    1.163008996
                ], 
                [
                    1.168280376, 
                    1.168280376
                ], 
                [
                    1.176922834, 
                    1.176922834
                ], 
                [
                    1.184745116, 
                    1.184745116
                ], 
                [
                    1.19210775, 
                    1.19210775
                ], 
                [
                    1.197939357, 
                    1.197939357
                ], 
                [
                    1.202257913, 
                    1.202257913
                ], 
                [
                    1.204712125, 
                    1.204712125
                ], 
                [
                    1.20987175, 
                    1.20987175
                ], 
                [
                    1.214072237, 
                    1.214072237
                ], 
                [
                    1.214900187, 
                    1.214900187
                ], 
                [
                    1.214264162, 
                    1.214264162
                ], 
                [
                    1.210780358, 
                    1.210780358
                ], 
                [
                    1.20624461, 
                    1.20624461
                ], 
                [
                    1.203964346, 
                    1.203964346
                ], 
                [
                    1.198601134, 
                    1.198601134
                ], 
                [
                    1.194751457, 
                    1.194751457
                ], 
                [
                    1.18684706, 
                    1.18684706
                ], 
                [
                    1.180615083, 
                    1.180615083
                ], 
                [
                    1.176154648, 
                    1.176154648
                ], 
                [
                    1.168729335, 
                    1.168729335
                ], 
                [
                    1.165194027, 
                    1.165194027
                ], 
                [
                    1.160056266, 
                    1.160056266
                ], 
                [
                    1.155265913, 
                    1.155265913
                ], 
                [
                    1.151437616, 
                    1.151437616
                ], 
                [
                    1.146708971, 
                    1.146708971
                ], 
                [
                    1.143748466, 
                    1.143748466
                ], 
                [
                    1.141012441, 
                    1.141012441
                ], 
                [
                    1.13942991, 
                    1.13942991
                ], 
                [
                    1.137251682, 
                    1.137251682
                ], 
                [
                    1.133502584, 
                    1.133502584
                ], 
                [
                    1.13415027, 
                    1.13415027
                ], 
                [
                    1.134286318, 
                    1.134286318
                ], 
                [
                    1.132811168, 
                    1.132811168
                ], 
                [
                    1.132622644, 
                    1.132622644
                ], 
                [
                    1.136078267, 
                    1.136078267
                ], 
                [
                    1.138604875, 
                    1.138604875
                ], 
                [
                    1.137214269, 
                    1.137214269
                ], 
                [
                    1.142888934, 
                    1.142888934
                ], 
                [
                    1.149857514, 
                    1.149857514
                ], 
                [
                    1.15209259, 
                    1.15209259
                ], 
                [
                    1.157077783, 
                    1.157077783
                ], 
                [
                    1.164599788, 
                    1.164599788
                ], 
                [
                    1.170061149, 
                    1.170061149
                ], 
                [
                    1.175569641, 
                    1.175569641
                ], 
                [
                    1.178670567, 
                    1.178670567
                ], 
                [
                    1.183631951, 
                    1.183631951
                ], 
                [
                    1.187432553, 
                    1.187432553
                ], 
                [
                    1.192167028, 
                    1.192167028
                ], 
                [
                    1.19771925, 
                    1.19771925
                ], 
                [
                    1.199329963, 
                    1.199329963
                ], 
                [
                    1.202978483, 
                    1.202978483
                ], 
                [
                    1.208468511, 
                    1.208468511
                ], 
                [
                    1.210865388, 
                    1.210865388
                ], 
                [
                    1.216061941, 
                    1.216061941
                ], 
                [
                    1.221179294, 
                    1.221179294
                ], 
                [
                    1.224923534, 
                    1.224923534
                ], 
                [
                    1.22563147, 
                    1.22563147
                ], 
                [
                    1.227159096, 
                    1.227159096
                ], 
                [
                    1.229282905, 
                    1.229282905
                ], 
                [
                    1.230288203, 
                    1.230288203
                ], 
                [
                    1.230953382, 
                    1.230953382
                ], 
                [
                    1.230440286, 
                    1.230440286
                ], 
                [
                    1.230511711, 
                    1.230511711
                ], 
                [
                    1.230569046, 
                    1.230569046
                ], 
                [
                    1.226759698, 
                    1.226759698
                ], 
                [
                    1.224733552, 
                    1.224733552
                ], 
                [
                    1.223330313, 
                    1.223330313
                ], 
                [
                    1.220274088, 
                    1.220274088
                ], 
                [
                    1.216803889, 
                    1.216803889
                ], 
                [
                    1.214387577, 
                    1.214387577
                ], 
                [
                    1.213825892, 
                    1.213825892
                ], 
                [
                    1.210624388, 
                    1.210624388
                ], 
                [
                    1.205724711, 
                    1.205724711
                ], 
                [
                    1.204426423, 
                    1.204426423
                ], 
                [
                    1.202051412, 
                    1.202051412
                ], 
                [
                    1.198832901, 
                    1.198832901
                ], 
                [
                    1.195728088, 
                    1.195728088
                ], 
                [
                    1.194814136, 
                    1.194814136
                ], 
                [
                    1.190840559, 
                    1.190840559
                ], 
                [
                    1.186127948, 
                    1.186127948
                ], 
                [
                    1.182094121, 
                    1.182094121
                ], 
                [
                    1.180273991, 
                    1.180273991
                ], 
                [
                    1.177857679, 
                    1.177857679
                ], 
                [
                    1.174938475, 
                    1.174938475
                ], 
                [
                    1.172105272, 
                    1.172105272
                ], 
                [
                    1.170485327, 
                    1.170485327
                ], 
                [
                    1.168087479, 
                    1.168087479
                ], 
                [
                    1.167892639, 
                    1.167892639
                ], 
                [
                    1.167132713, 
                    1.167132713
                ], 
                [
                    1.166274637, 
                    1.166274637
                ], 
                [
                    1.166959737, 
                    1.166959737
                ], 
                [
                    1.166912606, 
                    1.166912606
                ], 
                [
                    1.166003999, 
                    1.166003999
                ], 
                [
                    1.168564619, 
                    1.168564619
                ], 
                [
                    1.171227762, 
                    1.171227762
                ], 
                [
                    1.170858974, 
                    1.170858974
                ], 
                [
                    1.171600436, 
                    1.171600436
                ], 
                [
                    1.174965684, 
                    1.174965684
                ], 
                [
                    1.17573387, 
                    1.17573387
                ], 
                [
                    1.175687711, 
                    1.175687711
                ], 
                [
                    1.176977739, 
                    1.176977739
                ], 
                [
                    1.17845289, 
                    1.17845289
                ], 
                [
                    1.176727022, 
                    1.176727022
                ], 
                [
                    1.176929151, 
                    1.176929151
                ], 
                [
                    1.178996596, 
                    1.178996596
                ], 
                [
                    1.179454301, 
                    1.179454301
                ], 
                [
                    1.179423204, 
                    1.179423204
                ], 
                [
                    1.180734611, 
                    1.180734611
                ], 
                [
                    1.182385167, 
                    1.182385167
                ], 
                [
                    1.185220799, 
                    1.185220799
                ], 
                [
                    1.186808189, 
                    1.186808189
                ], 
                [
                    1.190061197, 
                    1.190061197
                ], 
                [
                    1.191939633, 
                    1.191939633
                ], 
                [
                    1.193008583, 
                    1.193008583
                ], 
                [
                    1.193665501, 
                    1.193665501
                ], 
                [
                    1.193811753, 
                    1.193811753
                ], 
                [
                    1.196227579, 
                    1.196227579
                ], 
                [
                    1.198543313, 
                    1.198543313
                ], 
                [
                    1.197380587, 
                    1.197380587
                ], 
                [
                    1.198150231, 
                    1.198150231
                ], 
                [
                    1.198330495, 
                    1.198330495
                ], 
                [
                    1.19874447, 
                    1.19874447
                ], 
                [
                    1.200815317, 
                    1.200815317
                ], 
                [
                    1.204214577, 
                    1.204214577
                ], 
                [
                    1.205422976, 
                    1.205422976
                ], 
                [
                    1.205999723, 
                    1.205999723
                ], 
                [
                    1.207841232, 
                    1.207841232
                ], 
                [
                    1.21096791, 
                    1.21096791
                ], 
                [
                    1.217531261, 
                    1.217531261
                ], 
                [
                    1.221427096, 
                    1.221427096
                ], 
                [
                    1.224336097, 
                    1.224336097
                ], 
                [
                    1.226368559, 
                    1.226368559
                ], 
                [
                    1.227603196, 
                    1.227603196
                ], 
                [
                    1.230395584, 
                    1.230395584
                ], 
                [
                    1.235235011, 
                    1.235235011
                ], 
                [
                    1.237606622, 
                    1.237606622
                ], 
                [
                    1.241404794, 
                    1.241404794
                ], 
                [
                    1.242821638, 
                    1.242821638
                ], 
                [
                    1.24613927, 
                    1.24613927
                ], 
                [
                    1.250000121, 
                    1.250000121
                ], 
                [
                    1.254688438, 
                    1.254688438
                ], 
                [
                    1.258099359, 
                    1.258099359
                ], 
                [
                    1.262152136, 
                    1.262152136
                ], 
                [
                    1.264957643, 
                    1.264957643
                ], 
                [
                    1.269931174, 
                    1.269931174
                ], 
                [
                    1.274920254, 
                    1.274920254
                ], 
                [
                    1.278033813, 
                    1.278033813
                ], 
                [
                    1.281363105, 
                    1.281363105
                ], 
                [
                    1.287720926, 
                    1.287720926
                ], 
                [
                    1.291040501, 
                    1.291040501
                ], 
                [
                    1.294655009, 
                    1.294655009
                ], 
                [
                    1.30010568, 
                    1.30010568
                ], 
                [
                    1.303570535, 
                    1.303570535
                ], 
                [
                    1.306707902, 
                    1.306707902
                ], 
                [
                    1.30888856, 
                    1.30888856
                ], 
                [
                    1.311648879, 
                    1.311648879
                ], 
                [
                    1.316696751, 
                    1.316696751
                ], 
                [
                    1.318312809, 
                    1.318312809
                ], 
                [
                    1.322347122, 
                    1.322347122
                ], 
                [
                    1.328086896, 
                    1.328086896
                ], 
                [
                    1.330989094, 
                    1.330989094
                ], 
                [
                    1.336315379, 
                    1.336315379
                ], 
                [
                    1.339991594, 
                    1.339991594
                ], 
                [
                    1.344417046, 
                    1.344417046
                ], 
                [
                    1.349031021, 
                    1.349031021
                ], 
                [
                    1.349816699, 
                    1.349816699
                ], 
                [
                    1.351448305, 
                    1.351448305
                ], 
                [
                    1.353927783, 
                    1.353927783
                ], 
                [
                    1.357514595, 
                    1.357514595
                ], 
                [
                    1.362884123, 
                    1.362884123
                ], 
                [
                    1.364553628, 
                    1.364553628
                ], 
                [
                    1.36862001, 
                    1.36862001
                ], 
                [
                    1.371192778, 
                    1.371192778
                ], 
                [
                    1.375673135, 
                    1.375673135
                ], 
                [
                    1.381152474, 
                    1.381152474
                ], 
                [
                    1.3878475, 
                    1.3878475
                ], 
                [
                    1.393135885, 
                    1.393135885
                ], 
                [
                    1.395908838, 
                    1.395908838
                ], 
                [
                    1.39936106, 
                    1.39936106
                ], 
                [
                    1.40249211, 
                    1.40249211
                ], 
                [
                    1.404444401, 
                    1.404444401
                ], 
                [
                    1.40690833, 
                    1.40690833
                ], 
                [
                    1.410636535, 
                    1.410636535
                ], 
                [
                    1.41238524, 
                    1.41238524
                ], 
                [
                    1.412627697, 
                    1.412627697
                ], 
                [
                    1.412264254, 
                    1.412264254
                ], 
                [
                    1.413175777, 
                    1.413175777
                ], 
                [
                    1.413576147, 
                    1.413576147
                ], 
                [
                    1.414807868, 
                    1.414807868
                ], 
                [
                    1.418254745, 
                    1.418254745
                ], 
                [
                    1.423498915, 
                    1.423498915
                ], 
                [
                    1.425786468, 
                    1.425786468
                ], 
                [
                    1.430461179, 
                    1.430461179
                ], 
                [
                    1.434381309, 
                    1.434381309
                ], 
                [
                    1.438312128, 
                    1.438312128
                ], 
                [
                    1.44122453, 
                    1.44122453
                ], 
                [
                    1.441736169, 
                    1.441736169
                ], 
                [
                    1.446164536, 
                    1.446164536
                ], 
                [
                    1.448616318, 
                    1.448616318
                ], 
                [
                    1.448042486, 
                    1.448042486
                ], 
                [
                    1.450035591, 
                    1.450035591
                ], 
                [
                    1.451714814, 
                    1.451714814
                ], 
                [
                    1.451970876, 
                    1.451970876
                ], 
                [
                    1.454501371, 
                    1.454501371
                ], 
                [
                    1.457605698, 
                    1.457605698
                ], 
                [
                    1.461359655, 
                    1.461359655
                ], 
                [
                    1.463336241, 
                    1.463336241
                ], 
                [
                    1.465683557, 
                    1.465683557
                ], 
                [
                    1.467956533, 
                    1.467956533
                ], 
                [
                    1.468952113, 
                    1.468952113
                ], 
                [
                    1.470577403, 
                    1.470577403
                ], 
                [
                    1.473606903, 
                    1.473606903
                ], 
                [
                    1.475710791, 
                    1.475710791
                ], 
                [
                    1.477895335, 
                    1.477895335
                ], 
                [
                    1.480644965, 
                    1.480644965
                ], 
                [
                    1.484803666, 
                    1.484803666
                ], 
                [
                    1.485386243, 
                    1.485386243
                ], 
                [
                    1.487724328, 
                    1.487724328
                ], 
                [
                    1.490915142, 
                    1.490915142
                ], 
                [
                    1.492831964, 
                    1.492831964
                ], 
                [
                    1.49607574, 
                    1.49607574
                ], 
                [
                    1.499912298, 
                    1.499912298
                ], 
                [
                    1.504499063, 
                    1.504499063
                ], 
                [
                    1.50604904, 
                    1.50604904
                ], 
                [
                    1.507396889, 
                    1.507396889
                ], 
                [
                    1.510530369, 
                    1.510530369
                ], 
                [
                    1.513348995, 
                    1.513348995
                ], 
                [
                    1.513332961, 
                    1.513332961
                ], 
                [
                    1.517996983, 
                    1.517996983
                ], 
                [
                    1.519975997, 
                    1.519975997
                ], 
                [
                    1.521403045, 
                    1.521403045
                ], 
                [
                    1.522414174, 
                    1.522414174
                ], 
                [
                    1.525944624, 
                    1.525944624
                ], 
                [
                    1.529541153, 
                    1.529541153
                ], 
                [
                    1.533000663, 
                    1.533000663
                ], 
                [
                    1.53677114, 
                    1.53677114
                ], 
                [
                    1.540406541, 
                    1.540406541
                ], 
                [
                    1.543072599, 
                    1.543072599
                ], 
                [
                    1.546584098, 
                    1.546584098
                ], 
                [
                    1.550240392, 
                    1.550240392
                ], 
                [
                    1.552063437, 
                    1.552063437
                ], 
                [
                    1.552802956, 
                    1.552802956
                ], 
                [
                    1.555245992, 
                    1.555245992
                ], 
                [
                    1.55804081, 
                    1.55804081
                ], 
                [
                    1.560377922, 
                    1.560377922
                ], 
                [
                    1.56193373, 
                    1.56193373
                ], 
                [
                    1.561493031, 
                    1.561493031
                ], 
                [
                    1.562391435, 
                    1.562391435
                ], 
                [
                    1.561307909, 
                    1.561307909
                ], 
                [
                    1.560510083, 
                    1.560510083
                ], 
                [
                    1.562267534, 
                    1.562267534
                ], 
                [
                    1.560023225, 
                    1.560023225
                ], 
                [
                    1.556931045, 
                    1.556931045
                ], 
                [
                    1.551861795, 
                    1.551861795
                ], 
                [
                    1.550052354, 
                    1.550052354
                ], 
                [
                    1.546457282, 
                    1.546457282
                ], 
                [
                    1.540818573, 
                    1.540818573
                ], 
                [
                    1.537041779, 
                    1.537041779
                ], 
                [
                    1.533139627, 
                    1.533139627
                ], 
                [
                    1.529845804, 
                    1.529845804
                ], 
                [
                    1.526246845, 
                    1.526246845
                ], 
                [
                    1.525412092, 
                    1.525412092
                ], 
                [
                    1.525161375, 
                    1.525161375
                ], 
                [
                    1.523441338, 
                    1.523441338
                ], 
                [
                    1.523203253, 
                    1.523203253
                ], 
                [
                    1.522831065, 
                    1.522831065
                ], 
                [
                    1.523360681, 
                    1.523360681
                ], 
                [
                    1.524095826, 
                    1.524095826
                ], 
                [
                    1.523177502, 
                    1.523177502
                ], 
                [
                    1.524674517, 
                    1.524674517
                ], 
                [
                    1.524486965, 
                    1.524486965
                ], 
                [
                    1.525861051, 
                    1.525861051
                ], 
                [
                    1.526740991, 
                    1.526740991
                ], 
                [
                    1.525903323, 
                    1.525903323
                ], 
                [
                    1.528388145, 
                    1.528388145
                ], 
                [
                    1.52871952, 
                    1.52871952
                ], 
                [
                    1.530361329, 
                    1.530361329
                ], 
                [
                    1.530094092, 
                    1.530094092
                ], 
                [
                    1.527375558, 
                    1.527375558
                ], 
                [
                    1.526855174, 
                    1.526855174
                ], 
                [
                    1.524982083, 
                    1.524982083
                ], 
                [
                    1.52513951, 
                    1.52513951
                ], 
                [
                    1.525833841, 
                    1.525833841
                ], 
                [
                    1.526047631, 
                    1.526047631
                ], 
                [
                    1.52834976, 
                    1.52834976
                ], 
                [
                    1.52941871, 
                    1.52941871
                ], 
                [
                    1.532760635, 
                    1.532760635
                ], 
                [
                    1.537068017, 
                    1.537068017
                ], 
                [
                    1.538793399, 
                    1.538793399
                ], 
                [
                    1.542721303, 
                    1.542721303
                ], 
                [
                    1.544430165, 
                    1.544430165
                ], 
                [
                    1.547997056, 
                    1.547997056
                ], 
                [
                    1.551526047, 
                    1.551526047
                ], 
                [
                    1.554899069, 
                    1.554899069
                ], 
                [
                    1.556161219, 
                    1.556161219
                ], 
                [
                    1.557196756, 
                    1.557196756
                ], 
                [
                    1.559046837, 
                    1.559046837
                ], 
                [
                    1.561837233, 
                    1.561837233
                ]
            ], 
            "cmax": 1.562391435, 
            "cmin": 0.888733027, 
            "colorscale": [
                [
                    "0", 
                    "rgb(217,217,255)"
                ], 
                [
                    "0.1", 
                    "rgb(217,217,255)"
                ], 
                [
                    "0.2", 
                    "rgb(217,217,255)"
                ], 
                [
                    "0.3", 
                    "rgb(217,217,255)"
                ], 
                [
                    "0.4", 
                    "rgb(217,217,255)"
                ], 
                [
                    "0.5", 
                    "rgb(217,217,255)"
                ], 
                [
                    "0.6", 
                    "rgb(217,217,255)"
                ], 
                [
                    "0.7", 
                    "rgb(217,217,255)"
                ], 
                [
                    "0.8", 
                    "rgb(217,217,255)"
                ], 
                [
                    "0.9", 
                    "rgb(217,217,255)"
                ], 
                [
                    "1", 
                    "rgb(217,217,255)"
                ]
            ], 
            name: "", 
            showscale: false, 
            type: "surface", 
            "uid": "4e5ed1", 
            "xsrc": "Diksha_Gabha:2343:fd16b0,b2457b", 
            "ysrc": "Diksha_Gabha:2343:807139,59d0b7", 
            "zsrc": "Diksha_Gabha:2343:237e77,1269f0"
        }
    ], 
    "layout": {
        "autosize": true, 
        "height": 690, 
        "scene": {
            "aspectratio": {
                x: 1, 
                y: 1, 
                z: 1
            }, 
            "camera": {
                "center": {
                    x: 0, 
                    y: 0, 
                    z: 0
                }, 
                "eye": {
                    x: 1.0919953574036658, 
                    y: 1.9460497462348987, 
                    z: 0.15005898984631597
                }, 
                "up": {
                    x: 0, 
                    y: 0, 
                    z: 1
                }
            }, 
            "xaxis": {
                "title": "Sample #"
            }, 
            "yaxis": {
                "title": "Wavelength"
            }, 
            "zaxis": {
                "title": "OD"
            }
        }, 
        "showlegend": false, 
        "title": "Ribbon Plot", 
        "width": 1531
    }
}