<template>
  <v-container>
    <v-row class="mt-15 ml-15">
      <v-col cols="8">
        <v-card>
          <v-row>
            <Header
              headerTitle="Shopping Basket"
              :hasMenu="hasMenu"
              :menuList="menuList"
              class="ml-3 mb-3"
            ></Header>
            <router-link
              data-cy="continue_shopping"
              class="font-railway mr-5 mt-7"
              color="success"
              :to="{ name: 'web-shop' }"
            >
              continue shopping</router-link
            >
          </v-row>
          <v-divider></v-divider>
          <v-card-subtitle class="text-subtitle-1 text">
            Your Items:
          </v-card-subtitle>
          <!-- shopping item cards -->
          <div v-for="item in shoppingCart" :key="item.priceId">
            <v-card elevation="0">
              <v-divider class="ml-3 mr-3"></v-divider>
              <v-row dense>
                <!-- item image -->
                <v-col cols="3">
                  <v-img
                    width="100"
                    class="ml-5 mt-5"
                    src="../../assets/hqsdollar.svg"
                  ></v-img>
                </v-col>
                <!-- item details -->
                <v-col cols="5">
                  <v-card-title class="text">{{ item.name }}</v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="5"
                        ><v-sheet class="mt-2 text">Quantity: </v-sheet>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          data-cy="quantity"
                          type="number"
                          v-model.number="item.quantity"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          data-cy="delete"
                          class="mt-2 ml-n2"
                          color="darkGrey"
                          icon
                          x-small
                          @click="deleteItem(item.priceId)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
                <!-- item pricing -->
                <v-col cols="4">
                  <v-card-text class="mt-9">
                    <v-sheet class="mt-2 text"
                      >Price per item: €{{ item.price.toFixed(2) }}
                    </v-sheet>
                    <v-sheet class="text mt-2"
                      >Subtotal: €{{ (item.price * item.quantity).toFixed(2) }}
                    </v-sheet>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card>
      </v-col>
      <!-- order column -->
      <v-col cols="4">
        <v-card class="mt-n3">
            <Header
              headerTitle="Your Order"
              :hasMenu="hasMenu"
              :menuList="menuList"
            ></Header>
            <v-divider></v-divider>
            <v-card-text class="text">
              <v-row>
                <v-sheet class="ml-3"
                  >Subtotal ({{ shoppingCart.length }}
                  {{ shoppingCart.length !== 1 ? 'items' : 'item' }}):
                </v-sheet>
                <v-spacer></v-spacer>
                <v-sheet class="mr-3">€{{ totalPrice.toFixed(2) }}</v-sheet>
              </v-row>
            </v-card-text>
            <v-card-actions class="mt-10">
              <v-spacer></v-spacer>
              <v-btn
                data-cy="place_order_and_pay"
                :disabled="shoppingCart.length === 0"
                rounded
                outlined
                :dark="shoppingCart.length !== 0"
                color="hqsOrange"
                @click="checkoutAndPay"
                >place order and pay</v-btn
              >
            </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const Header = () => import('../../components/general/Header.vue')

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    Header,
  },

  data() {
    return {
      /* Header */
      hasMenu: false,
      menuList: [],
      /* Stripe data */
      configurations: {},
    }
  },

  methods: {
    ...mapActions('payment', ['initializePayment']),
    ...mapMutations('payment', [
      'deleteCartItem',
      'archiveOrder',
      'resetShoppingCart',
      'set',
    ]),

    deleteItem(priceId) {
      this.deleteCartItem(priceId)
    },

    async checkoutAndPay() {
      let data = {
        price_id: this.shoppingCart[0].priceId,
        quantity: this.shoppingCart[0].quantity,
        success_url: window.location.origin + '/success',
        cancel_url: window.location.origin + '/failure',
      }
      let result = await this.initializePayment(data)
      window.location.href = result.data.url
    },
  },

  computed: {
    ...mapState('payment', {
      shoppingCart: (state) => state.shoppingCart,
    }),

    totalPrice() {
      if (this.shoppingCart.length > 0) {
        let subtotals = this.shoppingCart.map((e) => (e = e.price * e.quantity))
        return subtotals.reduce((a, b) => a + b)
      } else {
        return 0
      }
    },
  },

  created() {
    let localStorageCart = JSON.parse(localStorage.getItem('shoppingCart'))
    if (this.shoppingCart.length === 0 && localStorageCart.length > 0) {
      this.set(['shoppingCart', localStorageCart])
    }
  },

}
</script>

<style lang="css" scoped></style>
