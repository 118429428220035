<template>
  <div
    class="page-margin-left page-margin-top-app-dashbord page-margin-right page"
  >
    <v-row class="mt-15">
      <v-col cols="12">
        <v-card>
          <v-row>
            <!-- Heading -->
            <v-col sm="8" md="7" lg="5">
              <Header :headerTitle="mainTitle" />
            </v-col>
            <!-- Details Button-->
            <v-col>
              <v-btn
                data-cy="details_text"
                small
                depressed
                text
                class="text font-weight-regular caption text-lowercase mt-4"
                @click="showDetails = !showDetails"
                >details
                <v-icon right mt-3 v-if="!showDetails">
                  mdi-chevron-down
                </v-icon>
                <v-icon right mt-3 v-else> mdi-chevron-up </v-icon></v-btn
              >
            </v-col>
          </v-row>
          <!-- Details -->
          <v-card-text
            class="text body-2 font-weight-regular"
            v-if="showDetails"
          >
            Tokens are a means of authentication without using your
            user-credentials that are your username and password.
            A token consists of a token ID and a token secret. the token ID 
            consists of your owner ID and the token name: <br>
            &#60;owner_ID&#62;.&#60;token_name&#62;
            <br /><br />
            When creating a token you can assign a set of permissions to it.
            Since you can share a token with another person, this is a means to
            restrict the rights and permissions that can be executed using that
            token. Authenticating with a token does not allow for alterations of
            the user-account itself, like username, password or other settings
            of the account the token was created for.
            <br /><br />
            Since the HQS-Cloud can also be accessed via python scripts that
            need credentials to work with the HQS-Cloud, you can use tokens to
            circumvent using your user-credentials in plain text in your script.
            <br /><br />
            You can have multiple tokens at the same time with different sets
            of permissions.
            <br />
            Every token can be deleted anytime.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- tokens form -->
      <v-col cols="4">
        <v-card>
          <Header :headerTitle="createTitle" class mt-15></Header>
          <CreateTokens />
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card>
          <!-- tokens table -->
          <Header
            :headerTitle="manageTitle"
            :subHeader="'owner: ' + owner"
            class="ml-4"
          />
          <v-card-text>
            <TokenTable />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const Header = () => import('../components/general/Header.vue')
const CreateTokens = () => import('../components/tokens/CreateTokens.vue')
const TokenTable = () => import('../components/tokens/TokenTable.vue')
import { mapState, mapActions } from 'vuex'

export default {
  components: { Header, CreateTokens, TokenTable },

  data() {
    return {
      /* Header */
      mainTitle: 'Create And Manage Your Tokens Here',
      manageTitle: 'Manage Tokens',
      createTitle: 'Create Token',
      /* UI machanics */
      showDetails: false,
    }
  },

  methods: {
    ...mapActions('tokens', ['load', 'loadPermissionsAndGroups']),
  },

  computed: {
    ...mapState('tokens', {
      owner: (state) => state.owner,
    }),
  },

  async created() {
    try {
      this.load()
      this.loadPermissionsAndGroups()
    } catch (error) {
      console.log('error', error)
    }
  },
}
</script>

<style css scoped>
.alert {
  margin: auto;
}
</style>
