import Vue from 'vue'
import restClient from '../../views/lib/restClientNewAPI'

const paymentURL = 'https://payment.' + process.env.VUE_APP_SUBDOMAIN + 'cloud.quantumsimulations.de/'

export default {

    paymentURL,
    namespaced: true,
    state: {
        shoppingCart: [],
        archivedOrder: {},
    },

    getters: {
    },

    mutations: {

        addToCart(state, product) {
            let index = state.shoppingCart.findIndex(e => e.priceId === product.priceId)
            if (index !== -1) {
                state.shoppingCart[index].quantity += product.quantity
            } else {
                if (product.quantity > 0) {
                    state.shoppingCart.push(product)
                }
            }
            localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
        },

        deleteCartItem(state, priceId) {
            let index = state.shoppingCart.find(e => e.priceId = priceId)
            state.shoppingCart.splice(index, 1)
            localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
        },

        set(state, [type, payload]) {
            Vue.set(state, type, payload)
        },

        resetShoppingCart(state) {
            state.shoppingCart.splice(0, state.shoppingCart.length)
            localStorage.removeItem('shoppingCart')
        },

    },

    actions: {

        async loadConfigurations(/* { commit } */) {
            try {
                let result = await restClient.get(`${paymentURL}configurations`)
                return result
            } catch (e) {
                console.log(e)
            }
        },

        async initializePayment({ commit }, payload) {
            commit
            try {
                let result = await restClient.post(`${paymentURL}checkout-session`, payload)
                return result
            } catch (e) {
                console.log(e)
                throw e
            }
        }
    }
}