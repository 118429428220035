import utils from '../../appUtils/utils.js'
import workflowTags from './workflowTags'
import { cloneDeep, split, intersection, difference } from 'lodash'

const allowedTags = [
    ...workflowTags.topics,
    ...workflowTags.products,
    ...workflowTags.toolsAndMethods,
    ...workflowTags.systemTypes,
    ...workflowTags.calculationTypes,
    ...workflowTags.quantities,
]

export default {

    addCategory(topics, workflows, type) {
        if (type === 'topics') {
            return this.createTopics(topics, workflows)
        } else {
            for (let topic of topics) {
                if (type === 'products') {
                    this.addProducts(topic, workflows)
                } else {
                    this.addSubCategory(topic, workflows, type)
                }
            }
            return topics
        }
    },

    createTopics(topics, workflows) {
        for (let tag of workflowTags.topics) {
            if (this.hasWorkflowForCategory(workflows, [tag])) {
                let topic = this.getAppStructureObject(
                    tag, this.getRelatedTags(tag, workflows)
                )
                topic.products = []
                topics.push(topic)
            }
        }
        return topics
    },

    addProducts(topic, workflows) {
        for (let tag of workflowTags.products) {
            if (this.hasWorkflowForCategory(workflows, [tag, topic.name])) {
                let product = this.getAppStructureObject(tag, [tag])
                product.toolsAndMethods = []
                product.systemTypes = []
                product.calculationTypes = []
                product.quantities = []
                topic.products.push(product)
            }
        }
        return topic
    },

    addSubCategory(topic, workflows, type) {
        for (let product of topic.products) {
            for (let tag of workflowTags[type]) {
                let tags = [tag, topic.name, ...product.tags]
                if (this.hasWorkflowForCategory(workflows, tags)) {
                    product[type].push(this.getAppStructureObject(tag, [tag]))
                }
            }
        }
        return topic
    },

    getAppStructureObject(name, tags) {
        return {
            name: name,
            title: utils.formatLabel(name),
            tags: tags
        }
    },

    getActiveTags() {

    },

    getRelatedTags(mainTag, workflows) {
        let tags = []
        workflows.forEach(e => {
            if (e.tags.includes(mainTag)) {
                tags.push(...e.tags)
            }
        })
        let allowed = intersection(tags, allowedTags)
        let unique = new Set([...allowed])
        return Array.from(unique)
    },

    /* return true if the tagSubset is a subset of any workflow tags */
    hasWorkflowForCategory(workflows, tagSubset) {
        return workflows.find(e => difference(tagSubset, e.tags).length === 0)
    },

    getTypePlural(type) {
        return type === 'process' ? 'processes' : type + 's'
    },

    getProductLabelsByTags(tags, topicsHierarchy) {
        let products = []
        for (let card of topicsHierarchy) {
            for (let product of card.products) {
                for (let tag of tags) {
                    if (product.tags.includes(tag) && !products.includes(product.title)) {
                        products.push(product.title)
                    }
                }
            }
        }
        return products
    },

    getWorkflowTagsForData(structure, workflows) {
        let tags = []
        for (let workflow of workflows) {
            for (let key of Object.keys(workflow.inputs)) {
                if (typeof workflow.inputs[key] === 'object'
                    && !Array.isArray(workflow.inputs[key])
                    && 'hqschema' in workflow.inputs[key]
                    && workflow.inputs[key].hqschema.label === structure.hqschema.label) {
                    workflow.tags.forEach(e => tags.push(e))
                }
            }
        }
        return [...new Set(tags)]
    },

    getStructureSource(structure, processes) {
        let source = ''
        if ('_source' in structure) {
            // if source empty => uuid des jobs nicht mehr da => source = deleted job
            if ('process' in structure._source) {
                let process = processes.find(e => e._uuid === structure._source.process)
                if (process) {
                    source = 'job: ' + process.label
                } else {
                    source = 'deleted job'
                }
            } else if ('user' in structure._source) {
                source = 'me'
            }
        }
        return source
    },

    prepareResults(results) {
        let resultItems = []
        for (let result of results) {
            let def = result.schema.definition
            let resultItem = []
            for (let [key, value] of Object.entries(result.data)) {
                if (key.charAt(0) !== '_'
                    && key !== 'hqschema'
                    && Object.keys(result.data).includes(key)) {
                    let type = def[key].type ? def[key].type : ''
                    let unit = def[key].meta.unit ? def[key].meta.unit : ''
                    if (
                        ('check_with' in def[key] &&
                            def[key].check_with === 'svg_base64') || key.includes('svg')
                    ) {
                        type = 'svg'
                        value = decodeURIComponent(escape(atob(value)))
                    } else if (key === 'log') {
                        type = 'array'
                        value = this.formatLog(value)
                    }
                    key = utils.formatLabel(key)
                    resultItem.push({ name: key, value: value, type: type, unit: unit })
                }
            }
            resultItems.push(resultItem)
        }
        return resultItems
    },

    /* 
        See semver versioning: https://semver.org/lang/de/
    */
    getItemWithLatestVersion(items, versionIndex = 0) {
        const MAX_VERSION_LENGTH = 3
        const MAX_VERSION_INDEX = 2
        let filteredItems = []
        let versions = cloneDeep(items).map(e => e = e.version).map(e => split(e, '.'))
        if (versionIndex === MAX_VERSION_INDEX) {
            versions = versions.filter(e => e.length === MAX_VERSION_LENGTH)
        }
        let latestVersionPart = Math.max(...cloneDeep(versions).map(e => +e[versionIndex]))
        if (latestVersionPart >= 0) {
            filteredItems = items.filter(e => +split(e.version, '.')[versionIndex] === latestVersionPart)
        }
        if (filteredItems.length > 1 && versionIndex < 2) {
            return this.getItemWithLatestVersion(filteredItems, versionIndex += 1)
        } else if (filteredItems.length > 1 && versionIndex >= 2) {
            console.log('multiple items with same version detected', filteredItems)
            return filteredItems[0]
        } else {
            return filteredItems[0]
        }
    },

    getWorkflowsForProduct(label, workflows, topicsHierarchy) {
        let product = this.getProductByLabel(label, topicsHierarchy)
        let filteredWorkflows = workflows.filter(e =>
            (intersection(product.tags, e.tags)).length > 0
        )
        return filteredWorkflows
    },

    getAllProducts(topicsHierarchy) {
        let products = []
        for (let card of topicsHierarchy) {
            products.push(...card.products)
        }
        return products
    },

    getProductByLabel(label, topicsHierarchy) {
        for (let product of this.getAllProducts(topicsHierarchy)) {
            if (product.name === label) {
                return product
            }
        }
        return {}
    },

    setItemToEndOfObjectArray(array, itemKey, itemValue) {
        array.forEach(e => {
            let index = e.map(e => e[itemKey]).indexOf(itemValue)
            e.push(e.splice(index, 1)[0])
        })
        return array
    },

    filterDataForRelevance(type, dataArray, workflows) {
        if (type === 'schema') {
            return this.filterForActiveSchemas(dataArray, workflows)
        } else if (type === 'workflow') {
            return this.filterForActiveWorkflows(dataArray)
        } else {
            return dataArray
        }
    },

    filterForActiveWorkflows(workflows) {
        let activeWorkflows = workflows.filter(e => e.tags.includes('frontend'))
        let activeWorkflowsWithMaxVersion = []
        for (let workflow of activeWorkflows) {
            let group = activeWorkflows.filter(e => e.label === workflow.label)
            activeWorkflowsWithMaxVersion.push(this.getItemWithLatestVersion(group))
        }
        return activeWorkflowsWithMaxVersion
    },

    filterForActiveSchemas(dataArray) {
        return cloneDeep(dataArray).filter(e => e.tags.includes('frontend'))
    },

    getWorkflowOutputs(workflows) {
        let outputs = []
        workflows.forEach(e => {
            e = Object.values(e.outputs)
            outputs.push(...e)
        })
        return outputs.filter(e => typeof e === 'object' && !Array.isArray(e) && 'hqschema' in e)
    },

    getWorkflowInputs(workflows) {
        let inputs = []
        workflows.forEach(e => {
            e = Object.values(e.inputs)
            inputs.push(...e)
        })
        return inputs.filter(e => typeof e === 'object' && !Array.isArray(e) && 'hqschema' in e)
    },

    applyJobFilters(job, filter) {
        if (filter.state.length > 0 && !filter.state.includes(job.state)) {
            return false
        }
        if (filter.structure.length > 0 && job.inputSchemas.length > 0) {
            let hasStructure = false
            for (let label of filter.structure) {
                if (job.inputSchemas.includes(label)) {
                    hasStructure = true
                }
            }
            return hasStructure
        }
        if (filter.workflow.length > 0 && !filter.workflow.includes(job.workflow)) {
            return false
        }
        if (filter.date.length > 0) {
            let jobDate = this.convertDatesToTime(cloneDeep(job.createdDate).substring(0, 10))
            let dates = this.convertDatesToTime(filter.date)
            return this.isDateWithinFilter(dates, jobDate)
        }
        else {
            return true
        }
    },

    isDateWithinFilter(dates, jobDate) {
        if (dates.length === 2) {
            return !(jobDate < Math.min(...dates) || jobDate > Math.max(...dates))
        } else if (dates.length === 1) {
            return dates[0] === jobDate
        }
    },

    convertDatesToTime(dates) {
        if (Array.isArray(dates)) {
            dates = dates.map(e => {
                return new Date(e.replace(/-/g, '/')).getTime()
            })
        } else {
            dates = new Date(dates.replace(/-/g, '/')).getTime()
        }
        return dates
    },

    findWorkflowsByStructureName(name, workflows) {
        let currentWorkflows = []
        for (let workflow of workflows) {
            for (let key of Object.keys(workflow.inputs)) {
                if (typeof workflow.inputs[key] === 'object'
                    && !Array.isArray(workflow.inputs[key])
                    && 'hqschema' in workflow.inputs[key]
                    && workflow.inputs[key].hqschema.label === name) {
                    currentWorkflows.push(workflow)
                }
            }
        }
        return currentWorkflows
    },

    getInputStructures(job, datas, schemas) {
        let structures = []
        for (let input in job.inputs) {
            let inputStructures = datas.find(e => e._uuid === job.inputs[input])
            if (inputStructures) {
                let schema = schemas.find(e => e.label === inputStructures.hqschema.label)
                if (schema && schema.tags.includes('structure')) {
                    structures.push(inputStructures)
                }
            }
        }
        return structures
    },

    formatLog(value) {
        let array = value.split('********').join(',').split(',')
        return array
    },
}