<template>
  <v-container>
    <div>
      <v-img
        data-cy="splash"
        class="image"
        :src="require('../../assets/sarah-kilian-52jRtc2S_VE-unsplash.jpg')"
      >
        <h1 class="font mt-10" color="white--text">404</h1>
        <h2 class="font" color="white--text">
          Oh No! We couldn't find this page...!
        </h2>
      </v-img>
    </div>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped lang="css">
h1 {
  font-size: 100px !important;
}

.font {
  color: white;
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

.image {
    margin-top: 75px;
    margin-inline: 40px;
    border-radius: 10px;
}
</style>
