<template>
  <div class="page-margin-left page-margin-top">
    <v-row>
      <v-col v-for="card in structureCards" :key="card.title">
        <v-hover v-slot="{ hover }" close-delay="200">
          <v-card
            :data-cy="card.label + '_card'"
            @click.native="prepare(card)"
            :to="card.name + '-structures'"
            class="px-5 {'on-hover':hover} text structure-card"
            :elevation="hover ? 16 : 2"
          >
            <v-responsive :aspect-ratio="1 / 1">
              <v-card-title
                class="mt-5 text-subtitle-1 font-weight-bold structure-text"
              >
                {{ card.title }}
              </v-card-title>
              <v-card-text class="mt-2 text-body-1 structure-text"
                >{{ card.description }}
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-card class="mt-5 mr-7">
      <Header data-cy="structure_table_header" :headerTitle="headerTitle" :hasMenu="hasMenu" />
      <DynamicStructureTable />
    </v-card>
  </div>
</template>

<script>
const Header = () => import('../../components/general/Header.vue')
const DynamicStructureTable = () =>
  import('../../components/DynamicStructureTable.vue')
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: { DynamicStructureTable, Header },

  data() {
    return {
      /* Header */
      headerTitle: 'My Structures',
      hasMenu: false,
    }
  },
  methods: {
    ...mapMutations('dynamicForms', ['setStructureCard', 'createStructureCards']),

    ...mapActions('backend', ['load']),

    prepare(card) {
      this.setStructureCard(card.name)
    },
  },

  computed: {
    ...mapGetters('backend', ['getSchemasByTag']),

    ...mapState('dynamicForms', {
      structureCards: (state) => state.structureCards,
    }),

    ...mapState('backend', {
      schemas: (state) => state.schemas,
    }),
  },

  async created() {
    sessionStorage.clear()
    if (this.schemas.length === 0) {
      await this.load(['schema'])
    }
    await this.createStructureCards(this.getSchemasByTag('structure'))
  },
}
</script>

<style scoped>
.structure-card {
  background-image: linear-gradient(#e2ebfa, white);
  max-height: 200px;
  max-width: 200px;
  min-height: 200px;
  min-width: 200px;
}

.structure-text {
  word-break: keep-all;
}

.title {
  word-break: normal;
}
</style>
