<template>
  <div>
    <v-row class="mt-15 ml-15">
      <v-col cols="1"></v-col>
      <v-col cols="9" v-if="products.length === 0">
        <v-card color="#f3f7fd" class="mb-10">
            <v-skeleton-loader
          type="list-item-two-line, actions"
        ></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="9" v-if="products.length > 0">
        <v-card
          v-for="product in products"
          :key="product.priceId"
          data-cy="store_card"
          color="#f3f7fd"
          class="mb-10"
        >
          <v-row>
            <v-col cols="2">
              <v-img
                width="145"
                class="ml-3 mb-n3"
                src="../../assets/hqsdollar.svg"
              ></v-img>
            </v-col>
            <v-col cols="5">
              <v-card-text class="text title">
                {{ product.price.toFixed(2) }}{{ product.currency }}
                <span class="text-subtitle-2"> {{ product.name }}</span>
              </v-card-text>
              <v-card-text class="body-2">{{
                product.description
              }}</v-card-text>
            </v-col>
            <v-col cols="5" class="mt-10">
              <v-form v-model="valid">
                <v-row dense>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="quantity"
                      dense
                      type="number"
                      v-model.number="product.quantity"
                      label="Quantity"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col xl="8" lg="8" md="8" sm="9" xs="9">
                    <v-btn
                      data-cy="add_to_basket"
                      color="hqsOrange"
                      outlined
                      rounded
                      :disabled="product.quantity <= 0"
                      :dark="product.quantity > 0"
                      @click="addItem(product)"
                    >
                      Add to basket
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="1" class="mt-n10 ml-5"
        ><v-btn
          data-cy="go_to_basket"
          color="darkGrey"
          dark
          outlined
          :to="{ name: 'shopping-basket' }"
        >
          <v-icon>mdi-cart-variant</v-icon>{{ shoppingCart.length }}
        </v-btn></v-col
      >
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import { cloneDeep } from 'lodash-es'

export default {
  data() {
    return {
      valid: false,
      quantity: 0,
      configurations: {},
      products: [],
    }
  },

  methods: {
    ...mapMutations('payment', ['addToCart']),
    ...mapActions('payment', ['loadConfigurations']),

    createProductList() {
      //todo: as soon as there are more products we need to adjust here
      this.products = []
      let product = {
        priceId: this.configurations.data.price.id,
        name: 'HQ$',
        price: this.configurations.data.price.unit_amount / 100,
        currency: '€',
        description:
          '1 HQ$ is equivalent to 1 hour of computing time for any available HQS-Cloud Product.',
        quantity: 0,
      }
      this.products.push(product)
    },

    addItem(product) {
      this.addToCart(cloneDeep(product))
      console.log(
        'prod',
        this.products.filter((e) => e.priceId === product.priceId)
      )
      this.products
        .filter((e) => e.priceId === product.priceId)
        .map((e) => (e.quantity = 0))
    },
  },

  computed: {
    ...mapState('payment', {
      shoppingCart: (state) => state.shoppingCart,
    }),
  },

  async created() {
    this.configurations = await this.loadConfigurations()
    await this.createProductList()
  },
}
</script>

<style lang="css" scoped></style>
