<template>
  <div>
    <!-- number field -->
    <v-row v-if="data.type === 'number'" :data-cy="data.name + '_row'">
      <v-text-field
        class="mx-3"
        :data-cy="data.name"
        :label="data.label"
        v-model.number="value"
        :type="data.type"
        :rules="data.required === true ? req : []"
        :messages="[data.hint]"
      ></v-text-field>
      <v-col md="1" sm="2">
        <v-sheet :data-cy="data.name + '_unit'" class="text mt-6">{{
          data.unit
        }}</v-sheet>
      </v-col>
      <DynamicInfoDialog
        :info="data.doc"
        :headerTitle="data.label"
      ></DynamicInfoDialog>
    </v-row>
    <!-- text field -->
    <v-row v-if="data.type === 'text'" :data-cy="data.name + '_row'">
      <v-text-field
        class="mx-3"
        :data-cy="data.name"
        :label="data.label"
        v-model="value"
        :type="data.type"
        :rules="data.required === true ? req : []"
        :messages="[data.hint]"
      ></v-text-field>
      <DynamicInfoDialog
        :info="data.doc"
        :headerTitle="data.label"
      ></DynamicInfoDialog>
    </v-row>
    <!-- checkbox field-->
    <v-row v-if="data.type === 'checkbox'" :data-cy="data.name + '_row'">
      <v-checkbox
        class="mx-3 pt-3"
        :data-cy="data.name"
        v-if="data.type === 'checkbox'"
        :label="data.label"
        v-model="value"
        color="primary"
        :hint="data.hint"
        persistent-hint
      >
      </v-checkbox>
      <DynamicInfoDialog
        :info="data.doc"
        :headerTitle="data.label"
      ></DynamicInfoDialog>
    </v-row>
    <!-- complex field -->
    <ComplexField
      :data-cy="data.name"
      v-if="data.type === 'complex'"
      v-model="value"
      :valuePath="valuePath"
      :value="value"
      :label="data.label"
      :name="data.name"
      :doc="data.doc"
      :unit="data.unit"
      :hint="data.hint"
      :hints="data.hints"
      :required="data.required"
      :isNestedFormMultiple="isNestedFormMultiple"
    ></ComplexField>
    <!-- select field -->
    <v-row v-if="data.type === 'select'" :data-cy="data.name + '_row'">
      <v-select
        class="mx-3"
        :data-cy="data.name"
        :label="data.label"
        v-model="value"
        :items="data.items"
        :rules="data.required === true ? req : []"
        color="primary"
        :hint="data.hint"
        persistent-hint
      >
      </v-select>
      <DynamicInfoDialog
        :info="data.doc"
        :headerTitle="data.label"
      ></DynamicInfoDialog>
    </v-row>
    <!-- vector field -->
    <VectorField
      :data-cy="data.name"
      v-if="data.type === 'vector'"
      v-model="value"
      :valuePath="valuePath"
      :label="data.label"
      :name="data.name"
      :doc="data.doc"
      :unit="data.unit"
      :hints="data.hints"
      :hint="data.hint"
      :required="data.required"
      :isNestedFormMultiple="isNestedFormMultiple"
    ></VectorField>
    <!-- list field -->
    <v-row v-if="data.type === 'list'">
      <v-sheet class="text mt-5 mr-3 mb-3">
        {{ data.label }}
      </v-sheet>
      <div class="mt-n3">
        <DynamicInfoDialog
          :info="data.doc"
          :headerTitle="data.label"
        ></DynamicInfoDialog>
      </div>
      <v-col md="11" sm="10">
        <ListField
          :data-cy="data.name"
          v-model="value"
          :label="data.label"
          :name="data.name"
          :nodes="data.nodes"
          :required="data.required"
          :valuePath="valuePath"
          :isNestedFormMultiple="isNestedFormMultiple"
          :unit="data.unit"
        >
        </ListField>
      </v-col>
      <v-col md="1" sm="2">
        <v-sheet :data-cy="data.name + '_unit'" class="text mt-3">{{
          data.unit
        }}</v-sheet>
      </v-col>
    </v-row>
    <v-row v-if="data.type === 'list'">
      <span :data-cy="data.name + '_hint'" class="text text-caption font-weight-light mt-n8 ml-3">{{
        data.hint
      }}</span>
    </v-row>
    <!-- multiple field -->
    <NestedFormMultiple
      :data-cy="data.name"
      v-if="data.type === 'multiple'"
      :nodes="data.nodes"
      :label="data.label"
      :name="data.name"
      :doc="data.doc"
      :required="data.required"
      :valuePath="valuePath"
      :minlength="data.minlength"
      :maxlength="data.maxlength"
      v-model="value"
    >
    </NestedFormMultiple>
    <!-- dict field -->
    <NestedFormSingle
      :data-cy="data.name"
      v-if="data.type === 'dict'"
      :nodes="data.nodes"
      :label="data.label"
      :name="data.name"
      :doc="data.doc"
      :required="data.required"
      :valuePath="valuePath"
      v-model="value"
    >
    </NestedFormSingle>
  </div>
</template>

<script>
const NestedFormMultiple = () => import('./NestedFormMultiple.vue')
const NestedFormSingle = () => import('./NestedFormSingle.vue')
const ComplexField = () => import('./ComplexField.vue')
const VectorField = () => import('./VectorField.vue')
const ListField = () => import('./ListField.vue')
const DynamicInfoDialog = () => import('./DynamicInfoDialog.vue')
import { mapMutations, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'FormField',

  components: {
    ComplexField,
    VectorField,
    ListField,
    NestedFormMultiple,
    NestedFormSingle,
    DynamicInfoDialog,
  },

  data: () => ({
    req: [(v) => !!v || v === 0 || 'required'],
    dialog: false,
  }),

  props: {
    data: Object,
    valuePath: Array,
    isNestedFormMultiple: Boolean,
    isDict: Boolean,
  },

  methods: {
    ...mapMutations('dynamicForms', [
      'setValue',
      'setMultipleValue',
      'setDictValue',
    ]),
  },

  computed: {
    ...mapGetters('dynamicForms', [
      'getValue',
      'getMultipleValue',
      'getNestedValue',
    ]),

    value: {
      get() {
        let path = cloneDeep(this.valuePath)
        if (this.isNestedFormMultiple) {
          return this.getMultipleValue(path)
        } else if (this.isDict) {
          return this.getNestedValue(path)
        } else {
          return this.getValue(path)
        }
      },
      set(value) {
        let path = cloneDeep(this.valuePath)
        if (this.isNestedFormMultiple) {
          this.setMultipleValue([path, value])
        } else if (this.isDict) {
          this.setDictValue([path, value])
        } else {
          this.setValue([path, value])
        }
      },
    },
  },
}
</script>
