import latticeUtils from '../storeUtils/latticeUtils'
import utils from '../../appUtils/utils'
import { getField, updateField } from 'vuex-map-fields'
import Vue from 'vue'
import { cloneDeep } from 'lodash'

//const YAML = require('js-yaml')

export default {
    namespaced: true,

    state: {
        schema: {},
        currentProcess: {},
        hasSpinChannel: false,
        hasClusterEmbedding: false,
        updateLattice: false,
        showError: false,
        showSuccess: false,
        addOneMore: false,
        visibleMagField: true,
        bondParam: 'none',
        siteParam: 'default',
        isLargeOfset: false,
        hasLattice: false,
        latticeType: '',
        /* structure params */
        label: '',
        description: '',
        _uuid: '',
        sites: [],
        bonds: [],
        latticeVectorA: [0, 0, 0],
        latticeVectorB: [0, 0, 0],
        jobParam: {},
        system: {},
        clusterSize: [0, 0],
        boundaryConditions: ['', ''],
        editSiteIndex: '',
        editBondIndex: '',
        currentBond: {},
        currentSite: {},
        spinChannel: {
            e_uu: 0,
            e_dd: 0,
            e_ud_r: 0,
            e_ud_i: 0,
        },
        /* lattice plot bond colors */
        scaleColors: [
            '#4f7eb0', // rgb(79, 126, 176)    < -4
            '#6c7d90', // rgb(108, 125, 144)   < -3
            '#897c6f', // rgb(137, 124, 111)   < -2
            '#a87a4c', // rgb(168, 122, 76)    < -1
            '#c5792b', // rgb(197, 121, 43)    <  0
            '#de8612', // rgb(222, 134, 18)    >= 0
            '#e59c0e', // rgb(229, 156, 14)    >= 1
            '#edb10a', // rgb(237, 177, 10)    >= 2
            '#f4c606', // rgb(244, 198, 6)     >= 3
            '#fcdc02'  // rgb(252, 220, 2)     >= 4
        ],
    },

    getters: {
        getField,

        getParamColors: (state) => (type) => {
            let colorsAndIds = []
            let color = ''
            let value = 0
            let currentParam = ''
            let prunedParam = ''
            let items = []
            if (type === 'bond') {
                items = state.bonds
                currentParam = state.bondParam
            } else if (type === 'site') {
                items = state.sites
                currentParam = state.siteParam
            }
            for (let item of items) {
                if (currentParam.includes('_r')) {
                    prunedParam = currentParam.slice(0, -2)
                    value = item[prunedParam][0]
                } else if (currentParam.includes('_i')) {
                    prunedParam = currentParam.slice(0, -2)
                    value = item[prunedParam][1]
                } else {
                    value = item[currentParam]
                }
                if (value < -4) {
                    color = state.scaleColors[0];
                } else if (value < -3) {
                    color = state.scaleColors[1];
                } else if (value < -2) {
                    color = state.scaleColors[2];
                } else if (value < -1) {
                    color = state.scaleColors[3];
                } else if (value < 0) {
                    color = state.scaleColors[4];
                } else if (value >= 0 && value < 1) {
                    color = state.scaleColors[5];
                } else if (value >= 1 && value < 2) {
                    color = state.scaleColors[6];
                } else if (value >= 2 && value < 3) {
                    color = state.scaleColors[7];
                } else if (value >= 3 && value < 4) {
                    color = state.scaleColors[8];
                } else if (value >= 4) {
                    color = state.scaleColors[9];
                }
                colorsAndIds.push([item.id, color])
            }
            return colorsAndIds;
        },

        getSitesTable: state => {
            let sitesTable = [];
            for (let site of state.sites) {
                let id = site.id;
                let name = site.name;
                let position = site.position
                position = position.join(", ")
                position = `[ ${position} ]`
                if (state.latticeType.includes('spins')) {
                    let magneticField = [site.Bx, site.By, site.Bz]
                    magneticField = magneticField.join(", ")
                    magneticField = `[${magneticField}]`
                    sitesTable.push({ id, name, position, magneticField });
                }
                if (state.latticeType.includes('spinless')) {
                    let e0 = site.e0
                    sitesTable.push({ id, name, position, e0 });
                }
                if (state.latticeType.includes('spinful')) {
                    let e0 = site.e0
                    let Bx = site.Bx
                    let By = site.By
                    let Bz = site.Bz
                    let spinChannel = utils.calculateSpinChannel({ e0, Bx, By, Bz })
                    let e_uu = spinChannel.e_uu
                    let e_dd = spinChannel.e_dd
                    let e_ud = spinChannel.e_ud_r + " + " + spinChannel.e_ud_i + " i"
                    let onsite_U = site.onsite_U
                    let onsite_BCS = site.onsite_BCS[0] + " + " + site.onsite_BCS[1] + " i"
                    sitesTable.push({
                        id, name, position, Bx, By, Bz, e0,
                        e_uu, e_dd, e_ud, onsite_U, onsite_BCS
                    })
                }
            }
            return sitesTable;
        },

        getSiteIDs: state => {
            let siteIDs = [];
            for (let site of state.sites) {
                siteIDs.push(site.id);
            }
            return siteIDs
        },

        getBondsTable: state => {
            let tableData = []
            for (let bond of state.bonds) {
                let id = bond.id;
                let id_to = bond.id_to;
                let id_from = bond.id_from;
                let translation = bond.translation
                translation = translation.join(", ")
                translation = `[ ${translation} ]`
                if (state.latticeType.includes('spins')) {
                    let Jz = bond.Jz
                    let Jperp = bond.Jperp
                    let Jcross = bond.Jcross
                    let Jd = bond.Jd
                    tableData.push({ id, id_to, id_from, translation, Jz, Jperp, Jcross, Jd })
                }
                if (state.latticeType.includes('spinless')) {
                    let t = bond.t[0] + " + " + bond.t[1] + " i"
                    /* currently not in use because qolossal does not need it */
                    /* let U = bond.U */
                    let D = bond.D[0] + " + " + bond.D[1] + " i"
                    tableData.push({ id, id_to, id_from, translation, t, /* U, */ D })
                }
                if (state.latticeType.includes('spinful')) {
                    /* currently not in use because qolossal does not need it */
                /* let U = bond.U */
                    let Jz = bond.Jz
                    let Jperp = bond.Jperp
                    let Jcross = bond.Jcross
                    let Jd = bond.Jd
                    let t_uu = bond.t_uu[0] + " + " + bond.t_uu[1] + " i"
                    let t_dd = bond.t_dd[0] + " + " + bond.t_dd[1] + " i"
                    let t_ud = bond.t_ud[0] + " + " + bond.t_ud[1] + " i"
                    let t_du = bond.t_du[0] + " + " + bond.t_du[1] + " i"
                    let D_uu = bond.D_uu[0] + " + " + bond.D_uu[1] + " i"
                    let D_dd = bond.D_dd[0] + " + " + bond.D_dd[1] + " i"
                    let D_ud = bond.D_ud[0] + " + " + bond.D_ud[1] + " i"
                    let D_du = bond.D_du[0] + " + " + bond.D_du[1] + " i"
                    tableData.push({
                        id, id_to, id_from, translation,
                        /* U, */ Jz, Jperp, Jcross, Jd,
                        t_uu, t_dd, t_ud, t_du, D_uu, D_dd, D_ud, D_du
                    })
                }
            }
            return tableData
        },

        //returns a normalized lattice for interaction with backend
        getLattice: (state) => {
            let atoms = state.sites
            let bondsWithIds = cloneDeep(state.bonds)
            let bonds = []
            let lattice_vectors = [state.latticeVectorA, state.latticeVectorB, [0, 0, 0]]
            let system = state.system
            let structure = {}
            let type = ''
            for (let bond of bondsWithIds) {
                delete bond.id
                bonds.push(bond)
            }
            if (lattice_vectors.length === 2) {
                lattice_vectors.push([0, 0, 0])
            }
            system.cluster_size = state.clusterSize
            system.system_boundary_conditions = state.boundaryConditions
            if (state.latticeType.includes('spinful')) {
                system.site_type = 'spinful_fermions'
                type = 'lattice_fermions_spinful'
            }
            if (state.latticeType.includes('spinless')) {
                system.site_type = 'spinless_fermions'
                type = 'lattice_fermions_spinless'
            }
            if (state.latticeType.includes('spins')) {
                system.site_type = 'spins'
                type = 'lattice_spins'
            }
            system.system_size = [1, 1, 1]
            structure = {
                id: state._uuid,
                name: state.label,
                type: type,
                data: {
                    unitcell: {
                        atoms: atoms,
                        bonds: bonds,
                        lattice_vectors: lattice_vectors
                    },
                    system
                }
            }
            return structure
        },

        getDataNode: (state) => (validatedInput) => {
            return {
                label: state.label,
                description: state.description,
                config_dict: validatedInput,
                profile: 'cloudUI',
                hqschema: { label: state.schema.label, version: state.schema.version }
            }
        }

    },

    mutations: {
        updateField,

        set(state, [type, payload]) {
            Vue.set(state, type, payload)
        },

        //Converts lattices from spinful to spinless and vice versa
        convertType(state, type) {
            let bonds = cloneDeep(state.bonds)
            let sites = cloneDeep(state.sites)
            let system = cloneDeep(state.system)
            bonds = latticeUtils.convertBonds(bonds, type)
            sites = latticeUtils.convertSites(sites, type)
            system = latticeUtils.convertSystemType(system, type)
            Vue.set(state, 'currentBond', latticeUtils.setCurrentBond(type))
            Vue.set(state, 'currentSite', latticeUtils.getCurrentSite(type))
            Vue.set(state, 'bonds', bonds)
            Vue.set(state, 'sites', sites)
            Vue.set(state, 'system', system)
            state.latticeType = type
        },

        setMagneticField(state, field) {
            state.currentSite.e0 = field.e0
            state.currentSite.Bx = field.Bx
            state.currentSite.By = field.By
            state.currentSite.Bz = field.Bz
        },

        setSpinChannel(state, channel) {
            state.spinChannel.e_uu = channel.e_uu
            state.spinChannel.e_dd = channel.e_dd
            state.spinChannel.e_ud_r = channel.e_ud_r
            state.spinChannel.e_ud_i = channel.e_ud_i
            state.hasSpinChannel = true
        },

        setHasLattice: (state) => {
            state.hasLattice = true;
        },

        updatePlot: (state) => {
            state.updateLattice = !state.updateLattice;
        },

        activateAlert: (state, payload) => {
            if (payload == 'success') {
                state.showSuccess = true;
            }
            if (payload == 'error') {
                state.showError = true;
            }
        },

        resetAlert: (state) => {
            state.showError = false;
            state.showSuccess = false;
        },

        resetAddOneMore: (state) => {
            state.addOneMore = false;
        },

        resetBondParam: (state) => {
            state.bondParam = 'none';
        },

        resetSiteParam: (state) => {
            state.siteParam = 'default';
        },

        setId: (state, id) => {
            if (id !== undefined) {
                state.id = id
            }
        },

        prepareAddSite: (state) => {
            let currentSite = latticeUtils.getCurrentSite(state.latticeType)
            Vue.set(state, 'currentSite', currentSite)
            state.editSiteIndex = -1
            currentSite.id = 0
            if (state.sites.length > 0) {
                let lastSite = state.sites[state.sites.length - 1]
                currentSite.id = parseInt(lastSite.id) + 1;
            }
            state.spinChannel.e_uu = 0
            state.spinChannel.e_dd = 0
            state.spinChannel.e_ud_r = 0
            state.spinChannel.e_ud_i = 0
            Vue.set(state, 'currentSite', currentSite)
        },

        applyAddedSite: (state) => {
            let site = cloneDeep(state.currentSite)
            site = latticeUtils.fixEmptyInputSite(site, state.latticeType, true)
            site = latticeUtils.convertCurrentSiteToSite(site, state.latticeType)
            state.sites.push(cloneDeep(site))
            state.editSiteIndex = ''
            if (state.addOneMore) {
                state.currentSite.id++
            }
        },

        prepareEditSite: (state, index) => {
            state.editSiteIndex = index;
            let site = cloneDeep(state.sites[index])
            site = latticeUtils.convertSiteToCurrentSite(site, state.latticeType)
            Vue.set(state, 'currentSite', site)
        },

        applyEditedSite: (state) => {
            let site = cloneDeep(state.currentSite)
            site = latticeUtils.fixEmptyInputSite(site, state.latticeType, true)
            site = latticeUtils.convertCurrentSiteToSite(site, state.latticeType)
            Vue.set(state.sites, state.editSiteIndex, cloneDeep(site))
        },

        setEditBondIndex: (state, payload) => {
            state.editBondIndex = payload
        },

        setEditSiteIndex: (state, payload) => {
            state.editSiteIndex = payload
        },

        prepareAddBond: (state) => {
            let currentBond = latticeUtils.setCurrentBond(state.latticeType)
            Vue.set(state, 'currentBond', currentBond)
            state.editBondIndex = -1
            currentBond.id = 0
            if (state.bonds.length > 0) {
                let bondsArray = state.bonds
                let lastBond = bondsArray[bondsArray.length - 1]
                currentBond.id = parseInt(lastBond.id) + 1
                Vue.set(state, 'bonds', bondsArray)
            }
            Vue.set(state, 'currentBond', currentBond)
        },

        applyAddedBond: (state) => {
            if (state.currentBond.translation.a > 2 || state.currentBond.translation.b > 2) {
                state.isLargeOfset = true
            }
            let bond = cloneDeep(state.currentBond)
            bond = latticeUtils.fixEmptyInputBond(bond, state.latticeType, true)
            bond = latticeUtils.convertCurrentBondToBond(bond, state.latticeType)
            state.bonds.push(cloneDeep(bond))
            state.editBondIndex = ''
            if (state.addOneMore) {
                state.currentBond.id++
            }
        },

        prepareEditBond: (state, id) => {
            let bond = state.bonds.find(element => element.id == id)
            bond = cloneDeep(bond)
            bond = latticeUtils.convertBondToCurrentBond(bond, state.latticeType)
            Vue.set(state, 'currentBond', bond)
            let bondIndex = state.bonds.findIndex(i => i.id == id)
            state.editBondIndex = bondIndex
        },

        applyEditedBond: (state) => {
            let bond = cloneDeep(state.currentBond)
            if (bond.translation.a > 2 || bond.translation.b > 2) {
                state.isLargeOfset = true
            }
            bond = latticeUtils.fixEmptyInputBond(bond, state.latticeType, true)
            bond = latticeUtils.convertCurrentBondToBond(bond, state.latticeType)
            Vue.set(state.bonds, state.editBondIndex, cloneDeep(bond))
            state.currentBond.id = ''
        },

        delete: (state, [id, type]) => {
            if (type.includes('bond')) {
                let item = state.bonds.find(element => id === element.id)
                let index = state.bonds.indexOf(item)
                state.bonds.splice(index, 1)
            } else if (type.includes('site')) {
                state.sites.splice(id, 1)
            }
            state.updateLattice = !state.updateLattice
        },

        resetLattice: (state) => {
            state.latticeType = ''
            state.hasLattice = false
            state.isLargeOfset = false
            state._uuid = ''
            state.label = ''
            state.description = ''
            state.sites.splice(0, state.sites.length)
            state.bonds.splice(0, state.bonds.length)
            state.latticeVectorA = [0, 0, 0]
            state.latticeVectorB = [0, 0, 0]
            state.clusterSize = [0, 0]
            state.boundaryConditions = ['', '']
            Vue.set(state, 'system', {})
            state.hasSpinChannel = false
        },

        editAllMagneticFields: (state, field) => {
            for (let site of state.sites) {
                site.Bx = utils.fixEmptyInput(field[0])
                site.By = utils.fixEmptyInput(field[1])
                site.Bz = utils.fixEmptyInput(field[2])
            }
            state.updateLattice = !state.updateLattice
        },

        editJValues: (state, jValues) => {
            for (let bond of state.bonds) {
                bond.Jz = utils.fixEmptyInput(jValues[0])
                bond.Jperp = utils.fixEmptyInput(jValues[1])
                bond.Jcross = utils.fixEmptyInput(jValues[2])
                bond.Jd = utils.fixEmptyInput(jValues[3])
            }
            state.updateLattice = !state.updateLattice
        },

        resetSiteID: (state) => {
            state.currentSite.id = -1;
        },

        resetBondID: (state) => {
            state.currentBond.id = -1;
        },

        loadStructure(state, config) {
            state.hasLattice = true
            state.updateLattice = !state.updateLattice
            state.isLargeOfset = false
            state.label = config.label
            state.description = config.description
            state._uuid = config._uuid
            if (config.config_dict.system.site_type.includes('spins')) {
                state.latticeType = 'spins'
            }
            if (config.config_dict.system.site_type.includes('spinless')) {
                state.latticeType = 'spinless fermions'
            }
            if (config.config_dict.system.site_type.includes('spinful')) {
                state.latticeType = 'spinful fermions'
            }
            for (let site of config.config_dict.unitcell.atoms) {
                latticeUtils.fixEmptyInputSite(site, state.latticeType, false)
            }
            Vue.set(state, 'sites', cloneDeep(config.config_dict.unitcell.atoms))
            for (let bond of config.config_dict.unitcell.bonds) {
                latticeUtils.fixEmptyInputBond(bond, state.latticeType, false)
            }
            Vue.set(state, 'bonds', cloneDeep(config.config_dict.unitcell.bonds))
            Vue.set(state, 'latticeVectorA', config.config_dict.unitcell.lattice_vectors[0])
            if (config.config_dict.unitcell.lattice_vectors.length === 1) {
                Vue.set(state, 'latticeVectorB', [0, 0, 0])
            } else {
                Vue.set(state, 'latticeVectorB', config.config_dict.unitcell.lattice_vectors[1])
            }
            state.clusterSize[0] = config.config_dict.system.cluster_size[0]
            state.clusterSize[1] = config.config_dict.system.cluster_size[1]
            state.boundaryConditions[0] = config.config_dict.system.system_boundary_conditions[0]
            state.boundaryConditions[1] = config.config_dict.system.system_boundary_conditions[1]
            Vue.set(state, 'system', config.config_dict.system)
            Vue.set(state, 'jobParam', config.config_dict.optional)
            for (let [i, bond] of state.bonds.entries()) {
                bond.id = i
            }
        }
    },
}
