<template>
  <div
    id="jobs-dashboard"
    class="page-margin-left page-margin-top mb-15 page-margin-right"
  >
    <a class="anchor" href="#jobs-dashboard"></a>
    <h1 class="anchor">Jobs Dashboard</h1>
    <SecondaryAppBar v-bind:barColor="barColor" :appBarTitle="appBarTitle" />
    <v-card>
      <!-- Heading -->
      <Header
        :hasMenu="hasMenu"
        :menuList="menuList"
        headerTitle="Jobs Dashboard"
        :icon="icon"
      ></Header>
      <DynamicJobsTable />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const DynamicJobsTable = () => import('../components/jobs/DynamicJobsTable.vue')
const SecondaryAppBar = () => import('../components/general/SecondaryAppBar')
const Header = () => import('../components/general/Header')

export default {
  components: {
    DynamicJobsTable,
    SecondaryAppBar,
    Header,
  },

  data() {
    return {
      /* SecondaryAppBar */
      barColor: 'white',
      appBarTitle: 'Jobs',
      /* Header component */
      icon: 'mdi-plus',
      hasMenu: true,
    }
  },

  computed: {
    ...mapGetters('backend', ['getProductsForMenu']),
    
    menuList() {
      if (this.$route.name === 'jobs') {
        return this.getProductsForMenu
      } else {
        return []
      }
    },
  },

  created() {
    sessionStorage.clear()
  },
}
</script>

<style scoped>
.jobs-window {
  margin-top: -50px;
}

.app-icon {
  left: 10px;
  float: left !important;
  height: 50px !important;
  width: 80px;
}

.content {
  overflow-y: scroll;
}

.icon-tab {
  width: 200px;
}

.v-toolbar__title {
  margin-left: 10px;
  width: 300px !important;
}

.jobs-table {
  margin-right: 50px;
}

.stats {
  margin-top: 50px;
  margin-left: 50px;
}

.title {
  width: 80px !important;
}

.v-item--active {
  color: white !important;
}
</style>
