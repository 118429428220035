<template>
  <div
    id="structures-dashboard"
    class="page-margin-left page-margin-top page-margin-right"
  >
    <a class="anchor" href="#structures-dashboard"></a>
    <v-card class="mt-15">
      <!-- Heading -->
      <Header
        :headerTitle="headerTitle"
        :hasMenu="hasMenu"
        :icon="icon"
        :to="{ name: schemaLabelFromPathName }"
        :href="href"
        :menuList="menuList"
      ></Header>
      <!-- e2e testing setup creation -->
      <CreateTestingSetup />
      <!-- Structures Table -->
      <DynamicStructureTable />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
const Header = () => import('../../components/general/Header.vue')
const DynamicStructureTable = () =>
  import('../../components/DynamicStructureTable.vue')
const CreateTestingSetup = () =>
  import('../../components/general/CreateTestingSetup.vue')
import utils from '../../appUtils/utils.js'

export default {
  components: {
    Header,
    DynamicStructureTable,
    CreateTestingSetup,
  },

  data() {
    return {
      hasMenu: true,
      icon: 'mdi-plus',
      href: '',
      menuList: [],
    }
  },

  methods: {
    ...mapActions('backend', ['load']),
  },

  computed: {
    ...mapState('dynamicForms', {
      edit: (state) => state.edit,
    }),

    headerTitle() {
      return utils.formatLabel(this.schemaLabelFromPathName) + ' Structures'
    },

    schemaLabelFromPathName(){
        return utils.stripPathName(this.$route.name)
    },
  },

  created() {
    this.load(['data'])
  },
}
</script>

<style scoped>
.config {
  color: white !important;
  background-color: var(--v-hqsOrange-base) !important;
}

.template {
  color: white !important;
  background-color: var(--v-hqsOrange-base) !important;
}
</style>
