<template>
  <div>
    <v-container>
      <v-row class="mt-15">
        <v-col cols="1"></v-col>
        <v-col cols="5" class="mr-15">
          <!-- standard offer card -->
          <v-card class="text" height="700">
            <v-img
              height="100"
              class="standard"
              :src="require('../../assets/header_background.jpg')"
            ></v-img>
            <v-card-title> HQS Cloud Standard </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-sheet
                class="text ml-10 mt-10 mb-5 headline font-weight-regular"
                >1 HQ$<span class="title">/ 5.00 €</span></v-sheet
              >
              <v-sheet class="text ml-10 mt-5 mb-5 headline font-weight-regular"
                ><span class="title"
                  >Access to all cloud applications</span
                ></v-sheet
              >
              <v-sheet
                class="text ml-10 mt-5 mb-15 headline font-weight-regular"
                >0,02 HQ$<span class="title"
                  >/ Expansion of storage time per GB</span
                ></v-sheet
              >
            </v-card-text>
          </v-card></v-col
        >
        <v-col cols="5">
          <!-- premium offer card -->
          <v-card class="text" height="700">
            <v-img
              height="100"
              class="premium"
              :src="require('../../assets/header_background.jpg')"
            ></v-img>
            <v-card-title> HQS Cloud Premium </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-sheet
                class="text ml-10 mt-10 mb-5 headline font-weight-regular"
                >2000 HQ$<span class="title">/ 10,000.00 €</span></v-sheet
              >
              <v-sheet class="text ml-10 mt-5 mb-5 headline font-weight-regular"
                ><span class="title"
                  >Access to all cloud applications</span
                ></v-sheet
              >
              <v-sheet class="text ml-10 mb-5 headline font-weight-regular"
                >4.00 €<span class="title"
                  >/ for every further HQ$*</span
                ></v-sheet
              >
              <v-sheet class="text ml-10 mb-5 headline font-weight-regular"
                >0.015 HQ$<span class="title"
                  >/ Expansion of storage time per GB*</span
                ></v-sheet
              >
              <v-sheet class="text ml-10 headline font-weight-regular"
                >10%<span class="title"> discount for support*</span></v-sheet
              >
            </v-card-text>

            <v-card-actions
              ><v-sheet class="text ml-5 mt-5 font-weight-regular">
                *Available during premium period: 12 month from the time of
                purchase.</v-sheet
              ></v-card-actions
            >
          </v-card></v-col
        >
        <v-col cols="1"></v-col>
      </v-row>
      <v-row class="mt-5 mb-5 ml-3">
        <v-sheet class="text footnote">
          Interested? To get your offer please contact us at
          <a href="mailto:cloud@quantumsimulations.de">
            cloud@quantumsimulations.de</a
          >.
        </v-sheet>
      </v-row>
      <!-- temporarily removed for release april 23 -->
      <!-- <v-row>
        <v-sheet class="text footnote">
          Or use our
          <router-link :to="{ name: 'store' }"> web shop</router-link>.
        </v-sheet>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="css" scoped>
.standard {
  opacity: 0.8;
  filter: grayscale(90%);
}

.premium {
  opacity: 1;
}

.footnote {
  margin: auto;
}
</style>
