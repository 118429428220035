<template>
  <div id="contact" class="page-margin-left page-margin-top">
    <a class="anchor" href="#contact"></a>
    <v-container class="mt-15">
      <v-row>
        <v-col>
          <h1 class="text">Contact us</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <h2 class="text">General Support</h2>
          <p class="text mt-5">
            For support and general requests, <br />
            please contact our <br />
            <a href="mailto:cloud@quantumsimulations.de">HQS Cloud support team</a>
            <CopyToClipboard
              v-bind:color="color"
              v-bind:size="size"
              v-bind:copyData="{ configuration: 'cloud@quantumsimulations.de' }"
              v-bind:isString="isString"
            />
          </p>
        </v-col>

        <v-col cols="4">
          <h2 class="text">Physics Questions</h2>

          <p class="text mt-5">
            For physics related questions, <br />
            please contact our <br />
            <a href="mailto:cloud@quantumsimulations.de">development team</a>
            <CopyToClipboard
              class="copy"
              v-bind:color="color"
              v-bind:size="size"
              v-bind:copyData="{ configuration: 'cloud@quantumsimulations.de' }"
              v-bind:isString="isString"
            />
          </p>
        </v-col>

        <v-col cols="4">
          <h2 class="text">Feature Requests</h2>
          <p class="text mt-5">
            For feature requests, <br />
            please contact our <br />
            <a :href="`mailto:cloud@quantumsimulations.de`">development team</a>
            <CopyToClipboard
              v-bind:color="color"
              v-bind:size="size"
              v-bind:copyData="{ configuration: 'scce@quantumsimulations.de' }"
              v-bind:isString="isString"
            />
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-15">
        <v-col cols="12">
          <h3 class="text">You can also contact us via:</h3>
          <v-sheet class="text ml-3 mt-3"
            >cloud@quantumsimulations.de
            <CopyToClipboard
              @click="setCopyValue('ho')"
              v-bind:color="color"
              v-bind:size="size"
              v-bind:copyData="{ configuration: 'cloud@quantumsimulations.de' }"
              v-bind:isString="isString"
            />
          </v-sheet>
          <h3 class="text mt-5">Or visit us here:</h3>
          <v-sheet class="ml-3 mt-3">
            <a href="https://quantumsimulations.de/"
              >www.quantumsimulations.de</a
            >
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CopyToClipboard from '../../components/general/CopyToClipboard.vue'

export default {
  components: {
    CopyToClipboard,
  },

  data() {
    return {
      subdomain: process.env.VUE_APP_SUBDOMAIN,
      color: 'primary',
      size: 'large',
      isString: true,
    }
  },
}
</script>

<style scoped>
anchor {
  font-size: 0px;
}

.copy {
  margin-left: 5px !important;
}
</style>
