<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-form ref="form" v-model="valid">
          <v-card class="elevation-12">
            <v-toolbar color="hqsOrange" dark flat>
              <v-toolbar-title>API access</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert type="error" v-if="error">
                An error has occured, please try again.
              </v-alert>

              <div v-if="success">
                <v-alert
                    type="success"
                >Access to the API successfully granted.
                </v-alert>

                <p>To proceed, copy the following code and paste it into the application.</p>

                <input ref="code"
                    :value="code" 
                    class="code"
                    v-on:click="selectCode"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.code {
    width: 100%;
    border: 1px solid #aaa;
    padding: .3em;
}
</style>

<script>
export default {
  data() {
    return {
      code: '',
      error: false,
      success: false
    }
  },
  created() {
    if (this.$route.query.code && this.$route.query.code.length == 36) {
      this.code = this.$route.query.code
      this.success = true
      this.$nextTick(() => {
        this.selectCode();
      });
    } else {
      this.error = true
    }
  },
  methods: {
    selectCode() {
      this.$refs.code.focus();
      this.$refs.code.select();
    }
  }
}
</script>
