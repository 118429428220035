import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import credits from './modules/credits'
import auth from './modules/auth'
import dynamicForms from './modules/dynamicForms'
import backend from './modules/backend'
import latticeBackend from './modules/latticeBackend'
import lattice from './modules/lattice'
import payment from './modules/payment'
import tokens from './modules/tokens'

import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
})

const store = new Vuex.Store({
    modules: {
        credits: credits,
        auth: auth,
        backend: backend,
        latticeBackend: latticeBackend,
        lattice: lattice,
        dynamicForms: dynamicForms,
        payment: payment,
        tokens: tokens,
    },
    state: {
        
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
    plugins: [vuexLocal.plugin]
})

export default store
