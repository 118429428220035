<template>
  <div>
    <v-container>
      <v-card class="mt-15 ml-15">
        <Header
          headerTitle="My Orders"
          :hasMenu="hasMenu"
          :menuList="menuList"
        ></Header>
        <!-- list of orders -->
        <div v-for="item in orders" :key="item.id">
          <v-card elevation="0">
            <v-divider class="ml-3 mr-3"></v-divider>
            <v-row dense>
              <!-- item image -->
              <v-col cols="3">
                <v-img
                  width="100"
                  class="ml-5 mt-5"
                  src="../../assets/hqsdollar.svg"
                ></v-img>
              </v-col>
              <v-col cols="9">
                <v-row>
                  <v-card-title class="text mt-5"
                    >Order placed: {{ item.date }}</v-card-title
                  >
                </v-row>
                <v-row dense>
                  <!-- quantity -->
                  <v-col cols="6">
                    <v-card-text>
                      <v-sheet class="text"
                        >Quantity: {{ item.quantity }}
                      </v-sheet>
                    </v-card-text>
                  </v-col>
                  <!-- total -->
                  <v-col cols="6">
                    <v-card-text class="">
                      <v-sheet class="text"
                        >Total: €{{ item.total.toFixed(2) }}
                      </v-sheet>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
const Header = () => import('../../components/general/Header.vue')

export default {
  components: {
    Header,
  },

  data() {
    return {
      /* Header */
      hasMenu: false,
      menuList: [],
      orders: [
        {
          name: 'HQ$',
          date: '1 Jan, 23',
          total: 45.98,
          quantity: 10,
          id: '#01',
          priceId: 'xy-1',
        },
        {
          name: 'HQ$',
          date: '1 Jan, 23',
          total: 15.00,
          quantity: 3,
          id: '#02',
          priceId: 'xy-2',
        },
      ],
    }
  },
}
</script>

<style lang="css" scoped></style>
