<template>
  <div class="page-margin-left page-margin-top">
    <v-row v-if="currentProducts.length === 0">
      <v-col v-for="card in topicsHierarchy" :key="card.title">
        <v-hover v-slot="{ hover }" close-delay="200">
          <v-card
            class="px-5 { 'on-hover': hover } text topic-card"
            :data-cy="card.name + '_card'"
            :elevation="hover ? 16 : 2"
            @click="prepareApplication(card)"
          >
            <v-responsive :aspect-ratio="1 / 1">
              <v-card-title class="mt-10 heading-6 title">{{
                card.title
              }}</v-card-title>
            </v-responsive>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="4">
        <v-card
          class="px-5 text topic-card"
          :data-cy="currentTopic.name + '_card'"
        >
          <v-responsive :aspect-ratio="1 / 1">
            <v-card-title class="mt-10 heading-6 title"
              >{{ currentTopic.title }} Products</v-card-title
            >
          </v-responsive>
        </v-card>
      </v-col>
      <v-col v-for="product in currentProducts" :key="product.name">
        <v-hover v-slot="{ hover }" close-delay="200">
          <v-card
            class="px-5 { 'on-hover': hover } text product-card"
            :data-cy="product.name + '_card'"
            :elevation="hover ? 16 : 2"
            :to="{ name: product.name + '-dashboard' }"
            @click.native="setDocsRoute(product.name)"
          >
            <v-responsive :aspect-ratio="1 / 1">
              <v-card-title class="mt-1 subtitle-1 font-weight-medium title">{{
                product.title
              }}</v-card-title>
            </v-responsive>
          </v-card>
        </v-hover>
      </v-col>
      <v-col>
        <v-btn icon @click="goBack" data-cy="go_back"
          ><v-icon>mdi-arrow-left-bold-outline</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import utils from '../../appUtils/utils.js'

export default {
  data() {
    return {
      currentProducts: [],
    }
  },
  methods: {
    ...mapMutations('backend', [
      'setCurrentTopic',
      'setInputStructureSchemasForCurrentWorkflows',
      'set',
    ]),

    ...mapActions('backend', ['load']),

    setDocsRoute(productName) {
      let route = ''
      route = utils.createDocsRoute([this.currentTopic.name, productName])
      this.set(['docsRouteName', route])
    },

    prepareApplication(card) {
      this.setCurrentTopic([card.name, false])
      this.currentProducts = this.currentTopic.products
      this.set(['appBarTitle', 'Products'])
      this.setInputStructureSchemasForCurrentWorkflows()
      this.set(['docsRouteName', utils.createDocsRoute([card.name])])
    },

    goBack() {
      this.currentProducts = []
      this.setCurrentTopic(['', false])
    },
  },

  computed: {
    ...mapState('backend', {
      currentTopic: (state) => state.currentTopic,
      schemas: (state) => state.schemas,
      workflows: (state) => state.workflows,
      topicsHierarchy: (state) => state.topicsHierarchy,
    }),

    ...mapGetters('backend', ['getSchemasByTag']),

    ...mapGetters('dynamicForms', ['getAllStructureCardLabels']),
  },

  async created() {
    sessionStorage.clear()
    if (this.schemas.length === 0) {
      await this.load(['schema'])
    }
    if (this.workflows.length === 0) {
      await this.load(['workflow'])
    }
    this.setCurrentTopic(['', false])
    this.currentProducts = []
  },
}
</script>

<style scoped>
.topic-card {
  max-height: 300px;
  max-width: 300px;
  min-height: 300px;
  min-width: 300px;
  background-image: linear-gradient(#f2d3b3, white);
}

.title {
  word-break: normal;
}

.product-card {
  max-height: 205px;
  max-width: 205px;
  min-height: 205px;
  min-width: 205px;
  background-image: linear-gradient(#bba8cf, white);
}
</style>
