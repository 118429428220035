<template>
  <div id="structures">
    <a class="anchor" href="#structures"></a>
    <h1 class="anchor">Structures</h1>
    <SecondaryAppBar
      v-bind:barColor="barColor"
      :appBarTitle="appBarTitle"
    />

    <router-view />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'

const SecondaryAppBar = () =>
  import(
    '../../components/general/SecondaryAppBar'
  )

export default {
  components: {
    SecondaryAppBar,
  },

  data() {
    return {
      appBarTitle: 'Structures',
      barColor: 'blueGreyLight',
    }
  },

  methods: {
    ...mapMutations('dynamicForms', ['createUserInputFromSchema']),

    ...mapMutations('backend', ['set']), 

    ...mapActions('backend', ['load']),
  },

  computed: {
    ...mapGetters('backend', ['getSchemasByTag']),

    ...mapGetters('dynamicForms', ['getAllStructureCardLabels']),

    ...mapState('backend', {
        schemas: (state) => state.schemas,
    }),

  },

  async created() {
    if(this.schemas.length === 0){
        await this.load(['schema'])
    }
    
  },
}
</script>

<style scoped>
.toolbar-title {
  overflow: visible;
}

.app-icon {
  left: 10px;
  float: left !important;
  height: 50px !important;
  width: 50px;
}

.content {
  overflow-y: scroll;
}

.icon-tab {
  width: 200px;
}

.v-toolbar__title {
  margin-left: 10px;
  width: 170px !important;
}

.doku-tabs {
  padding-right: 10px;
}
</style>
