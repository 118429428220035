import { cloneDeep, intersection } from 'lodash-es'

export default {

    calculateSpinChannel(field) {
        for (let item of Object.values(field)) {
            this.fixEmptyInput(item)
        }
        let e_uu = field.e0 - (0.5 * field.Bz)
        let e_dd = field.e0 + (0.5 * field.Bz)
        let e_ud_r = 0.5 * field.Bx * (-1)
        let e_ud_i = 0.5 * field.By
        return { e_uu: e_uu, e_dd: e_dd, e_ud_r: e_ud_r, e_ud_i: e_ud_i }
    },

    calculateMagneticField(channel) {
        let e_uu = this.fixEmptyInput(channel.e_uu)
        let e_dd = this.fixEmptyInput(channel.e_dd)
        let e_ud_r = this.fixEmptyInput(channel.e_ud_r)
        let e_ud_i = this.fixEmptyInput(channel.e_ud_i)
        let e0 = 0.5 * e_uu + 0.5 * e_dd
        let Bx = 2 * e_ud_r * (-1)
        let By = 2 * e_ud_i
        let Bz = (-1) * e_uu + e_dd
        return { e0: e0, Bx: Bx, By: By, Bz: Bz }

    },

    fixEmptyInput(value) {
        if (value == '' || value == null) {
            return 0
        } else {
            return value
        }
    },

    /*
        Chops an array into sub-array of definded length
        and returns an array containing the sub-arrays.
    */
    subdivideArray(array, subdivisionLength) {
        array = cloneDeep(array)
        let subdividedArray = []
        while (array.length) {
            subdividedArray.push(array.splice(0, subdivisionLength))
        }
        return subdividedArray
    },

    filterForMatchingTags(items, tags) {
        let filteredItems = []
        if (tags.every(e => typeof e === 'string')) {
            tags = [tags]
        }
        for (let item of items) {
            let boolArray = []
            for (let tag of tags) {
                boolArray.push(intersection(tag, item.tags).length > 0)
            }
            if (boolArray.every(e => e === true)) {
                filteredItems.push(item)
            }
        }
        return filteredItems
    },

    isArraySubset(mainArray, subsetArray) {
        return (subsetArray.every(e => mainArray.includes(e)))
    },

    filterArrayOfObjectsForDuplicates(array) {
        return array.filter((currentValue, index, self) =>
            index === self.findIndex((e) => e.type === currentValue.type))
    },

    removeSubsetFromArray(array, subset) {
        return array.filter(e => !subset.includes(e))
    },

    hasSubstring(array, substring) {
        if (array.some(e => e.includes(substring))) {
            return true
        }
        return false
    },

    formatLabel(label) {
        label = label.replace(/_/g, ' ')
        label = label.replace(/-/g, ' ')
        label = label.replace(/\w\S*/g, function (label) {
            return label.charAt(0).toUpperCase() + label.substr(1).toLowerCase()
        })
        return label
    },

    convertCamelCase(payload, caseType) {
        let result = payload.replace(/([A-Z])/g, " $1")
        if (caseType === 'snakeCase') {
            return result.split(' ').join('_').toLowerCase()
        }
        else if (caseType === 'kebabCase') {
            return result.split(' ').join('-').toLowerCase()
        }
    },

    convertToPathNotation(payload) {
        payload = payload.replaceAll('_', '-')
        payload = payload.replaceAll(' ', '-')
        payload = payload.toLowerCase()
        return payload
    },

    convertToUnderscoreNotation(payload) {
        payload = payload.replaceAll('-', '_')
        payload = payload.replaceAll(' ', '_')
        payload = payload.toLowerCase()
        return payload
    },

    createDocsRoute(pathParts) {
        let path = ''
        for (let [index, part] of pathParts.entries()) {
            part = part.replaceAll('-', '_')
            if (index !== pathParts.length - 1) {
                path = path + part + '/'
            } else {
                path = path + part + '.html'
            }
        }
        return path
    },

    addPartToDocsRoute(route, part) {
        return route.replace('.html', '/' + part + '.html')
    },

    getStructureDashboardRouteName(routeName) {
        return routeName.replace('-structures', '') + '-structure-dashboard'
    },

    getProductDashboardRouteName(productName) {
        return productName + '-dashboard'
    },

    stripPathName(name) {
        if (name.includes('-dashboard')) {
            name = name.replace('-dashboard', '')
        }
        if (name.includes('-structures')) {
            name = name.replace('-structures', '')
        }
        if (name.includes('-structure')) {
            name = name.replace('-structure', '')
        }
        return name
    },

    getUniqueArray(array) {
        array = new Set([...array])
        return Array.from(array)
    },

    transformJobTableToFormattedStrings(jobTable) {
        return jobTable.map(job => ({
            ...job,
            workflow: this.formatLabel(job.workflow),
            structureLabels: job.structureLabels
                .split(',')
                .map(label => this.formatLabel(label))
                .join(', ')
                .trim(),
        }))
    }
}
