import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
})

export default new Vuetify({

    theme: {
        options: { customProperties: true },
        themes: {
            dark: {
                hqsOrange: {
                    base: '#d97815',
                    lighten1: '#e09343'
                },
                darkGrey: '#4d4d4d',
                lightGrey: '#b4b4b4',
                blueGreyMedium: '#c5cee3',
                blueGreyLight: '#e2ebfa',
                error: '#f34a6a',
                success: '#40aa53',
                primary: '#427cbe',
                primaryLight: '#D9E4F2',
                hqsPurple: '#77529f',
                //medium blue with hint of turquoise
                info: '#34c7dd',
                //reddish purple
                raspberry: '#a30158',
                teal: '#046578',
                //brownish red
                darkRed: '#7b0900',
            },

            light: {
                hqsOrange: {
                    base: '#d97815',
                    lighten1: '#e09343'
                },
                darkGrey: '#4d4d4d',
                lightGrey: '#b4b4b4',
                blueGreyMedium: '#c5cee3',
                blueGreyLight: '#e2ebfa',
                error: '#f34a6a',
                success: '#40aa53',
                primary: '#427cbe',
                hqsPurple: '#77529f',
                //medium blue with hint of turquoise
                info: '#34c7dd',
                //reddish purple
                raspberry: '#a30158',
                teal: '#046578',
                //brownish red
                darkRed: '#7b0900',
            },
        },
    },
});
