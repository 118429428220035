<template>
  <div class="form">
    <v-row>
      <!-- example schemas for development -->
      <v-col
        ><v-autocomplete
          data-cy="schema_select"
          outlined
          label="Schema Examples"
          :items="originalSchemas"
          item-text="name"
          @input="prepareSchema($event, true)"
        ></v-autocomplete
      ></v-col>
      <!-- schemas on the cloud DB -->
      <v-col
        ><v-autocomplete
          data-cy="cloud_schema_select"
          outlined
          label="DB Schemas"
          item-text="name"
          :items="schemas"
          @input="prepareSchema($event, false)"
        ></v-autocomplete
      ></v-col>
      <!-- Workflows: only for development -->
      <v-col
        ><v-autocomplete
          outlined
          label="DB Workflows"
          item-text="name"
          :items="workflows"
          @input="prepareWorkflow($event)"
        ></v-autocomplete
      ></v-col>
      <!-- Datas: only for development -->
      <v-col
        ><v-autocomplete
          outlined
          label="DB Datas"
          item-text="label"
          :items="datas"
          @input="prepareData($event)"
        ></v-autocomplete
      ></v-col>
    </v-row>
    <!-- Schema editor -->
    <v-card class="mb-10">
      <v-card-title class="text subtitle-1"
        >Schema Editor: {{ schema.label }}</v-card-title
      >
      <v-card-text>
        <editor
          data-cy="editor"
          v-model="editorSchema"
          lang="json"
          theme="clouds"
          width="80%"
          height="300"
        ></editor>
      </v-card-text>
      <v-card-actions>
        <v-btn
          data-cy="create_form"
          small
          rounded
          dark
          depressed
          color="hqsOrange"
          @click="createForm"
          >create form</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- dynamic forms for testing schemas -->
    <v-toolbar flat>
      <v-toolbar-title
        data-cy="toolbar_title"
        class="ml-n5 hqsOrange--text text"
      >
        {{ normalizedSchema.label }}
      </v-toolbar-title>
    </v-toolbar>

    <v-form ref="form" v-model="valid">
      <FormField
        v-for="(data, index) in normalizedSchema.nodes"
        :key="index"
        :valuePath="[data.name]"
        :data="data"
      >
      </FormField>

      <!-- actions -->
      <v-btn
        data-cy="clear_form"
        small
        rounded
        outlined
        depressed
        class="mt-10 mr-3"
        color="primary"
        @click="clearForm"
        >clear form</v-btn
      >
      <!-- dismiss form button -->
      <v-btn
        data-cy="dismiss_form"
        small
        class="mt-10 mr-3"
        rounded
        outlined
        depressed
        color="primary"
        @click="dismissForm"
        >dismiss form</v-btn
      >
      <!-- dismiss schema button -->
      <v-btn
        data-cy="dismiss_schema"
        small
        class="mt-10 mr-3"
        rounded
        outlined
        depressed
        color="primary"
        @click="dismissSchema"
        >dismiss schema</v-btn
      >
      <!-- Show the created data node button -->
      <v-btn
        data-cy="show_output"
        small
        class="mt-10 mr-3"
        rounded
        outlined
        depressed
        color="primary"
        @click="showResult"
        >show created data node</v-btn
      >
      <!-- test-submit button -->
      <v-btn
        data-cy="submit"
        small
        class="mt-10 mr-3"
        rounded
        outlined
        depressed
        color="primary"
        @click="submit"
        :disabled="!valid"
        >test submit</v-btn
      >
      <!-- test app structure creation button -->
      <v-btn
        small
        class="mt-10 mr-3"
        rounded
        outlined
        depressed
        color="primary"
        @click="createAppStructure"
        >create app structure</v-btn
      >
    </v-form>

    <v-dialog data-cy="dialog" v-model="showDialog" max-width="650">
      <v-card>
        <v-card-text>
          <v-row>
            <v-card elevation="0">
              <v-card-text>
                <p v-for="(field, index) in fields" :key="index">
                  The {{ field }} lacks some input. If you leave it like that it
                  will be submitted empty.
                </p>
                <pre data-cy="result_text">{{ result }}</pre>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            class="mt-10 mr-3"
            rounded
            outlined
            depressed
            color="success"
            @click="submitEmpty"
            >submit empty</v-btn
          >
          <v-btn
            small
            class="mt-10 mr-3"
            rounded
            outlined
            depressed
            color="success"
            @click="showDialog = false"
            >return to form</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as editor from 'vue2-ace-editor'
import FormField from '../components/dynamicForms/FormField.vue'
import testSchemas from '../../examples/testingSchemas'
import dynamicFormsUtils from '../store/storeUtils/dynamicFormsUtils'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'

import 'brace/ext/language_tools'
import 'brace/mode/json'
import 'brace/theme/chrome'
import 'brace/theme/clouds'
import 'brace/snippets/json'

const testingSchemas = testSchemas.schemas

export default {
  name: 'SchemaTest',

  components: {
    FormField,
    editor,
  },

  data: () => ({
    valid: false,
    required: [(v) => !!v || v === 0 || 'required'],
    result: '',
    showDialog: false,
    editorSchema: '',
    fields: [],
    hasEmpty: false,
  }),

  methods: {
    ...mapMutations('dynamicForms', [
      'setArrayValue',
      'print',
      'reset',
      'createUserInputFromSchema',
      'set',
      'normalizeSchema',
      'wipeForm',
      'createUserInputFromDataNode',
    ]),

    ...mapMutations('backend', {
      setBackendStore: 'set',
      createAppStructure: 'createAppStructure',
    }),

    ...mapActions('backend', ['create', 'loadAll']),

    dismissSchema() {
      this.setBackendStore(['schema', {}])
      this.wipeForm()
      window.location.reload()
    },

    prepareWorkflow(value) {
      let workflow = this.workflows.find((e) => e.name === value)
      this.editorSchema = JSON.stringify(workflow, null, 4)
    },

    prepareData(value) {
      let data = this.datas.find((e) => e.label === value)
      this.editorSchema = JSON.stringify(data, null, 4)
    },

    prepareSchema(value, isLocal) {
      let isJSON = false
      let schema = {}
      this.reset()
      if (isLocal) {
        schema = Object.values(testingSchemas).find(
          (schema) => schema.name === value
        )
      } else {
        schema = this.schemas.find((schema) => schema.name === value)
      }
      this.editorSchema = JSON.stringify(schema, null, 4)
      this.setBackendStore(['schema', schema])
      this.normalizeSchema([schema, isJSON])
      this.$refs.form.resetValidation()
      this.createUserInputFromSchema()
      this.set(['showChild', false])
      navigator.clipboard.writeText(JSON.stringify(this.schemas, null, 2))
      //navigator.clipboard.writeText(JSON.stringify(this.datas[0], null, 2))
      navigator.clipboard.writeText(JSON.stringify(this.workflows, null, 2))
      navigator.clipboard.writeText(JSON.stringify(this.datas, null, 2))
    },

    createForm() {
      this.setBackendStore(['schema', JSON.parse(this.editorSchema)])
      let isJSON = true
      this.wipeForm()
      this.normalizeSchema([this.editorSchema, isJSON])
      this.$refs.form.resetValidation()
      this.createUserInputFromSchema()
      this.set(['showChild', false])
    },

    showResult() {
      let result = JSON.stringify(this.getDataNode, null, 4)
      this.result = result
      this.showDialog = true
    },

    submit() {
      this.hasEmpty = false
      this.fields = []
      let [hasEmpty, isEmpty, name] = dynamicFormsUtils.checkForEmptyStrings(
        this.normalizedSchema.nodes,
        this.value
      )
      if (hasEmpty && !isEmpty) {
        this.hasEmpty = true
        this.fields.push(name)
        this.showDialog = true
      } else {
        this.getDataNode
      }
    },

    submitEmpty() {
      this.getDataNode
      this.showDialog = false
    },

    clearForm() {
      this.reset()
      this.$refs.form.resetValidation()
      this.createUserInputFromSchema()
    },

    dismissForm() {
      this.wipeForm()
    },
  },

  computed: {
    ...mapState('dynamicForms', {
      normalizedSchema: (state) => state.normalizedSchema,
      userInput: (state) => state.userInput,
      resultSchema: (state) => state.resultSchema,
    }),

    ...mapState('backend', {
      schema: (state) => state.schema,
      schemas: (state) => state.schemas,
      workflows: (state) => state.workflows,
      datas: (state) => state.datas,
    }),

    ...mapGetters('dynamicForms', ['getDataNode']),

    originalSchemas() {
      let names = []
      Object.values(testingSchemas).forEach((element) =>
        names.push(element.name)
      )
      return names
    },
  },

  async created() {
    this.editorSchema = JSON.stringify(this.schema, null, 4)
    await this.loadAll(['schema'])
    await this.loadAll(['workflow'])
    await this.loadAll(['data'])
  },

  destroyed() {
    this.setBackendStore(['schemas', []])
    this.setBackendStore(['workflows', []])
  },
}
</script>

<style scoped>
.form {
  margin: 100px 100px 100px;
}

.ace-editor .ace-chrome {
  border: 1px solid, black;
}
</style>
