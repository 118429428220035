<template>
  <div id="products">
    <a href="#products">Products</a>
    <h1>Products</h1>
    <SecondaryAppBar
      v-bind:barColor="barColor"
      :appBarTitle="appBarTitle"
    />

    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
const SecondaryAppBar = () => import('../../components/general/SecondaryAppBar')

export default {
  components: {
    SecondaryAppBar,
  },

  data() {
    return {
      barColor: 'white',
    }
  },

  methods: {
    ...mapMutations('backend', ['set', 'setInputStructureSchemasForCurrentWorkflows']),

    ...mapActions('backend', ['load'])

  },

  computed:{

    ...mapState('backend', {
        appBarTitle: (state) => state.appBarTitle,
        schemas: (state) => state.schemas,
        workflows: (state) => state.workflows,
    })
  },

  async created() {
    if(this.schemas.length === 0){
        this.load(['schema'])
    }
    if(this.workflows.length === 0){
        this.load(['workflow'])
    }
    await this.set(['appBarTitle', 'Applications'])
    if(!this.$route.name.includes('structure-dashboard')){
        await this.set(['currentWorkflows', this.workflows])
    }
    await this.setInputStructureSchemasForCurrentWorkflows()
  },
}
</script>

<style scoped>
#products {
  font-size: 0px;
}

h1 {
  color: white;
}

.toolbar-title {
  overflow: visible;
}

.second-app-bar {
  position: fixed;
  margin-top: 65px !important;
}

.content {
  overflow-y: scroll;
}

.v-toolbar__title {
  margin-left: 10px;
  width: 150px !important;
}
</style>
