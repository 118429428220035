import Vue from 'vue'
import restClient from '../../views/lib/restClientNewAPI'
import utils from '../../appUtils/utils'

const baseURL = 'https://rest-api.' + process.env.VUE_APP_SUBDOMAIN + 'cloud.quantumsimulations.de'

export default {
    namespaced: true,
    state: {
        loading: true,
        credits: 0,
        transactions: [],
    },

    getters: {

        getTransactionsTable: (state) => (jobs) => {
            let table = []
            for (let transaction of state.transactions) {
                let job = {}
                let jobLabel = ''
                let activity = ''
                if (transaction.process_id) {
                    job = jobs.find(e => e._uuid === transaction.process_id)
                    jobLabel = job ? job.label : 'deleted'
                } else {
                    jobLabel = '-'
                }
                if (transaction.activity_instance_id) {
                    activity = transaction.activity_instance_id.substring(
                        0, transaction.activity_instance_id.indexOf(':')
                    )
                    activity = utils.formatLabel(activity)
                }
                let tableItem = {
                    job: jobLabel,
                    activity: activity,
                    amount: transaction.amount,
                    comment: transaction.comment,
                    startedAt: transaction.started_at,
                    finishedAt: transaction.finished_at ? transaction.finished_at : '-',
                    id: transaction.transaction_id,
                    status: transaction.transaction_status,
                    type: transaction.transaction_type,
                }
                table.push(tableItem)
            }
            return table
        },
    },

    mutations: {
        set(state, [type, payload]) {
            Vue.set(state, type, payload)
        },
    },

    actions: {

        async loadCredits({ commit }) {
            try {
                let result = await restClient.get(`${baseURL}/credit`)
                if (Array.isArray(result.data.credit)) {
                    commit('set', ['credits', result.data.credit[0].user_balance])
                    commit('set', ['loading', false])
                } else {
                    commit('set', ['credits', result.data.credit.user_balance])
                    commit('set', ['loading', false])
                }
            } catch (error) {
                console.log('error', error)
                throw error
            }
        },

        async loadCreditTransactions({ commit }) {
            let result = []
            try {
                result = await restClient.get(`${baseURL}/transactions`)
                if (result) {
                    commit('set', ['transactions', result.data.transaction])
                }
            } catch (error) {
                console.log('error', error)
                throw error
            }
        },
    }
}
